import React, {useEffect, useState} from "react";
import {Cascader, Col, InputNumber, Mentions, notification, Segmented, Space, Switch, Tooltip} from "antd";
import {
    CalendarOutlined,
    CheckOutlined,
    CloseOutlined,
    ClusterOutlined,
    DesktopOutlined,
    FormOutlined,
    GithubFilled,
    InfoCircleOutlined,
    RedoOutlined
} from "@ant-design/icons";
import InputC from "../../components/input";
import CheckboxC from "../../components/checkbox";
import RowC from "../../components/row";
import {colors} from "../../assets/data/colors";
import {FutureDatepicker} from "../../components/datepicker/datepicker";
import ButtonC from "../../components/button";
import {getGithubBranches, getTestGithubBranch} from "../../redux/actions/github_actions";
import {useDispatch} from "react-redux";
import {useProjectContext} from "../../contexts/projectContext";
import {useCreateRunContext} from "../../contexts/createRunContext";
import {safeHandleErrorResponse} from "../../assets/helpers/errorHandler";
import {AppDispatch} from "../../redux/store";


function RunConfigSettings(): JSX.Element {
    const dispatch: AppDispatch = useDispatch()

    const {currentProject} = useProjectContext()
    const {settings, notifications} = useCreateRunContext()

    const {
        runTitle, setRunTitle, githubBranch, setGithubBranch, reruns, setReruns,
        maxParallel, setMaxParallel, useMaxParallel, setUseMaxParallel, run, browsers
    } = settings

    const {
        runOption, setRunOption, recurringOption, setRecurringOption, isRecurring, setIsRecurring,
        endDate, setEndDate, runDate, setRunDate, endRecurring
    } = run

    const {
        availableBrowsers, checkedBrowsers, setCheckedBrowsers,
        sequence, setSequence, runInterval, setRunInterval
    } = browsers

    const {custom, setCustom} = notifications

    const [browserNames, setBrowserNames] = useState<string[]>([])

    const label_column_width = "100px"
    const icon_column_width = "30px"
    const icon_style = {color: colors.green.bamboo, fontSize: 18}
    const styleHiddenRow = {display: 'flex', marginLeft: `1${icon_column_width}`}

    useEffect(() => {
        if (checkedBrowsers.length > 1) {

            setBrowserNames(availableBrowsers.flatMap(browser => {
                if (checkedBrowsers.some(arr => JSON.stringify(arr) === JSON.stringify([browser.value]))) {
                    return browser.label
                }
                return []
            }))
        }
    }, [checkedBrowsers])

    const handleFetchBranches = () => {
        dispatch(getGithubBranches({useCache:false}))
            .then((response:any)=>{
                notification.warning({
                    placement: 'topRight',
                    message: 'Bravo Christo',
                    description: response
                    ,
                    duration: 20
                })
                console.log(response)
            })
            .catch((err:any) => { safeHandleErrorResponse(err) })
    }

    const handleTestBranch = () => {
        dispatch(getTestGithubBranch({branch:githubBranch}))
            .then((response:any)=>{
                notification.warning({
                    placement: 'topRight',
                    message: 'Bravo Christo',
                    description: response
                    ,
                    duration: 20
                })
                console.log(response)
            })
            .catch((err:any) => { safeHandleErrorResponse(err) })
    }

    const onSelect = () => {
    };

    const handleChangeReruns = (number:number | null) => {
        if(number){
            setReruns(number)

        }else{
            const notificationsNeedsConfirmation = custom.filter((notification)=> notification.trigger.before === 2 || notification.trigger.after === 2)
            const totalNNC = notificationsNeedsConfirmation.length
            if(totalNNC>0){
                const confirmation = window.confirm(`The following custom notification${totalNNC>1?'s':''} "${notificationsNeedsConfirmation.map(notification=>notification.title).toString()}" will change from 'At every iteration to once'`)
                if(confirmation){
                    setCustom(oldState=>oldState.map((notification)=>({
                        ...notification,
                        trigger: {
                            ...notification.trigger,
                            after: notification.trigger.after === 2 ? 1 : notification.trigger.after,
                            before: notification.trigger.before === 2 ? 1 : notification.trigger.before,
                        }
                    })))
                    setReruns(number)
                }
            }else{
                setReruns(number)
            }
        }
    }

    return (
        <Space direction="vertical" size="middle" style={{display: 'flex'}}>
            <RowC>
                <Col flex={icon_column_width}> <FormOutlined style={icon_style}/></Col>
                <Col flex={label_column_width}>Title</Col>
                <Col flex="auto">
                    <Mentions
                        style={{width: 250}}
                        onChange={(e) => {
                            setRunTitle(e)
                        }}
                        prefix='{{'
                        onSelect={onSelect}
                        value={runTitle}
                        options={[
                            {
                                value: 'DATE}}',
                                label: 'DATE',
                            },
                            {
                                value: 'USER}}',
                                label: 'USER',
                            },...(currentProject.info.project_type === 1? [{
                                value: 'BROWSER}}',
                                label: 'BROWSER',
                            }]: []),...(currentProject.info.project_type !== 2? [{
                                value: 'BRANCH}}',
                                label: 'BRANCH',
                            }]: [])
                        ]}
                    />
                </Col>
            </RowC>
            {currentProject.info.project_type !== 2 &&
            <RowC>
                <Col flex={icon_column_width}> <GithubFilled style={icon_style}/></Col>
                <Col flex={label_column_width}>Github Branch
                    <br/>
                    <
                        div>
                        <ButtonC onClick={handleFetchBranches} size={'small'} type={'text'} text={<div className='button-resize-small'>Fetch</div>}/>
                        <ButtonC onClick={handleTestBranch} size={'small'} type={'text'} text={<div className='button-resize-small'>Test</div>}/>
                    </div>
                </Col>
                <Col flex="auto">
                    <InputC
                        value={githubBranch}
                        onChange={(e) => setGithubBranch(e.target.value)}
                        placeholder='Branch name'
                        style={{width: 250}}/>
                </Col>
            </RowC>}
            <RowC>
                <Col flex={icon_column_width}> <CalendarOutlined style={icon_style}/></Col>
                <Col flex={label_column_width}>Run</Col>
                <Col flex="auto">
                    <Segmented
                        options={['Now', 'Manually', 'Scheduled']}
                        value={runOption}
                        onChange={setRunOption}
                    />
                    <Tooltip
                        placement="right"
                        title={
                            <>
                                <h3>{runOption}</h3>
                                {
                                    runOption === 'Now' ?
                                        <p>Starts the execution immediately after the Run Creation.</p> : null
                                }
                                {
                                    runOption === 'Manually' ?
                                        <p>Saves the Run after the Run Creation but the execution will not start.
                                            You should start the execution manually when you desire.</p> : null
                                }
                                {
                                    runOption === 'Scheduled' ?
                                        <p>Provides the desired execution Schedule Plan.</p> : null
                                }
                            </>
                        }
                        color={colors.green.bamboo}
                    >
                        <InfoCircleOutlined style={Object.assign({}, {marginLeft: 15}, icon_style)}/>
                    </Tooltip>
                </Col>
            </RowC>
            {
                runOption === 'Now' &&
                <Space direction="vertical" size="large" style={styleHiddenRow}>
                </Space>
            }
            {
                runOption === 'Manually' &&
                <Space direction="vertical" size="large" style={styleHiddenRow}>
                </Space>
            }
            {
                runOption === 'Scheduled' ?
                    <Space direction="vertical" size="large" style={styleHiddenRow}>
                        <RowC>
                            <Col flex={label_column_width}>Select Date:</Col>
                            <Col flex="auto">
                                <FutureDatepicker
                                    selectedDate={runDate}
                                    setSelectedDate={setRunDate}/>
                            </Col>
                        </RowC>
                        <RowC>
                            <Col flex={label_column_width}>Recurring?</Col>
                            <Col flex="auto">
                                <Switch
                                    onChange={() => setIsRecurring((prevState) => !prevState)}
                                    checked={isRecurring}
                                    checkedChildren={<CheckOutlined/>}
                                    unCheckedChildren={<CloseOutlined/>}
                                />
                            </Col>
                        </RowC>
                        {
                            isRecurring ?
                                <>
                                    <RowC>
                                        <Col flex={label_column_width}>Run Every</Col>
                                        <Col flex="auto">
                                            <Segmented
                                                value={recurringOption}
                                                onChange={setRecurringOption}
                                                options={['Day', 'Week', 'Month']}/>
                                        </Col>
                                    </RowC>
                                    {/*<RowC>*/}
                                    {/*    <Col flex={label_column_width}>End Recurring?</Col>*/}

                                    {/*    <Col flex="auto">*/}
                                    {/*        <Switch*/}
                                    {/*            onChange={() => {*/}
                                    {/*                setEndRecurring((prevState) => (!prevState));*/}
                                    {/*            }}*/}
                                    {/*            checked={endRecurring}*/}
                                    {/*            checkedChildren={<CheckOutlined/>}*/}
                                    {/*            unCheckedChildren={<CloseOutlined/>}*/}
                                    {/*        />*/}
                                    {/*    </Col>*/}
                                    {/*</RowC>*/}
                                    {
                                        endRecurring &&
                                        <RowC>
                                            <Col flex={label_column_width}>End Date:</Col>
                                            <Col flex="auto">
                                                <FutureDatepicker
                                                    selectedDate={endDate}
                                                    setSelectedDate={setEndDate}/>
                                            </Col>
                                        </RowC>
                                    }
                                </>
                                : null
                        }
                    </Space>
                    : null
            }
            {
                currentProject.info.project_type === 1 &&
                <RowC>
                    <Col flex={icon_column_width}> <DesktopOutlined style={icon_style}/></Col>
                    <Col flex={label_column_width}>Browsers</Col>
                    <Col flex="auto">
                        <Cascader
                            style={{width: 250}}
                            value={checkedBrowsers}
                            options={availableBrowsers}
                            onChange={setCheckedBrowsers}
                            placeholder="Select Browsers"
                            multiple={true}/>

                    </Col>
                </RowC>
            }
            {
                checkedBrowsers.length > 1 && runOption !== "Manually" ?
                    <Space direction="vertical" size="middle" style={styleHiddenRow}>
                        <RowC>
                            <Col flex={label_column_width}>Sequence</Col>
                            <Col flex="auto">
                                <Segmented
                                    value={sequence}
                                    onChange={setSequence}
                                    options={['Continuously', 'In Parallel']}/>
                                <Tooltip
                                    placement="right"
                                    title={
                                        <>
                                            <h3>{sequence}</h3>
                                            {
                                                sequence === 'Continuously' ?
                                                    <p>The run for <b>{browserNames[1]} </b>
                                                        will start as soon as the <b>{browserNames[0]} </b>
                                                        is completed </p> :
                                                    <p> Eg. The run for <b>{browserNames[0]}</b> will
                                                        start {runOption ? 'now' : runDate ? 'run.runDate' : '{On Selected Day}'}.
                                                        The run for <b>{browserNames[1]}</b> will
                                                        start {runInterval} minutes later </p>
                                            }
                                        </>
                                    }
                                    color={colors.green.bamboo}
                                >
                                    <InfoCircleOutlined style={Object.assign({}, {marginLeft: 15}, icon_style)}/>
                                </Tooltip>
                            </Col>
                        </RowC>
                        {
                            sequence === 'Continuously' ? null :
                                <RowC>
                                    <Col flex={label_column_width}>Interval</Col>
                                    <Col flex="auto">
                                        <InputNumber
                                            min={0}
                                            keyboard
                                            style={{width: 70}}
                                            defaultValue={runInterval}
                                            onChange={e => setRunInterval(e ? e : 0)}/> Minutes
                                    </Col>
                                </RowC>
                        }
                    </Space>
                    : null
            }
            {currentProject.info.project_type !== 2 &&
            <RowC>
                <Col flex={icon_column_width}> <RedoOutlined style={icon_style}/></Col>
                <Col flex={label_column_width}>Reruns</Col>
                <Col flex="auto">
                    <InputNumber min={0}
                                 keyboard
                                 style={{width: 60}}
                                 value={reruns}
                                 onChange={handleChangeReruns}/>
                </Col>
            </RowC>}
            {currentProject.info.project_type !== 2 &&
            <RowC>
                <Col flex={icon_column_width}> <ClusterOutlined style={icon_style}/></Col>
                <Col flex={label_column_width}>Parallel</Col>
                <Col flex="auto">
                    <CheckboxC checked={useMaxParallel}
                               onChange={() => setUseMaxParallel(!useMaxParallel)}
                               label='max'/>
                    <InputNumber min={1}
                                 keyboard
                                 value={maxParallel < 0 ? 1 : maxParallel}
                                 onChange={setMaxParallel}
                                 disabled={useMaxParallel}
                                 defaultValue={maxParallel < 0 ? 1 : maxParallel}/>
                </Col>
            </RowC>}
        </Space>
    )
}

export default RunConfigSettings