import React, {Dispatch, SetStateAction, useMemo, useState} from 'react';
import {Select, Space} from "antd";
import debounce from "lodash/debounce";
import {SelectProps} from "rc-select/lib/Select";
import ButtonC, {Buttons} from "../components/button";

interface selectMultipleProps {
    style?: React.CSSProperties,
    selectedOptions: string[],
    setSelectedOptions: Dispatch<SetStateAction<string[]>>,
    options: SelectProps[],
    setSearch: Dispatch<SetStateAction<string>>
    // size: SizeType
}

interface selectSingleProps {
    style?: React.CSSProperties,
    selectedOption: any,
    setSelectedOption: Dispatch<SetStateAction<any>>,
    options: SelectProps[],
    setSearch: Dispatch<SetStateAction<string>>
    // size: SizeType
}

interface TPTextToSingleSelectProps{
    select: selectSingleProps,
    button?: {
        text?: string,
        style?: React.CSSProperties
    },
    onOK: (e: any) => void
}

export function TPSelectMultiple({selectedOptions, setSelectedOptions, style, options, setSearch}: selectMultipleProps): JSX.Element {
    /*
    * Default with backend Search
    * */

    const debouncedSetSearch = useMemo(() => debounce((input) => {
        setSearch(input);
    }, 500), []);

    return <Select
        mode="multiple"
        size='large'
        placeholder="Type to search .."
        optionLabelProp="label"
        filterOption={false}

        style={style}

        value={selectedOptions}
        onChange={setSelectedOptions}
        options={options}
        onSearch={debouncedSetSearch}
    />
}

export function TPSelectSingle({selectedOption, setSelectedOption, style, options, setSearch}: selectSingleProps): JSX.Element {
    /*
    * Default with backend Search
    * */

    const debouncedSetSearch = useMemo(() => debounce((input) => {
        setSearch(input);
    }, 500), []);

    return <Select
        showSearch
        size='large'
        placeholder="Type to search .."
        optionLabelProp="label"
        filterOption={false}

        style={style}

        value={selectedOption}
        onChange={setSelectedOption}
        options={options}
        onSearch={debouncedSetSearch}
    />
}

export function TPTextToSingleSelect({select, button, onOK}:TPTextToSingleSelectProps):JSX.Element{
    const [isOnEditMode, setIsOnEditMode] = useState<boolean>(false)

    const reset = () => {
        setIsOnEditMode(false)
    }

    return isOnEditMode? <Space>
            <TPSelectSingle {...select}/>
            <Buttons.ButtonsSaveOnEdit onClick={onOK} reset={reset} />

    </Space>
        :<ButtonC
            onClick={() => {
                setIsOnEditMode(true)
            }}
            type='text'
            text={button && button.text ?
                button.text
                :select.selectedOption ?
                    `${select.selectedOption}`
                    : 'Empty'}
            style={button?.style}
        />
}