import React, {useEffect, useState} from 'react';
import {AppDispatch} from "../../redux/store";
import {useDispatch} from "react-redux";
import {Avatar, Col, Divider, Dropdown, List, Mentions, Space, Spin, Table, Tag, Tooltip} from "antd";
import {
    CommentOutlined,
    DeleteOutlined,
    DislikeOutlined,
    DownOutlined,
    LikeOutlined,
    MonitorOutlined,
    MoreOutlined,
    RedoOutlined
} from "@ant-design/icons";
import ButtonC, {CommentButton} from "../../components/button";
import {colors} from "../../assets/data/colors";
import {RunCases} from "../../models/entities/batches";
import {PageResource} from "../../models/dtos/page_resource";
import {run_statuses} from "../../assets/data/run";
import {
    deleteCommentRunCase,
    deleteRunCases,
    getRunCaseComments,
    postRunCasesComment
} from "../../redux/actions/batch_actions";
import CancelAction from "../../components/notification/cancel_req";
import {Comment} from "../../models/entities/comment";
import RowC from "../../components/row";
import {deleteFromArray} from "../../assets/helpers/helper";
import {TablePaginationConfig} from "antd/es/table/interface";
import {getProjectUsers} from '../../redux/actions/project_actions';
import {MassCommentModal} from '../../components/modals/mass_comment_modal';
import { safeHandleErrorResponse } from '../../assets/helpers/errorHandler';
import {useUserContext} from "../../contexts/userContext";
import {useProjectContext} from "../../contexts/projectContext";
import {useOrgContext} from "../../contexts/orgContext";
import {createEmptyPage} from "../../services/utils/PageResourceUtils";


interface BatchTableProps {
    tablePage: PageResource<RunCases>,
    setTablePage: any,
    handleStatus: (ids: number[], status: number, resolved_comment: string) => void
    paginationProps?: TablePaginationConfig,
    loading: boolean
}

interface DataType {
    key: React.Key,
    title: React.ReactNode,
    dataIndex: string,
    width: string,
    align?: any
}

interface CommentPageProps {
    comment: string,
    mentions: number[],
    visible: boolean,
    loading: boolean,
    commentPage: PageResource<Comment>
}

interface CommentState {
    [key: number]: CommentPageProps
}


function BatchTable({tablePage, setTablePage, handleStatus, loading, paginationProps}: BatchTableProps): JSX.Element {
    const dispatch: AppDispatch = useDispatch();

    const [checkedCases, setCheckedCases] = useState<any[]>([])
    const [newComments, setNewComments] = useState<CommentState>({})
    const [visible, setVisible] = useState<boolean>(false)
    const [projectUsers, setProjectUsers] = useState<{key: string, value: string, label: string}[]>([]);
    const {loggedInUser} = useUserContext()
    const {currentOrg} = useOrgContext()
    const {currentProject} = useProjectContext()

    const mentions: any = [];

    const org_id = currentOrg.info.id
    const project_id = currentProject.info.id

    const selected_cases: number = checkedCases ? checkedCases.length : 0

    const deleteSelectedCases = () => {
        dispatch(deleteRunCases(checkedCases)).then(() => {
            let new_table = {...tablePage}
            new_table.content = new_table.content.filter(item => !checkedCases.includes(item.row_id))
            setTablePage(new_table)
            setCheckedCases([])
        }).catch((err) => {
            safeHandleErrorResponse(err)
        })
    }

    const handleCheckedOnDelete = (ids: number[], status: number, resolved_comment: string) => {
        handleStatus(ids, status, resolved_comment)
        let temp_selected = [...checkedCases]
        temp_selected = temp_selected.filter(item => !ids.includes(item));
        setCheckedCases(temp_selected)
    }

    const columns: DataType[] = [
        {title: 'ID', dataIndex: 'id', key: 'id', width: '7%', align: 'center'},
        {title: 'P.', dataIndex: 'priority', key: 'priority', width: '3%', align: 'center'},
        {title: 'Title', dataIndex: 'title', key: 'title', width: '50%'},
        {title: 'Reliability', dataIndex: 'reliability', key: 'reliability', width: '10%', align: 'center'},
        {title: 'Assignee', dataIndex: 'assignee', key: 'assignee', width: '10%', align: 'center'},
        {title: 'Status', dataIndex: 'status', key: 'status', width: '10%', align: 'center'},
        {
            title: <>
                <Dropdown
                    disabled={selected_cases === 0}
                    menu={{
                        items: [{
                            key: `pass`,
                            label: <><LikeOutlined/> Pass</>,
                            onClick: () => {
                                handleCheckedOnDelete(checkedCases, 1, "")
                            }
                        }, {
                            key: `fail`,
                            label: <><DislikeOutlined/> Fail</>,
                            onClick: () => {
                                handleCheckedOnDelete(checkedCases, 0, "")
                            }

                        }, {
                            key: `unresolved`,
                            label: <><MonitorOutlined/> Not Resolved</>,
                            onClick: () => {
                                handleCheckedOnDelete(checkedCases, 3, "")
                            }

                        }, {
                            key: `untested`,
                            label: <><MonitorOutlined/> Untested</>,
                            onClick: () => {
                                handleCheckedOnDelete(checkedCases, 2, "")
                            }

                        }, {
                            key: 'comment',
                            label: (
                                <>
                                    <CommentOutlined /> Mass Comment
                                </>
                            ),
                            onClick: () => {setVisible(true)}
                        }, ...(currentProject.info.project_type === 2 ? [] : [{
                            key: `rerun`,
                            label: <><RedoOutlined /> Rerun</>
                        }]), {
                            key: `mass-delete`,
                            label: <CancelAction secs={2}
                                                 action={deleteSelectedCases}><DeleteOutlined/> Delete</CancelAction>,
                        }]
                    }}
                >
                    <a>
                        <Space>
                            Actions
                            <DownOutlined/>
                        </Space>
                    </a>
                </Dropdown>
                <div style={{fontSize: 11}}>selected {selected_cases}</div>
            </>, dataIndex: 'actions', key: 'actions', width: '10%', align: 'center'
        }
    ]

    const init_case_comment = (): CommentPageProps => {
        /*
        * TODO move this to empty entities
        * */
        return {
            comment: '', mentions: [], visible: true, loading: true, commentPage: createEmptyPage()
        }
    }

    const finalize_comment = (pop_comments: CommentPageProps, case_id: number) => {
        let all_comments = {...newComments}
        all_comments[case_id] = pop_comments

        setNewComments(all_comments);
    }

    const updateBadgeNumber = (case_id:number, increase=true) => {
        let temp_data = {...tablePage}
        const row: RunCases | undefined = temp_data.content.find(item => item.test.id === case_id)
        if (row) {
            const rowIndex = temp_data.content.indexOf(row)
            if(increase){
                row.test.comments += 1
            }else{
                row.test.comments -= 1
            }
            temp_data.content[rowIndex] = row
            setTablePage(temp_data)
        }
    }

    const handleCommentsButton = (comments: CommentPageProps, run_id: number, case_id: number, offset = 0) => {

        if (!comments) {
            dispatch(getRunCaseComments(run_id, case_id, offset))
                .then(foundPage => {
                    let t_comments = init_case_comment()
                    t_comments.commentPage = foundPage
                    t_comments.loading = false
                    finalize_comment(t_comments, case_id)
                }).catch((err) => {
                    safeHandleErrorResponse(err)
                })
        }
        else if (comments && offset>0){
            dispatch(getRunCaseComments(run_id, case_id, offset))
                .then(foundPage => {
                    let temp_new_comments = {...newComments}
                    temp_new_comments[case_id].commentPage.content = [...temp_new_comments[case_id].commentPage.content, ...foundPage.content]

                    setNewComments(temp_new_comments)
                }).catch((err) => {
                    safeHandleErrorResponse(err)
                })
        }
    }

    const handleDeleteCommentRunCase = (index: number, comment_id:number, case_id:number) =>{
        dispatch(deleteCommentRunCase(comment_id))
            .then(()=>{
                let temp_new_comments = {...newComments}
                // t_comments.commentPage.content.indexOf()
                temp_new_comments[case_id].commentPage.content = deleteFromArray(temp_new_comments[case_id].commentPage.content, index)

                setNewComments(temp_new_comments)

                // update badge number
                updateBadgeNumber(case_id, false)

            }).catch((err) => {
                safeHandleErrorResponse(err)
            })
    }

    const handlePostComment = (t_comments: CommentPageProps, run_id: number, case_id: number) => {
        const post_comment = newComments[case_id].comment
        const data = {
            run_id,
            case_ids: [case_id],
            comment: post_comment,
            project_id: project_id,
            org_id: org_id
        }
        dispatch(postRunCasesComment(data))
            .then(result => {
                t_comments.comment = ''
                t_comments.commentPage.content = [{
                    commentator: loggedInUser,
                    id: result.content,
                    created_on: new Date().toLocaleString('en-US', {
                        year: 'numeric',
                        month: 'short',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                    }),
                    comment: post_comment
                }, ...t_comments.commentPage.content]

                // update newComments
                let temp_new_comments = {...newComments}
                temp_new_comments[case_id] = t_comments

                setNewComments(temp_new_comments)

                // update badge number
                updateBadgeNumber(case_id)
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })
    }

    const handleCommentText = (pop_comments: CommentPageProps, case_id: number, event: string) => {
        if (!pop_comments) {
            pop_comments = init_case_comment()
        }

        pop_comments.comment = event

        finalize_comment(pop_comments, case_id)
    }

    useEffect(() => {
        const fetchData = () => {
            dispatch(getProjectUsers()).then(users => {
                let data: {key: string, value: string, label: string}[] = []
                users.content.forEach(user => {
                    data.push({key: user.id.toString(), value: user.name + ' ' + user.lastname, label: user.name + ' ' + user.lastname})
                });
                setProjectUsers(data)
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })
        }
        fetchData()
    }, [dispatch])


    const table_data: any = Array.isArray(tablePage.content) ? tablePage.content.map(item => {
        if (item && item.run && item.test && item.test.title) {
            const status = run_statuses[item.run.status];
            const test_id = item.test.id;
            const t_comments = newComments[test_id]

            return {
                key: item.row_id,  // case id
                id: test_id,  // case id
                title: <Tooltip arrow title={item.test.path}>{item.test.title}</Tooltip>,  // case title
                status:
                    item.run.state === 2 ?
                        <Spin tip="Running Now..." style={{color: colors.blue.dark}} size="small">
                            <Tag color={status.color}>{status.title}</Tag>
                        </Spin> :
                        <Tag onClick={() => {
                        }} color={status.color}>{status.title}</Tag>,
                priority: item.test.priority,
                assignee: <Tooltip arrow title={item.assignee.fullname}>
                    <Avatar size={'small'} style={{backgroundColor: colors.blue.dark}}>{item.assignee.initials}</Avatar>
                </Tooltip>,
                reliability: <> {100 - test_id}%</>,
                actions: <Space>
                    <ButtonC disabled={item.run.status === 1 || item.run.state === 2} size={'small'} shape={'circle'}
                             icon={<LikeOutlined/>} onClick={() => {
                        handleCheckedOnDelete([item.row_id], 1, "")
                    }}/>
                    <ButtonC disabled={item.run.status === 0 || item.run.state === 2} size={'small'} shape={'circle'}
                             icon={<DislikeOutlined/>} onClick={() => {
                        handleCheckedOnDelete([item.row_id], 0, "")
                    }}/>
                    {
                        <CommentButton
                            comments={item.test.comments}
                            content={<div style={{maxHeight: 450, overflowY: 'auto', paddingRight: 15}}>
                                <Mentions
                                    rows={4}
                                    placeholder="You can use @ to ref user here"
                                    value={t_comments === undefined ? '' : t_comments.comment}
                                    onChange={(e) => {
                                        handleCommentText(t_comments, test_id, e)
                                    }}
                                    onSelect={(e) => {
                                        mentions.push(e)
                                    }}
                                    options={projectUsers}
                                />
                                <Space style={{float: 'right', marginTop: 10}}>
                                    <ButtonC
                                        disabled={t_comments === undefined || t_comments.comment.length === 0}
                                        type={"primary"}
                                        onClick={() => handlePostComment(t_comments, item.run.id, test_id)}
                                        text={'Comment'}/>
                                </Space>
                                <Divider style={{marginTop: 55}}/>
                                {
                                    t_comments === undefined || t_comments.loading ?
                                        <Spin style={{marginLeft: '48%'}}/> :
                                        <>
                                            <List
                                                itemLayout="horizontal"
                                                dataSource={t_comments.commentPage.content}
                                                renderItem={(comment_item: Comment, index) => (
                                                    <List.Item>
                                                        <List.Item.Meta
                                                            avatar={<a
                                                                href={`/${currentOrg.info.domain}/projects/${currentProject.info.p_key}/users/${comment_item.commentator.id}/view`}
                                                                onMouseOver={(e: any) => e.target.style.color = 'black'}
                                                                onMouseOut={(e: any) => e.target.style.color = 'white'}><Avatar
                                                                // src={`https://api.dicebear.com/7.x/miniavs/svg?seed=${index}`}
                                                            >{comment_item.commentator.name.charAt(0) + comment_item.commentator.lastname.charAt(0)}
                                                            </Avatar></a>}
                                                            title={<RowC style={{paddingBottom: 10}}>
                                                                <Col
                                                                    flex={'auto'}>{comment_item.commentator.lastname} {comment_item.commentator.name}<br/>
                                                                    <div style={{
                                                                        fontSize: 11,
                                                                        fontWeight: 'normal',
                                                                        opacity: '0.75'
                                                                    }}>{comment_item.created_on}</div>
                                                                </Col>
                                                                <Col>
                                                                    {
                                                                        comment_item.commentator.id === loggedInUser.id ?
                                                                            <Dropdown
                                                                                menu={{
                                                                                    items: [{
                                                                                        key: 'delete',
                                                                                        label:
                                                                                            <div
                                                                                                onClick={() => handleDeleteCommentRunCase(index, comment_item.id, item.test.id)}>
                                                                                                <DeleteOutlined/> Delete
                                                                                            </div>
                                                                                    }]
                                                                                }}
                                                                                trigger={['click']}
                                                                                arrow>
                                                                                <ButtonC
                                                                                    onClick={() => {
                                                                                    }}
                                                                                    icon={<MoreOutlined
                                                                                        style={{color: colors.green.forest}}/>}
                                                                                    size='small'
                                                                                    shape='circle'
                                                                                />
                                                                            </Dropdown>
                                                                            : null
                                                                    }
                                                                </Col>
                                                            </RowC>
                                                            }
                                                            description={
                                                                <div
                                                                    dangerouslySetInnerHTML={{__html: comment_item.comment.replace(/\n/g, '<br />')}}/>
                                                            }
                                                        />
                                                    </List.Item>

                                                )}
                                            />
                                            {
                                                t_comments.commentPage.content.length < item.test.comments ?
                                                    <ButtonC style={{marginLeft: '35%', marginTop: 15}}
                                                             type='primary'
                                                             text={'Load More'}
                                                             onClick={() => {
                                                                 handleCommentsButton(t_comments, item.run.id, test_id, t_comments.commentPage.content.length)
                                                             }}/>
                                                    : null
                                            }
                                        </>

                                }

                            </div>}
                            onClick={() => handleCommentsButton(t_comments, item.run.id, test_id)}
                        />

                    }

                </Space>
            }
        }
    }) : [];

    return <>
    <Table
        style={{marginRight: 25}}
        columns={currentProject.info.project_type !== 2 ? columns : columns.filter(column => column.dataIndex !== 'reliability')}
        rowSelection={{
            selectedRowKeys: checkedCases,
            onChange: (keys) => {
                setCheckedCases(keys)
            }
        }}
        loading={loading}
        dataSource={table_data}
        pagination={{
            position: ['topRight'],
            size: 'small',
            total: tablePage.totalElements,
            showSizeChanger: true,
            pageSizeOptions: [15, 30, 50],
            ...paginationProps
        }}  // TODO: get total from the back along with pagination
    />
    <MassCommentModal visible={visible} setVisible={setVisible} 
                      page={tablePage} setPage={setTablePage}
                      checkedCases={checkedCases} setCheckedCases={setCheckedCases}
                      newComments={newComments} setNewComments={setNewComments}
                      projectUsers={projectUsers} setProjectUsers={setProjectUsers}/>
    </>
}

export default BatchTable;
