import { fetchErrorAction, fetchStartAction, fetchSuccessAction } from "./creators/common_actions";
import { AppDispatch } from "../store";
import { PageResource } from "../../models/dtos/page_resource";
import { CalendarRuns } from "../../models/entities/calendar";
import {getWithProjectWrapper} from '../../services/axiosWrapper';
const api_path = '/api/run/';

export const getCalendarMonthRuns = (params: {year: number, month: string}) => (dispatch: AppDispatch): Promise<PageResource<CalendarRuns>> => {

    dispatch(fetchStartAction());

    const request: any = getWithProjectWrapper(`${api_path}getCalendarMonthRuns`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}