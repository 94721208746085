import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {Button, Form, Input, notification, Select} from 'antd';
import {PageResource} from "../../models/dtos/page_resource";
import {Project, ProjectTypes} from "../../models/entities/project";
import {createProject, getOrgProjectKeys, getProjectTypes} from '../../redux/actions/project_actions';
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../redux/store";
import ModalC from "../modal";
import { safeHandleErrorResponse } from "../../assets/helpers/errorHandler";
import {useOrgContext} from "../../contexts/orgContext";


type ProjectProps = {
    visible: boolean;
    setVisible: Dispatch<SetStateAction<boolean>>;
    setProjectPage: Dispatch<SetStateAction<PageResource<Project>>>;
    projectPage: PageResource<Project>
}

const {Option} = Select;

export function ProjectModal(props: ProjectProps): JSX.Element {
    const {setVisible, visible, setProjectPage, projectPage} = props;

    const default_validation = {name:true, key:true}

    const dispatch: AppDispatch = useDispatch();
    const {currentOrg} = useOrgContext();

    const [loading, setLoading] = useState<boolean>(false);
    const [name, setName] = useState<string>("");
    const [projectKey, setProjectKey] = useState<string>("");
    const [validations, setValidations] = useState<{name:boolean, key:boolean}>(default_validation);
    const [projectKeys, setProjectKeys] = useState<string[]>([]);
    const [isDirtyKey, setIsDirtyKey] = useState<boolean>(false);
    const [savedProjectTypes, setSavedProjectTypes] = useState<ProjectTypes[]>([]);
    const [projectType, setProjectType] = useState<number>();

    const [hasModalBeenOpened, setHasModalBeenOpened] = useState(false);

    const org_id = currentOrg.info.id

    useEffect(() => {
        if (visible && !hasModalBeenOpened) {
            setHasModalBeenOpened(true); // Mark that the modal has been opened
        }
    }, [visible, hasModalBeenOpened]);

    useEffect(() => {
        if(hasModalBeenOpened){
            dispatch(getOrgProjectKeys())
                .then(keys => {
                    setProjectKeys(keys)
                }).catch((err) => {
                    safeHandleErrorResponse(err)
                })
        }
    }, [hasModalBeenOpened])


    useEffect(() => {
        dispatch(getProjectTypes())
            .then(settings => {
            setSavedProjectTypes(settings)
        }).catch((err) => {
            safeHandleErrorResponse(err)
        })
    }, [hasModalBeenOpened])

    const handleOk = () => {
        // setJobsPage(new_job_page_after_save)

        const new_project = {
            name: name,
            project_key: projectKey,
            test_cases: 0,
            org_id: org_id,
            icon: "/icons/default_project.png",
            project_type: projectType ? projectType : 1
        }
        const isNameValid = name.length > 2
        const isKeyValid = projectKey.length > 0 && projectKey.length < 6
        // useEffect(() => {dispatch(createProject(new_project, projectPage.content))})
        // axios.post('/api/project/create', new_project)
        if (!isNameValid || !isKeyValid) {
            setValidations({name:isNameValid, key:isKeyValid})
        } else {
            setLoading(true)
            setValidations(default_validation)

            dispatch(createProject(new_project, projectPage.content))
                .then(foundPage => {
                    projectPage.content = foundPage;
                    const new_project = foundPage[foundPage.length - 1]
                    setProjectPage(projectPage)
                    notification.success({
                        message: `New Project - ${new_project.name}`,
                        description: <>Click <a
                            href={`/${currentOrg.info.domain}/projects/${new_project.p_key}/manage/settings`}>here</a> to
                            configure your new project</>,
                    })
                }).catch((err) => {
                    safeHandleErrorResponse(err)
                })
            setName('');
            setProjectKey('')

            setTimeout(() => {
                setLoading(false)
                setVisible(false)
            }, 500);
        }

    };

    const handleSetName = (e: React.ChangeEvent<HTMLInputElement>) => {
        let title: string = e.target.value
        setName(title)

        if(!isDirtyKey){
            let project_key = title.split(' ')
                .map(word => word.charAt(0))
                .join('')
                .toUpperCase()

            if (project_key.length >= 10) {
                project_key = project_key.substring(0, 10)
            }
            setProjectKey(project_key)
        }
    }

    const handleSetKey = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const onlyLetters = /^[a-zA-Z]+$/

        if ((value === '' || onlyLetters.test(value)) && value.length<6) {
            setProjectKey(value.toLocaleUpperCase());
            setIsDirtyKey(true)
        }
    }

    const handleCancel = () => {
        setVisible(false)
        setName('')
        setProjectKey('')
    };

    const handleUpdateProjectTypeChange = async (event: number) => {
        setProjectType(event)
    };

    const constructProjectTypes = () => {
        return savedProjectTypes.map(project_type => {
            return <Option value={project_type.id} label={project_type.id}>
                {project_type.title}
            </Option>
        });
    }

    return (
        <ModalC open={visible}
                title="Create New Project"
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button key="Cancel" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button
                        key="submit"
                        type="primary"
                        loading={loading}
                        onClick={handleOk}
                        disabled={!projectKeys || projectKeys.includes(projectKey) || projectKey === '' || name === '' || projectType === undefined}>
                        Create
                    </Button>
                ]}
        >
            <Form>
            {projectKeys.includes(projectKey) && <span style={{ color: '#F6C324', marginTop: 5 }}>The specific key is already in use. Please choose another one.</span>}
                <Form.Item label="Project Title"
                           extra={validations.name ? null : 'Project must have at least 3 characters'}
                           validateFirst={false}
                           validateStatus={validations.name ? 'validating' : 'error'}>
                    <Input value={name}
                           onChange={handleSetName}
                           placeholder="Enter Project Title"/>
                </Form.Item>
                <Form.Item label="Project Key"
                           extra={validations.key ? null : 'Project must have 1-5 characters'}
                           validateStatus={validations.key ? 'validating' : 'error'}>
                    <Input value={projectKey}
                           onChange={handleSetKey}
                           placeholder="Enter Project Title"/>
                </Form.Item>
                <Form.Item label="Project Type">
                <Select onChange={handleUpdateProjectTypeChange} 
                        placeholder="Choose Project Type">
                        {constructProjectTypes()}
                </Select>
                </Form.Item>
            </Form>

        </ModalC>
    )
}