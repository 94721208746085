import React, {createContext, useContext, useEffect, useRef, useState} from 'react';
import {Outlet, useNavigate, useParams} from 'react-router-dom';
import {AppDispatch} from "../redux/store";
import {useDispatch} from "react-redux";
import LcsManager from '../assets/local_storage/lcs';
import {hasOnlyNumbers} from "../assets/helpers/helper";
// models
import { TeamProviderModel } from "../models/entities/team";
// empty entities
import { empty_team } from "../services/EmptyEntities/EmptyTeam";
import {getOrgTeams} from "../redux/actions/team_actions";
import {useOrgContext} from "./orgContext";

const lcs_manager = new LcsManager();

const set_team_to_lcs = (team: TeamProviderModel) => {
    lcs_manager.set_item_to_lcs(lcs_manager.Keys.current_team, team);
};

const lcsTeam = lcs_manager.get_item_from_lcs(lcs_manager.Keys.current_team);

interface TeamContextProps {
    currentTeam: TeamProviderModel;
    setCurrentTeam: (team: TeamProviderModel) => void;
}

const defaultValue: TeamContextProps = {
    currentTeam: lcsTeam
        ? lcsTeam
        : {
            info: empty_team,
            settings: {},
            userPermissions: {
                manage: false
            }
        },
    setCurrentTeam: set_team_to_lcs,
};

const TeamContext = createContext<TeamContextProps>(defaultValue);

export const TeamProvider: React.FC = () => { // Fix: Remove empty brackets
    const dispatch: AppDispatch = useDispatch()
    const navigate = useNavigate()

    const {currentOrg} = useOrgContext()

    const {team_id}  = useParams<{team_id: string}>()

    const [currentTeam, setCurrentTeam] = useState<TeamProviderModel>(defaultValue.currentTeam)
    const [loading, setLoading] = useState(true)

    const previousTeamIdRef = useRef<string | undefined>(undefined)

    const set_current_team = (team: TeamProviderModel) => {
        if (team !== currentTeam) {
            setCurrentTeam(team);
            lcs_manager.set_item_to_lcs(lcs_manager.Keys.current_team, team);
        }
    };

    useEffect(() => {
        // Check if the team has changed
        if (team_id && hasOnlyNumbers(team_id)) {
            if(previousTeamIdRef.current !== team_id){
                setLoading(true);
                dispatch(getOrgTeams({
                    org_id: currentOrg.info.id,
                    limit: 1,
                    team_id,
                    teams_type: "my_teams",
                }))
                    .then((found_teams) => {
                        if (found_teams.content.length > 0) {
                            const team = found_teams.content[0];

                            // Update the state only if the team has changed
                            set_current_team({
                                info: team,
                                settings: {},
                                userPermissions: {
                                    manage: true
                                }
                            });

                            // Update the ref AFTER the state has been successfully updated
                            previousTeamIdRef.current = team.id.toString();
                            setLoading(false);
                        } else {
                            navigate('/errors/403');
                        }
                    })
                    .catch(() => {
                        navigate('/errors/403')
                    });
            }
        }else {
            navigate('/errors/403');
        }
    }, [team_id]);


    const value: TeamContextProps = {
        currentTeam,
        setCurrentTeam: set_current_team,
    }

    if (loading) {
        return <></>
    }

    return <TeamContext.Provider value={value}><Outlet /></TeamContext.Provider>
};

export const useTeamContext = (): TeamContextProps => {
    return useContext(TeamContext);
};
