import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import Page from "../../components/page_structure/page";
import PageHeader from "../../components/page_structure/page_header";
import PageContent from "../../components/page_structure/page_content";
import {PageDivider} from '../../components/page_structure/page_divider';
import {Segmented} from "antd";
import {BatchesProps} from '../../models/entities/batches';
import {empty_run} from "../../services/EmptyEntities/EmptyRun";
import RunReviewResults from "./batch_results";
import {AppDispatch} from "../../redux/store";
import {useDispatch} from "react-redux";
import { CheckCircleOutlined, HddOutlined, InfoCircleOutlined
} from "@ant-design/icons";
import RunReviewLogs from "./batch_logs";
import CenteredWrapper from "../../components/wraper";
import { useProjectContext } from '../../contexts/projectContext';


function RunReview(): JSX.Element {
    const {currentProject} = useProjectContext()
    const dispatch: AppDispatch = useDispatch();
    const location = useLocation()
    const { state } = location;
    let default_reviewInfo:BatchesProps = empty_run
    const hasState = state && state.run

    if(hasState){
        default_reviewInfo = state.run
    }

    const [reviewInfo, setReviewInfo] = useState<BatchesProps>(default_reviewInfo)

    const [page, setPage]  = useState<'Results' | 'Run Details'| 'System Logs'>('Results');

    useEffect(()=>{
        if(reviewInfo === empty_run){
            console.log('request the data')
        }
    }, [dispatch, reviewInfo])

    useEffect(() => {
        // Save state information to sessionStorage
        sessionStorage.setItem('myState', JSON.stringify(state));

        // Attach a listener to the beforeunload event
        const beforeUnloadHandler = () => {
            // Clear the state information from sessionStorage
            sessionStorage.removeItem('myState');
        };

        window.addEventListener('beforeunload', beforeUnloadHandler);

        // Cleanup function
        return () => {
            // Remove the beforeunload event listener when the component is unmounted
            window.removeEventListener('beforeunload', beforeUnloadHandler);
        };
    }, [state])


    function goBack() {
        window.history.back()
    }

    const handleChangeSegment = (e: any) => {
        setPage(e)
    }

    return <Page title="Run Review">
        <PageHeader
            title={`Review: ${reviewInfo.info.name}`}
            buttonHeader={{
                type: "back",
                title: "Go Back",
                onClickFunction: goBack
            }}/>
        {/* <CenteredWrapper>
            <Segmented
                size="large"
                onChange={handleChangeSegment}
                options={[
                    {
                        label: "Results",
                        value: "Results",
                        icon: <CheckCircleOutlined />,
                    },
                    {
                        label: "Run Details",
                        value: "Run Details",
                        icon: <InfoCircleOutlined />,
                    },
                    ...(currentProject.info.project_type === 2 ? [] : [{
                        label: "System Logs",
                        value: "System Logs",
                        icon: <HddOutlined />,
                    }])
                ]}
            />
        </CenteredWrapper> */}
        <PageContent>

            <PageDivider/>
            {
                page === "Results"? <RunReviewResults/>
                    : page=== "Run Details"?
                        <>panos- {page}</>
                        : <RunReviewLogs/>
            }
        </PageContent>
    </Page>

}

export default RunReview;