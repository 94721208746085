import {AppDispatch} from "../store";
import {fetchErrorAction, fetchStartAction, fetchSuccessAction} from "./creators/common_actions";
import {
    get,
    post,
    put,
    deletion,
    getWithOrgWrapper,
    getWithOrgProjectIdsWrapper,
    deleteWithOrgWrapper
} from '../../services/axiosWrapper';

// models
import {
    User,
    OrgUsersInfo,
    UserProfileInfo,
    UserProfileJobInfo,
    UserNotifications,
    Tool
} from "../../models/entities/user";
import {PageResource} from "../../models/dtos/page_resource";

//data
import {Organization, OrgProviderModel} from "../../models/entities/organization";
import { Job } from "../../models/entities/job";
import { Team } from "../../models/entities/team";
import { PermissionsInterface } from "../../models/entities/permissions";
import { GithubSetting } from "../../models/entities/settings";

const api_path = "/api/users/"

interface updateToolParams{
    tool_id: number
}


interface createOrgUserParams {
    org_id: number;
    name: string;
    lastname: string;
    email: string;
    password: string;
    color: string;
    role: boolean;
    jobs: Job[];
    teams: Team[]
}

interface updateProjectGithubUserIntegrationParams {
    org_id: number,
    token_name?: string,
    token_value?: string
}

interface updateProjectDesktopAppUserIntegrationParams {
    desktop_app_token: string
}


export const getUsers = () => (dispatch: AppDispatch): Promise<PageResource<User>> => {

    dispatch(fetchStartAction());

    const request: any = getWithOrgProjectIdsWrapper(`${api_path}getUsers`)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const getUserOrgRole = () => (dispatch: AppDispatch): Promise<{'role': number}> => {

    dispatch(fetchStartAction());

    const request: any = getWithOrgWrapper(`${api_path}getUserOrgRole`)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}


export const getOrgUserInfo = (search?: string, roles?: number[], jobs?: number[]) => (dispatch: AppDispatch): Promise<PageResource<OrgUsersInfo>> => {


    const request: any = getWithOrgWrapper(`${api_path}getOrgUsersInfo`, {search, roles, jobs})
    
    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));

            return Promise.reject();
    })
}


export const getUserInfo = (params: {user_id:number}) => (dispatch: AppDispatch): Promise<UserProfileInfo> =>{
    const request: any = get(`${api_path}getUserInfo`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));

            return Promise.reject();
        })
}

export const userCasesMaintain = (params: {user_id:number, org_id: number, current: boolean | null}) => (dispatch: AppDispatch): Promise<{user_tests: number}> =>{
    const request: any = get(`${api_path}userCasesMaintain`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));

            return Promise.reject();
        })
}

export const userRunsCreated = (params: {user_id:number, org_id: number, current: boolean | null}) => (dispatch: AppDispatch): Promise<{runs_created: number}> =>{
    const request: any = get(`${api_path}userRunsCreated`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));

            return Promise.reject();
        })
}

export const userReliabilityAction = (params: {user_id:number, org_id: number}) => (dispatch: AppDispatch): Promise<{user_reliability: string}> =>{
    const request: any = get(`${api_path}userReliability`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));

            return Promise.reject();
        })
}

export const getUserProfileTeams = (params: {targetUserId:number, org_id: number}) => (dispatch: AppDispatch): Promise<{content: {id: number, name: string}[]}> =>{
    const request: any = get(`${api_path}getUserProfileTeams`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));

            return Promise.reject();
        })
}

export const getUserProfileJobs  = (user_id: number, org_id: number) => (dispatch: AppDispatch): Promise<PageResource<UserProfileJobInfo>> =>{
    const request: any = get(`${api_path}getUserProfileJobs?user_id=${user_id}&org_id=${org_id}`)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));

            return Promise.reject();
        })
}

export const getNotifications  = (params: {org_id: number, viewed?: boolean | number, offset?: number, notification_type?: number, entity_id?: number}) => (dispatch: AppDispatch): Promise<PageResource<UserNotifications>> =>{
    const request: any = get(`${api_path}getNotifications`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));

            return Promise.reject();
        })
}

export const markAsRead  = (params: {org_id: number, notification_type?: number, notification_id?: number, mark_read?: boolean}) => (dispatch: AppDispatch): Promise<boolean> =>{
    const request: any = put(`${api_path}markAsRead`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));

            return Promise.reject();
        })
}

export const sendVerificationEmail = (params: {email: string}) => (dispatch: AppDispatch): Promise<boolean> =>{
    const request: any = put(`${api_path}sendVerificationEmail`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));

            return Promise.reject();
        })
}

export const getUnreadNotifications  = (params: {org_id: number}) => (dispatch: AppDispatch): Promise<{unread_notifications: number}> =>{
    const request: any = get(`${api_path}getUnreadNotifications`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));

            return Promise.reject();
        })
}

export const deleteNotification = (notification_id: number, org_id: number) => (dispatch: AppDispatch): Promise<boolean> => {
    dispatch(fetchStartAction());
    
    const request: any = deletion(`${api_path}deleteNotification?id=${notification_id}&org_id=${org_id}`)
    return Promise.resolve(request)
    .then(value => {
        dispatch(fetchSuccessAction());

        return value;
    })
    .catch(reason => {
        dispatch(fetchErrorAction(reason));
        return Promise.reject();
    })
}

export const getUserProfileProjects = (params: {targetUserId:number, org_id: number}) => (dispatch: AppDispatch): Promise<{content: {id: number, name: string, p_key: string}[]}> =>{
    const request: any = get(`${api_path}getUserProfileProjects`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));

            return Promise.reject();
        })
}

export const logoutUser = () => (dispatch: AppDispatch): Promise<boolean> => {
    const request: any = post(`${api_path}logoutUser`)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));

            return Promise.reject();
        })
}

// refactor
export const deleteOrgUser = (user_ids:number[]) => (dispatch: AppDispatch): Promise<boolean> => {
    dispatch(fetchStartAction());
    
    const request: any = deleteWithOrgWrapper(`${api_path}deleteOrgUser?user_ids=${user_ids}`)
    return Promise.resolve(request)
    .then(value => {
        dispatch(fetchSuccessAction());

        return value;
    })
    .catch(reason => {
        dispatch(fetchErrorAction(reason));
        return Promise.reject();
    })
}

export const createOrgUser = (data: createOrgUserParams) => (dispatch: AppDispatch): Promise<OrgUsersInfo> => {

    dispatch(fetchStartAction());

    const request: any = post(`${api_path}createOrgUser`, data)
    return Promise.resolve(request)
            .then(value => {
                dispatch(fetchSuccessAction());

                return value.data;
            })
            .catch(reason => {
                dispatch(fetchErrorAction(reason));
                return Promise.reject();
            })

}

export const updateOrgUser = (new_user:OrgUsersInfo) => (dispatch: AppDispatch): Promise<OrgUsersInfo[]> =>{
    dispatch(fetchStartAction());
    
    const request: any = put(`${api_path}updateOrgUser`, new_user)    
    return Promise.resolve(request)
            .then(value => {
                dispatch(fetchSuccessAction());
                return value.data;
            })
            .catch(reason => {
                dispatch(fetchErrorAction(reason));
                return Promise.reject();
            })
}

export const updateUserOrgJobs = (params: {org_id: number, user_id: number, new_job_ids: number[]}) => (dispatch: AppDispatch): Promise<Job[]> =>{
    dispatch(fetchStartAction());
    
    const request: any = put(`${api_path}updateUserOrgJobs`, params)    
    return Promise.resolve(request)
            .then(value => {
                dispatch(fetchSuccessAction());
                return value.data;
            })
            .catch(reason => {
                dispatch(fetchErrorAction(reason));
                return Promise.reject();
            })
}

export const updateOrgRole = (params: {org_id: number, id: number}) => (dispatch: AppDispatch): Promise<{"new_role": number, "new_label": string, permissions: PermissionsInterface}> =>{
    dispatch(fetchStartAction());
    
    const request: any = put(`${api_path}updateOrgRole`, params)    
    return Promise.resolve(request)
            .then(value => {
                dispatch(fetchSuccessAction());
                return value.data;
            })
            .catch(reason => {
                dispatch(fetchErrorAction(reason));
                return Promise.reject();
            })
}

export const getMyOrgs = (params: { domain?:string }) => (dispatch: AppDispatch): Promise<PageResource<Organization>> => {

    dispatch(fetchStartAction());

    const request: any = get(`${api_path}myOrgs`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const getCurrentOrg = (domain:string) => (dispatch: AppDispatch): Promise<OrgProviderModel> => {
    dispatch(fetchStartAction());

    const request: any = get(`${api_path}getCurrentOrg`, {domain})

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const updateUserInfo = (params: {name: string, lastname: string, description: string, email: string}) => (dispatch: AppDispatch): Promise<PageResource<UserProfileInfo>> => {

    dispatch(fetchStartAction());

    const request: any = put(`${api_path}updateUserInfo`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const updatePassword = (params: {password: string, new_password: string}) => (dispatch: AppDispatch): Promise<PageResource<boolean>> => {

    dispatch(fetchStartAction());

    const request: any = put(`${api_path}updatePassword`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const getTools = (params: { filter?: 'all'| 'myTools'| 'newTools'}) => (dispatch: AppDispatch): Promise<Tool[]> => {

    dispatch(fetchStartAction());

    const request: any = get(`${api_path}getTools`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const getUserGithubIntegrations = () => (dispatch: AppDispatch): Promise<{content: GithubSetting}> => {

    dispatch(fetchStartAction());

    const request: any = getWithOrgWrapper(`${api_path}getUserGithubIntegrations`)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const addTool = (data:updateToolParams) => (dispatch: AppDispatch): Promise<boolean> => {
    const request: any = post(`${api_path}addTool`, data)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));

            return Promise.reject();
        })
}

export const deleteTool = (params:updateToolParams) => (dispatch: AppDispatch): Promise<boolean> => {
    dispatch(fetchStartAction());

    const request: any = deletion(`${api_path}deleteTool`, params)
    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const updateUserGithubIntegration = (params: updateProjectGithubUserIntegrationParams) => (dispatch: AppDispatch): Promise<any> => {

    dispatch(fetchStartAction());

    const request: any = put(`${api_path}updateUserGithubIntegration`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const updateUserDesktopAppIntegration = (params: updateProjectDesktopAppUserIntegrationParams) => (dispatch: AppDispatch): Promise<any> => {

    dispatch(fetchStartAction());

    const request: any = put(`${api_path}updateDesktopAppToken`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}
