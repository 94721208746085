import React from 'react';
import { Modal } from 'antd';
import {DialogProps} from "rc-dialog";

type modalOptionsType = {
    open: boolean,
    children: React.ReactNode,
    title?: React.ReactNode,
    onOk?: any,
    onCancel?: any,
    footer?: any,
    key?: string,
    maskClosable?: boolean,
    keyboard?: boolean,
    style?: React.CSSProperties,
    width?: number,
    styles?: Omit<NonNullable<DialogProps['styles']>, 'wrapper'>
}


function ModalC(modal_options:modalOptionsType): JSX.Element {
    return(
    <Modal {...modal_options}
          >
            {modal_options.children}
    </Modal>)

}
export default ModalC;