import {AppDispatch} from "../store";
import {fetchErrorAction, fetchStartAction, fetchSuccessAction} from "./creators/common_actions";
import { User } from "../../models/entities/user";
import axios from "axios";

const api_path = "/api/auth/"

interface signUpUserParams {
    name: string;
    lastname: string;
    email: string;
    password?: string;
}

export const loginUser = (email:string, password:string) => (dispatch: AppDispatch): Promise<{
    user: User,
    token: string
}> => {
    const request: any = axios.get(`${api_path}loginUser?email=${email}&password=${password.replace('#', "%23")}`, {
        headers:{
            XClienTtype: 'web'
        }
    })

    return Promise.resolve(request)
    
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason.response.data.error)); // Pass the error message to the catch block
            return Promise.reject(reason.response.data.error); // Reject the promise with the error message
        });
}

export const googleLogin = (email: string) => (dispatch: AppDispatch): Promise<{
    user: User,
    token: string
}> => {
    const request: any = axios.get(`${api_path}googleLogin?email=${email}`)
    return Promise.resolve(request)
        .then(value => {
            if (value.status === 290) {
                // User not found, proceed to create a new user
                return value.status
            }
            dispatch(fetchSuccessAction());
            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason)); // Pass the error message to the catch block
            return Promise.reject(reason.response.data.error); // Reject the promise with the error message
        });
}

export const signUpUser = (params: signUpUserParams) => (dispatch: AppDispatch): Promise<{new_user: User, token: string}> => {
    dispatch(fetchStartAction());
    const request: any = axios.post(`${api_path}signUpUser`, params, {
        headers:{
            XClienTtype: 'web'
        }
    })

    return Promise.resolve(request)
            .then(value => {
                dispatch(fetchSuccessAction());
                return value.data;
            })
            .catch(reason => {
                dispatch(fetchErrorAction(reason));
                return Promise.reject();
            })
}

export const recoverUserPassword   = (params: {email: string}) => (dispatch: AppDispatch): Promise<boolean> =>{

    const request: any = axios.put(`${api_path}recoverUserPassword`, params, {
        headers:{
            XClienTtype: 'web'
        }
    })

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));

            return Promise.reject();
        })
}

export const resetPassword  = (params: {temporary_password:string, password:string, repeat_password:string, email?:string}) => (dispatch: AppDispatch): Promise<boolean> => {
    const request: any = axios.put(`${api_path}resetPassword`, params, {
        headers:{
            XClienTtype: 'web'
        }
    })

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));

            return Promise.reject();
        })
}

export const verifyUser = (params: {hash_id:string, email:string}) => (dispatch: AppDispatch): Promise<{status: number, message: string}> =>{
    const request: any = axios.put(`${api_path}verifyUser`, params, {
        headers:{
            XClienTtype: 'web'
        }
    })

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));

            return Promise.reject();
        })
}
