import React, { Dispatch, useEffect, useState } from "react";
import { Form, Input, Switch } from 'antd';
import { OrgUsersInfo } from "../../models/entities/user";
import { InputGenerateColor } from "../form/generate_color";
import { FormInstance } from "antd/lib/form/Form";
import { getJobs } from '../../redux/actions/job_actions';
import { AppDispatch } from "../../redux/store";
import { useDispatch } from "react-redux";
import { Job } from '../../models/entities/job';
import {getOrgTeams} from "../../redux/actions/team_actions";
import { Team } from "../../models/entities/team";
import { JobFormSelect } from "../form/job_form_select";
import {createOrgUser, updateOrgUser} from '../../redux/actions/user_actions';
import { PageResource } from "../../models/dtos/page_resource";
import { CheckOutlined, CloseOutlined, EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { empty_org_user_info } from "../../services/EmptyEntities/EmptyUser";
import ButtonC from "../button";
import ModalC from "../modal";
import { TeamFormSelect } from "../form/team_form_select";
import CheckboxC from "../checkbox";
import { safeHandleErrorResponse } from "../../assets/helpers/errorHandler";
import {useOrgContext} from "../../contexts/orgContext";

type UserModalProps = {
    setVisible:(is_visible:boolean)=>void,
    visible:boolean,
    user: OrgUsersInfo,
    userPage: PageResource<OrgUsersInfo>,
    setUserPage: Dispatch<PageResource<OrgUsersInfo>>,
    setUser: Dispatch<OrgUsersInfo>,
}

export function UserModal(props:UserModalProps):JSX.Element{
    //props    
    const {setVisible, visible, user, userPage, setUserPage, setUser} = props;

    const dispatch: AppDispatch = useDispatch();
    const {currentOrg} = useOrgContext()

    // set modal state
    // form state
    const [componentSize, setComponentSize] = useState();
    const [form] = Form.useForm();
    
    // set user state
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [color, setColor] = useState<string>("");
    const [password, setPassword] = useState<any>();
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [userJobs, setUserJobs] = useState<Job[]>([]);
    const [userTeams, setUserTeams] = useState<Team[]>([]);
    const [keepWindowOpen, setKeepWindowOpen] = useState<boolean>(false);
    
    // set state
    const [jobs, setJobs] = useState<Job[]>([]);
    const [teams, setTeams] = useState<Team[]>([]);

    const [searchTeam, setSearchTeam] = useState<string>('');

    // set modal state
    const [isEditMode, setIsEditMode] = useState<boolean>(false);

    const org_id = currentOrg.info.id

    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]).{8,}$/;

    const onFormLayoutChange = ({ size } :any) => {
      setComponentSize(size);
    };

    useEffect(() => {
        dispatch(getJobs())
            .then(foundPage => setJobs(foundPage.content)).catch((err) => {
                safeHandleErrorResponse(err)
            })

        dispatch(getOrgTeams({org_id, search: searchTeam, limit: 1000, teams_type: 'all'}))
            .then(foundPage => {
                setTeams(foundPage.content)
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })

    }, [dispatch, currentOrg])


    useEffect(() => {
        setFirstName(user.user_info ? user.user_info.name : '');
        setLastName(user.user_info ? user.user_info.lastname : '');
        setEmail(user.user_info ? user.user_info.email : '');
        setIsAdmin(user.role===1);
        setPassword(user.user_info ? user.user_info.password : '')
        user.user_jobs_info?setUserJobs(user.user_jobs_info):setUserJobs([]);
        user.user_teams_info?setUserTeams(user.user_teams_info):setUserTeams([]);
        setColor(user.color);
    }, [user]);

    useEffect(()=>{
        form.setFieldsValue({
            firstname: firstName,
            lastname: lastName,
            email: email,
            password: password,
            jobs: userJobs.map(job=>{return job.id}),
            teams: userTeams.map(team=>{return team.id}),
            is_admin: isAdmin,
            color: color
        })
    }, [form, firstName, lastName, email, password, userJobs, userTeams, isAdmin, color])
    
    useEffect(()=>{
        setIsEditMode(user !== empty_org_user_info)
    }, [dispatch, user])

    const onCreate = (values:any) => {
        if (isEditMode) {
            let confirmed = true;
            let update_user_info: OrgUsersInfo = {
                user_info: {
                    id: user.user_info.id,
                    name: values.firstname ? values.firstname : user.user_info.name,
                    lastname: values.lastname ? values.lastname : user.user_info.lastname,
                    email: values.email ? values.email : user.user_info.email
                },
                org_id: org_id,
                color: values.color ? values.color : user.color,
                role: values.role ? values.role : user.role,
                user_teams_info: values.user_teams_info ? values.user_teams_info : user.user_teams_info,
                user_jobs_info: values.user_jobs_info ? values.user_jobs_info : user.user_jobs_info
            }
    
            // Uncomment if password handling is required
            // if(values.password){
            //     confirmed = window.confirm('Even if you change the password, the user is forced to change it at the first log in.')
            //     update_user_info.password = values.password
            // }
    
            if (confirmed) {
                dispatch(updateOrgUser(update_user_info)).then(() => {
                    if (userPage && userPage.content) {
                        const updatedUsers = userPage.content.map(u =>
                            u.user_info.id === update_user_info.user_info.id ? update_user_info : u
                        );
                        setUserPage({ ...userPage, content: updatedUsers });
                    }
                }).catch((err) => {
                    safeHandleErrorResponse(err)
                });
                setVisible(false);
            }
        }else{
            let new_user: any = {
                org_id: org_id,
                name: firstName,
                lastname: lastName,
                password: password,
                email: email,
                color: color,
                role: isAdmin,
                jobs: userJobs,
                teams: userTeams
            }
            dispatch(createOrgUser(new_user))
            .then((foundUser) => {
                const updatedUserPage: any = {
                    ...userPage,
                    content: [
                        ...userPage.content,
                        foundUser
                    ]
                };
                setUserPage(updatedUserPage); // Ensure setUserPage updates the state correctly
    
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })
            if(!values.continue){
                keepWindowOpen ? setVisible(true) : setVisible(false)
            }
        }
        resetUser()
    }

    const handleOk = (form:FormInstance<any>) => {
        form
            .validateFields()
            .then(values => {
                // form.resetFields();
                onCreate(values)
        })
        .catch(() => {
            // console.log('Validate Failed:', info);
        });
    }

    const resetUser = () => {
        setFirstName('')
        setLastName('')
        setEmail('')
        setColor('')
        setPassword('')
        setIsAdmin(false)
        // setJobs([])
        // setTeams([])
        setUserJobs([])
        setUserTeams([])
        setIsEditMode(false)
        setUser(empty_org_user_info)
    }

    const handleCancel = () => {
        resetUser()
        setVisible(false)
    };
    
    const is_edit_mode = ():boolean =>{
        return user === empty_org_user_info;

    }
   
    const footer_buttons = [
        <ButtonC key="submit_cancel" onClick={handleCancel} text="Cancel"/>,
        <ButtonC key="submit_ok" type="primary" onClick={() => handleOk(form)} text={isEditMode? 'Save' : 'Create'} 
                 disabled={firstName === '' || lastName === '' || email === '' || password === '' 
                           || (!isEditMode && userPage?.content.some(user => user.user_info.email === email))
                           || !passwordRegex.test(password)}/>
      ];

    return (
        <ModalC key={'user_modal'} open={visible} title={isEditMode? 'Edit User' : 'Add New User'} onCancel={handleCancel} footer={footer_buttons}>
            {password !== undefined && password !== null && password !== '' && !passwordRegex.test(password) ?
            <p style={{color: '#F6C324'}}>Password must contain at least one lowercase letter, one uppercase letter, one special character, and be at least 8 characters long!</p> : null}
            {!isEditMode && userPage?.content.some(user => user.user_info.email === email) && <p style={{color: '#F6C324'}}>The specific email already exists!</p>}
            <Form form={form}
                  name="createUser"
                  labelCol={{ span: 4 }}
                  wrapperCol={{ span: 14 }}
                  layout="horizontal"
                  initialValues={{ size: componentSize }}
                  onValuesChange={onFormLayoutChange}
                  size={componentSize}
            >
                <Form.Item  name="firstname" label="Firstname" 
                            rules={[{ required: !isEditMode, message: 'Please input a First Name!' }]}>
                    <Input  value={firstName} placeholder="Enter user first name"
                            onChange={(event) => setFirstName(event.target.value)}/>
                </Form.Item>
                <Form.Item  name="lastname" label="Lastname" 
                            rules={[{ required: !isEditMode, message: 'Please input a Last Name!' }]}>
                    <Input  value={lastName} placeholder="Enter user last name"
                            // disabled={true}
                            onChange={(event) => setLastName(event.target.value)}/>
                </Form.Item>
                <Form.Item  name="email" label="Email"
                            rules={[{ required:  !isEditMode, message: 'Please input an Email!' }]}>
                            
                    <Input  value={email} placeholder="Enter user email"
                            onChange={(event) => setEmail(event.target.value)}/>
                </Form.Item>
                <Form.Item  name="password" label="Password"
                            rules={[{ required: !isEditMode, 
                                      message: 'Please input a Password!' }
                                    ]}  
                            >
                    <Input.Password  value={password} placeholder="Enter user password"
                            onChange={(event) => setPassword(event.target.value)}
                            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                            />
                </Form.Item>

                <JobFormSelect  preselectedJobIds={userJobs.map(job=>{return job.name})}
                                jobs={jobs}
                                setUserJobs={setUserJobs}
                />
                <TeamFormSelect preselectedTeamIds={userTeams.map(team=>{return team.name})}
                                teams={teams}
                                setUserTeams={setUserTeams}
                />
                <Form.Item name="is_admin" label="Admin">
                    <Switch checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            checked={isAdmin}
                            onChange={(event) => setIsAdmin(event)}/>
                </Form.Item>
                <InputGenerateColor form_id="createUser"
                                    setColor={setColor}
                                    color={color}
                                    infoText= "This will be used in charts and test case assignments" 
                />
                {is_edit_mode()?
                    <Form.Item name="continue" valuePropName="checked">
                        <CheckboxC
                            style={{marginTop: 'auto', marginBottom: 15}}
                            label='Continue Adding Users'
                            checked={keepWindowOpen}
                            onChange={(e) => {
                                setKeepWindowOpen(e.target.checked)
                        }}/>
                    </Form.Item>
                    : null
                }
                
            </Form>
            </ModalC>
    )
}
