import {PageResource} from "../../models/dtos/page_resource";
import {AppDispatch} from "../store";
import {fetchErrorAction, fetchStartAction, fetchSuccessAction} from "./creators/common_actions";
import { Job } from "../../models/entities/job";
import {post, put, getWithOrgWrapper, deleteWithOrgWrapper} from '../../services/axiosWrapper';

const api_path = '/api/job/';

export const getJobs = () => (dispatch: AppDispatch): Promise<PageResource<Job>> => {
    dispatch(fetchStartAction());

    const request: any = getWithOrgWrapper(`${api_path}getJobs`)

    return Promise.resolve(request)
            .then(value => {
                dispatch(fetchSuccessAction());

                return value.data;
            })
            .catch(reason => {
                dispatch(fetchErrorAction(reason));
                return Promise.reject();
            })
} 

//{ name:string, description:string, organization_id: string, color:string}
export const createJob = (data:Omit<Job, 'id'>, jobs:Job[]) => (dispatch: AppDispatch): Promise<Job[]> => {
    dispatch(fetchStartAction());

    const request: any = post(`${api_path}create`, data)

    return Promise.resolve(request)
            .then(value => {
                dispatch(fetchSuccessAction());

                value.data = [...jobs, value.data.new_job]
                return value.data;
            })
            .catch(reason => {
                dispatch(fetchErrorAction(reason));
                return Promise.reject();
            })

}

// this is not working
export const updateJob = (new_job:Job, job_list:Job[]) => (dispatch: AppDispatch): Promise<Job[]> => {
    dispatch(fetchStartAction());
    const request: any = put(`${api_path}updateJob`, new_job)
    return Promise.resolve(request)
            .then(value => {
                dispatch(fetchSuccessAction());

                const job_index = job_list.findIndex(job => job.id === new_job.id);
                job_list[job_index] = new_job;
                value.data = job_list;

                return value.data;
            })
            .catch(reason => {
                dispatch(fetchErrorAction(reason));
                return Promise.reject();
            })
}   

export const deleteJob = (job_id:number) => (dispatch: AppDispatch): Promise<PageResource<Job>> => {
    dispatch(fetchStartAction());
    
    const request: any = deleteWithOrgWrapper(`${api_path}deleteJob?job_id=${job_id}`)
    // todo remove job from users etc
    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));

            return Promise.reject();
        })
}