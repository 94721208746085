import React, {createContext, useContext, useEffect, useRef, useState} from 'react';
import {Outlet, useNavigate, useParams} from "react-router-dom"
import {AppDispatch} from "../redux/store";
import {useDispatch} from "react-redux";
import LcsManager from '../assets/local_storage/lcs'
// models
import {ProjectProviderModel} from '../models/entities/project';
// empty entities
import {empty_project} from '../services/EmptyEntities/EmptyProject';
import {getCurrentProject} from "../redux/actions/project_actions";

const lcs_manager = new LcsManager()

export let globalProjectId = -1;

export const getGlobalProjectId = () => globalProjectId;

// Default set to lcs
const set_project_to_lcs = (project: ProjectProviderModel) => {
    lcs_manager.set_item_to_lcs(lcs_manager.Keys.current_project, project)
}

const lcsProject = lcs_manager.get_item_from_lcs(lcs_manager.Keys.current_project);

interface ProjectContextProps {
    currentProject: ProjectProviderModel,
    setCurrentProject: (project: ProjectProviderModel) => void,
}

const defaultValue: ProjectContextProps = {
    currentProject: lcsProject ? lcsProject : {
        info: empty_project,
        settings: {
            canCreateRun: false
        },
        userPermissions: {
            manage: false
        }
    },
    setCurrentProject: set_project_to_lcs,
};

const ProjectContext = createContext<ProjectContextProps>(defaultValue);

export const ProjectProvider:  React.FC = () => {
    const dispatch: AppDispatch = useDispatch()
    const navigate = useNavigate()

    const {project_key}  = useParams<{project_key: string}>()

    const [currentProject, setCurrentProject] = useState<ProjectProviderModel>(defaultValue.currentProject)
    const [loading, setLoading] = useState(true)

    const previousPKeyRef = useRef<string | undefined>(undefined)

    const set_current_project = (project: ProjectProviderModel) => {
        if(project !== currentProject){
            setCurrentProject(project)
            lcs_manager.set_item_to_lcs(lcs_manager.Keys.current_project, project)
            globalProjectId = project.info.id
        }
    }

    useEffect(()=>{
        if(project_key && previousPKeyRef.current !== project_key){
            setLoading(true);
            dispatch(getCurrentProject({project_key}))
                .then(found_projects => {
                    if(found_projects){
                        set_current_project(found_projects)
                        previousPKeyRef.current = found_projects.info.p_key;
                        setLoading(false);
                    }else{
                        navigate('/errors/403')
                    }
                })
                .catch(() => {navigate('/errors/403')})
        }
    }, [project_key])

    const value: ProjectContextProps = {
        currentProject,
        setCurrentProject: set_current_project,
    }

    if (loading) {
        return <></>
    }

    return <ProjectContext.Provider value={value}><Outlet /></ProjectContext.Provider>;
};


export const useProjectContext = (): ProjectContextProps => {
    return useContext(ProjectContext);
};
