import { Dispatch, SetStateAction, useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import React from "react";
import ModalC from "../modal";
import {Menu, Select, Popconfirm, notification, Input, Col, Table} from 'antd';
import { AppDispatch } from "../../redux/store";
import {getOrgTeams} from "../../redux/actions/team_actions";
import RichEditor from "../rich_editor/rich_editor";
import { safeHandleErrorResponse } from "../../assets/helpers/errorHandler";
import RowC from "../row";
import ButtonC from "../button";
import {notificationTypes, triggerProps} from "../../models/entities/run_script_notifications";
import CheckboxC from "../checkbox";
import {useCreateRunContext} from "../../contexts/createRunContext";
import {useOrgContext} from "../../contexts/orgContext";
import {getProjectRunScripts, getProjectSlackIntegrations} from "../../redux/actions/project_actions";
import {DeleteOutlined, EditOutlined, PlusCircleOutlined} from "@ant-design/icons";
import TPTexts from "../../sds/text";
import {colors} from "../../assets/data/colors";
import Helper from "../../assets/helpers/helper";

const { TextArea } = Input;

type PostProps = {
    visible: boolean,
    setVisible: Dispatch<SetStateAction<boolean>>
}

export type headerProps = {label:string, value: string}
type OptionProps = {label:React.ReactNode, value: React.Key}

type itemProps = {
    id: number,
    title: string,
    msg: string,
    selectedInfo: OptionProps[]
}

const defaultItem = {
    id: 0,
    title: '',
    msg: '',
    selectedInfo: []
}

let defaultSetup: Record<notificationTypes, itemProps> = {
    team: defaultItem,
    slack: defaultItem,
    scripts: defaultItem,
    notifications: defaultItem,
    webhook: defaultItem
};

let defaultHeaders = [{label:'Content-Type', value: 'Application/JSON'}]

const convertToOption = (label: React.ReactNode, value:React.Key):OptionProps => {
    return { label, value }
}

function RenderClear({handleEditorChange}: {handleEditorChange: (content: string)=>void}):JSX.Element{
    return <h4>* Message: <ButtonC size={'small'} text={'Clear'} onClick={()=>{handleEditorChange('')}}/></h4>
}

function RenderTeam({renderSelect, handleEditorChange, message}:
                        {renderSelect: ()=>React.ReactNode, message:string, handleEditorChange: (content: string)=>void}
):JSX.Element{
    return <>
        <RowC>
            <h4>* Select Teams</h4>
            {renderSelect()}
        </RowC>
        <RowC>
            <RenderClear handleEditorChange={handleEditorChange}/>
            <RichEditor value={message} onChange={handleEditorChange}/>
        </RowC>
    </>
}

function RenderSlack({renderSelect, handleEditorChange, message}:
                         {renderSelect: ()=>React.ReactNode, message:string, handleEditorChange: (content: string)=>void}
):JSX.Element{
    return <>
        <RowC>
            <h4>* Select Slack Channels</h4>
            {renderSelect()}
        </RowC>
        <RowC>
            <RenderClear handleEditorChange={handleEditorChange}/>
            <TextArea
                rows={6}
                value={message}
                onChange={(value)=>{handleEditorChange(value.target.value)}}/>
        </RowC>
    </>
}

export function RenderWebhook({method, setMethod, url, setUrl, dataType, setDataType, data, setData, validUrl, headers, setHeaders, setFormatError, formatError}:
    {
        method: string,
        setMethod: Dispatch<SetStateAction<string>>,
        url:string,
        setUrl: Dispatch<SetStateAction<string>>,
        dataType:string,
        setDataType: Dispatch<SetStateAction<string>>,
        data:string,
        setData: Dispatch<SetStateAction<string>>,
        validUrl: boolean,
        headers: headerProps[],
        setHeaders: Dispatch<SetStateAction<headerProps[]>>,
        setFormatError: Dispatch<SetStateAction<string>>,
        formatError: string
    }
):JSX.Element{
    const [renderHeaders, setRenderHeaders] = useState<any[]>()

    const handleUpdateData = (value:string) => {
        setData(value)
        try{
            JSON.parse(value)
            setFormatError('')
        }catch (error){
            console.log(error)
            setFormatError('Invalid JSON. Please note that you have to wrap the keys with double quotes {"} ')
        }
    }

    useEffect(()=>{
        setRenderHeaders(headers.map((header, index)=>{
            return {
                key: index,
                label: <Input value={header.label}
                              onChange={(e) => {
                                  const newHeaders = [...headers];
                                  newHeaders[index] = { ...newHeaders[index], label: e.target.value };
                                  setHeaders(newHeaders);
                              }}
                />,
                value: <Input value={header.value}
                              onChange={(e) => {
                                  const newHeaders = [...headers];
                                  newHeaders[index] = { ...newHeaders[index], value: e.target.value };
                                  setHeaders(newHeaders);
                              }}
                />,
                action: <ButtonC shape={'circle'} text={<DeleteOutlined />} onClick={()=>{
                    setHeaders(Helper.deleteFromArray(headers, index))
                }}/>
            }
        }))
    }, [headers])

    return <>
        <RowC>
            <h4>Configure Outgoing Webhook</h4>
        </RowC>
        <RowC>
            <Col flex={'150px'}><b style={{fontSize:16}}>* HTTP METHOD: </b></Col>
            <Col flex={'auto'}><Select
                style={{ width: '100%' }}
                placeholder='Type to search...'
                value={method}
                onChange={setMethod}
                options={[
                    {value: 'GET'},
                    {value: 'POST'},
                    {value: 'PUT'},
                    {value: 'DELETE'},
                    {value: 'PATCH'},
                    {value: 'HEAD'}
                ]}
            /></Col>
        </RowC>
        <RowC>
            <h4>* URL</h4>
            <Input status={validUrl?undefined :'error'} value={url} onChange={(e)=> {
                setUrl(e.target.value)
            }}/>
            {validUrl?'': TPTexts.errorMsg('Not a valid url')}
        </RowC>
        <RowC style={{paddingTop: 15}}>
            <Col flex={'150px'}><b style={{fontSize:16}}>Request Body: </b></Col>
            <Col flex={'auto'}><Select
                style={{ width: '100%' }}
                placeholder='Type to search...'
                value={dataType}
                onChange={setDataType}
                options={[
                    {label: 'Empty Body', value: 'empty'},
                    {label: 'Custom Data', value: 'custom'}
                ]}
            /></Col>
        </RowC>
        {
            dataType=== 'custom'? <>
                <TextArea
                    style={{marginTop: 15}}
                    placeholder={'enter your JSON data'}
                    rows={6}
                    status={formatError!==''? 'error': ''}
                    autoSize={{ minRows: 6 }}
                    value={data}
                    onChange={(value)=>{handleUpdateData(value.target.value)}}
                />
                {TPTexts.errorMsg(formatError)}
            </>: null
        }
        <RowC>
            <h4>Headers (optional)</h4>

        </RowC>
        <RowC>
            <Table
                style={{width:'100%'}}
                dataSource={renderHeaders}
                pagination={false}
                columns={[{
                    title: 'Key',
                    dataIndex: 'label',
                    key: "label",
                    width: '45%'
                }, {
                    title: 'Value',
                    dataIndex: 'value',
                    key: "value",
                    width: '45%'
                }, {
                    title: <ButtonC
                        shape={'circle'}
                        text={<PlusCircleOutlined style={{color: colors.blue.default}}/>}
                        onClick={()=>{setHeaders(prevState => [...prevState, {label:'', value: ''}])}}
                    />,
                    dataIndex: 'action',
                    key: "action",
                    width: '10%'
                }]}
            />
        </RowC>
    </>
}

function RenderScript({renderSelect}:{renderSelect: ()=>React.ReactNode}):JSX.Element{
    return <RowC>
        <h4>* Select Slack Channels</h4>
        {renderSelect()}
    </RowC>
}

export default function ModalAddNewNotification(props:PostProps):JSX.Element {
    const dispatch: AppDispatch = useDispatch();
    const {notifications} = useCreateRunContext()
    const {currentOrg} = useOrgContext()

    const {setCustom} = notifications

    const {visible, setVisible} = props
    const isFirstRender = useRef(true);

    const [menuItem, setMenuItem] = useState<notificationTypes>( 'team')
    const [keepWindowOpen, setKeepWindowOpen] = useState<boolean>(false)
    const [setUp, setSetUp] = useState<Record<notificationTypes, itemProps>>(defaultSetup)
    const [teams, setTeams] = useState<OptionProps[]>([])
    const [slacks, setSlacks] = useState<OptionProps[]>([])
    const [scripts, setScripts] = useState<OptionProps[]>([])

    const [url, setUrl] = useState<string>('')
    const [data, setData] = useState<string>('')
    const [formatError, setFormatError] = useState<string>('')
    const [validUrl, setValidUrl] = useState<boolean>(true)
    const [headers, setHeaders] = useState<headerProps[]>(defaultHeaders)
    const [method, setMethod] = useState<string>('POST')
    const [dataType, setDataType] = useState<string>('empty')

    const [selectedItems, setSelectedItems] = useState<string[]>(defaultSetup[menuItem].selectedInfo.map(info=>info.value.toString()) )
    const [message, setMessage] = useState<string>(defaultSetup[menuItem].msg)

    const [draftTabs, setDraftTabs] = useState<string[]>([])
    const [options, setOptions] = useState<{label:React.ReactNode, value: React.Key}[]>([])

    const renderMenuTitle = (title:string, key:string) => {
        return {
            label: <>{title} {draftTabs.includes(key)? <EditOutlined />:null}</>,
            key,
        }
    }

    const generateOptions = (optionsTypes: notificationTypes) => {
        if(optionsTypes==="team") setOptions(teams)
        else if(optionsTypes==="slack") setOptions(slacks)
        else if(optionsTypes==="scripts") setOptions(scripts)
    }

    const handleDrafts = () => {
        setDraftTabs([
            ...Object.entries(setUp)
            .filter(([key, value]) => (value.selectedInfo.length !== 0 || value.msg !== '' ) && key !== menuItem)
            .map(([key]) => key),
            ...menuItem !== 'webhook' && validUrl && url!==''? ['webhook']: []])
    }


    const fetchTeamOptions = async (search?: string) => {
        dispatch(getOrgTeams({org_id: currentOrg.info.id, teams_type: 'my_teams', limit: 10, search}))
            .then(results=>{
                setTeams(results.content.map(team=>{
                    return convertToOption(team.name, `team-${team.id}`)
                }))
            })
            .catch((err) => {safeHandleErrorResponse(err)})
    }

    const fetchSlackOptions = async (search?: string) => {
        dispatch(getProjectSlackIntegrations({only_visible: true, search}))
            .then(results => {
                setSlacks(results.content.map(slack=>{
                    return convertToOption(slack.title, `slack-${slack.id}`)
                }))
            })
            .catch((err) => {safeHandleErrorResponse(err)})
    }

    const fetchScriptOptions = async (search?: string) => {
        dispatch(getProjectRunScripts({only_visible: true, search}))
            .then(scripts => {
                setScripts(scripts.content.map(script=>{
                    return convertToOption(script.title, `scripts-${script.id}`)
                }))
            })
            .catch((err) => {safeHandleErrorResponse(err)})
    }

    useEffect(()=>{
        if(url === '') setValidUrl(true)
        else setValidUrl(Helper.validateUrlFormat(url))
    }, [url])

    useEffect(() => {
        Promise.all([
            fetchTeamOptions(),
            fetchSlackOptions(),
            fetchScriptOptions()
        ])
            .then(()=> {
                isFirstRender.current = false
            })
    }, [dispatch])

    useEffect(()=>{
        generateOptions('team')
    }, [teams])

    useEffect(()=>{
        if(!isFirstRender){
            generateOptions('slack')
        }
    }, [slacks])

    useEffect(()=>{
        if(!isFirstRender){
            generateOptions('scripts')
        }
    }, [scripts])

    useEffect(() => {
        generateOptions(menuItem)
        handleDrafts()
        setSelectedItems(setUp[menuItem].selectedInfo.map(info=>info.value.toString()))
        setMessage(setUp[menuItem].msg)

    }, [menuItem])

    const handleEditorChange = (content: any) => {
        setSetUp((prevState)=>{
            return {
                ...prevState,
                [menuItem]:{
                    ...prevState[menuItem],
                    msg: content
                }
            }
        })
        setMessage(content)
    };

    const handleReset = () => {
        setSelectedItems([])
        setMessage('')
        if(menuItem === 'webhook'){
            setUrl('')
            setData('')
            setValidUrl(true)
            setHeaders(defaultHeaders)
        }
    }
    const handleCancel = () => {
        handleReset()
        setDraftTabs([])
        setSetUp(defaultSetup)
        setVisible(false)
    };

    const handleOk = () => {
        let selectedItems:OptionProps[], numberOfSelectedItems:number
        const trigger: { before: triggerProps; after: triggerProps } = {
            before: 0,
            after: 0,
        }

        if(menuItem !== 'webhook'){
            selectedItems = setUp[menuItem].selectedInfo
            numberOfSelectedItems = setUp[menuItem].selectedInfo.length

            setCustom((prevState)=>{
                return  [
                    ...prevState,
                    ...selectedItems.map((item) => {
                        let [type, id] = item.value.toString().split('-');
                        return {
                            id: parseInt(id, 10),
                            title: item.label?item.label.toString(): '',
                            type: type as notificationTypes,
                            message,
                            trigger
                        }
                    })
                ]
            })
        }else{
            numberOfSelectedItems = 1
            setCustom((prevState)=>{
                return  [
                    ...prevState,
                    {
                        id: Math.floor(Date.now() / 1000),
                        title: url,
                        type: 'webhook',
                        message:JSON.stringify({
                            method,
                            dataType,
                            url,
                            data: dataType !== 'empty' ? JSON.parse(data) : '',
                            headers
                        }),
                        trigger
                    }
                ]
            })
        }


        notification.success({
            message: 'Successfully Added',
            description: `${numberOfSelectedItems} new item${numberOfSelectedItems>1?'s':''} to custom notifications`,
            duration: 3
        })

        if(keepWindowOpen){
            handleReset()
            setSetUp((prevState)=>{
                return {
                    ...prevState,
                    [menuItem]:defaultItem
                }
            })
        }else{
            handleCancel()
        }
    };

    const handleTeamsChange = (event: string[], options:any) => {
        setSelectedItems(event);
        setSetUp((prevState)=>{
            return {
                ...prevState,
                [menuItem]:{
                    ...prevState[menuItem],
                    selectedInfo: options
                }
            }
        })
    };

    const disableButton = ():boolean => {
        let disabled;

        if(menuItem === 'webhook'){
            disabled = url === '' || !validUrl || formatError !== ''
        }else if(menuItem === 'scripts'){
            disabled = selectedItems.length === 0
        }else {
            disabled = selectedItems.length === 0 || message===''
        }

        return disabled
    }

    const footer_buttons = [
        <ButtonC key="submit_cancel" onClick={handleCancel} text="Cancel"/>,
         !keepWindowOpen && draftTabs.length !== 0 ?
            <Popconfirm
                key="submit_ok"
                title="Are you sure?"
                placement={'topRight'}
                description={<div style={{width:200}}>
                    <h4>It seems like you might have forgotten to add your draft.</h4>
                    <p>Before closing the window, make sure you don't need your draft tab{draftTabs.length>1?'s':''}: <b>{draftTabs.toString()}</b></p>
                    {TPTexts.disclaimer(<>*You can check the '<b>Keep Window Open</b>' to add your draft</>)}
                </div>}
                onConfirm={handleOk}
                onCancel={()=>{}}
                okText="Continue"
                cancelText="Keep Editing"
            >
                <ButtonC
                    key="submit_ok"
                    type="primary"
                    disabled={disableButton()}
                    text={'Add'}
                />
            </Popconfirm>
            : <ButtonC
                 key="submit_ok"
                 type="primary"
                 disabled={disableButton()}
                 onClick={() => handleOk()}
                 text={'Add'}/>
    ]

    const renderSelect = ():React.ReactNode => {
        return <Select
            mode="multiple"
            style={{ width: '100%' }}
            placeholder='Type to search...'
            value={selectedItems}
            onChange={handleTeamsChange}
            optionLabelProp="label"
            options={options}
        />
    }

    return <ModalC
        open={visible}
        title='Add New Action'
        onCancel={handleCancel}
        footer={footer_buttons}
        styles={{
            body:{
                minHeight: 400, // Ensures the modal body has a minimum height
                maxHeight: 700,
                overflow: 'auto',
                display: 'flex',
                flexDirection: 'column',
            }
        }}
    >
        <RowC style={{ width: '100%' }}>
            <Menu
                mode="horizontal"
                style={{ width: '100%' }} // Set Menu to take full width
                defaultSelectedKeys={[menuItem]}
                onSelect={(e:any)=> {setMenuItem(e.key)}}
                items={[renderMenuTitle('Team', 'team'),
                    renderMenuTitle('Slack', 'slack'),
                    renderMenuTitle('Webhook', 'webhook')
                ]}
            />
        </RowC>
        {
            menuItem === 'team'? <RenderTeam renderSelect={renderSelect} message={message} handleEditorChange={handleEditorChange}/> :
                menuItem === 'slack'? <RenderSlack renderSelect={renderSelect} message={message} handleEditorChange={handleEditorChange}/>:
                    menuItem === 'webhook'? <RenderWebhook
                        method={method}
                        setMethod={setMethod}
                        url={url}
                        setUrl={setUrl}
                        dataType={dataType}
                        setDataType={setDataType}
                        data={data}
                        setData={setData}
                        validUrl={validUrl}
                        headers={headers}
                        setHeaders={setHeaders}
                        setFormatError={setFormatError}
                        formatError={formatError}
                        />:
                        menuItem === 'scripts'? <RenderScript renderSelect={renderSelect}/>: null
        }

        <div style={{ marginTop: 'auto', marginBottom: 5 }}>
            <RowC>
                <CheckboxC
                    style={{marginTop: 'auto', marginBottom: 15}}
                    label='Keep Window Open'
                    checked={keepWindowOpen}
                    onChange={(e) => {
                        setKeepWindowOpen(e.target.checked)
                    }}/>
            </RowC>
        </div>

    </ModalC>
}
