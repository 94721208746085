import {AppDispatch} from "../store";
import {fetchErrorAction, fetchStartAction, fetchSuccessAction} from "./creators/common_actions";
import {
    getWithOrgProjectIdsWrapper
} from '../../services/axiosWrapper';

const api_path = '/api/run/';

export const getGithubBranches = (params:{useCache:boolean})=> (dispatch: AppDispatch): Promise<any> => {
    const request: any = getWithOrgProjectIdsWrapper(`${api_path}getGithubBranches`, params)
    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            return value;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const getTestGithubBranch = (params:{branch:string})=> (dispatch: AppDispatch): Promise<any> => {
    const request: any = getWithOrgProjectIdsWrapper(`${api_path}testGitHubBranch`, params)
    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            return value;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}