import React, {Suspense, useEffect, useState} from 'react';
import { useParams, Outlet } from 'react-router-dom';
import Sidemenu from './sidemenu';
import HeaderMenu from './header';
import {Layout} from 'antd';
import './index.css';
import {getMyProjects} from "../../redux/actions/project_actions";
import {AppDispatch} from "../../redux/store";
import {useDispatch} from "react-redux";
import {
    orgMenuItems,
    projectMenuItems,
    sideMenuItems,
    teamMenuItems,
    userMenuItems
} from "./menu_items/sidebar_menu_items";
import Spin from "antd/lib/spin";
import {LoadingOutlined} from "@ant-design/icons";
import {Project} from "../../models/entities/project";
import SkeletonLayout from "../../components/skeleton";
import {Team} from "../../models/entities/team";
import {getOrgTeams} from "../../redux/actions/team_actions";
import { safeHandleErrorResponse } from '../../assets/helpers/errorHandler';
import {useOrgContext} from "../../contexts/orgContext";
import { useProjectContext } from '../../contexts/projectContext';


function Index(): JSX.Element {
    const dispatch: AppDispatch = useDispatch();

    const {currentOrg} = useOrgContext()
    const {currentProject} = useProjectContext()

    const {org_domain, project_key, team_id, user_id, team_name}  = useParams<{
        org_domain?: string,
        project_key?: string,
        team_id?: string,
        team_name?: string,
        user_id?: string
    }>()

    // let new_menu_type:SideMenuOptions = 'org';
    let root = `/${org_domain}/`;
    let menuTitle: "Organization" | "Project" | "Team" | "User" = 'Organization'
    if(org_domain && project_key){
        menuTitle = 'Project'
        root = `${root}projects/${project_key}/`
    }else if(org_domain && team_id){
        root = `${root}teams/${team_id}/${team_name}/`
        menuTitle = 'Team'
    }else if(org_domain && user_id){
        root = `${root}user/${user_id}/`
        menuTitle = 'User'
    }else if(org_domain && !user_id && !team_id && !project_key){
        menuTitle = 'Organization'
    }

    // state
    const [selectedSideMenu, setSelectedSideMenu] = useState<any>(['0'])
    const [projects, setProjects] = useState<Project[]>([])
    const [teams, setTeams] = useState<Team[]>([])
    const [menuItems, setMenuItems] = useState<sideMenuItems[]>([])
    const [useSkeleton, setUseSkeleton] = useState<boolean>(true)


    useEffect(()=>{
        let default_menu_items = orgMenuItems(currentOrg.userPermissions)
        if(org_domain && project_key){
            default_menu_items = projectMenuItems(currentOrg.userPermissions, currentProject.info.project_type)
        }else if(org_domain && team_id){
            default_menu_items = teamMenuItems(currentOrg.userPermissions)
        }else if(org_domain && user_id){
            default_menu_items = userMenuItems()
        }else if(org_domain && !user_id && !team_id && !project_key){
            // do nothing it already has the default
        }
        setMenuItems(default_menu_items)
    }, [currentOrg, org_domain, user_id, team_id, project_key])


    useEffect(() => {
        setUseSkeleton(true)

        dispatch(getMyProjects({limit: 5}))
            .then((found_projects)=>{
                setProjects(found_projects)
            })
            .catch((err) => {safeHandleErrorResponse(err)})

        dispatch(getOrgTeams({
            org_id: currentOrg.info.id,
            limit: 5,
            teams_type: 'my_teams'
        }))
            .then(found_teams=>{
                setTeams(found_teams.content)
                setUseSkeleton(false)
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })

    }, [org_domain]);


    if(useSkeleton){
        return <SkeletonLayout/>
    }

    return <Layout style={{minHeight: "100vh", minWidth: 1100}}>
        <Sidemenu root={root}
                  menuTitle={menuTitle}
                  menu_items={menuItems}
                  selectedSideMenu={selectedSideMenu}
                  setSelectedSideMenu={setSelectedSideMenu}/>
        <Layout className="site-layout">
            <HeaderMenu org={currentOrg.info}
                        projects={projects}
                        teams={teams}
                setSelectedSideMenu={setSelectedSideMenu}/>
            <Layout.Content className="site-layout-background site-layout-content">
                <Suspense fallback={<Spin indicator={<LoadingOutlined style={{fontSize: 24}} spin/>}/>}>
                    <Outlet />{/* <RouterProvider router={getRoutes} /> */}
                </Suspense>
            </Layout.Content>
        </Layout>
    </Layout>
}

export default Index;