import React, {Dispatch, SetStateAction} from 'react';
import { List } from 'antd';
import {Buttons} from "../button";
import {RunNotifications} from "../../models/entities/run_script_notifications";


function RunNotificationsList(props:{notifications:RunNotifications[], setNotifications:Dispatch<SetStateAction<RunNotifications[]>>}): JSX.Element {

    const {notifications, setNotifications} = props

    const handleUpdateNotification = (item: RunNotifications) => {
        setNotifications((prevState) =>
            prevState.map((temp) =>
                temp.id === item.id ? { ...temp, status: !temp.status } : temp
            )
        );
    };


    return <List itemLayout="horizontal">
        {
            notifications.map((item)=>{
                return <List.Item key={item.id}>
                    <List.Item.Meta
                        avatar={<Buttons.CircleStatus
                            onClick={()=>handleUpdateNotification(item)}
                            defaultStatus={item.status}
                        />}
                        title={item.title}
                        description={item.description}
                    />
                </List.Item>
            })
        }
    </List>
}

export default RunNotificationsList;