import React, {useEffect, useState} from 'react';
import PageHeader from "../../components/page_structure/page_header";
import RowC from "../../components/row";
import {Col, notification, Space, Tooltip, Dropdown, MenuProps} from "antd";
import {Outlet} from "react-router-dom";
import {DrawerLoadTemplates} from "../../components/drawers/drawerTemplates";
import Page from "../../components/page_structure/page";
import BatchCreateLayoutStepper from "./stepper";
import {useCreateRunContext} from "../../contexts/createRunContext";
import {useProjectContext} from "../../contexts/projectContext";
import {
    CommonTemplateSettings,
    CreateBatch,
    CreateTemplate,
    RunStep,
    UpdateTemplate
} from "../../models/entities/template";
import {
    notificationAsRequest,
    RunNotifications
} from "../../models/entities/run_script_notifications";
import TimeHandler from "../../assets/helpers/timeHandler";
import {createNewTestRun} from "../../redux/actions/batch_actions";
import {safeHandleErrorResponse} from "../../assets/helpers/errorHandler";
import {createRunTemplate} from "../../redux/actions/template_actions";
import {AppDispatch} from "../../redux/store";
import {useDispatch} from "react-redux";
import {
    getAddProjectSettingsActions,
    getProjectBrowsers,
    getProjectRunArgs, getProjectRunNotifications
} from "../../redux/actions/project_actions";
import {getSections} from "../../redux/actions/section_actions";
import {getComponents} from "../../redux/actions/component_actions";
import ButtonC from "../../components/button";
import {useOrgContext} from "../../contexts/orgContext";
import {CheckOutlined, CloseOutlined, EditOutlined, SaveOutlined} from "@ant-design/icons";
import {pushIfNoDuplicate} from "../../assets/helpers/helper";


function BatchCreateLayout(): JSX.Element {
    const dispatch: AppDispatch = useDispatch();

    const {currentOrg} = useOrgContext()
    const {currentProject} = useProjectContext()
    const projectType = currentProject.info.project_type
    const {settings, args, setArgs, selection, notifications, utils} = useCreateRunContext()

    const {runTitle, setRunTitle, githubBranch, setGithubBranch, reruns, setReruns,
        maxParallel, setMaxParallel, setUseMaxParallel, run, browsers} = settings

    const {runOption, recurringOption, isRecurring, endDate, runDate, endRecurring} = run

    const {checkedBrowsers, setCheckedBrowsers, setAvailableBrowsers,
        sequence, runInterval} = browsers

    const {sections, components, cases} = selection

    const {success, setSuccess, fail, setFail, custom, setCustom} = notifications

    const [showModalTemplate, setSowModalTemplate] = useState<boolean>(false)

    const all_rules: boolean[] = [
        runTitle !== "",
        projectType === 2? true: githubBranch !== "",
        projectType !== 1? true: checkedBrowsers.length > 0,
        sections.checkedSections.checked?.length > 0
        || cases.checkedCasesIds.length > 0
        || components.checkedComponents.length > 0,
        runOption === "Scheduled" ? runDate !== null : true,  // only check runDate if runOption is "Scheduled"
        runOption === "Scheduled" && isRecurring ? endDate !== null : true
    ];
    // console.log(all_rules)
    const get_step_progress = (rules: boolean[]) => {
        const trueCount = rules.filter((rule) => rule).length
        return (trueCount / rules.length) * 100
    }

    useEffect(()=>{
        const runSteps:RunStep[] = [
            { title: "Settings", to: "settings", percent: get_step_progress([
                    all_rules[0],
                    all_rules[1],
                    all_rules[2],
                    all_rules[4],
                    all_rules[5]
                ])},
            ...(projectType !== 2
                ? [{ title: "Arguments", to: "arguments", percent: 100}]
                : []), // Only include this step if project_type is 2
            { title: "Notifications", to: "notifications", percent: 100},
            { title: "Select Cases", to: "selection", percent: get_step_progress([all_rules[3]])},
            { title: "Review", to: "review", percent: get_step_progress(all_rules)}
        ]

        utils.setSteps(runSteps)
        // , sections
    }, [runTitle, githubBranch, checkedBrowsers, runOption, runDate, endDate, sections.checkedSections, components.checkedComponents, cases.checkedCasesIds])

    useEffect(() => {
        // get  defaults from appContext
        dispatch(getAddProjectSettingsActions())
            .then((settings) => {
                setGithubBranch(settings.branch)
                setReruns(settings.runs_number)
                setMaxParallel(settings.parallels)
                setUseMaxParallel(settings.parallels === -1)
            })
            .catch((err) => { safeHandleErrorResponse(err)})


        dispatch(getSections())
            .then(foundPage => sections.setTreeSections([{
                title: currentProject.info.name,
                key: -currentProject.info.id,
                children: foundPage,
                selectable: true
            }])).catch((err) => {
            safeHandleErrorResponse(err)
        })
            .catch((err) => { safeHandleErrorResponse(err)})

        dispatch(getProjectRunNotifications())
            .then((notifications)=>{
                if(notifications.length>0){
                    let successNotifications:RunNotifications[] = []
                    let failNotifications:RunNotifications[]  = []
                    notifications.forEach((notification)=>{
                        const { id, title, description, status, on_success } = notification
                        let temp_notification:RunNotifications = {id: id, title, description, status, type: 'notifications'}
                        if(on_success){
                            successNotifications.push(temp_notification)
                        }else{
                            failNotifications.push(temp_notification)
                        }
                    })
                    setSuccess(successNotifications)
                    setFail(failNotifications)
                }
            })
            .catch((err) => { safeHandleErrorResponse(err)})

        dispatch(getComponents())
            .then(foundPage => {
                components.setComponentPage(foundPage)
            })
            .catch((err) => { safeHandleErrorResponse(err)})

        dispatch(getProjectRunArgs())
            .then(args => {
                setArgs(args)
            })
            .catch((err) => { safeHandleErrorResponse(err)})

        if(projectType !== 2){
            dispatch(getProjectBrowsers())
                .then(browsers => {
                    setAvailableBrowsers(browsers.available);
                    if(browsers.selected.length>0) setCheckedBrowsers([browsers.selected])
                })
                .catch((err) => { safeHandleErrorResponse(err)})
        }

    }, [dispatch])

    const handleLoadTemplate = (selectedOptions: any) => {
        // calculate if i can update the doc
        const newLoadedTemplates: string[] = []
        const {selection, settings, args, notifications} = selectedOptions
        if(selection) pushIfNoDuplicate(newLoadedTemplates, selection.templateId)
        if(settings) pushIfNoDuplicate(newLoadedTemplates, settings.templateId)
        if(args) pushIfNoDuplicate(newLoadedTemplates, args.templateId)
        if(notifications) pushIfNoDuplicate(newLoadedTemplates, notifications.templateId)
        utils.setLoadedTemplate(newLoadedTemplates)


        if (settings) {
            setRunTitle(settings.value.title);
            setGithubBranch(settings.value.githubBranch);
            setMaxParallel(settings.value.maxParallel);
            setReruns(settings.value.total_runs);
            // setSelectedBrowsers(settings.value.browsers);
        }

        if (args) {
            setArgs(args.value);
        }

        if (notifications) {
            setCustom(notifications.value.custom)
            setSuccess(notifications.value.success)
            setFail(notifications.value.fail)
        }

        if (selection) {
            components.setCheckedComponents(selection.value.components);
            cases.setCheckedCases(selection.value.cases);
            sections.setCheckedSections({checked: selection.value.sections, halfChecked: []});
        }

        // Close the modal after applying the settings
        // utils.setLoadedTemplate(selectedOptions.id)
        setSowModalTemplate(false);
    };

    const handleCreateBatch = () => {
        let browsers: (string | number)[] = []
        checkedBrowsers.forEach((checkedBrowser)=>{
            browsers = [...browsers, ...checkedBrowser]
        })

        const notifications:notificationAsRequest[] = []

        success.forEach(notification=>{
            if(notification.status){
                notifications.push({
                    id: notification.id,
                    entityType: notification.type,
                    status: notification.status,
                    message: '',
                    trigger: {
                        before: 1,
                        after: 1
                    }
                })
            }
        })

        fail.forEach(notification=>{
            if(notification.status){
                notifications.push({
                    id: notification.id,
                    entityType: notification.type,
                    status: notification.status,
                    message: '',
                    trigger: {
                        before: 1,
                        after: 1
                    }
                })
            }
        })

        custom.forEach(notification=>{
            if(notification.trigger.before > 0 || notification.trigger.after > 0 ) {
                notifications.push({
                    id: notification.id,
                    entityType: notification.type,
                    status: true,
                    message: notification.message,
                    trigger: {
                        before: notification.trigger.before,
                        after: notification.trigger.after
                    }
                })
            }
        })

        let data: CreateBatch = {
            projectId: currentProject.info.id,
            projectType: currentProject.info.project_type,
            settings: {
                title: runTitle,
                githubBranch,
                browsers,
                sequence: sequence === 'Continuously' ? {sequenceType: sequence} : {
                    sequenceType: sequence,
                    runInterval: runInterval
                },
                total_runs: reruns + 1,
                maxParallel,
                runSchedule: {
                    runOption,
                    start: runOption === "Manually" ? undefined : TimeHandler.getDatejsToSend(runDate),
                    recurring: isRecurring ? {
                        every: recurringOption,
                        end: endRecurring ? TimeHandler.getDatejsToSend(endDate) : undefined
                    } : undefined
                }

            },
            args,
            notifications,
            selection: {
                sections: sections.checkedSections.checked,
                components: components.checkedComponents,
                cases: cases.checkedCasesIds
            }

        }

        // setForceDisableButton(true)
        dispatch(createNewTestRun(data))
            .then((response: any) => {
                if (response.success === false) {
                    notification.error({
                        message: 'Empty Combination',
                        description: response.message,
                    });
                } else {
                    notification.success({
                        message: 'New Run Created',
                        description: 'Your cases has been reset',
                    });
                    sections.setCheckedSections({checked: [], halfChecked: []})
                    cases.setCheckedCases({})
                    components.setCheckedComponents([])
                    setCustom([])
                }
            })
            .catch((err) => {
                safeHandleErrorResponse(err)
            })

        setTimeout(() => {
            // setForceDisableButton(false)
        }, 2000);
    }

    const prepareOptions = (): CommonTemplateSettings =>  {

        return {
            settings: {
                title: runTitle,
                githubBranch,
                // browsers: checkedBrowsers,
                total_runs: reruns,
                maxParallel
            },
            args,
            notifications: {
                success,
                fail,
                custom
            },
            selection: {
                sections: sections.checkedSections.checked,
                components: components.checkedComponents,
                cases: cases.checkedCasesIds
            }
        }
    }
    const handleUpdateTemplate = () => {
        if(utils.loadedTemplate.length === 1){
            let data: UpdateTemplate = {
                id: utils.loadedTemplate[0],
                options: prepareOptions()
            }

            dispatch(createRunTemplate(data))
                .then(() => {
                    notification.success({
                        message: 'Your Template has been successfully Updated',
                        duration: 1.3
                    })
                })
        }else {
            const msg:string = utils.loadedTemplate.length === 0? 'In order to update a template, you have to load 1':
                'You can not use the update functionality since you have loaded a combination of templates.'
            notification.warning({
                message: msg,
                duration: 1.3
            })
        }
    }

    const handleCreateTemplate = (e:any) => {
        if(e.key === '0'){
            setSowModalTemplate(true)
        }else{
            const tempDate = TimeHandler.getDateToShowJanDDYYHHMM()

            const data: CreateTemplate = {
                name: `${runTitle}:  ${tempDate}`,
                projectId: currentProject.info.id,
                projectType: currentProject.info.project_type,
                options: prepareOptions()
            }

            dispatch(createRunTemplate(data))
                .then((res) => {
                    if(res.success){
                        notification.success({
                            message: 'Your Template has been successfully Created',
                            duration: 1.3
                        })
                        utils.setLoadedTemplate([res.newTemplate.id])
                    }
                })
        }
    }

    const items: MenuProps['items'] = [
        {
            label: 'Load Template',
            key: '0',
            icon: <SaveOutlined />,
            onClick: handleCreateTemplate
        },{
            label: 'Save as new Template',
            key: '1',
            icon: <SaveOutlined />,
            onClick: handleCreateTemplate
        },{
            label: 'Update Loaded Template',
            key: '2',
            disabled: utils.loadedTemplate.length !== 1,
            onClick: handleUpdateTemplate,
            icon: <EditOutlined />,
        }
    ];


    return <Page title={'Configure New Batch'}>
        <PageHeader
            title={`Configure New Batch`}
            headerButton={<Space>
                <Tooltip
                    placement={'right'}
                    title={<div>
                        <h3> * Required Fields</h3>
                        <RowC><Col flex={'30px'}>{all_rules[0]? <CheckOutlined />: <CloseOutlined />}</Col><Col flex={'auto'}> Run Title</Col></RowC>
                        {projectType !== 2? <RowC><Col flex={'30px'}>{all_rules[1]? <CheckOutlined />: <CloseOutlined />}</Col><Col flex={'auto'}> Github Branch</Col></RowC>: null}
                        {projectType === 1? <RowC><Col flex={'30px'}>{all_rules[2]? <CheckOutlined />: <CloseOutlined />}</Col><Col flex={'auto'}> Browsers</Col></RowC>: null}
                        <RowC><Col flex={'30px'}>{all_rules[3]? <CheckOutlined />: <CloseOutlined />}</Col><Col flex={'auto'}> Case Selection</Col></RowC>
                        {runOption === "Scheduled"? <RowC><Col flex={'30px'}>{all_rules[4]? <CheckOutlined />: <CloseOutlined />}</Col><Col flex={'auto'}> Start Date</Col></RowC>: null}
                        {runOption === "Scheduled" && isRecurring? <RowC><Col flex={'30px'}>{all_rules[5]? <CheckOutlined />: <CloseOutlined />}</Col><Col flex={'auto'}> End Date</Col></RowC>: null}
                    </div>}
                >
                    <ButtonC
                        disabled={!all_rules.every((item: boolean) => item)}
                        size={'small'}
                        text={'Create'}
                        onClick={handleCreateBatch}

                    />
                </Tooltip>

                {
                    currentOrg.userPermissions.project.create_templates?
                        <Dropdown.Button style={{marginTop:5}} size={'small'} menu={{items}} onClick={handleCreateTemplate} >
                            Save Template
                        </Dropdown.Button>
                        :null
                }
            </Space>}
        />

        <RowC style={{flexFlow: "nowrap"}}>
            <Col flex='160px'>
                <BatchCreateLayoutStepper/>
            </Col>
            <Col flex='25px'/>
            <Col flex='auto'>
                <Outlet />
            </Col>
        </RowC>
        <DrawerLoadTemplates
            setShowModalTemplate={setSowModalTemplate}
            showModalTemplate={showModalTemplate}
            handleOk={handleLoadTemplate}
        />
    </Page>
}

export default BatchCreateLayout
