import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';

import ButtonC from '../../components/button';

import {
    CloseCircleOutlined,
    CopyOutlined,
    DeleteOutlined,
    DownOutlined,
    EditOutlined,
    FolderFilled,
    LinkOutlined,
    MoreOutlined
} from '@ant-design/icons';
import {Dropdown, MenuProps, Space, Table} from 'antd';
import {DataNode} from "antd/lib/tree";
import {deleteCase, deleteCases, moveCases} from "../../redux/actions/cases";
import {AppDispatch} from "../../redux/store";
import {useDispatch} from "react-redux";
import {AddSection} from "../../models/entities/menu";
import {colors} from "../../assets/data/colors";
import {TestSuiteCasesTableType} from '../../models/entities/suite_section_props';
import {copyToClipboard} from "../../assets/helpers/copyToClipboard";
import CancelAction from '../../components/notification/cancel_req';
import {SectionsModal} from "../../components/modals/sections_modal";
import { TestCase } from '../../models/entities/test_case_info';
import { empty_test_case } from '../../services/EmptyEntities/EmptyTestCase';
import { NewFolderModal } from '../../components/modals/add_new_file_modal';
import { safeHandleErrorResponse } from '../../assets/helpers/errorHandler';
import {useProjectContext} from "../../contexts/projectContext";

interface DataType {
    key: React.Key;
    id: React.ReactNode;
    title: React.ReactNode;
    actions: React.ReactNode;
}

function TestSuiteCasesTable({casesOptions, sections, setIsExpandedTree, modal, cases, setCases, handleHideTestCaseInfo}: TestSuiteCasesTableType): JSX.Element {
    const dispatch: AppDispatch = useDispatch();

    const location = useLocation();
    const {currentProject} = useProjectContext()

    // unpack caseOptions
    const {selectedSections, checkedCases, setCheckedCases} = casesOptions
    const [moveModalVisible, setMoveModalVisible] = useState<boolean>(false)

    // unpack modal
    const {useModal, setOpenModal, setCreateType, setSelectedSection} = modal
    || { useModal: false, setOpenModal: () => {}, setCreateType: () => {}, setSelectedSection: () => {}}

    const [inHover, setInHover] = useState<boolean>(false)

    // move state
    const [modalTreeData, setModalTreeData] = useState<DataNode[]>(sections !== undefined?sections.treeData:[])
    const [sectionsToMove, setSectionsToMove] = useState<DataNode[]>([])
    const [section, setSection] = useState<DataNode>()
    const [sectionBeforeMove, setSectionBeforeMove] = useState<number>()
    const [specificCaseToMove, setSpecificCaseToMove] = useState<number>()
    const [moveErr, setMoveErr] = useState<string>('')
    const [selectedCase, setSelectedCase] = useState<TestCase>(empty_test_case)
    const [editModalVisibility, setEditModalVisibility] = useState<boolean>(false)
    const [copy, setCopy] = useState<boolean>(false)

    useEffect(()=>{
        if(sections && sections.treeData !== modalTreeData){
            setModalTreeData(sections.treeData)
        }
    }, [sections])

    const deleteSelectedCases = (section_id:React.Key) => {
        const case_ids = checkedCases[(section_id as number)]
        dispatch(deleteCases(case_ids))
            .then(()=>{
                // update selection
                let temp_section = {...checkedCases}
                delete temp_section[(section_id as number)]
                setCheckedCases(temp_section)

                // update actual cases
                let temp_cases = cases.filter(t_case => !case_ids.includes(t_case.id))
                setCases(temp_cases)
                // checkedCases, setCheckedCases
            })
            .catch((err) => {
                safeHandleErrorResponse(err)
            })
    }

    const handleDeleteCase = (section_id:number, case_id: number) => {
        dispatch(deleteCase(case_id)).then(()=>{
            let temp_section = {...checkedCases}

            temp_section[section_id] = (temp_section[section_id] || []).filter(temp_case_id => temp_case_id !== case_id);
            setCheckedCases(temp_section)

            // update actual cases
            let temp_cases = cases.filter(t_case => t_case.id !== case_id)
            setCases(temp_cases)
        }).catch((err) => {
            safeHandleErrorResponse(err)
        })
    }

    const focusTree = () => {
        const elements = document.getElementsByClassName('ant-tree-list-holder')

        elements[0].classList.add('focus-effect')

        setTimeout(() => {
            elements[0].classList.remove('focus-effect');
        }, 700);
    }

    const groupedBySection: { [sectionId: number]: DataType[] } = cases.reduce((result, item) => {
        const sectionId = item.section_id;
        if (!result[sectionId]) {
            result[sectionId] = [];
        }
        const onMenuClick: MenuProps['onClick'] = (e) => {
            if (e.key === `edit-${item.id}`) {
                // console.log('TODO: EDIT')
            }
        }

    function stripHtml(html: string): string {
            let doc = new DOMParser().parseFromString(html, 'text/html');
            return doc.body.textContent || "";
          }

        result[sectionId].push({
            key: item.id,
            id: item.project_cid,
            title:
                <div style={{cursor: 'pointer', display: 'inline-block'}}
                     onClick={() => {handleHideTestCaseInfo(true, item)}}>
                    {item.title}
                </div>,
            actions:
                <Dropdown
                    menu={{
                        items: [{
                            key: `edit-${item.id}`,
                            label: <><EditOutlined/> Edit</>,
                            onClick: () => {
                                setCopy(false)
                                setSelectedCase(item)
                                setEditModalVisibility(true)
                            }
                        }, {
                            key: `move-${item.id}`,
                            label: <><img alt='Move Favicon' style={{width: 14}} src={'/icons/transfer.png'}/> Move</>,
                            onClick: () => {
                                setMoveModalVisible(true)
                                setSectionBeforeMove(item.section_id)
                                setSpecificCaseToMove(item.id)
                            }
                        }, {
                            key: `copy-steps`,
                            label: <><CopyOutlined/> Copy Steps</>,
                            onClick: () => {
                                if (typeof item.steps === 'string') {
                                let steps: any = item.steps ? JSON.parse(item.steps) : []
                                copyToClipboard(
                                    Array.isArray(steps)
                                      ? steps.map((item: string) => stripHtml(item)).join(", ")
                                      : ""
                                  );}
                            }
                        }, {
                            key: `duplicate-${item.id}`,
                            label: <><img alt='Duplicate Favicon' style={{width: 14}}
                                          src={'/icons/duplicate.png'}/> Duplicate</>,
                            onClick: () => {
                                const section: any = selectedSections?.find(section => section.key === item.section_id);

                                setCopy(true)
                                setSelectedCase(item)
                                setSection(section)
                                setEditModalVisibility(true)
                            }
                        }, {
                            key: `new-${item.id}`,
                            label: <a style={{float: 'left'}}
                                      target="_blank"
                                      rel="noreferrer"
                                      href={`${location.pathname}/${item.id}/info`}>
                                <LinkOutlined/> New Tab
                            </a>
                        }, {
                            key: `delete-${item.id}`,
                            label: <div><DeleteOutlined/> Delete</div>,
                            onClick: () => {handleDeleteCase(item.section_id, item.id)}
                        }],
                        onClick: onMenuClick
                    }}
                    trigger={['click']}
                    arrow>

                    <ButtonC
                        icon={<MoreOutlined style={{color: colors.green.forest}}/>}
                        size='small'
                        shape='circle'
                    />
                </Dropdown>
        });
        return result;
    }, {} as { [sectionId: number]: DataType[] });

    const handleFooter = (section: DataNode) => {
        return useModal ?
            <div style={{display: "flex", whiteSpace: "nowrap"}}>
                Add&nbsp;
                <div style={{cursor: 'pointer'}}
                     onClick={() => {
                         handleModal('Section', section)
                     }}
                >Section
                </div>
                &nbsp;or&nbsp;
                <div style={{cursor: 'pointer'}}
                     onClick={() => {
                         handleModal('Case', section)
                     }}
                >Case&nbsp;</div>
            </div>
            : undefined
    }
    const handleModal = (menuType: AddSection, section: DataNode) => {
        if (useModal) {
            setSelectedSection(section)
            setCreateType(menuType)
            setOpenModal(true)
        }
    }

    const removeSelectedSection = (section:DataNode) => {
        if(sections?.select?.selectedSections){
            let temp_selected_options = sections?.select?.selectedSections.filter(section_temp=> section_temp.key!== section.key)
            sections?.select?.setSelectedSections(temp_selected_options)
        }
    }

    const onSelectChange = (section_id: React.Key, newSelectedRowKeys: React.Key[]) => {
        let new_selectedRowKeys = {...checkedCases};
        new_selectedRowKeys[(section_id as number)] = newSelectedRowKeys

        setCheckedCases(new_selectedRowKeys);

        if (groupedBySection[(section_id as number)].length === newSelectedRowKeys.length){
            // console.log('here')
        }else{

        }
    };

    const handleMoveCase = () => {
        if(sectionsToMove.length>0){
            if(sectionBeforeMove){

                const case_ids = specificCaseToMove ? [specificCaseToMove] : checkedCases[sectionBeforeMove] as number[]
                if(sectionBeforeMove === sectionsToMove[0].key){
                    setMoveErr(`You must select another section, your selected case${case_ids.length>1?'s':''} are already in section ${sectionsToMove[0].title}`)
                }else{
                    dispatch(moveCases({
                        project_id: currentProject.info.id,
                        section_id: sectionsToMove[0].key as number,
                        case_ids
                    }))
                        .then(()=>{
                            // handle checkboxes
                            let temp_section = {...checkedCases}
                            temp_section[sectionBeforeMove] = temp_section[sectionBeforeMove] && temp_section[sectionBeforeMove]
                                .filter(temp_case_id => !case_ids.includes(temp_case_id as number))
                            setCheckedCases(temp_section)

                            // update actual cases
                            let temp_cases = cases.map(t_case => {
                                if(!case_ids.includes(t_case.id)){
                                    return t_case
                                }
                                let temp_case = {...t_case}
                                temp_case.section_id= sectionsToMove[0].key as number
                                return temp_case
                            })
                            setCases(temp_cases)
                        })
                        .catch((err) => {
                            safeHandleErrorResponse(err)
                        })
                    setMoveErr('')
                    setMoveModalVisible(false)
                }
            }

        }else{
            setMoveErr('Please choose 1 section')
        }
    }

    const getColumns = (section_id: React.Key): ({ dataIndex: string; width: string; title: React.ReactNode; key: string } | { dataIndex: string; width: string; title: string; key: string } | { dataIndex: string; width: string; title: JSX.Element; key: string })[] => {

        const s_checked_cases = checkedCases[(section_id as number)]
        const selected_cases: number = s_checked_cases ? s_checked_cases.length : 0
        return [
            {title: 'ID', dataIndex: 'id', key: 'id', width: '7%'},
            {title: 'Title', dataIndex: 'title', key: 'title', width: '83%'},
            {
                title: <>
                    <Dropdown
                        disabled={selected_cases === 0}
                        menu={{
                            items: [{
                                key: `copy-id`,
                                label: <><CopyOutlined/> Copy IDs</>,
                                onClick: () => {
                                    copyToClipboard(s_checked_cases.join(', '))
                                }
                            }, {
                                key: `copy-title`,
                                label: <><CopyOutlined/> Copy Titles</>,
                                onClick: () => {
                                    let titles = groupedBySection[(section_id as number)]
                                        .filter(t_case => s_checked_cases.includes(t_case.id as React.Key))
                                        .map(selected => {
                                            const selectedWithTypeAssertion = selected as { title: { props: any } };
                                            return selectedWithTypeAssertion.title.props.children})
                                        .join(', ')
                                    copyToClipboard(titles)
                                }
                            }, {
                                key: `mass-move`,
                                label: <><img alt='Move Favicon' style={{width: 14}}
                                              src={'/icons/transfer.png'}/> Move</>,
                                onClick: () => {
                                    setMoveModalVisible(true)
                                    setSectionBeforeMove(section_id as number)
                                    setSpecificCaseToMove(undefined)
                                }
                            }, {
                                key: `mass-delete`,
                                label: <CancelAction
                                    action={() => {}}
                                ><DeleteOutlined/> Delete</CancelAction>,
                                onClick: () => {
                                    deleteSelectedCases(section_id)
                                }
                            }]
                        }}
                        trigger={['click']}
                    >
                        <a>
                            <Space>
                                Actions
                                <DownOutlined/>
                            </Space>
                        </a>
                    </Dropdown>
                    <div style={{fontSize: 11}}>selected {selected_cases}</div>
                </>, dataIndex: 'actions', key: 'actions', width: '10%'
            },
        ];
    }

    return (
        <>
            {
                selectedSections && selectedSections.length > 0 ?
                    selectedSections.map((section) => {
                        return (
                            <div key={section.key}>
                                <h3
                                    style={{marginBottom:30}}
                                    onMouseEnter={()=>setInHover(true)}
                                    onMouseLeave={()=>setInHover(false)}
                                >
                                    <div style={{display: 'inline-block'}}>
                                        <FolderFilled style={{color:colors.yellow.default, opacity: '85%'}}/> {`${section.title}`}
                                        {
                                            inHover?
                                                <div style={{cursor: 'pointer', marginTop:1, float:'right'}}
                                                     onClick={()=>removeSelectedSection(section)}>
                                                &nbsp; <CloseCircleOutlined />
                                                 </div>
                                                :null
                                    }
                                    </div><br/>
                                    <div style={{float:'left', marginTop:2, fontSize:12, color:colors.gray.shadow}}>ID: {`${section.key}`}</div>
                                </h3>
                                <Table
                                    columns={getColumns(section.key)}
                                    rowSelection={{
                                        selectedRowKeys: section ? checkedCases[(section.key as number)] : [],
                                        onChange: (keys) => {
                                            onSelectChange((section.key as number), keys)
                                        }
                                    }}
                                    dataSource={groupedBySection[(section.key as number)]}
                                    size="small"
                                    footer={() => useModal? handleFooter(section):null}
                                    pagination={false}
                                />
                            </div>
                        )
                    })
                    : <h3 style={{cursor: 'pointer'}}
                          onClick={() => {
                              if(setIsExpandedTree){
                                  setIsExpandedTree(true);
                              }
                              focusTree()
                          }}>
                        Please Select A Section
                    </h3>
            }
            {
                sections?
                    <SectionsModal
                        modalOptions={{
                            title: 'Select New Section',
                            visible: moveModalVisible,
                            setVisible: setMoveModalVisible,
                            buttonText: 'Move',
                            handleOk: handleMoveCase,
                            errMsg: moveErr
                        }}
                        sections={{
                            treeData:modalTreeData,
                            setTreeData:setModalTreeData,
                            select:{
                                selectedSections:sectionsToMove,
                                setSelectedSections: setSectionsToMove
                            }
                        }}
                    />
                    :null
            }
            {
                <NewFolderModal
                    visible={editModalVisibility}
                    setVisible={setEditModalVisibility}
                    createType={'Case'}
                    selectedCase={selectedCase}
                    setSelectedCase={setSelectedCase}
                    copy={copy} setCopy={setCopy}
                    selectedSection={section}
                    setSelectedSection={setSelectedSection}
                    treeData={modalTreeData}
                    setTreeData={setModalTreeData}
                    selectedSections={selectedSections}
                    cases={cases}
                    setCases={setCases}
            />
}

        </>
    );
}

export default TestSuiteCasesTable;
