import { colors } from "../../assets/data/colors";
import { Job } from "../../models/entities/job";

export const empty_job:Job = {
    id: 0,
    name: "",
    description: "",
    org_id: 0,
    color: colors.green.bamboo
}
