import React, {useCallback, useEffect, useRef, useState} from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'quill-mention';
import 'quill-mention/dist/quill.mention.css';
import {Col, Row} from 'antd';
import './rich_editor.css';
import Quill from 'quill';
import Mention from 'quill-mention';
import EmojiPicker from 'emoji-picker-react';
import {useUserContext} from "../../contexts/userContext";
import {useTeamContext} from "../../contexts/teamContext";


interface MentionModule {
    allowedChars: RegExp;
    mentionDenotationChars: string[];
    source: (
        searchTerm: string,
        renderItem: (items: { id: number; value: string }[] | undefined, searchTerm: string) => void,
        mentionChar: string,
    ) => void;
}

interface Props {
    atValues?: { id: number; value: any }[] | Record<string, any>,
    onChange: (content: any) => void,
    height?: string,
    toolbarOptions?: Record<string, any>,
    formatOptions?: string[],
    value?: string,
    placeholder?: string
}

const RichEditor: React.FC<Props> = function (props: Props) {
    const {atValues, value, onChange, toolbarOptions, formatOptions, placeholder} = props;
    const quillRef = useRef<ReactQuill | null>(null);

    const {loggedInUser} = useUserContext();
    const {currentTeam} = useTeamContext();

    const [emojiPickerVisible, setEmojiPickerVisible] = useState(false);

    const [editorValue, setEditorValue] = useState(value);
    const [editorKey, setEditorKey] = useState(0);

    const handleEditorChange = (content: any) => {
        const value = content ===  "<p><br></p>"? '': content

        setEditorValue(value);
        onChange(value);
    };

    const onEmojiClick = (emojiObject: any) => {
        const quill = quillRef.current?.getEditor();
        if (quill) {
            quill.focus(); // Focus the editor
            const cursorPosition = quill.getSelection()?.index || 0;
            quill.insertText(cursorPosition, emojiObject.emoji);
            handleToggleEmojiPicker();
        }
    };

    const handleToggleEmojiPicker = () => {
        quillRef.current?.getEditor().focus()
        setEmojiPickerVisible(!emojiPickerVisible);
    };

    useEffect(() => {
        setEditorValue(value)
    }, [value])


    useEffect(() => {
        if (quillRef.current) {
            const quill = quillRef.current.getEditor();

            if (quill) {
                const mentionModuleOptions: MentionModule = {
                    allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
                    mentionDenotationChars: ['@'],
                    source: (
                        searchTerm: string,
                        renderItem: (items: { id: number; value: string }[] | undefined, searchTerm: string) => void,
                        mentionChar: string,
                    ) => {
                        if (mentionChar === '@') {
                            const matches = atValues?.filter((item: any) =>
                                item.value.toLowerCase().includes(searchTerm.toLowerCase()),
                            );
                            renderItem(matches, searchTerm);
                        }
                    },
                };

                Quill.register('modules/mention', Mention);
                const mentionModule = quill.getModule('mention') as any;
                if (mentionModule) {
                    mentionModule.options = mentionModuleOptions;

                    // Trigger a re-render by updating the key
                    setEditorKey((prevKey) => prevKey + 1);
                }
            }
        }
    }, [atValues, currentTeam, setEditorKey]);

    const modules = {
        mention: {
            allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
            mentionDenotationChars: ['@'],
            source: useCallback(
                async (
                    searchTerm: string,
                    renderItem: (arg0: { id: number; value: string }[] | undefined, arg1: any) => void,
                    mentionChar: string,
                ) => {
                    if (mentionChar === '@') {
                        const matches = atValues?.filter((item: any) =>
                            item.value.toLowerCase().includes(searchTerm.toLowerCase()),
                        );
                        renderItem(matches, searchTerm);
                    }
                },
                [atValues, currentTeam],
            ),
        },

        toolbar: toolbarOptions || [
            [
                // { 'header': [1, 2, false] }, {'color': []},
                'bold', 'italic', 'underline', 'strike', 'blockquote',
                {'list': 'ordered'}, {'list': 'bullet'},
                'link', 'image',
                // 'video',
                {'indent': '-1'}, {'indent': '+1'},
                {'script': 'sub'}, {'script': 'super'}, {'color': []}]
        ],
    };

    const formats = formatOptions || [
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'list',
        'bullet',
        'indent',
        'link',
        'image',
        // 'video',
        'color',
        'script',
        'mention',
    ];

    return <Row>
        <Col style={{flex: '0 0 10px'}}>
            <img
                src={'/user_images/Happy.png'}
                onClick={handleToggleEmojiPicker}
                style={{width: 18, height: 'auto', marginTop: 12, maxWidth: 18}}
            />
        </Col>
        <Col style={{flex: '1'}}>
            <ReactQuill
                key={editorKey} // Use key to force re-render
                placeholder={placeholder ? placeholder : `${loggedInUser?.name}, share your thoughts...`}
                ref={(ref) => {
                    quillRef.current = ref;
                }}
                modules={modules}
                onChange={handleEditorChange}
                formats={formats}
                value={value !== '' ? editorValue : ''}
            />
            {emojiPickerVisible && (
                // <div style={{ marginTop: 10 }}><Picker onSelect={handleSelectEmoji}/></div>
                <div style={{marginTop: 10}}><EmojiPicker onEmojiClick={onEmojiClick}/></div>
            )}
        </Col>
    </Row>
};

export default React.memo(RichEditor);
