import React, { useState } from 'react';
import { InputNumber, Button, Typography, Space } from 'antd';
import {colors} from "../../assets/data/colors";
import { Buttons } from '../button';

const { Text } = Typography;

const loremIpsumText = `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`;

const generateLoremIpsum = (length: number): string => {
    if (length <= 0) return '';
    const repeatedText = loremIpsumText.repeat(Math.ceil(length / loremIpsumText.length));
    return repeatedText.slice(0, length);
};

const LoremIpsumGenerator: React.FC = () => {
    const [length, setLength] = useState<number>(100); // Default length
    const [text, setText] = useState<string>(generateLoremIpsum(100));

    const handleGenerate = () => {
        setText(generateLoremIpsum(length));
    };

    return <>
        <p style={{backgroundColor: colors.gray.background, padding: 25}}>
            <Text strong>Description:</Text> Enter the desired number of characters in the box below (1 to 10,000) and click "Generate Lorem Ipsum" to create a block of placeholder text with that length.
        </p>
        <Space direction="horizontal" size="large" style={{ width: '100%', padding: '20px', marginLeft:'15%'}}>
            <InputNumber
                min={1}
                max={10000}
                value={length}
                onChange={(value) => setLength(value || 0)}
                style={{ width: '200px' }}
                placeholder="Enter length in characters"
            />
            <Button type="primary" onClick={handleGenerate}>
                Generate Lorem Ipsum
            </Button>
            <Buttons.CopyToClipboard text={text}/>
        </Space>
        <div style={{padding: 25}}>
            <Text>{text}</Text>
        </div>
    </>
};

export default LoremIpsumGenerator;
