import React, {ComponentType} from 'react';
import {Navigate} from 'react-router-dom';
import {stringHasValue} from "../assets/helpers/helper";
import {useUserContext} from "../contexts/userContext";

interface WithAuthProps {
    // Define any additional props if needed
}

const withAuth = <P extends WithAuthProps>(WrappedComponent: ComponentType<P>): React.FC<P> => {
    return (props: P) => {
        const {userToken} = useUserContext();
        const isAuthenticated = stringHasValue(userToken)

        if (!isAuthenticated) {
            return <Navigate to="/login"/>;
        }

        return <WrappedComponent {...props} />;
    }
}

// Helper function to create a React element wrapped with the HOC
const withAuthElement = (Component: ComponentType<any>) => {
    const WrappedComponent = withAuth(Component)
    return <WrappedComponent />
};

export default withAuthElement
