import {Argument, ArgumentScriptSetting} from "../../models/entities/settings";

export const empty_argument: ArgumentScriptSetting = {
    title: '', 
    label: '', 
    default_value: '', 
    status: false,
    org_id: -1,
    project_id: -1,
    id: -1,
    notify: {brs: false, ber: false, aer: false, are: false},
}

export const empty_arg:Argument = {
    id: 0,
    label: '',
    value: '',
    arg_key: '',
    status: true
}