import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import { AppDispatch } from "../../redux/store";
import { useDispatch } from "react-redux";
import {Space, Table, TableColumnsType} from "antd";
import {CommonSelection} from "../../models/entities/template";
import MenuC from "../../components/menu";
import {getSectionsByIds} from "../../redux/actions/section_actions";
import {getCases} from "../../redux/actions/cases";
import {getComponents} from "../../redux/actions/component_actions";

interface PropsSelection {
    selection: CommonSelection
}

interface PropsSelectionEntity {
    ids: React.Key[],
    search: string,
    setSearch: Dispatch<SetStateAction<string>>
}


const RunTemplateSelectionSections: React.FC<PropsSelectionEntity> = ({ids, search, setSearch}) => {
    const dispatch: AppDispatch = useDispatch()

    const [data, setData] = useState<any[]>([])
    const [loading, setLoading] = useState<boolean>(true)

    const columns: TableColumnsType<any> | undefined = [{
        title: 'Section Title',
        dataIndex: 'title',
        key: "title",
        width: '40%'
    },{
        title: 'Parent Path',
        dataIndex: 'path',
        key: "path",
    }]

    useEffect(()=>{
        if(ids && ids.length>0){
            dispatch(getSectionsByIds({sectionIds: ids}))
                .then(results=>{
                    console.log(results)
                    setLoading(false)
                })
        }else{
            setLoading(false)
        }
    }, [dispatch])

    return <><Table columns={columns} dataSource={data} loading={loading}/></>
}

const RunTemplateSelectionComponents: React.FC<PropsSelectionEntity> = ({ids, search, setSearch}) => {
    const dispatch: AppDispatch = useDispatch()

    const [data, setData] = useState<any[]>([])
    const [loading, setLoading] = useState<boolean>(true)

    const columns: TableColumnsType<any> | undefined = [{
        title: 'Component Title',
        dataIndex: 'title',
        key: "title",
        width: '40%'
    },{
        title: 'Description',
        dataIndex: 'description',
        key: "description",
    }]

    useEffect(()=>{
        if(ids && ids.length>0){
            dispatch(getComponents({componentIds: ids}))
                .then(results=>{
                    console.log(results)
                    setLoading(false)
                })
        }
    }, [dispatch])

    return <><Table columns={columns} dataSource={data} loading={loading}/></>
}

const RunTemplateSelectionCases: React.FC<PropsSelectionEntity> = ({ids, search, setSearch}) => {
    const dispatch: AppDispatch = useDispatch()

    const [data, setData] = useState<any[]>([])
    const [loading, setLoading] = useState<boolean>(true)

    const columns: TableColumnsType<any> | undefined = [{
        title: 'Case Title',
        dataIndex: 'title',
        key: "title",
        width: '40%'
    },{
        title: 'Section Path',
        dataIndex: 'path',
        key: "path",
    }]

    useEffect(()=>{
        if(ids && ids.length>0){
            dispatch(getCases({caseIds: ids, search}))
                .then(results=>{
                    console.log(results)
                    setLoading(false)
                })
        }
    }, [dispatch])

    return <><Table columns={columns} dataSource={data} loading={loading}/></>
}

const RunTemplateSelection: React.FC<PropsSelection> = ({ selection }) => {
    const [searchSections, setSearchSections] = useState<string>('')
    const [searchComponents, setSearchComponents] = useState<string>('')
    const [searchCases, setSearchCases] = useState<string>('')

    const [component, setComponent] = useState<React.ReactNode>(<></>)

    const [menuSelected, setMenuSelected] = useState<'Section' | 'Components' | 'Cases'>('Section')


    const {cases, components, sections} = selection

    useEffect(()=>{
        if(menuSelected === "Section"){
            setComponent(<RunTemplateSelectionSections ids={sections} search={searchSections} setSearch={setSearchSections}/>)
        } else if(menuSelected === "Components"){
            setComponent(<RunTemplateSelectionComponents ids={components} search={searchComponents} setSearch={setSearchComponents}/>)
        } else if(menuSelected === "Cases"){
            setComponent(<RunTemplateSelectionCases ids={cases} search={searchCases} setSearch={setSearchCases}/>)
        }
    }, [menuSelected])


    return (
        <Space direction="vertical" size="middle" style={{ display: "flex" }}>
            <MenuC items={[{
                label: `Sections (${selection.sections?selection.sections.length: 0})`,
                onClick: () => {setMenuSelected('Section')}
            },{
                label: `Components (${selection.components?selection.components.length: 0})`,
                onClick: () => {setMenuSelected('Components')}
            },{
                label: `Cases (${selection.cases?selection.cases.length: 0})`,
                onClick: () => {setMenuSelected('Cases')}
            }]}
            mode={'horizontal'}
            />
            {component}
        </Space>
    );
};

export default RunTemplateSelection;
