
import React, { useEffect, useState} from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../redux/store';
// models
import { OrgUsersInfo } from '../../models/entities/user';
import { PageResource } from "../../models/dtos/page_resource";
import { createEmptyPage } from "../../services/utils/PageResourceUtils";
import { empty_org_user_info } from "../../services/EmptyEntities/EmptyUser";
// components
import Page from '../../components/page_structure/page';
import PageHeader from '../../components/page_structure/page_header';
import PageContent from '../../components/page_structure/page_content';
// antd
import { CheckCircleOutlined, SyncOutlined, UserAddOutlined, UserDeleteOutlined } from '@ant-design/icons';
import { Button, Input, Select, Space, Tag } from 'antd';
// actions
import {deleteOrgUser, getOrgUserInfo, updateOrgRole, updateOrgUser, updateUserOrgJobs} from "../../redux/actions/user_actions";
import { Link } from 'react-router-dom';
import { UserModal } from '../../components/modals/user_modal';
import TableUsers from "../../components/tables/table_users";
import {allowedExtraUserColumns} from "../../models/entities/helpers/table";
import { safeHandleErrorResponse } from '../../assets/helpers/errorHandler';
import {useOrgContext} from "../../contexts/orgContext";
import { colors } from '../../assets/data/colors';
import { Job } from '../../models/entities/job';
import { getJobs } from '../../redux/actions/job_actions';

const { Search } = Input;

function OrgUsers(): JSX.Element {
    const dispatch: AppDispatch = useDispatch();
    const {currentOrg} = useOrgContext();

    const org_id = currentOrg.info.id

    const [userPage, setUsersPage] = useState<PageResource<OrgUsersInfo>>(createEmptyPage());
    const [selectedUser, setSelectedUser] = useState<OrgUsersInfo>(empty_org_user_info);
    const [visible, setVisible] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('')
    const [selectedRoles, setSelectedRoles] = useState<number[]>([])
    const [selectedJobs, setSelectedJobs] = useState<number[]>([])
    const [jobs, setJobs] = useState<Job[]>([]);
    const [editedUserId, setEditedUserId] = useState(0);

    useEffect(() => {
            dispatch(getOrgUserInfo(search)).then(users => {
                setUsersPage(users)
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })

            dispatch(getJobs())
                        .then(foundPage => setJobs(foundPage.content)).catch((err) => {
                            safeHandleErrorResponse(err)
                        })
        
    }, [dispatch, currentOrg])

    let users = userPage.content
    const extra_columns: allowedExtraUserColumns[] = currentOrg.userPermissions.organization.manage_org_users ? ['jobs', 'teams', 'actions']: ['jobs', 'teams']

    const deleteUsers = (user_ids: number[]) => {
        dispatch(deleteOrgUser(user_ids))
            .then(() => {
                // console.log('users', users)
                // console.log('user_ids', user_ids)
                // Filter out the checkedUsers from the users array
                let updatedContent = users.filter((user: OrgUsersInfo) => !user_ids.includes(user.user_info.id));
                // console.log('updatedContent', updatedContent)

                // Update the content property in updatedList
                let updatedList: any = {
                    ...userPage,  // Assuming props.userPage contains other properties you want to keep unchanged
                    content: updatedContent
                };

                setUsersPage(updatedList);
            }).catch((err) => {
                safeHandleErrorResponse(err)
            });
    }

    const fetchData = async () => {
        dispatch(getOrgUserInfo(search, selectedRoles, selectedJobs)).then(foundPage => {
            setUsersPage(foundPage);
    }).catch((err) => {
        safeHandleErrorResponse(err)
    })}

    useEffect(() => {
        fetchData();
    }, [dispatch, selectedJobs, selectedRoles])

    const updateUserRole = async (user: OrgUsersInfo) => {
        dispatch(updateOrgRole({org_id: org_id, id: user.user_info.id})).then((found) => {
            setUsersPage(prevUsers => ({
                ...prevUsers,
                content: prevUsers.content.map(u => 
                    u.user_info.id === user.user_info.id ? { ...u, role: found.new_role, role_label: found.new_label, permission: found.permissions } : u
                )
            }));
        }).catch((err) => {
            safeHandleErrorResponse(err)
        });
    };

    const constructJobs = () => {
            return jobs.map(job => {
                return <Select.Option key={job.id} value={job.id} label={job.name}>
                            {job.name}
                       </Select.Option>
            });
        }

        const updateJobs = (user: OrgUsersInfo, event: number[]) => { 
            dispatch(updateUserOrgJobs({ org_id: org_id, user_id: user.user_info.id, new_job_ids: event }))
                .then((found) => { // `found` is the updated list of job IDs from the backend        
                    if (userPage && userPage.content) {
                        const updatedUsers = userPage.content.map(u => {
                            if (u.user_info.id === user.user_info.id) {
                                // Replace `user_jobs_info` completely with the updated list of jobs
                                const updatedJobs: Job[] = found.map(job => ({
                                    id: job.id,
                                    org_id: job.org_id,
                                    name: job.name,  // Placeholder name since only IDs are returned
                                    color: job.color,
                                    description: job.description // Default empty description since only IDs are returned
                                }));
        
                                // Return a fully updated user object
                                return {
                                    ...u,
                                    user_jobs_info: updatedJobs
                                };
                            }
                            return u;
                        });
        
                        // Update the state with the modified content
                        setUsersPage({ ...userPage, content: updatedUsers });
                    }
                })
                .catch((err) => {
                    safeHandleErrorResponse(err);
                });
        }

        const handleReset = () => {
            setSelectedRoles([])
            setSelectedJobs([])
            setSearch('')
        }

        const handleRolesChange = (event: number[]) => {
            setSelectedRoles(event);
        };

        const handleJobsChange = (event: number[]) => {
            setSelectedJobs(event);
        };
        

    const setData = () => {
        let data: any = [];
        if(users){
            users.forEach((user)=> {
                data.push({
                    key: user.user_info.id,
                    name: <Link to={`/${currentOrg.info.domain}/users/${user.user_info.id}/view`} title="Go To Members Profile" style={{ color: "inherit"}}>{`${user.user_info.lastname} ${user.user_info.name}`}</Link>,
                    email: user.user_info.email,
                    // teams: user.user_teams_info ? 
                    //         [user.user_teams_info.map( (team) =>{
                    //             return <Tag key={`UT-${user.user_info.id}-${team.id.toString()}`}> {team.name} </Tag>
                    //         })]: [],
                    jobs: !(editedUserId === user.user_info.id) ? Array.isArray(user.user_jobs_info) && user.user_jobs_info.length > 0 ?
                            [user.user_jobs_info.map( (job) => {
                                return <Tag style={{cursor: "pointer"}} color={job.color} key={`UJ-${job.id.toString()}`} onClick={() => {setEditedUserId(user.user_info.id)}}> {job.name} </Tag>
                            })] : <div style={{color: colors.green.bamboo, cursor: "pointer"}} key={`UJU-${user.user_info.id}`} onClick={() => {setEditedUserId(user.user_info.id)}}> No Jobs </div> :
                            <><Select
                                mode="multiple"
                                defaultValue={user.user_jobs_info.map(job => job.id)}
                                style={{ width: '80%' }}
                                placeholder="Select jobs"
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())}
                                onChange={(selectedValues) => updateJobs(user, selectedValues)}
                            >{constructJobs()}</Select>
                            <Button
                            style={{marginLeft: 10}}
                            size='small'
                            shape='circle'
                            onClick={() => setEditedUserId(0)}
                            icon={<CheckCircleOutlined
                                style={{color: colors.green.forest}}/>}/></>, 
                    role: user.role_label, 
                    actions:
                        currentOrg.userPermissions.organization.manage_org_users ?
                      (
                        <Button type='text' disabled={user.role === 1} onClick={() => updateUserRole(user)}>
                          {user.role === 1 || user.role === 2 ? <div><UserAddOutlined /> Remove as Admin </div> : <div><UserDeleteOutlined /> Add as Admin </div>}
                        </Button>
                          
                      )
                      : null
                });
            });
        }
        return data;
    } 

    const buttonHeader = currentOrg.userPermissions.organization.manage_org_users
        ? { type: "create" as const, onClickFunction: () => setVisible(true) }
        : null;

    return (
        <>
            <Page title={`${currentOrg.info.name} Users`}>
                <PageHeader title={`${currentOrg.info.name} Users`}
                            buttonHeader={buttonHeader}/>
                <PageContent>
                
                <Select
                        mode="multiple"
                        style={{marginLeft: 10, width: 200}}
                        placeholder="Select Role(s)"
                        value={selectedRoles}
                        onChange={handleRolesChange}
                        optionLabelProp="label"
                    >
                    <Select.Option 
                            key={1}
                            value={1} 
                            label={`Owner`}>
                            <Space>Owner</Space>
                        </Select.Option>
                        <Select.Option 
                            key={2}
                            value={2} 
                            label={`Admin`}>
                            <Space>Admin</Space>
                        </Select.Option>
                        <Select.Option 
                            key={5}
                            value={5} 
                            label={`Employee`}>
                            <Space>Employee</Space>
                        </Select.Option>
                        </Select>
                    <Select
                        mode="multiple"
                        style={{marginLeft: 10, width: 200}}
                        placeholder="Select Job(s)"
                        value={selectedJobs}
                        onChange={handleJobsChange}
                        optionLabelProp="label"
                        filterOption={(input, option) =>
                            (option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())}
                        >
                        {constructJobs()}
                    </Select>
                    <Search
                    style={{marginLeft: 10, marginBottom: 20, width: 200}}
                    placeholder="Search for user"
                    onSearch={fetchData}
                    value={search}
                    onChange={(value)=>setSearch(value.target.value)}
                />
                <Button
                        icon={<SyncOutlined/>}
                        style={{float: "right", marginRight: 10}}
                        onClick={handleReset}
                        loading={false}>Reset</Button>
                    <TableUsers
                        data = {setData()}
                        deleteUserAction={deleteUsers}
                        permissions={currentOrg.userPermissions.organization.manage_org_users}
                        limit={userPage.limit}
                        // fetchFilteredUsers={(value_type: string, value:React.Key | boolean) => true}
                        extra_columns={extra_columns}
                    />
                    <UserModal  setVisible={setVisible}
                                visible={visible}
                                user={selectedUser}
                                userPage={userPage}
                                setUserPage={setUsersPage}
                                setUser={setSelectedUser}
                    />
                </PageContent>
            </Page>
        </>
    )
}

export default OrgUsers;
