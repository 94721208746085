import React, {Dispatch, SetStateAction, useEffect, useMemo, useState} from "react";
import {Button, Collapse, Checkbox, Input, Menu, Col, Divider, Space, Dropdown, Empty, Table} from "antd";
import { colors } from "../../assets/data/colors";
import {LoadTemplatePage, notificationItems, RunTemplateOption} from "../../models/entities/template";
import { PageResource } from "../../models/dtos/page_resource";
import { AppDispatch } from "../../redux/store";
import { useDispatch } from "react-redux";
import { createEmptyPage } from "../../services/utils/PageResourceUtils";
import { CollapseProps } from "antd/lib";
import CheckboxC from "../checkbox";
import {deleteTemplate, fetchRunTemplates, updateFavorite, updateTemplate} from "../../redux/actions/template_actions";
import {safeHandleErrorResponse} from "../../assets/helpers/errorHandler";
import RowC from "../row";
import {
    ClusterOutlined,
    DeleteOutlined, DesktopOutlined,
    EditOutlined,
    EyeOutlined,
    FileSyncOutlined,
    FormOutlined, GithubFilled,
    MoreOutlined, RedoOutlined,
    StarFilled
} from "@ant-design/icons";
import debounce from "lodash/debounce";
import CancelAction from "../notification/cancel_req";
import Drawer from "../drawer";
import {useUserContext} from "../../contexts/userContext";
import TPTexts from "../../sds/text";
import RunTemplateSelection from "../../views/run/run_template_selection";
import {Buttons} from "../button";
import {RenderCollapseNotification} from "../../views/run/run_notifications";
import {RenderNotificationTitle, RenderOptions} from "../tables/tableRunCustomNotifications";

type itemsProps = { items:
        { title: React.ReactNode, trigger: React.ReactNode }[]
}

const renderSingleInputOption = (key:number, icon:React.ReactNode, title?:string, value?:React.Key, pixels='100px'):React.ReactNode => {
    if(value === undefined || value === null || title === undefined) return <></>

    return <RowC key={`${key}-${title}`}>
        <Col flex={'30px'} style={{color: colors.green.bamboo, fontSize: 18}}>{icon}</Col>
        <Col flex={pixels} style={{paddingTop:3, fontSize:15}}>{title}</Col>
        <Col flex={"auto"}><Input disabled={true} value={value}/></Col>
    </RowC>
}

const RenderNotificationTable = ({items}: itemsProps):JSX.Element => {
    return <Table
        columns={[{
            title: "Title",
            dataIndex: "title",
            key: "title"
        },{
            title: "Trigger",
            dataIndex: "trigger",
            key: "trigger",
            width: 150
        }]
        }
        dataSource={items}
        pagination={false}
    />
}
const RenderNotification = (notifications: notificationItems):JSX.Element => {
    const {success, custom, fail} = notifications
    const failTotal = fail.length

    return <Collapse items={[
        {
            key: 1,
            label: <RenderCollapseNotification totalItems={success.filter((item)=>item.status).length} type={"success"}/>,
            children: <RenderNotificationTable items={success.map(notification=>({
                title: <>
                    <h4>{notification.title}</h4>
                    <p>{notification.description}</p>
                </>,
                trigger: <Input value={notification.status? 'Active': 'Disabled'} disabled={true}/>
            }))}/>
        },{
            key: 2,
            label: <RenderCollapseNotification totalItems={custom.length} type={"custom"}/>,
            children: <RenderNotificationTable items={custom.map(notification=>({
                title: <RenderNotificationTitle
                    title={notification.title}
                    type={notification.type}
                    message={notification.message}
                />,
                trigger:<>
                    <RenderOptions
                        preview={true}
                        title={'Before Run'}
                        defaultValue={notification.trigger.before}
                        handleSegmented={()=>{}}
                    /> <br/>pm2
                    <RenderOptions
                        preview={true}
                        title={'On Completion'}
                        defaultValue={notification.trigger.after}
                        handleSegmented={()=>{}}
                    />
                </>
            }))}/>
        },(failTotal ? {
            key: 3,
            label: <RenderCollapseNotification totalItems={fail.filter((item)=>item.status).length} type={"fail"}/>,
            children: <RenderNotificationTable items={fail.map(notification=>({
                title: <>
                    <h4>{notification.title}</h4>
                    <p>{notification.description}</p>
                </>,
                trigger: <Input value={notification.status? 'Active': 'Disabled'} disabled={true}/>
            }))}/>
        }: {})
    ]} style={{width:'100%'}}
    />


}

export function DrawerLoadTemplates({
   setShowModalTemplate,
   showModalTemplate,
   handleOk
}: {
    showModalTemplate: boolean,
    setShowModalTemplate: Dispatch<SetStateAction<boolean>>,
    handleOk: (selectedOptions: any) => void
}): JSX.Element {
    const dispatch: AppDispatch = useDispatch();

    const {loggedInUser} = useUserContext()

    const emptySelection = {
        settings: null,
        args: null,
        notifications: null,
        selection: null
    }
    // Track selected sub-option and its values globally across all templates
    const [selectedOptions, setSelectedOptions] = useState<{
        settings: { templateId: string | null; value: any } | null;
        args: { templateId: string | null; value: any } | null;
        notifications: { templateId: string | null; value: any } | null;
        selection: { templateId: string | null; value: any } | null;
    }>(emptySelection);

    const [templatesPage, setTemplatePage] = useState<PageResource<LoadTemplatePage>>(createEmptyPage());
    const [collapseOptions, setCollapseOptions] = useState<CollapseProps["items"]>([]);
    const [showOnlyMine, setOnlyMine] = useState<boolean>(false)
    const [onlyFavorites, setOnlyFavorites] = useState<boolean>(false)
    const [search, setSearch] = useState<string>('')
    const [menuOptions, setMenuOptions] = useState<string>('all-templates')
    const [editingTemplateId, setEditingTemplateId] = useState<string | null>(null)
    const [newTemplateName, setNewTemplateName] = useState<string>('')

    const hasPermissionToEdit = (templateOwner:number) => {
        const isAdmin = true // get this from org role
        const isProjectAdmin = false // get this from project role
        return isAdmin || isProjectAdmin || templateOwner === loggedInUser.id;
    }

    const renderMenu = (template: LoadTemplatePage):React.ReactNode => {
        const canEdit = hasPermissionToEdit(template.creatorId)
        const canMarkAsFavorite = template.creatorId === loggedInUser.id
        let items = []

        if(canMarkAsFavorite){
            items.push({
                key: `favorite`,
                onClick: () => {
                    dispatch(updateFavorite({id: template.id, isFavorite: !template.isFavorite}))
                        .then(()=>{
                            const updatedContent = templatesPage.content.map(tempTemplate =>
                                tempTemplate.id === template.id
                                    ? { ...tempTemplate, isFavorite: !tempTemplate.isFavorite } // Toggle isFavorite for the matching template
                                    : tempTemplate
                            );

                            // Update the state with the new content array
                            setTemplatePage({
                                ...templatesPage, // Keep other properties of templatesPage the same
                                content: updatedContent // Set the updated content array
                            });
                        })
                        .catch((err) => { safeHandleErrorResponse(err); });

                },
                label: template.isFavorite? <Space>
                    <div style={{ position: 'relative', display: 'inline-block' }}>
                        <StarFilled style={{ color: colors.yellow.gold }} />
                        <div style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            pointerEvents: 'none', // Ensure it doesn't interfere with clicks
                            }}>
                            <div style={{
                                position: 'absolute',
                                width: '1px', // Thickness of the line
                                height: '100%',
                                backgroundColor: '#000', // Color of the line
                                transform: 'rotate(45deg)',
                                transformOrigin: 'center',
                                top: 0,
                                left: '50%',
                            }}/>
                        </div>
                </div>Remove Favorite</Space>:<Space>
                    <StarFilled style={{ color: colors.yellow.gold }} /> Make Favorite
                </Space>
            })
        }
        if(canEdit){
            items.push({
                key: 'edit',
                label: <Space><EditOutlined /> Edit Name</Space>,
                onClick: () => {
                    setEditingTemplateId(template.id)
                    setNewTemplateName(template.name)
                }
            })
            items.push({
                key: `delete`,
                label: <CancelAction secs={2} action={()=>{}}><Space><DeleteOutlined /> Delete</Space></CancelAction>,
                onClick: () => {
                    dispatch(deleteTemplate(template.id))
                        .then(()=>{
                            const updatedContent = templatesPage.content.filter(tempTemplate => template.id !== tempTemplate.id);

                            // Update the state with the new content array
                            setTemplatePage({
                                ...templatesPage, // Keep other properties of templatesPage the same
                                content: updatedContent // Set the updated content array
                            });
                        })
                        .catch((err) => { safeHandleErrorResponse(err); });

                }
            })
        }

        if(items.length === 0) return <></>

        return <div onClick={(e: React.MouseEvent) => e.stopPropagation()} style={{float:'right'}}>
            <Dropdown
                trigger={['click']}
                menu={{ items }}
            >
                <a>
                    <Space>
                        <MoreOutlined style={{color:'#000'}} />
                    </Space>
                </a>
            </Dropdown>
        </div>
    }

    const fetchTemplates = (offset=0, forceSearch:string|null=null) => {
        dispatch(fetchRunTemplates({
            onlyFavorites,
            search: forceSearch? forceSearch:search,
            offset,
            onlyMine: showOnlyMine
        }))
            .then(results => {setTemplatePage(results)})
            .catch((err) => { safeHandleErrorResponse(err); });
    };

    useEffect(() => {
        if (showModalTemplate) {
            fetchTemplates();
        }
    }, [onlyFavorites, showOnlyMine, showModalTemplate]);

    const handleSubOptionChange = (
        option: keyof typeof selectedOptions,
        templateId: string,
        value: any // Value parameter to store the actual value
    ) => {
        // Update the selectedOptions to allow one option selected globally
        setSelectedOptions((prevState) => ({
            ...prevState,
            [option]: prevState[option]?.templateId === templateId
                ? null // Deselect the option if already selected
                : { templateId, value } // Store the templateId and value
        }));
    };

    const handleTemplateChange = (
        templateId: string,
        templateOptions: { settings?: any; args?: any; notifications?: any; selection?: any }
    ) => {
        const allSelected = areAllOptionsSelected(templateId, templateOptions);

        if (allSelected) {
            // Deselect all if currently selected
            setSelectedOptions((prevState) => ({
                ...prevState,
                settings: prevState.settings?.templateId === templateId ? null : prevState.settings,
                args: prevState.args?.templateId === templateId ? null : prevState.args,
                notifications: prevState.notifications?.templateId === templateId ? null : prevState.notifications,
                selection: prevState.selection?.templateId === templateId ? null : prevState.selection
            }));
        } else {
            // Select all options
            setSelectedOptions((prevState) => ({
                ...prevState,
                settings: templateOptions.settings ? { templateId, value: templateOptions.settings } : prevState.settings,
                args: templateOptions.args ? { templateId, value: templateOptions.args } : prevState.args,
                notifications: templateOptions.notifications ? { templateId, value: templateOptions.notifications } : prevState.notifications,
                selection: templateOptions.selection ? { templateId, value: templateOptions.selection } : prevState.selection
            }));
        }
    };

    // Helper to check if all sub-options are selected
    const areAllOptionsSelected = (templateId: string, templateOptions: { settings?: any; args?: any; notifications?: any; selection?: any }) => {
        return (
            (templateOptions.settings ? selectedOptions.settings?.templateId === templateId : true) &&
            (templateOptions.args ? selectedOptions.args?.templateId === templateId : true) &&
            (templateOptions.notifications ? selectedOptions.notifications?.templateId === templateId : true) &&
            (templateOptions.selection ? selectedOptions.selection?.templateId === templateId : true)
        );
    };

    // Helper to check if some but not all sub-options are selected (for indeterminate state)
    const areSomeOptionsSelected = (templateId: string, templateOptions: { settings?: any; args?: any; notifications?: any; selection?: any }) => {
        return (
            (templateOptions.settings && selectedOptions.settings?.templateId === templateId) ||
            (templateOptions.args && selectedOptions.args?.templateId === templateId) ||
            (templateOptions.notifications && selectedOptions.notifications?.templateId === templateId) ||
            (templateOptions.selection && selectedOptions.selection?.templateId === templateId)
        ) && !areAllOptionsSelected(templateId, templateOptions);
    };

    // Helper to get the selected sub-options label for display
    const getSelectedSubOptionsLabel = (templateId: string, templateOptions: { settings?: any; args?: any; notifications?: any; selection?: any }) => {
        const selectedOptionsList = [];

        if (templateOptions.settings && selectedOptions.settings?.templateId === templateId) {
            selectedOptionsList.push(`Settings`);
        }
        if (templateOptions.args && selectedOptions.args?.templateId === templateId) {
            selectedOptionsList.push(`Args`);
        }
        if (templateOptions.notifications && selectedOptions.notifications?.templateId === templateId) {
            selectedOptionsList.push(`Notifications`);
        }
        if (templateOptions.selection && selectedOptions.selection?.templateId === templateId) {
            selectedOptionsList.push(`Selection`);
        }

        return selectedOptionsList.length > 0 ? `(${selectedOptionsList.join(", ")})` : "";
    }

    const handleResetEditMode = (e?:any) => {
        if(e) e.stopPropagation()
        setEditingTemplateId(null)
        setNewTemplateName('')
    }

    const handleSaveTemplateName = (templateId:string, e?:any) => {
        dispatch(updateTemplate({id: templateId, name:newTemplateName}))
            .catch((err) => { safeHandleErrorResponse(err); })

        const updatedContent = templatesPage.content.map(tempTemplate =>
            tempTemplate.id === templateId
                ? { ...tempTemplate, name: newTemplateName } // Toggle isFavorite for the matching template
                : tempTemplate
        );

        // Update the state with the new content array
        setTemplatePage({
            ...templatesPage, // Keep other properties of templatesPage the same
            content: updatedContent // Set the updated content array
        });
        handleResetEditMode(e)
    }

    const renderTemplateTitle = (
        label:string,
        key:"settings" | "args" | "notifications" | "selection",
        template?: LoadTemplatePage
    ): React.ReactNode => {

        return template? <CheckboxC
            label={label}
            checked={selectedOptions[key]?.templateId === template.id}
            onClick={(e: React.MouseEvent) => e.stopPropagation()}
            onChange={() => handleSubOptionChange(key, template.id, template.options[key])}
        /> : label
    }

    const getTemplateOptions = (options: RunTemplateOption, template?: LoadTemplatePage):CollapseProps["items"] => {
        const newCollapseOptions: CollapseProps["items"] = []

        if (options.settings) {
            const selectedBrowsers = options.settings.browsers && options.settings.browsers.length>0?
                options.settings.browsers.map(browser => JSON.parse(browser.toString()).value)
                : []
            newCollapseOptions.push({
                key: "settings",
                label: renderTemplateTitle("Settings", "settings", template),
                children: <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                    {renderSingleInputOption(1, <FormOutlined/>, 'Title', options.settings.title)}
                    {renderSingleInputOption(2, <GithubFilled/>, 'Github Branch', options.settings.githubBranch)}
                    {renderSingleInputOption(3, <DesktopOutlined/>, 'Browsers', selectedBrowsers?.join(' , '))}
                    {renderSingleInputOption(4, <></>, 'Sequence', options.settings.sequence?.sequenceType)}
                    {renderSingleInputOption(5, <></>, 'Interval', options.settings.sequence?.runInterval)}
                    {renderSingleInputOption(6, <RedoOutlined/>, 'Reruns', options.settings.total_runs?options.settings.total_runs - 1: undefined)}
                    {renderSingleInputOption(7, <ClusterOutlined/>, 'Parallel', options.settings.maxParallel)}
                    {TPTexts.disclaimer('* Please note that the Run execution will not be affected by the template ')}
                </Space>
            })
        }

        if (options.args) {
            newCollapseOptions.push({
                key: "args",
                label: renderTemplateTitle("Args", "args", template),
                children: <>
                        <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                            {options.args ? options.args.map((arg, key) => (
                                renderSingleInputOption(key, '', arg.label, arg.value, '160px')
                            )) : "No arguments available."}
                        </Space>
                    </>
            })
        }

        if (options.notifications) {
            newCollapseOptions.push({
                key: "notifications",
                label: renderTemplateTitle("Notifications", "notifications", template),
                children: <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                    <RenderNotification {...options.notifications} />
                </Space>
            });
        }

        if (options.selection) {
            newCollapseOptions.push({
                key: "selection",
                label: renderTemplateTitle("Selection", "selection", template),
                children: <RunTemplateSelection selection={options.selection}/>
            });
        }

        return newCollapseOptions
    }

    useEffect(() => {
        const tempOptions: CollapseProps["items"] = templatesPage.content.map((template) => {
            const nestedCollapseItems: CollapseProps["items"] = getTemplateOptions(template.options, template);

            // Template checkbox to select/deselect all options
            const allSelected = areAllOptionsSelected(template.id, template.options);
            const someSelected = areSomeOptionsSelected(template.id, template.options);

            return {
                key: template.id,
                style: {background: template.isFavorite?colors.yellow.gradient: '#fff'},
                label: editingTemplateId === template.id? <RowC>
                        <Col flex={'510px'}>
                            <Input
                                // style={input.style}
                                maxLength={80}
                                onChange={(e) => setNewTemplateName(e.target.value)}
                                onPressEnter={(e:any)=>handleSaveTemplateName(template.id, e)}
                                onClick={(e: React.MouseEvent) => e.stopPropagation()}
                                value={newTemplateName}/>
                        </Col>
                        <Col style={{marginLeft: 10}}>
                            <Buttons.ButtonsSaveOnEdit onClick={() => {handleSaveTemplateName(template.id)}} reset={handleResetEditMode}/>
                        </Col>
                    </RowC> :
                    <RowC>
                        <Col flex={'auto'} onClick={(e: React.MouseEvent) => e.stopPropagation()}>
                            <Checkbox
                                checked={allSelected}
                                indeterminate={someSelected}
                                onChange={() => handleTemplateChange(template.id, template.options)}
                            >
                                <div style={{ textAlign: "center" }} >
                                    <span>{template.name} </span>
                                    <span style={{ fontSize: 11, marginLeft: 5 }}>{getSelectedSubOptionsLabel(template.id, template.options)}</span>
                                </div>
                            </Checkbox>
                        </Col>
                        <Col flex={'25px'} onClick={(e: React.MouseEvent) => e.stopPropagation()}>{renderMenu(template)}</Col>
                    </RowC>,
                children: <Collapse items={nestedCollapseItems} />
            };
        });

        setCollapseOptions(tempOptions);
    }, [templatesPage, selectedOptions, editingTemplateId, newTemplateName]);

    const handleCancel = () => {
        setShowModalTemplate(false);
    };

    const debouncedSearch = useMemo(() => debounce((search) => {
        fetchTemplates(0, search);
    }, 500), [search]);

    useEffect(() => {
        if(showModalTemplate){
            debouncedSearch(search);
        }
        // Cleanup debounce on unmount
        return () => {
            debouncedSearch.cancel();
        };
    }, [search, debouncedSearch])

    const handleLoad = () => {
        handleOk(selectedOptions)
        setMenuOptions('all-templates')
        setSelectedOptions({
            settings: null,
            args: null,
            notifications: null,
            selection: null,
        })
    }

    return <Drawer
        open={showModalTemplate}
        title="Load From Template"
        size={'large'}
        onClose={handleCancel}
        footer={[
            <Button key="Cancel" onClick={handleCancel}>
                Cancel
            </Button>,
            <Button
                key="submit"
                type="primary"
                style={{ background: colors.green.bamboo, float: 'right'}}
                onClick={handleLoad}
            >
                Load
            </Button>
        ]}
    >
        <Menu
            items={[
                {
                    label: 'All Templates',
                    icon: <FileSyncOutlined />,
                    key: 'all-templates',
                },
                {
                    label: 'Preview',
                    icon: <EyeOutlined />,
                    key: 'preview',
                }
            ]}
            mode="horizontal"
            selectedKeys={[menuOptions]}
            onClick={(item) => setMenuOptions(item.key)} // Global onClick handler
        />
        {
            menuOptions === "all-templates"? <>
                <RowC style={{marginTop: 15}}>
                    <Col flex={'auto'}>
                        <Input placeholder={'type to search for templates...'}
                               value={search}
                               onChange={(event)=> {setSearch(event.target.value)}}
                        />
                    </Col>
                    <Col style={{marginLeft:15, marginTop:5}} flex={'110px'}>
                        <CheckboxC label={'Only Mine'}
                                   checked={showOnlyMine}
                                   onChange={(checked)=> {setOnlyMine(checked.target.checked)}} />
                    </Col>
                    <Col style={{marginTop:5}} flex={'140px'}>
                        <CheckboxC label={'Only Favorites'}
                                   checked={onlyFavorites}
                                   onChange={(checked)=> {setOnlyFavorites(checked.target.checked)}} />
                    </Col>
                    <Divider/>
                </RowC>
                {
                    collapseOptions && collapseOptions.length > 0? <Collapse size="large" items={collapseOptions} />:
                        <Empty description={'No Templates found'} />
                }
            </>
            : <div style={{marginTop:25}}>
                {TPTexts.disclaimer('* This view is not editable')}
                {
                    JSON.stringify(selectedOptions) === JSON.stringify(emptySelection)? <><Empty description={''} > <Button type={'text'} onClick={()=>{setMenuOptions('all-templates')}}>Please select something from your templates</Button> </Empty> </>
                        : <Collapse defaultActiveKey={['settings']} items={getTemplateOptions({
                            settings: selectedOptions.settings?.value,
                            args: selectedOptions.args?.value,
                            notifications: selectedOptions.notifications?.value,
                            selection: selectedOptions.selection?.value
                        })}/>
                }

            </div>
        }

    </Drawer>
}
