import React  from 'react';
import { RouterProvider } from 'react-router-dom';
import { Provider } from "react-redux";
import configureStore from "./redux/store";

import {IntlProvider} from 'react-intl';
import english from './intl/entries/en';
import { getRoutes } from './services/RouterService';

import './App.css';
// import 'antd/dist/antd.css';
// import 'antd/dist/antd.min.css'; // or 'antd/dist/antd.less'
import {UserProvider} from './contexts/userContext';
// import 'antd/dist/antd.less';

const store = configureStore();

function App(): JSX.Element {
    return (
        <Provider store={store}>
            <IntlProvider locale={english.locale} messages={english.messages}>
                <UserProvider>
                    <RouterProvider router={getRoutes} />
                </UserProvider>
            </IntlProvider>
        </Provider>
    );
}

export default App;
