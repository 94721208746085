import React from 'react';
import { AppDispatch } from "../store";
import { fetchErrorAction, fetchStartAction, fetchSuccessAction } from "./creators/common_actions";
import { PageResource } from "../../models/dtos/page_resource";
import { BatchesProps } from "../../models/entities/batches";
import {CasesPreviewAction} from "../../models/entities/suite_section_props";
import {CasesPreview, Reviewer} from "../../models/entities/test_case_info";
import {get, post, put, deletion, getWithOrgWrapper, getWithOrgProjectIdsWrapper} from '../../services/axiosWrapper';
import {CreateBatch} from "../../models/entities/template";

const api_path = '/api/run/';

interface getBatchActionsParams {
    filters?:{
        project_ids: number[],
        status?: string[],
        state?: number[],
        search?: string,
        created_from?: any,
        created_to?: any,
        created_by?: boolean
        assignees?: number[],
    }
    limit: number,
    offset: number
}

interface getBatchCasesParams {
    run_id: number,
    limit: number,
    offset: number,
    assignees?: number[],
    assignee?: number,
    search?: string,
    statuses?: number[]
}

export const getBatches = (params: getBatchActionsParams) => (dispatch: AppDispatch): Promise<PageResource<BatchesProps>> => {

    dispatch(fetchStartAction());

    const request: any = getWithOrgWrapper(`${api_path}getRuns`, params)

    return Promise.resolve(request)
            .then(value => {
                dispatch(fetchSuccessAction());

                return value.data;
            })
            .catch(reason => {
                dispatch(fetchErrorAction(reason));
                return Promise.reject();
            })
}

export const viewCasesPerComponents = (components_ids_array: React.Key[], section_id_array?: React.Key[], case_id_array?: React.Key[]) => (dispatch: AppDispatch): Promise<{component_title: string, component_cases: number}[]> => {
    dispatch(fetchStartAction());

    const request: any = getWithOrgProjectIdsWrapper(`${api_path}viewCasesPerComponents` ,
        {components_ids_array, section_id_array, case_id_array})

    return Promise.resolve(request)
            .then(value => {
                dispatch(fetchSuccessAction());

                return value.data;
            })
            .catch(reason => {
                dispatch(fetchErrorAction(reason));
                return Promise.reject();
            })
}

export const createNewTestRun = (data:CreateBatch) => (dispatch: AppDispatch): Promise<{}> => {
    dispatch(fetchStartAction());

    const request: any = post(`${api_path}create`, data)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const calculateRunTime = (project_id:number, selection:CasesPreviewAction) => (dispatch: AppDispatch): Promise<CasesPreview> => {
    dispatch(fetchStartAction());

    const request: any = post(`${api_path}calculateRunTime?project_id=${project_id}`, selection)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const calculateReviewers = (project_id:number, selection:CasesPreviewAction) => (dispatch: AppDispatch): Promise<{total: number, reviewers: []}> => {
    dispatch(fetchStartAction());

    const request: any = post(`${api_path}calculateReviewers?project_id=${project_id}`, selection)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const archiveRun = (data:any) => (dispatch: AppDispatch): Promise<any> => {
    dispatch(fetchStartAction());

    const request: any = put(`${api_path}archiveRun`, data)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const deleteRun = (run_id:number) => (dispatch: AppDispatch): Promise<any>  => {
    dispatch(fetchStartAction());
    const request: any = deletion(`${api_path}deleteRun?run_id=${run_id}`)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            return value;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const startRun = (data:any) => (dispatch: AppDispatch): Promise<CasesPreview> => {
    dispatch(fetchStartAction());

    const request: any = put(`${api_path}editRunStatus`, data)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const getRunCases = (params: getBatchCasesParams)=> (dispatch: AppDispatch): Promise<any>  => {
    dispatch(fetchStartAction());
    const request: any = get(`${api_path}getRunCases`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const deleteRunCases = (ids:number[]) => (dispatch: AppDispatch): Promise<any> => {
    dispatch(fetchStartAction());
    // Create a request config object with data as a query parameter

    const request: any = deletion(`${api_path}deleteRunCases`, { ids } );

    return Promise.resolve(request)
        .then((response) => {
            dispatch(fetchSuccessAction());
            return response; // Return the response data
        })
        .catch((error) => {
            dispatch(fetchErrorAction(error));
            return Promise.reject(error);
        });
};

export const updateRunCaseStatus = (ids:number[], body:{
    status: number,
    resolved_comment: string
}) => (dispatch: AppDispatch): Promise<any> => {
    dispatch(fetchStartAction());
    // Create a request config object with data as a query parameter

    const request: any = put(`${api_path}updateRunCaseStatus?ids=${ids}`, body);

    return Promise.resolve(request)
        .then((response) => {
            dispatch(fetchSuccessAction());
            return response; // Return the response data
        })
        .catch((error) => {
            dispatch(fetchErrorAction(error));
            return Promise.reject(error);
        });
};

export const getRunReviewers = (run_id:number, search:string, ignore_user?:number)  => (dispatch: AppDispatch): Promise<Reviewer[]> => {
    const request: any = get(`${api_path}getRunReviewers?run_id=${run_id}&search=${search}&ignore_user=${ignore_user}`);

    return Promise.resolve(request)
        .then((response) => {
            dispatch(fetchSuccessAction());
            return response.data; // Return the response data
        })
        .catch((error) => {
            dispatch(fetchErrorAction(error));
            return Promise.reject(error);
        });
}

export const getRunCaseComments = (run_id:number, case_id:number, offset:number) => (dispatch: AppDispatch): Promise<any> => {

    const request: any = get(`${api_path}getRunCaseComments?run_id=${run_id}&case_id=${case_id}&offset=${offset}`);

    return Promise.resolve(request)
        .then((response) => {
            dispatch(fetchSuccessAction());
            return response.data; // Return the response data
        })
        .catch((error) => {
            dispatch(fetchErrorAction(error));
            return Promise.reject(error);
        });
}

export const postRunCasesComment = (data:{run_id:number, case_ids:number[], comment:string, project_id: number, org_id: number}) => (dispatch: AppDispatch): Promise<any> =>  {

    const request: any = post(`${api_path}postRunCasesComment`, data)
    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const deleteCommentRunCase = (comment_id:number) =>(dispatch: AppDispatch): Promise<any> =>  {

    const request: any = deletion(`${api_path}deleteCommentRunCase?comment_id=${comment_id}`)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            return value;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const getRunComponentCases = (params:{run_id:number, max_to_show?:number}) => (dispatch: AppDispatch): Promise<any> =>  {

    const request: any = getWithOrgWrapper(`${api_path}getRunComponentCases`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const getAllRunsStats = (params:{project_id?:number}) => (dispatch: AppDispatch): Promise<PageResource<{tests_in_queue: number, total_tests_executed: number, defects_found: number, unresolved_cases: number}>> =>  {

    dispatch(fetchStartAction());
    
    const request: any = getWithOrgWrapper(`${api_path}getAllRunsStats`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const getCurrentYearRuns = (params:{project_id?:number}) => (dispatch: AppDispatch): Promise<PageResource<{month: string, key: string, value: number}>> =>  {

    dispatch(fetchStartAction());
    
    const request: any = getWithOrgWrapper(`${api_path}getCurrentYearRuns`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const getCurrentYearRunResults = (params:{project_id?:number}) => (dispatch: AppDispatch): Promise<PageResource<{month: string, key: string, title: string, value: number}>> =>  {

    dispatch(fetchStartAction());
    
    const request: any = getWithOrgWrapper(`${api_path}getCurrentYearRunResults`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const getRunInfo = (run_id: any) => (dispatch: AppDispatch): Promise<{content: {state: string, created_at: string, branch: string}}> =>  {

    dispatch(fetchStartAction());
    
    const request: any = getWithOrgWrapper(`${api_path}getRunInfo?run_id=${run_id}`)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const getRunTotalCasesStatuses = (run_id: number) => (dispatch: AppDispatch): Promise<{content: {type: string, value: number}[]}> =>  {

    dispatch(fetchStartAction());
    
    const request: any = get(`${api_path}getRunTotalCasesStatuses?run_id=${run_id}`)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const getRunResultsViaExcel = (run_id:number) => (dispatch: AppDispatch): Promise<any> => {

    const request: any = get(`${api_path}getRunResultsViaExcel?run_id=${run_id}`);

    return Promise.resolve(request)
        .then((response) => {
            dispatch(fetchSuccessAction());
            return response.data; // Return the response data
        })
        .catch((error) => {
            dispatch(fetchErrorAction(error));
            return Promise.reject(error);
        });
}