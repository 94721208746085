import React from 'react';
import { Collapse, Space } from 'antd';
import { BellOutlined, CheckOutlined, WarningOutlined } from '@ant-design/icons';
import { colors } from '../../assets/data/colors';
import TooltipsTP from "../../components/tooltips";
import RunNotificationsList from "../../components/tables/batch_notifications_list";
import { useCreateRunContext } from "../../contexts/createRunContext";
import TableRunCustomNotification from "../../components/tables/tableRunCustomNotifications";
import {notificationCategories} from "../../models/entities/run_script_notifications";

export const RenderCollapseNotification = ({ totalItems, type }: { type: notificationCategories, totalItems:number }):JSX.Element => {
    const fontSize = 18

    const constants = {
        success: {
            title: 'Success Notifications',
            icon: <CheckOutlined style={{ color: colors.green.bamboo, fontSize }} />,
            description: 'Includes all the notifications configured by your Administrator. ' +
                'These notifications will trigger whenever an action is successfully completed.',
            itemsDescription: 'Active'
        },
        custom: {
            title: 'Custom Notifications',
            icon: <BellOutlined style={{ color: colors.yellow.gold, fontSize }} />,
            description: 'Configured to send custom messages to teams and Slack channels ' +
                'when an action is successfully completed.',
            itemsDescription: 'Total'
        },
        fail: {
            title: 'Fail Notifications',
            icon: <WarningOutlined style={{ color: colors.red.vermilion, fontSize }} />,
            description: 'Includes all the notifications configured by your Administrator. ' +
                'These notifications will trigger whenever an action fails.',
            itemsDescription: 'Active'
        }
    }

    return <Space>
        {TooltipsTP.RunNotificationInfo(
            constants[type].icon,
            constants[type].description
        )}
        <div style={{ display: 'inline', width: '100%' }}>
            {constants[type].title}{' '}
            <span style={{ fontSize: 11 }}>({constants[type].itemsDescription}:{totalItems})</span>
        </div>
    </Space>
}

function RunNotifications(): JSX.Element {
    const { notifications } = useCreateRunContext();

    const { success, setSuccess, custom, fail, setFail } = notifications;

    const items = [
        {
            key: 1,
            label: <RenderCollapseNotification
                type={"success"}
                totalItems={success.filter((item)=>item.status).length}
            />,
            children: <RunNotificationsList notifications={success} setNotifications={setSuccess} />
        },
        {
            key: 2,
            label: <RenderCollapseNotification
                type={"custom"}
                totalItems={custom.length} />,
            children: <TableRunCustomNotification />,
        },
        ...(fail.length > 0
            ? [{
                key: 3,
                label: <RenderCollapseNotification
                    type={"fail"}
                    totalItems={fail.filter((item)=>item.status).length}
                />,
                children: <RunNotificationsList notifications={fail} setNotifications={setFail} />
                }]
            : []),
    ];

    return <Collapse defaultActiveKey={[1]} items={items} style={{width:'100%'}}/>
}

export default RunNotifications;
