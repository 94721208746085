import React from 'react';
import {colors} from "../assets/data/colors";


class TPTexts{
    static disclaimer = (text:React.ReactNode):JSX.Element => {
        return <p style={{fontSize: 11, color:colors.gray.shadow}}>{text}</p>
    }

    static errorMsg = (text:React.ReactNode):JSX.Element => {
        return <p style={{fontSize: 11, color:colors.red.vermilion}}>{text}</p>
    }
}

export default TPTexts;