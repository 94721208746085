import React from 'react';
import {colors} from "../assets/data/colors";
import {Tooltip} from "antd";

class TooltipsTP{
    static RunNotificationInfo = (icon: React.ReactNode, text: React.ReactNode):JSX.Element => {
        return <Tooltip
            placement="left"
            title={<>
                <h3>Info</h3>
                <p>{text}</p>
            </>}
            style={{width:250}}
            color={colors.green.bamboo}
        >
            {icon}
        </Tooltip>
    }
}

export default TooltipsTP;