import React, {createContext, Dispatch, SetStateAction, useContext, useState} from 'react';
import {Tool, User} from "../models/entities/user";
import {empty_user} from "../services/EmptyEntities/EmptyUser";
import LcsManager from '../assets/local_storage/lcs'

const lcs_manager = new LcsManager()

const set_user_to_lcs = (user: User) => {
    lcs_manager.set_item_to_lcs(lcs_manager.Keys.user, user)
}
const set_user_token_to_lcs = (token: string) => {
    lcs_manager.set_item_to_lcs(lcs_manager.Keys.user_token, token)
}

const lcsUser = lcs_manager.get_item_from_lcs(lcs_manager.Keys.user);
const lcsUserToken = lcs_manager.get_item_from_lcs(lcs_manager.Keys.user_token);

interface UserContextProps {
    loggedInUser: User,
    setAppContextUser: (user: User) => void,
    userToken: string,
    setUserToken: (token: string) => void,
    resetApp: () => void,
    tools: Tool[],
    setTools: Dispatch<SetStateAction<Tool[]>>
}

const defaultValues: UserContextProps = {
    loggedInUser: lcsUser ? lcsUser : empty_user,
    setAppContextUser: set_user_to_lcs,
    userToken: lcsUserToken ? lcsUserToken : "",
    setUserToken: set_user_token_to_lcs,
    resetApp: () => {},
    tools: [],
    setTools: ()=> []
}

type UserProviderProps = {
    children: React.ReactNode;
};

const UserContext = createContext<UserContextProps>(defaultValues);

export const UserProvider: React.FC<UserProviderProps> = ({children}) => {
    const [loggedInUser, setAppContextUser] = useState<User>(defaultValues.loggedInUser)
    const [userToken, setUserToken] = useState<string>(defaultValues.userToken)
    const [tools, setTools] = useState<Tool[]>(defaultValues.tools)

    const set_loggedin_user = (user: User) => {
        setAppContextUser(user)
        lcs_manager.set_item_to_lcs(lcs_manager.Keys.user, user)
    }

    const set_user_token = (token: string) => {
        setUserToken(token)
        lcs_manager.set_item_to_lcs(lcs_manager.Keys.user_token, token)
    }

    const resetApp = () => {
        setAppContextUser(empty_user)
        setUserToken("")
        lcs_manager.reset_local_storage([lcs_manager.Keys.is_sidemenu_collapsed])
    };

    const value: UserContextProps = {
        loggedInUser,
        setAppContextUser: set_loggedin_user,
        userToken,
        setUserToken: set_user_token,
        resetApp,
        tools: tools,
        setTools
    };

    return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUserContext = (): UserContextProps => {
    return useContext(UserContext);
};