import React, {useEffect, useState} from 'react';
import {Dropdown, Space, Table, Segmented, Input, Popover} from "antd";
import {
    BellOutlined,
    CodeOutlined,
    DeleteOutlined,
    DownOutlined,
    EditOutlined, PlusCircleOutlined,
    SlackOutlined,
    TeamOutlined
} from '@ant-design/icons';
import {useCreateRunContext} from "../../contexts/createRunContext";
import {useProjectContext} from "../../contexts/projectContext";
import RowC from "../row";
import {notificationTypes, triggerProps} from "../../models/entities/run_script_notifications";
import ButtonC from "../button";
import ModalAddNewNotification, {headerProps} from "../modals/modalAddNewNotification";
import Helper from "../../assets/helpers/helper";
import ModalEditNotification from "../modals/modalEditNotification";


export const NotificationIcons = {
    'team': <TeamOutlined title={'Team'}/>,
    'scripts': <CodeOutlined title={'Script'}/>,
    'slack': <SlackOutlined title={'Slack'}/>,
    'notifications': <BellOutlined title={'Notification'}/>,
    'webhook': <svg height="16" viewBox="0 0 24 24" width="16"><path d="m10.46 19c-1.46 2.07-4.31 2.59-6.37 1.15-2.05-1.44-2.53-4.31-1.09-6.4.87-1.25 2.21-1.92 3.58-1.98l.05 1.43c-.91.07-1.79.54-2.36 1.36-1 1.44-.69 3.38.68 4.35 1.38.96 3.31.59 4.31-.84.31-.45.49-.94.56-1.44v-1.01l5.58-.04.07-.11c.53-.92 1.68-1.24 2.58-.72s1.21 1.68.68 2.6c-.53.91-1.69 1.23-2.59.71-.41-.23-.7-.6-.83-1.02l-4.07.02c-.11.67-.37 1.32-.78 1.94m7.28-7.14c2.53.31 4.33 2.58 4.02 5.07-.31 2.5-2.61 4.27-5.14 3.96-1.49-.18-2.72-1.03-3.43-2.21l1.24-.72c.49.77 1.32 1.32 2.32 1.45 1.75.21 3.3-.98 3.51-2.65s-1.03-3.2-2.76-3.41c-.54-.06-1.06.01-1.53.18l-.85.44-2.58-4.77h-.22c-1.06-.04-1.88-.91-1.85-1.95s.93-1.85 1.98-1.81c1.05.06 1.88.91 1.85 1.95-.02.44-.19.84-.46 1.15l1.9 3.51c.62-.2 1.3-.27 2-.19m-9.49-2.72c-1-2.35.06-5.04 2.37-6.02 2.32-.98 5 .13 6 2.48.59 1.37.47 2.87-.2 4.07l-1.24-.72c.42-.81.49-1.8.09-2.73-.68-1.6-2.49-2.37-4.04-1.72-1.56.66-2.26 2.5-1.58 4.1.28.66.75 1.17 1.32 1.51l.39.21-3.07 4.99c.03.05.07.11.1.19.49.91.15 2.06-.77 2.55-.91.49-2.06.13-2.56-.81-.49-.93-.15-2.08.77-2.57.39-.21.82-.26 1.23-.17l2.31-3.77c-.47-.43-.87-.97-1.12-1.59z"/></svg>
}

const mapSegmentOption = {
    0: 'Off',
    1: 'Once',
    2: 'At every iteration'
}

export const RenderOptions = (props: {title:React.ReactNode, defaultValue:triggerProps, handleSegmented:(value:triggerProps)=>void, preview?:boolean}):JSX.Element => {
    const {title, defaultValue, handleSegmented, preview} = props

    const {currentProject} = useProjectContext()
    const { settings } = useCreateRunContext()
    const [selectedOption, setSelectedOption] = useState(defaultValue)

    const getSegmentStyle = () => {
        switch (selectedOption) {
            case 1:
                return { color: 'white', backgroundColor: '#228B22' }
            case 2:
                return { color: 'white', backgroundColor: '#006442' }
            default:
                return {};
        }
    }

    const handleOnChange = (value:triggerProps) => {
        handleSegmented(value)
        setSelectedOption(value)
    }

    return <>
        <RowC style={{textAlign: 'center' }}><div style={{width: '100%'}}>{title}</div></RowC>
        <RowC style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {
                !preview?
                    <Segmented
                        onChange={(value: any) => {
                            handleOnChange(value)
                        }
                        }
                        defaultValue={selectedOption}
                        style={getSegmentStyle()}
                        options={[
                            { label: mapSegmentOption['0'], value: 0 },
                            { label: mapSegmentOption['1'], value: 1 },
                            { label: mapSegmentOption['2'], value: 2, disabled: settings.reruns === 0 }
                        ].filter(option => !(currentProject.info.project_type === 2 && option.value === 2))}
                    />
                    : <Input value={mapSegmentOption[defaultValue]} disabled={true}/>
            }

        </RowC>
    </>
}

interface defaultSelectedItemType {
    index: number,
    type: notificationTypes,
    title: string,
    id: number,
    message: string
}

const defaultSelectedItem:defaultSelectedItemType = {
    index: -1,
    type: 'team',
    title: '',
    id: 0,
    message: ''
}


const renderWebhookDescription = (msg:string):JSX.Element => {
    const parsedMsg:
    {method: string, dataType: "custom" | "empty", data:string, headers:headerProps[]}
     = JSON.parse(msg)

    const {method, dataType, headers} = parsedMsg
    let {data} = parsedMsg

    return <>
        <p>Method: {method}</p>
        <p>Data Type: {dataType} {
            dataType !== 'empty'? <Popover content={<pre><code>{data}</code></pre>}
                                           title="Body JSON"
                                           placement="right"
            > <a>view data</a> </Popover>: null
        }</p>
        <p>Headers:</p>
        <ul>
            {headers.map((header)=><li>{header.label}: {header.value}</li>)}
        </ul>
    </>
}

export const RenderNotificationTitle = ({type, title, message}: {type:notificationTypes, title:React.ReactNode, message: string}):JSX.Element => {
    return <>
        <h3 style={{marginTop: type==='webhook'? 10: -25}}>{NotificationIcons[type]} {title}</h3>
        {
            type === 'team'?
                <p><div dangerouslySetInnerHTML={{ __html: message }} /></p>:
                type === 'webhook'? renderWebhookDescription(message): message
        }

    </>
}

function TableRunCustomNotification(): JSX.Element{
    const { notifications } = useCreateRunContext()
    const {custom, setCustom} = notifications

    // const [runScripts, setRunScripts] = useState<ArgumentScriptSetting[]>([empty_script_run]);
    const [checkedNotifications, setCheckedNotifications] = useState<any[]>([])
    const [visible, setVisible] = useState<boolean>(false)
    const [visibleEdit, setVisibleEdit] = useState<boolean>(false)
    const [data, setData] = useState<{
        actions: React.ReactNode,
        key:string,
        title: React.ReactNode,
        trigger: React.ReactNode,
    }[]>([])
    const [selectedItem, setSelectedItem] = useState<defaultSelectedItemType>(defaultSelectedItem)

    const selected_scripts: number = checkedNotifications ? checkedNotifications.length : 0

    const deleteSelectedCases = () => {
        const rowsToDelete = checkedNotifications.map(notification=>parseInt(notification.split('-')[2], 10))

        let newSelectedRowKeys = custom.filter((_, index) => {
            return !rowsToDelete.includes(index)
        })

        setCustom(newSelectedRowKeys);
        setCheckedNotifications([]);
    }

    const handleDelete = (type:notificationTypes, id:number, index:number) => {
        setCheckedNotifications(prevState => prevState.filter((item=> item !== `${type}-${id}-${index}`)))
        setCustom(Helper.deleteFromArray(custom, index))
    }

    const handleSegmented = (
        id: number,
        notificationType: notificationTypes,
        notifyOn: 'before' | 'after',
        value: triggerProps
    ) => {
        setCustom((prevState) =>
            prevState.map((notification) => {
                if (notification.type === notificationType && notification.id === id) {
                    return {
                        ...notification,
                        trigger: {
                            ...notification.trigger,
                            [notifyOn]: value, // Dynamically update 'before' or 'after'
                        },
                    };
                }
                return notification; // Return the original notification if no match
            })
        );
    };

    useEffect(()=>{
        setData(custom.map((notification, key)=>{
            const {id, type, title, trigger, message} = notification
            return {
                title: <RenderNotificationTitle title={title} type={type} message={message}/>,
                key: `${type}-${id}-${key}`,
                trigger: <>
                    <RenderOptions
                        title={'Before Run'}
                        defaultValue={trigger.before}
                        handleSegmented={(value)=>handleSegmented(id, type, "before", value)}
                    /> <br/>
                    <RenderOptions
                        title={'On Completion'}
                        defaultValue={trigger.after}
                        handleSegmented={(value)=>handleSegmented(id, type, "after", value)}
                    />
                </>,
                actions: <Space direction={'vertical'}>
                    {
                        type !== 'scripts'?
                            <ButtonC
                                type={'text'}
                                onClick={() => {
                                    setSelectedItem({
                                        index: key,
                                        type,
                                        title,
                                        id,
                                        message
                                    })
                                    setVisibleEdit(true)
                                }}
                                text={<Space><EditOutlined/> Edit </Space>}
                            />
                            :null
                    }
                    <ButtonC
                        type={'text'}
                        onClick={() => {handleDelete(type, id, key)}}
                        text={<Space><DeleteOutlined/> Remove </Space>}
                    />
                </Space>
            }
        }))
    }, [JSON.stringify(custom)])

    const columns: object[] = [
        {
            title: <Space>Title <ButtonC
                text={<><PlusCircleOutlined /> Add New</>}
                size={'small'}
                onClick={()=>{setVisible(true)}}
            /></Space>,
            dataIndex: 'title',
            key: 'title'
        },
        {
            title: <>Trigger </>,
            dataIndex: 'trigger',
            key: 'trigger',
            width: '300px',
            align: 'center'
        },
        {
            title:
                <>
                    <Dropdown
                        disabled={selected_scripts === 0}
                        menu={{
                            items: [{
                                key: `mass-delete`,
                                label: <><DeleteOutlined /> Delete</>,
                                onClick: () => deleteSelectedCases()
                            }]
                        }}
                    >
                        <a><Space> Actions <DownOutlined /> </Space></a>
                    </Dropdown>
                    <div style={{fontSize: 11}}>selected {selected_scripts}</div></>,
            dataIndex: 'actions',
            key: 'actions',
            width: "5%"
        }
    ]

    const handleSelection = (changeableRowKeys:React.Key[], notificationType: notificationTypes) => {
        let newSelectedRowKeys = changeableRowKeys.filter((key) => {
            return key.toString().includes(notificationType)
        })

        setCheckedNotifications(newSelectedRowKeys)
    }

    return <>
        <Table
            style={{width: '100%'}}
            columns={columns}
            dataSource={data}
            rowSelection={{
                selectedRowKeys: checkedNotifications,
                selections:[
                    {
                        key: 'teams',
                        text: <Space>{NotificationIcons.team} Teams</Space>,
                        onSelect: (changeableRowKeys)=>handleSelection(changeableRowKeys, 'team')
                    },{
                        key: 'slack',
                        text: <Space>{NotificationIcons.slack} Slack</Space>,
                        onSelect: (changeableRowKeys)=>handleSelection(changeableRowKeys, 'slack')
                    },{
                        key: 'scripts',
                        text: <Space>{NotificationIcons.webhook} Webhooks</Space>,
                        onSelect: (changeableRowKeys)=>handleSelection(changeableRowKeys, 'webhook')
                    }
                ],
                onChange: setCheckedNotifications
            }}
            pagination={false}
        />
        <ModalAddNewNotification
            visible={visible}
            setVisible={setVisible}
        />
        <ModalEditNotification
            visible={visibleEdit}
            setVisible={setVisibleEdit}
            loadItem={selectedItem}
        />
    </>

}

export default TableRunCustomNotification;