import React, {useState} from 'react';
import {BatchDropdownOptions} from "../../models/entities/batches";
import {DownOutlined} from '@ant-design/icons';
import Space from 'antd/lib/space';
import {Button, Dropdown, Select} from 'antd';
import ButtonC, {Buttons} from "../button";

const DropdownC: React.FC<BatchDropdownOptions> = ({items, onClick, title}) => {

    const menuProps = {
        items,
        onClick: onClick,
    };

    return (
        <Space wrap>
            <Dropdown menu={menuProps}>
                <Button>
                    <Space>
                        {title}
                        <DownOutlined/>
                    </Space>
                </Button>
            </Dropdown>
        </Space>
    );
};

type SelectProps = {
    options: {
        value: React.Key,
        label: React.ReactNode
    }[],
    onChange: (e: any) => void,
    onOK: (e: any) => void,
    value?: React.Key | React.Key[]
    mode?: "multiple" | "tags",
    showSearch?: boolean,
    style?: React.CSSProperties,
    placeholder?: string,
    filterOption?: any,
    button: {
        text: React.ReactNode,
        style?: React.CSSProperties
    }
}


export const DropDownOnClick = (props: SelectProps): JSX.Element => {
    const [editMode, setEditMode] = useState<boolean>(false)

    const {
        value, options, mode, onChange, showSearch, button,
        style, placeholder, onOK
    } = props

    const reset = () => {
        setEditMode(false)
    }

    return <div style={{display: 'inline-block'}}>
        {
            editMode ?
                <>
                    <Select
                        showSearch={showSearch === undefined ? true : showSearch}
                        style={style}
                        mode={mode}
                        placeholder={placeholder ? placeholder : 'Search to Select'}
                        filterOption={(input, option) =>
                            (option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())
                        }
                        options={options}
                        value={value}
                        onChange={(e, d) => {
                            onChange(d)
                        }}
                    />
                    <Buttons.ButtonsSaveOnEdit onClick={onOK} reset={reset}/>
                </> :
                <ButtonC
                    onClick={() => {
                        setEditMode(true)
                    }}
                    type={'text'}
                    text={button.text}
                    style={{display: 'inline-block', ...button.style}}/>
        }

    </div>
}

export default DropdownC;

