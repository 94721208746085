import React, {useState} from "react";
import InputC from "../../components/input";
import RowC from "../../components/row"
import {Col} from "antd";
import { colors } from "../../assets/data/colors";
import {useCreateRunContext} from "../../contexts/createRunContext";
import {Buttons} from "../../components/button";


function RunConfigArgs():JSX.Element{
    const {args, setArgs} = useCreateRunContext()

    const [hover, setHover] = useState(false);

    const styles: React.CSSProperties = {
        float: 'right',
        marginTop: -30,
        color: colors.green.bamboo,
        position: 'relative'
    };

    const messageStyle: React.CSSProperties = {
        display: hover ? 'block' : 'none',
        marginTop: -40,
    };

    const handleChangeArgValue = (e:any, id?:number) => {
        const updatedData = args.map(item => {
            if (item.id === id) {
                return { ...item, value: e.target.value };
            }
            return item;
        });

        setArgs(updatedData);
    }

    const convertedObject = args.reduce((acc, arg) => {
        if(arg.label !== undefined){
            acc[arg.label] = arg.value
        }
        return acc;
    }, {} as { [key: string]: any });


    return <RowC>
        <Col flex={'auto'}>
            {
                args.map((arg, key)=>{
                    return <RowC key={key} style={{marginTop:10}}>
                        <Col flex='150px'>{arg.label}</Col>
                        <Col flex='150px'><InputC
                            placeholder='Please add a value'
                            value={arg.value}
                            onChange={(e) => handleChangeArgValue(e, arg.id)}
                        /></Col>
                    </RowC>
                })
            }
        </Col>
        <Col flex='300px'>
            <div
                style={styles}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            >
                <Buttons.CopyToClipboard
                    type={'icon'}
                    text={JSON.stringify(convertedObject, null, 2)}
                    style={{float: 'right', color: colors.green.bamboo}}
                />

                <div style={messageStyle}>
                            Copy to clipboard
                </div>
            </div>
            <pre style={{ overflowX: 'scroll', width:'300px', backgroundColor: '#f4f4f4', padding: '10px', borderRadius: '5px' }}>
              <code>
                {JSON.stringify(convertedObject, null, 2)}
              </code>
            </pre>
        </Col>

    </RowC>
}

export default RunConfigArgs