import React, {createContext, useContext, useEffect, useRef, useState} from 'react';
import LcsManager from '../assets/local_storage/lcs'
// models
import {OrgProviderModel} from '../models/entities/organization';
// empty entities
import {empty_organization} from '../services/EmptyEntities/EmptyOrganization';
import {useNavigate, useParams} from "react-router-dom";
import {AppDispatch} from "../redux/store";
import {useDispatch} from "react-redux";
import {getCurrentOrg} from "../redux/actions/user_actions";
import Index from "../layouts/menu_layouts";
import SkeletonLayout from "../components/skeleton";
import {useUserContext} from "./userContext";

const lcs_manager = new LcsManager()

export let globalOrgId = -1;

export const getGlobalOrgId = () => globalOrgId;

// Default set to lcs
const set_org_to_lcs = (org: OrgProviderModel) => {
    lcs_manager.set_item_to_lcs(lcs_manager.Keys.current_org, org)
}

const lcsOrganization = lcs_manager.get_item_from_lcs(lcs_manager.Keys.current_org);

interface OrgContextProps {
    currentOrg: OrgProviderModel,
    setCurrentOrg: (org: OrgProviderModel) => void
}

const defaultValue: OrgContextProps = {
    currentOrg: lcsOrganization ? lcsOrganization : empty_organization,
    setCurrentOrg: set_org_to_lcs,
};


const OrgContext = createContext<OrgContextProps>(defaultValue);

export const OrgProvider:  React.FC = () => {
    const dispatch: AppDispatch = useDispatch()
    const navigate = useNavigate()

    const {resetApp} = useUserContext()
    const {org_domain}  = useParams<{org_domain: string}>()

    const [currentOrg, setCurrentOrg] = useState<OrgProviderModel>(defaultValue.currentOrg)
    const [loading, setLoading] = useState(true)

    const previousDomainRef = useRef<string | undefined>(undefined)

    const set_current_org = (org: OrgProviderModel) => {
        if(org !== currentOrg){
            setCurrentOrg(org)
            lcs_manager.set_item_to_lcs(lcs_manager.Keys.current_org, org)
            globalOrgId = org.info.id
        }
    }

    useEffect(()=>{
        if(org_domain && org_domain !== previousDomainRef.current){
            setLoading(true);
            dispatch(getCurrentOrg(org_domain))
                .then(orgFound => {
                    if(orgFound && orgFound.info){
                        set_current_org(orgFound)
                        setLoading(false);
                    } else{
                        navigate('/errors/403')
                    }
                })
                .catch(() => {
                    resetApp()
                })
        }else{
            setLoading(false);
        }
    }, [org_domain])


    if (loading) {
        return <SkeletonLayout/>
    }

    const value: OrgContextProps = {
        currentOrg,
        setCurrentOrg: set_current_org
    };

    return <OrgContext.Provider value={value}><Index /></OrgContext.Provider>;
};


export const useOrgContext = (): OrgContextProps => {
    return useContext(OrgContext);
};
