import { AppDispatch } from "../store";
import { fetchErrorAction, fetchStartAction, fetchSuccessAction } from "./creators/common_actions";
import {get, getWithOrgWrapper, put} from '../../services/axiosWrapper';
import { ProvidePermissionsInterface, PermissionsInterface } from "../../models/entities/permissions";
import { UserPerms } from "../../models/entities/user";

const api_path = '/api/org/';
const api_path_users = '/api/users/';

export const getAllPermissions = () => (dispatch: AppDispatch): Promise<{content: PermissionsInterface[]}> => {

    dispatch(fetchStartAction());

    const request: any = get(`${api_path}getAllPermissions`)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const getOrgMembersPermissions = (search?: string) => (dispatch: AppDispatch): Promise<{content: ProvidePermissionsInterface[]}> => {

    dispatch(fetchStartAction());

    const request: any = getWithOrgWrapper(`${api_path}getOrgMembersPermissions?search=${search}`)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const getLoggedUserPermissions = (org_id: number) => (dispatch: AppDispatch): Promise<UserPerms> => {

    dispatch(fetchStartAction());

    const request: any = get(`${api_path_users}getLoggedUserPermissions?org_id=${org_id}`)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const addUserPermission = (params: {org_id: number, entity_ids: number[], entity_type: number, permission: string}) => (dispatch: AppDispatch): Promise<boolean> => {
    dispatch(fetchStartAction());

    const request: any = put(`${api_path}addUserPermission`, {params})

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const removeUserPermission = (params: {org_id: number, entity_id: number, entity_type: number, permission: string}) => (dispatch: AppDispatch): Promise<boolean> => {
    dispatch(fetchStartAction());

    const request: any = put(`${api_path}removeUserPermission`, {params})

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}
