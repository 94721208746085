import React, {useEffect, useState} from 'react';
import Page from "../../components/page_structure/page";
import PageHeader from "../../components/page_structure/page_header";
import PageContent from "../../components/page_structure/page_content";
import {CardIcons} from '../../components/cards/card_icon';
import {getProjects} from '../../redux/actions/project_actions';
import {useDispatch} from 'react-redux';
import {AppDispatch} from '../../redux/store';
import {PageResource} from '../../models/dtos/page_resource';
import {Project} from '../../models/entities/project';
import {createEmptyPage} from '../../services/utils/PageResourceUtils';
import {CardItem} from '../../models/entities/helpers/cardItem';
import {ProjectModal} from '../../components/modals/project_modal';
import {Divider, Input, Row} from 'antd';
import {safeHandleErrorResponse} from '../../assets/helpers/errorHandler';
import {useOrgContext} from "../../contexts/orgContext";

const {Search} = Input;


function Projects(): JSX.Element {
    const dispatch: AppDispatch = useDispatch();

    const {currentOrg} = useOrgContext();
    const [projectPage, setProjectPage] = useState<PageResource<Project>>(createEmptyPage());
    const [visible, setVisible] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('')

    useEffect(() => {
        dispatch(getProjects({}))
            .then(found_projects => {
                setProjectPage(found_projects)
            })
            .catch((err) => {
                safeHandleErrorResponse(err)
            })

    }, [dispatch]);

    const constructCardData = () => {

        const cardItems: CardItem[] = []
        const projects: Project[] = projectPage.content

        projects.forEach(project => {
            cardItems.push({
                id: project.id,
                link: `/${currentOrg.info.domain}/projects/${project.p_key}/overview`,
                icon: <img 
                src={project.icon} 
                alt="Project Icon" 
                style={{ width: '150px', height: '150px' , display: 'block', margin: 'auto'}}
            />,
                name: <div style={{ 
                    wordWrap: 'break-word',
                    overflowWrap: 'break-word',
                    whiteSpace: 'normal',
                    maxWidth: '100px',  // Adjust the max width as needed
                    textAlign: 'center'
                }}>
                    {project.name}
                </div>,
                description: `${project.test_cases} Cases`
            })
        })
        return cardItems
    }

    return (
        <Page title="Projects">
            <PageHeader 
                title="Projects" 
                buttonHeader={currentOrg.userPermissions.project.manage_projects ? {
                    type: "create",
                    onClickFunction: () => setVisible(true)
                } : undefined}
            />
            <PageContent>
                <Search
                    style={{width: 250}}
                    // ref={searchInputRef}
                    placeholder="Search for project"
                    onSearch={() => dispatch(getProjects({search: search}))
                        .then(foundPage => {
                            setProjectPage(foundPage)
                        })
                        .catch((err) => {safeHandleErrorResponse(err)})}
                    value={search}
                    onChange={(value) => setSearch(value.target.value)}
                />
                <Row>
                    <Divider orientation="left"> All Projects </Divider>
                    {CardIcons(constructCardData())}
                </Row>
                <ProjectModal visible={visible}
                              setVisible={setVisible}
                              setProjectPage={setProjectPage}
                              projectPage={projectPage}/>
            </PageContent>
        </Page>
    )
}

export default Projects;