import React from 'react';
import {Line} from '@ant-design/plots';
import {colors} from '../data/colors';

interface DemoLineProps {
    data: {
        month: string,
        key: string,
        value: number
    }[]
}

const DemoLine = React.memo<DemoLineProps>(({data}) => {
    const config = {
        data,
        xField: 'month',
        yField: 'value',
        legend: undefined,
        // theme: 'dark',
        seriesField: 'value',
        style: {height: 300},
        stepType: 'hvh',
        tooltip: {
            customContent: (title: string, items: any[]) => (
                <div style={{
                    backgroundColor: '#f9f9f9',
                    borderRadius: 8,
                    padding: 20,
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                }}>
                    <h2 style={{fontSize: '1.2rem', fontWeight: 'bold', marginBottom: 10, color: 'grey'}}>{title}</h2>
                    <hr style={{border: 'none', borderBottom: '1px solid #ccc', marginBottom: 10}}/>
                    <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: 10}}>
                        {items.map((item) => (
                            <div key={item.label} style={{
                                marginBottom: 8,
                                padding: 8,
                                backgroundColor: '#fff',
                                borderRadius: 6,
                                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                            }}>
                                <span style={{fontWeight: 'bold', color: colors.blue.default}}>Total Batches:</span>
                                <span style={{marginLeft: 4}}>{item.value}</span>
                            </div>
                        ))}
                    </div>
                </div>
            )
        }
    }

    return <Line {...config} />
})

export default DemoLine
