import React, {useMemo} from 'react';
import {Input} from "antd";
import debounce from "lodash/debounce";

interface SearchPros {
    style?: React.CSSProperties,
    placeholder?: string,
    onChange: (text:string) => void
}

class Searches{
    static FrontSearch = ({style, placeholder, onChange}:SearchPros):JSX.Element => {
        // set Defaults
        style = style? style: {width: 300}
        placeholder = placeholder? placeholder: 'Type to search....'

        return <Input
            style={style}
            placeholder={placeholder}
            onChange={(value)=> onChange(value.target.value)}
        />
    }

    static BackWithoutOkButton = ({style, placeholder, onChange}:SearchPros):JSX.Element => {
        // set Defaults
        style = style? style: {width: 300}
        placeholder = placeholder? placeholder: 'Type to search....'

        const debouncedSearch = useMemo(() => debounce((search) => {
            onChange(search)
        }, 500), []);

        return <Input
            style={style}
            placeholder={placeholder}
            onChange={(value)=> debouncedSearch(value.target.value)}
        />
    }
}

export default Searches;