import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {AppDispatch} from '../../redux/store';
// models
import {MemberInfo} from '../../models/entities/user';
import {PageResource} from "../../models/dtos/page_resource";
import {createEmptyPage} from "../../services/utils/PageResourceUtils";
// components
import Page from '../../components/page_structure/page';
import PageHeader from '../../components/page_structure/page_header';
import PageContent from '../../components/page_structure/page_content';
// antd
import {
    TeamOutlined,
    UserAddOutlined,
    UserDeleteOutlined,
    UserOutlined
} from '@ant-design/icons';
import {Button, Input, notification} from 'antd';
// actions
import {addTeamMembers, deleteTeamMember, getTeamMembers, updateUserTeamRole} from '../../redux/actions/team_actions';
import ModalAddMembers from "../../components/modals/modalAddNewMember";
import {safeHandleErrorResponse} from "../../assets/helpers/errorHandler";
import {addMemberParamsModalType} from "../../models/entities/helpers/modal";
import TableUsers from "../../components/tables/table_users";
import {allowedExtraUserColumns} from "../../models/entities/helpers/table";
import {useTeamContext} from "../../contexts/teamContext";
import {useOrgContext} from "../../contexts/orgContext";


const {Search} = Input;


function TeamMembers(): JSX.Element {
    const dispatch: AppDispatch = useDispatch();
    const {currentTeam} = useTeamContext();
    const {currentOrg} = useOrgContext();

    const [members, setMembers] = useState<PageResource<MemberInfo>>(createEmptyPage(15));
    const [addVisible, setAddVisible] = useState<boolean>(false);

    const [search, setSearch] = useState<string>('')

    const [data, setData] = useState<any[]>([])

    const extra_columns: allowedExtraUserColumns[] = currentOrg.userPermissions.team.manage_teams ? ['icon', 'actions']: ['icon']

    const team_id = currentTeam.info.id

    const fetchData = () => {
        dispatch(getTeamMembers({
            id:team_id,
            search: search,
            limit: members.limit,
            offset: 0,
            org_id: currentOrg.info.id
        }))
            .then((found)=>{
                setMembers(found)
            })
            .catch((err) => {safeHandleErrorResponse(err)})
    }

    useEffect(() => {
        fetchData()
    }, [dispatch])


    const updateUserRole = async (user: MemberInfo, role:{id:number, label: string}) => {
        const reverseRole = role.id === 2? {id:5, label: 'Member'} : {id:2, label: "Admin"}
        dispatch(updateUserTeamRole({team_id: team_id, id: user.id, role_id: reverseRole.id, org_id: currentOrg.info.id}))
            .then(() => {
                let tempMembers = {...members}
                tempMembers.content = tempMembers.content.map((tempMember)=> {
                    if(tempMember.id === user.id){
                        tempMember.role = reverseRole
                        return tempMember
                    }
                    return tempMember
                    }
                )
                setMembers(tempMembers)
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })
    }

    useEffect(()=>{
        let tData: any[] = []
        members.content.forEach(member => {
            const memberType = member.type
            tData.push({
                key: member.id,
                icon: memberType === 1? <UserOutlined />: <TeamOutlined/>,
                disabled: member.role.id === 1 || member.id === 0,
                name: <a target='_blank' rel="noreferrer"
                         href={memberType === 1?
                             `/${currentOrg.info.domain}/user/${member.member_id}/profile`
                             : `/${currentOrg.info.domain}/teams/${member.member_id}/${member.name}/wall`
                         }
                         title="Go To Members Profile"
                         style={{color: "inherit"}}>{member.name}</a>
                ,
                email: member.email,
                role: member.role.label,
                actions: currentOrg.userPermissions.team.manage_teams ?
                    <Button type='text' disabled={member.role.id === 1}
                            onClick={() => updateUserRole(member, member.role)}>
                        { [1, 2].includes(member.role.id) ? <><UserAddOutlined /> Remove as Admin </>
                            : <><UserDeleteOutlined /> Add as Admin </>}
                    </Button> : null
            });
        });

        setData(tData)
    }, [members])


    const buttonHeader = currentOrg.userPermissions.team.manage_teams ?
        {type: "create" as const, onClickFunction: () => setAddVisible(true)}
        : null;

    const handleAdd = (params: addMemberParamsModalType) => {
        dispatch(addTeamMembers(params))
            .then(() => {
                fetchData()
                const totalMembers = params.members.length
                notification.success({
                    message: `New Members`,
                    description: <><b>{totalMembers} Member{totalMembers>1? 's': ''}</b> added successfully</>,
                })
            })
            .catch((err) => {
                safeHandleErrorResponse(err)
            })
    }

    const handleDelete = (user_ids:number[]) => {
        dispatch(deleteTeamMember(team_id, user_ids, currentOrg.info.id))
            .then(()=>{fetchData()}).catch((err) => {
                safeHandleErrorResponse(err)
            })
    }

    return <Page title={`Members of ${currentTeam.info.name}`}>
        <PageHeader title={`Members of ${currentTeam.info.name}`}
                    buttonHeader={buttonHeader}/>
        <PageContent>
            <Search
                style={{marginBottom: 10, width: 250, float: 'left'}}
                placeholder="Search for member"
                onSearch={fetchData}
                value={search}
                onChange={(value) => setSearch(value.target.value)}
            />

            <TableUsers
                data={data}
                permissions={currentOrg.userPermissions.team.manage_teams}
                limit={members.limit}
                deleteUserAction={handleDelete}
                extra_columns={extra_columns}
            />

            <ModalAddMembers
                setVisible={setAddVisible}
                visible={addVisible}
                entity={{type:'team', id: team_id}}
                handleAdd={handleAdd}
            />

        </PageContent>
    </Page>
}

export default TeamMembers;
