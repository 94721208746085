import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {AppDispatch} from '../../redux/store';
import {DataNode} from 'antd/lib/tree';
import Page from "../../components/page_structure/page";
import PageHeader from "../../components/page_structure/page_header";
import PageContent from "../../components/page_structure/page_content";

import TestSuiteFolders from './test_suite_folders';
import TestSuiteCases from './test_suite_cases';

import {getSections} from '../../redux/actions/section_actions';
import {NewFolderModal} from "../../components/modals/add_new_file_modal";
import {AddSection} from "../../models/entities/menu";
import RowC from "../../components/row";
import {Col} from "antd";
import {
    TestSuiteProps,
    // checked_section,
    ssDividerOptions,
    SectionProps,
    modalOptions, CasesProps
} from '../../models/entities/suite_section_props';
import { TestCase } from '../../models/entities/test_case_info';
import { getCases } from '../../redux/actions/cases';
import { safeHandleErrorResponse } from '../../assets/helpers/errorHandler';
import {useProjectContext} from "../../contexts/projectContext";


function TestSuite({title, sectionProps, casesProps, options}: TestSuiteProps): JSX.Element {
    const dispatch: AppDispatch = useDispatch();

    const {currentProject} = useProjectContext()

    // init default
    let defaultSections:SectionProps;
    let defaultDividerOptions: ssDividerOptions;
    let defaultModalOptions: modalOptions|undefined = undefined
    let defaultCases: CasesProps;

    const [initIsExpandedTree, setInitIsExpandedTree] = useState<boolean>(true)
    const [treeData, setTreeData] = useState<DataNode[]>( [])
    const [selectedSection, setSelectedSection] = useState<DataNode>({key: -1, title: ""}) // not sure about this default state
    const [selectedSections, setSelectedSections] = useState<DataNode[]>([]) // not sure about this default state
    // const [initCheckedSections, setInitCheckedSections] = useState<checked_section>({checked: [], halfChecked: []})
    const [checkedCases, setCheckedCases] = useState<{ [key: number]: React.Key[] }>({});
    // modal
    const [openModal, setOpenModal] = useState<boolean>(false)
    const [sectionFlag, setSectionFlag] = useState<boolean>(false)
    const [createType, setCreateType] = useState<AddSection>('Section')
    const [cases, setCases] = useState<TestCase[]>([])
    // set default values
    defaultSections = {
        treeData: sectionProps? sectionProps.sections.treeData: treeData,  // DataNode[]
        setTreeData: sectionProps? sectionProps.sections.setTreeData: setTreeData,  // DataNode[]
        checkbox: sectionProps?.sections.checkbox,
        // use this if the test case has extra mass actions for the sections
        // checkbox: sectionProps?.sections.checkbox?{
        //     checkedSections: sectionProps.sections.checkbox.checkedSections,
        //     setCheckedSections: sectionProps.sections.checkbox.setCheckedSections,
        // }:{
        //     checkedSections: initCheckedSections,
        //     setCheckedSections: setInitCheckedSections
        // },
        select: sectionProps?.sections.select?{
            selectedSections: sectionProps.sections.select.selectedSections,
            setSelectedSections: sectionProps.sections.select.setSelectedSections,
            setSelectedSection: sectionProps.sections.select.setSelectedSection,
            selectedCases: sectionProps.sections.select.selectedCases,
            setSelectedCases: sectionProps.sections.select.setSelectedCases
        }:{
            selectedSections,
            setSelectedSections,
            setSelectedSection
        }
    }

    defaultDividerOptions = {
        isExpandedTree: sectionProps && sectionProps.divider? sectionProps.divider.isExpandedTree: initIsExpandedTree ,
        setIsExpandedTree: sectionProps && sectionProps.divider? sectionProps.divider.setIsExpandedTree: setInitIsExpandedTree ,
        showDivider: sectionProps && sectionProps.divider? sectionProps.divider.showDivider: true
    }

    if (options && options.useModal){
        defaultModalOptions = {
            useModal: sectionProps && sectionProps.modal ? sectionProps.modal.useModal: true,
            setOpenModal: sectionProps && sectionProps.modal? sectionProps.modal.setOpenModal: setOpenModal,
            setCreateType: sectionProps && sectionProps.modal? sectionProps.modal.setCreateType: setCreateType,
            setSelectedSection: sectionProps && sectionProps.modal? sectionProps.modal.setSelectedSection: setSelectedSection,
            openModal: sectionProps && sectionProps.modal && sectionProps.modal.openModal!==undefined? sectionProps.modal.openModal: openModal
        }
    } else if(!options){
        defaultModalOptions = {
            useModal: true,
            setOpenModal,
            setCreateType,
            setSelectedSection,
            openModal
        }
    }

    defaultCases = {
        selectedSections: casesProps && casesProps.casesOptions.selectedSections !== undefined? casesProps.casesOptions.selectedSections: selectedSections,
        checkedCases: casesProps? casesProps.casesOptions.checkedCases: checkedCases,
        setCheckedCases: casesProps? casesProps.casesOptions.setCheckedCases: setCheckedCases
    }

    useEffect(() => {
        try {
            let new_cases: TestCase[] = []

            const section_ids = selectedSections ? selectedSections.map(section => {
                return section.key
            }) : []

            if (section_ids.length > 0) {
                dispatch(getCases({section_ids}))
                    .then(found_page => {
                        new_cases = found_page.content
                        setCases(new_cases)
                    }).catch((err) => {
                        safeHandleErrorResponse(err)
                    })
            } else {
                setCases([])
            }
        } catch (err) {
        }
    }, [selectedSections]);

    useEffect(() => {
            dispatch(getSections())
                .then(foundPage => {
                    setTreeData([{
                        title: currentProject.info.name,
                        key: -currentProject.info.id,
                        children: foundPage,
                        selectable: false
                    }])
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })

    }, [dispatch, sectionFlag])
    // Style

    return (
        <Page title="Test Suite">
            <PageHeader title={title ? title : `Test Suite`}/>
            <PageContent>
                <RowC>
                    <Col style={{minWidth: `${defaultDividerOptions.isExpandedTree}px`}}>
                        <TestSuiteFolders
                            sections={defaultSections}
                            divider={defaultDividerOptions}
                            modal={defaultModalOptions}
                        />
                    </Col>
                    <Col flex="auto" style={{marginLeft: 25}}>
                        <TestSuiteCases
                            sectionOptions={defaultSections.checkbox}
                            sections={defaultSections}
                            casesOptions={defaultCases}
                            setIsExpandedTree={defaultDividerOptions.setIsExpandedTree}
                            modal={defaultModalOptions}
                            cases={cases}
                            setCases={setCases}/>
                    </Col>
                </RowC>
                {
                    defaultModalOptions && defaultModalOptions.useModal?
                        <NewFolderModal
                            visible={openModal}
                            setVisible={setOpenModal}
                            createType={createType}
                            setCreateType={setCreateType}
                            selectedSection={selectedSection}
                            setSelectedSection={setSelectedSection}
                            treeData={treeData}
                            setTreeData={setTreeData}
                            selectedSections={selectedSections}
                            setSelectedSections={setSelectedSections}
                            cases={cases}
                            setCases={setCases}
                            sectionFlag={sectionFlag}
                            setSectionFlag={setSectionFlag}
                        />:null
                }

            </PageContent>
        </Page>
    )
}

export default TestSuite;
