import React, {useEffect, useState} from 'react';
import Page from "../../components/page_structure/page";
import PageHeader from "../../components/page_structure/page_header";
import PageContent from "../../components/page_structure/page_content";
import {Row} from 'antd';
import {CardIcon} from "../../components/cards/card_icon";
import {AppDispatch} from "../../redux/store";
import {useDispatch} from "react-redux";
import {Tool} from '../../models/entities/user'
import {addTool, deleteTool, getTools} from "../../redux/actions/user_actions";
import {safeHandleErrorResponse} from "../../assets/helpers/errorHandler";
import ButtonC from "../../components/button";
import {colors} from "../../assets/data/colors";
import {useUserContext} from "../../contexts/userContext";

function Tools(): JSX.Element {
    const dispatch: AppDispatch = useDispatch()

    const {setTools} = useUserContext();

    const [tools, setAllTools] = useState<Tool[]>([])

    useEffect(() => {
        dispatch(getTools({filter:'all'}))
            .then(tools=> setAllTools(tools))
            .catch(err=> safeHandleErrorResponse(err))
    }, [dispatch])

    const handleDeleteTool = (id:number) => {
        dispatch(deleteTool({tool_id: id}))
            .then(()=> {
                setAllTools(prevTools => prevTools.map(tool=> {
                    if(tool.id === id){
                        let tempTool = {...tool}
                        tempTool.hasTool = false
                        return tempTool
                    }
                    return tool
                }))

            })
            .catch(err=> safeHandleErrorResponse(err))
    }

    useEffect(()=>{
        const myTools = [...tools].filter(tool=> tool.hasTool)
        setTools(myTools);
    }, [tools, setTools])

    const handleAddTool = (id:number) => {
        dispatch(addTool({tool_id: id}))
            .then(()=>{
                setAllTools(prevTools=> prevTools.map(tool=> {
                    if(tool.id === id){
                        let tempTool = {...tool}
                        tempTool.hasTool = true
                        return tempTool
                    }
                    return tool
                }))
            })
            .catch(err=> safeHandleErrorResponse(err))
    }

    return (
        <Page title="Tools">
            <PageHeader title="Tools"/>
            <PageContent>
                <Row gutter={[16, 16]} style={{marginLeft: 20}}>
                    {
                        tools.map(tool=>{
                            return <div style={{margin: 20}}>{CardIcon({
                                id: tool.id,
                                icon: tool.icon,
                                name: tool.title,
                                style: { width: 250, height:500, textAlign: "center", marginLeft: "10px", marginTop: "20px"},
                                bodyStyle: {height:145},
                                description: tool.description,
                                actions: [tool.hasTool?
                                    <ButtonC style={{color: colors.red.vermilion}} text='Remove' type='text' onClick={()=> handleDeleteTool(tool.id)}/> :
                                    <ButtonC style={{color: colors.green.forest}} text='Add' type='text' onClick={()=> handleAddTool(tool.id)}/>]
                            }, tool.id)}</div>
                        })
                    }
                </Row>
            </PageContent>
        </Page>
    )
}

export default Tools;