import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import ModalC from "../modal";
import {Form, Input, Switch} from 'antd';
import {AppDispatch} from "../../redux/store";
import ButtonC from "../button";
import {empty_arg} from "../../services/EmptyEntities/EmptyArgs";
import {Argument} from "../../models/entities/settings";
import {saveProjectRunArgument} from "../../redux/actions/project_actions";
import {safeHandleErrorResponse} from "../../assets/helpers/errorHandler";
import {useProjectContext} from "../../contexts/projectContext";
import {useOrgContext} from "../../contexts/orgContext";

type CustomArgumentProps = {
    visible: boolean;
    setVisible: Dispatch<SetStateAction<boolean>>;
    allArgs: Argument[];
    handleSaveUpdate: (isNew:boolean, item:Argument)=>void,
    selectedArg: Argument;
    setSelectedArg: Dispatch<SetStateAction<Argument>>;
}

export default function CustomArgument({visible, setVisible, allArgs, handleSaveUpdate, selectedArg, setSelectedArg}: CustomArgumentProps): JSX.Element {
    const dispatch: AppDispatch = useDispatch();

    const {currentOrg} = useOrgContext();
    const {currentProject} = useProjectContext();

    const org_id = currentOrg.info.id
    const project_id = currentProject.info.id

    const [argLabel, setArgLabel] = useState<string>(selectedArg.label);
    const [argKey, setArgKey] = useState<string>(selectedArg.arg_key);
    const [argDefaultValue, setArgDefaultValue] = useState<string>(selectedArg.value);
    const [argStatus, setArgStatus] = useState<boolean>(selectedArg.status);

    useEffect(() => {
        setArgKey(selectedArg.arg_key)
        setArgLabel(selectedArg.label)
        setArgDefaultValue(selectedArg.value)
        setArgStatus(selectedArg.status)
    }, [selectedArg]);

    const handleCancel = () => {
        setVisible(false)
        setSelectedArg(empty_arg)
    }

    const handleCusArgRunChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;

        // Validate against .env restrictions
        const envValueRegex = /^[A-Za-z_][A-Za-z0-9_]*$/;
        if (!envValueRegex.test(inputValue)) {
            // If the input value violates the .env restrictions, don't update the state
        }

        // If the input value passes validation, update the state
        setArgKey(inputValue);
        setArgLabel(inputValue.replace(/ /g, "_").toUpperCase())
    };

    const handleUpdateDefaultProjectCusArgs = () => {
        const params = {
            id: selectedArg.id,
            project_id: project_id,
            org_id: org_id,
            label: argLabel,
            arg_key: argKey,
            default_value: argDefaultValue,
            status: argStatus
        }

        dispatch(saveProjectRunArgument(params))
            .then((result) => {
                let newArgument:Argument = {
                    id: result.isNew? result.newId: params.id,
                    label: params.label,
                    value: params.default_value,
                    arg_key: params.arg_key,
                    status: params.status
                }

                handleSaveUpdate(result.isNew, newArgument)

            })
            .catch((err) => {safeHandleErrorResponse(err)})

        handleCancel()
    };

    const footer_buttons = [
        <ButtonC key="submit_cancel" onClick={handleCancel} text="Cancel"/>,
        <ButtonC key="submit_ok" type="primary" onClick={handleUpdateDefaultProjectCusArgs}
                 text={selectedArg === empty_arg ? 'Create' : 'Save'}
                 disabled={argLabel===''}
        />
    ];


    return (
        <ModalC open={visible}
                title={selectedArg !== empty_arg ? 'Edit Custom Argument' : 'Create Custom Argument'}
                onOk={handleUpdateDefaultProjectCusArgs} onCancel={handleCancel}
                footer={footer_buttons}>
            <Form layout="vertical">
                <Form.Item label="Label">
                    {
                        allArgs.some(item => item.label === argLabel)?
                            <p style={{color: '#F6C324'}}>The specific Custom Argument already exists!</p>
                            : null
                    }
                    <Input
                        placeholder='Provide value'
                        onChange={handleCusArgRunChange}
                        value={argLabel}/>
                </Form.Item>
                <Form.Item label="Key">
                    <Input
                        placeholder='Autocomplete'
                        disabled={true}
                        value={argKey}/>
                </Form.Item>
                <Form.Item label="Default Value">
                    <Input
                        placeholder='Provide default value'
                        onChange={(event)=>setArgDefaultValue(event.target.value)}
                        value={argDefaultValue}/>
                </Form.Item>
                <Form.Item label="Status">
                    <Switch
                        onChange={(checked)=> setArgStatus(checked)}
                        checked={argStatus}
                        defaultChecked={argStatus}/>
                </Form.Item>
            </Form>
        </ModalC>
    );
}
