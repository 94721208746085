import { AppDispatch } from "../store";
import { fetchErrorAction, fetchStartAction, fetchSuccessAction } from "./creators/common_actions";
import { NewPostComment, NewWallPost, PostComments, WallInfo, WallPosts } from "../../models/entities/team";
import { PageResource } from "../../models/dtos/page_resource";
import {get, post, put, deletion} from '../../services/axiosWrapper';


const api_path = '/api/team/';

export const createWallPost = (data:NewWallPost, posts:WallPosts[]) => (dispatch: AppDispatch): Promise<WallPosts[]> => {
    dispatch(fetchStartAction());

    const request: any = post(`${api_path}createWallPost`, data)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            value.data = [value.data.new_post, ...posts]
            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })

}

export const createPostComment = (data:NewPostComment, comments:PostComments[]) => (dispatch: AppDispatch): Promise<PostComments[]> => {
    dispatch(fetchStartAction());

    const request: any = post(`${api_path}createPostComment`, data)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            value.data = [value.data.new_comment, ...comments]
            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })

}

export const getTeamPosts = (params: {org_id: number, team_id: number, offset: number}) => (dispatch: AppDispatch): Promise<PageResource<WallPosts>> => {

    dispatch(fetchStartAction());

    const request: any = get(`${api_path}getTeamPosts`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const getTeamPinnedPosts = (params: {org_id: number, team_id: number, offset: number}) => (dispatch: AppDispatch): Promise<PageResource<WallPosts>> => {

    dispatch(fetchStartAction());

    const request: any = get(`${api_path}getTeamPinnedPosts`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const getPostComments = (params: {post_id: number | undefined, offset: number, org_id:number, team_id:number}) => (dispatch: AppDispatch): Promise<PageResource<PostComments>> => {

    dispatch(fetchStartAction());

    const request: any = get(`${api_path}getPostComments`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const updatePost = (data:WallPosts, posts:WallPosts[]) => (dispatch: AppDispatch): Promise<WallPosts[]> => {
    dispatch(fetchStartAction());

    const request: any = put(`${api_path}editPost`, data)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            const post_index: number = posts.findIndex(post => post.post_id === data.post_id)
            posts[post_index] = data
            value.data = posts
            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })

}

export const pinPost = (params: {post_id: number, org_id: number, team_id: number}) => (dispatch: AppDispatch): Promise<WallPosts[]> => {
    dispatch(fetchStartAction());

    const request: any = put(`${api_path}pinPost`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            // const post_index: number = posts.findIndex(post => post.post_id === data.post_id)
            // posts[post_index] = data
            // value.data = posts
            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })

}

export const deletePost = (post_id:number, org_id: number, team_id: number) => (dispatch: AppDispatch): Promise<PageResource<WallPosts[]>> => {
    dispatch(fetchStartAction());
    
    const request: any = deletion(`${api_path}deletePost?post_id=${post_id}&org_id=${org_id}&team_id=${team_id}`)
    // todo remove job from users etc
    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));

            return Promise.reject();
        })
}

export const updatePostComment = (data:PostComments, comments:PostComments[]) => (dispatch: AppDispatch): Promise<PostComments[]> => {
    dispatch(fetchStartAction());

    const request: any = put(`${api_path}ediPostComment`, data)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            const comment_index: number = comments.findIndex(comment => comment.comment_id === data.comment_id)
            comments[comment_index] = data
            value.data = comments
            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })

}


export const deletePostComment = (comment_id:number, org_id: number, team_id: number) => (dispatch: AppDispatch): Promise<PageResource<PostComments[]>> => {
    dispatch(fetchStartAction());
    
    const request: any = deletion(`${api_path}deletePostComment?comment_id=${comment_id}&org_id=${org_id}&team_id=${team_id}`)
    // todo remove job from users etc
    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));

            return Promise.reject();
        })
}

export const getTeamWallInfo = (params: {org_id: number, team_id: number}) => (dispatch: AppDispatch): Promise<WallInfo> => {

    dispatch(fetchStartAction());

    const request: any = get(`${api_path}getTeamWallInfo`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}
