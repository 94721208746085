import React from "react";

import {notification} from "antd";
import {safeHandleErrorResponse} from "./errorHandler";

export function copyToClipboard(text:string) {
    navigator.clipboard.writeText(text)
        .then(() => {
            notification.success({
                message: 'Text Copied',
                placement: 'bottomLeft',
                duration: 0.8
            })
        })
        .catch(error => {
            safeHandleErrorResponse(error)
        });

}


