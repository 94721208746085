import { AppDispatch } from "../store";
import { fetchErrorAction, fetchStartAction, fetchSuccessAction } from "./creators/common_actions";
import { post, deletion, getWithProjectWrapper} from '../../services/axiosWrapper';
import {PageResource} from "../../models/dtos/page_resource";
import {CreateTemplate, LoadTemplatePage, UpdateTemplate} from "../../models/entities/template";

const api_path = '/api/runTemplates/';


export const createRunTemplate = (data:CreateTemplate | UpdateTemplate) => (dispatch: AppDispatch): Promise<{
    success:boolean,
    newTemplate: LoadTemplatePage
}> => {
    dispatch(fetchStartAction());

    const request: any = post(`${api_path}create`, data)
    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const fetchRunTemplates = (params: {onlyMine:boolean, offset:number, search:string, onlyFavorites:boolean}) => (dispatch: AppDispatch): Promise<PageResource<LoadTemplatePage>> => {

    dispatch(fetchStartAction());

    const request: any = getWithProjectWrapper(`${api_path}fetch`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const updateFavorite = (data:{id:string, isFavorite:boolean}) => (dispatch: AppDispatch): Promise<LoadTemplatePage> => {

    const request: any = post(`${api_path}updateFavorite`, data)
    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const deleteTemplate = (id:string) => (dispatch: AppDispatch): Promise<LoadTemplatePage> => {

    const request: any = deletion(`${api_path}deleteTemplate?id=${id}`)
    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const updateTemplate = (data:{id:string, name:string}) => (dispatch: AppDispatch): Promise<LoadTemplatePage> => {

    const request: any = post(`${api_path}updateTemplate`, data)
    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();

        })
}

