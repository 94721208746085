import React, { useEffect, useState} from 'react';
import {Card, Col, Timeline, Slider, notification, Statistic, Tooltip} from 'antd';
import RowC from "../../components/row";
import {colors} from "../../assets/data/colors";
import { Pie } from '@ant-design/plots';
import { TimelineItemProps } from 'antd/lib/timeline';
import {AppDispatch} from "../../redux/store";
import {useDispatch} from "react-redux";
import {CasesPreview} from "../../models/entities/test_case_info";
import {empty_cases_preview} from "../../services/EmptyEntities/EmptyTestCase";
import {FolderOpenOutlined, InfoCircleOutlined} from "@ant-design/icons";
import {calculateReviewers, calculateRunTime, viewCasesPerComponents} from "../../redux/actions/batch_actions";
import ReviewersAvatars from "../../components/reviewersAvatar";
import { safeHandleErrorResponse } from '../../assets/helpers/errorHandler';
import {useProjectContext} from "../../contexts/projectContext";
import {useCreateRunContext} from "../../contexts/createRunContext";
import {CasesPreviewAction} from "../../models/entities/suite_section_props";


function RunComplete(): JSX.Element {
    const dispatch: AppDispatch = useDispatch();
    const {currentProject} = useProjectContext()
    const {settings, selection} = useCreateRunContext()

    const [dataPreview, setDataPreview] = useState<CasesPreview>(empty_cases_preview);  // Set this per project configuration
    const [componentData, setComponentData] = useState<{type: string, value: number}[]>([]);

    const {run, githubBranch, reruns, maxParallel, setMaxParallel, runTitle} = settings
    const {runDate, endDate, isRecurring, recurringOption, runOption} = run

    const {cases, components, sections} = selection

    const selected:CasesPreviewAction = {
        sections: sections.checkedSections.checked,
        cases:cases.checkedCasesIds,
        components: components.checkedComponents
    }

    useEffect(() => {
        dispatch(calculateRunTime(currentProject.info.id, selected))
            .then((calculatedData)=>{
                if (calculatedData) {
                    setDataPreview(prevDataPreview => ({
                        ...prevDataPreview,
                        new_cases: calculatedData.new_cases,
                        total_cases: calculatedData.total_cases,
                        estimation: calculatedData.estimation
                    }));
                }
            })
            .catch((err) => {safeHandleErrorResponse(err)})

        dispatch(calculateReviewers(currentProject.info.id, selected))
            .then((review)=>{
                if (review) {
                    setDataPreview(prevDataPreview => ({
                        ...prevDataPreview,
                        reviewers: review.reviewers,
                        total_reviewers: review.total
                    }));
                }
            })
            .catch((err) => {safeHandleErrorResponse(err)})

        console.log(selected.components)
        dispatch(viewCasesPerComponents(selected.components, selected.sections, selected.cases))
            .then((found)=> {
                let comp_found: {type: string, value: number}[]= []
                found.forEach(element => {
                    comp_found.push({type: element.component_title, value: element.component_cases})
                });
                setComponentData(comp_found)
            })
            .catch((err) => {safeHandleErrorResponse(err)})

    }, [dispatch]);


    const config = {
        appendPadding: 10,
        data: componentData,
        angleField: 'value',
        colorField: 'type',
        radius: 1,
        innerRadius: 0.64,
        label: {
            type: 'inner',
            offset: '-50%',
            style: {
                textAlign: 'center',
            },
            autoRotate: false,
            content: '{value}',
        },
        statistic: {
            title: {
                offsetY: -4,
            },
            content: {
                offsetY: 4,
                style: {
                    fontSize: '32px',
                },
            },
        },

        interactions: [
            {
                type: 'element-selected',
            },
            {
                type: 'element-active',
            },
            {
                type: 'pie-statistic-active',
            },
        ],
    }

    const get_items=(): TimelineItemProps[]=>{
        let items: TimelineItemProps[] = [{
            label: 'On Create',
            children: !isRecurring && runOption === 'Scheduled' ? <>Create services {runDate? runDate.format('YYYY-MM-DD HH:MM'):''} for <b>{runTitle}</b></>
            : 
            runOption === 'Now' ? <>Create every services for <b>{runTitle} </b>Now.</> :
            runOption === 'Manually' ? <>Create every services for <b>{runTitle} </b>Manually.</> :
            <>Create every {recurringOption} services for <b>{runTitle}</b>
            {endDate ? ` until ${endDate.format('YYYY-MM-DD HH:MM')}` : ''}
            </>
        }]

        if(reruns>0){
            items.push({
                label: 'Repeat Process',
                children: `Repeat ${reruns} time${reruns>1?'s':''}`
            })
        }

        items = [...items, {
            label: 'Step 1',
            children: `Scripts Before Test Execution: {4}`,
        },
            {
                label: 'Step 2',
                children: <>Run <b>{dataPreview.total_cases}</b> Test Cases on <b>{githubBranch}</b></>,
            },
            {
                label: 'Step 3',
                children: `Scripts After Test Execution: {5}`,
            }]

        if(reruns>0){
            items = [...items, {
                label: 'New Rerun',
                children: `Rerun Failed Cases`
            },{
                label: 'End of Repeat',
                children: `------`
            }]
        }

        items.push({
            label: 'On Complete',
            children: 'Destroy services',
        })
        return items
    }

    const pop_up = (new_parallel:number) => {
        // openNotification()
        notification.success({
            message: 'Slider Value Changed',
            description: `Slider value changed to: ${new_parallel}.`,
        });

    }

    return (
        <div style={{backgroundColor: colors.gray.background, height:580, textAlign:"center"}}>
            <RowC gutter={16} style={{margin:'0px 20px 0px 20px', paddingTop:25}}>
                <Col span={7}>
                    <Card title={'Timeline'} style={{height:530, overflowY:"auto"}} bordered={false}>
                        <Timeline
                            mode='left'
                            items={get_items()}
                        />
                    </Card>
                </Col>
                <Col span={7} >
                    {currentProject.info.project_type !== 2 && 
                    <Card
                        title={`Estimation: ${dataPreview.total_cases?`${Math.round(dataPreview.estimation/3600/maxParallel * 100) / 100} Hours`: '-'}`}
                        bordered={false}
                        // style={{height:'20vh'}}
                        style={{height: 255}}
                    >
                        <h3 style={{marginRight: 15}}> Total Cases: {dataPreview.total_cases} </h3>
                        {
                            dataPreview.new_cases>0?
                                <h3 style={{marginLeft: 5}}> New Cases: {dataPreview.new_cases}
                                    <Tooltip
                                        placement="right"
                                        title={
                                            <>
                                                <h3>Info</h3>
                                                <p> Our Pandas can’t factor in new cases for estimating the total run time, so the execution might take a bit longer than expected.</p>
                                            </>
                                        }
                                        color={colors.green.bamboo}
                                    >
                                        <InfoCircleOutlined style={Object.assign({}, {marginLeft:15}, {color: colors.green.bamboo, fontSize:18})}/>
                                    </Tooltip>
                                </h3>
                                : null
                        }

                        <h3 style={{marginRight: 10}}> Concurrently: {maxParallel}
                        </h3>
                        <Slider
                            min={1}
                            max={100}
                            onChange={setMaxParallel}
                            onChangeComplete={pop_up}
                            value={maxParallel}
                        />
                    </Card>}
                    <Card title={"Reviewers"} bordered={false} style={{marginTop:25, height:250}}>
                        <h2> Total Reviewers: {dataPreview.total_reviewers} </h2>
                        <ReviewersAvatars
                            reviewers={{reviewers:dataPreview.reviewers, total_reviewers: dataPreview.total_reviewers}}
                            avatarSize={'large'}
                        />
                    </Card>
                </Col>
                <Col span={10} >
                    <Card title={"Components"} style={{height:335}} bordered={false}>
                        <Pie style={{height: 250}} {...config}/>
                    </Card>
                    <RowC gutter={16} style={{paddingTop:25}}>
                        <Col span={12}>
                            <Card title={"Sections"} style={{height: 170}}>
                                <Statistic
                                    suffix={<FolderOpenOutlined />}
                                    value={selected.sections.length}
                                />
                            </Card>
                        </Col>
                        <Col span={12}>
                            <Card title={"Cases"} style={{height:170}}>
                                <Statistic
                                    suffix={<img alt={'statistics'} src={'/icons/script.png'} style={{width:20}}/>}
                                    value={selected.cases.length}
                                />
                            </Card>
                        </Col>
                    </RowC>
                </Col>
            </RowC>
        </div>
    )
}

export default RunComplete;