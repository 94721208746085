import React, {useEffect, useState} from 'react';
import Page from "../../components/page_structure/page";
import PageHeader from "../../components/page_structure/page_header";
import PageContent from "../../components/page_structure/page_content";
import {AppDispatch} from "../../redux/store";
import {useDispatch} from "react-redux";
import Settings from "../../components/settings";
import {setting_sections} from '../../models/entities/settings';
import {Button, FloatButton, Input, Row} from "antd";
import TextArea from 'antd/es/input/TextArea';
import {
    getUserGithubIntegrations,
    getUserInfo,
    updatePassword,
    updateUserDesktopAppIntegration,
    updateUserGithubIntegration,
    updateUserInfo
} from '../../redux/actions/user_actions';
import UploaderC from '../../components/uploaders/uploaderC';
import {safeHandleErrorResponse} from '../../assets/helpers/errorHandler';
import {useUserContext} from "../../contexts/userContext";
import {useOrgContext} from "../../contexts/orgContext";


function UserSettings(): JSX.Element {
    const dispatch: AppDispatch = useDispatch();

    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}[\]:;<>,.?~\\-]).{8,}$/;

    const {loggedInUser, setAppContextUser} = useUserContext();
    const {currentOrg} = useOrgContext()

    const [name, setName] = useState<string>('');
    const [lastname, setLastname] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [email, setEmail] = useState<any>('');
    const [password, setPassword] = useState<string>('');
    const [newPassword, setNewPassword] = useState<string>('');
    const [reNewPassword, setReNewPassword] = useState<string>('');
    const [githubTokenName, setGithubTokenName] = useState<string>();
    const [githubTokenValue, setGithubTokenValue] = useState<string>();
    const [desktopAppTokenValue, setDesktopAppTokenValue] = useState<string>();

    useEffect(() => {
        dispatch(getUserInfo({user_id: loggedInUser.id})).then(info => {
            setName(info.name)
            setLastname(info.lastname)
            setDescription(info.description)
            setEmail(info.email)
        }).catch((err) => {
            safeHandleErrorResponse(err)
        })

        dispatch(getUserGithubIntegrations())
            .then(settings => {
                setGithubTokenName(settings.content.token_name);
                setGithubTokenValue('');
            }).catch((err) => {
            safeHandleErrorResponse(err)
        })

    }, [dispatch, loggedInUser.id])

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    };

    const handleLastnameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLastname(event.target.value);
    };

    const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDescription(event.target.value);
    };

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    };

    const handleNewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewPassword(event.target.value);
    };

    const handleReNewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setReNewPassword(event.target.value);
    };

    const handleGithubTokenName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setGithubTokenName(event.target.value);
    };

    const handleGithubTokenValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        setGithubTokenValue(event.target.value);
    };

    const handleDesktopAppTokenValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDesktopAppTokenValue(event.target.value);
    };

    const handleUpdateInfo = () => {
        const params = {
            name: name,
            lastname: lastname,
            description: description,
            email: email
        }
        dispatch(updateUserInfo(params))
            .then(() => {
                setName(name)
                setLastname(lastname)
                setDescription(description)
                setEmail(email)
                loggedInUser.name = name
                loggedInUser.lastname = lastname
                loggedInUser.email = email
                setAppContextUser(loggedInUser)
            }).catch((err) => {
            safeHandleErrorResponse(err)
        })
    };

    const handleChangePassword = () => {
        const params = {
            password: password,
            new_password: newPassword
        }
        // TODO: pantelis handle new pass === repeat
        dispatch(updatePassword(params)).then(() => {
            setPassword('')
            setNewPassword('')
            setReNewPassword('')
        }).catch((err) => {
            safeHandleErrorResponse(err)
        })
    };

    const handleUpdateGitHubToken = async () => {
        dispatch(updateUserGithubIntegration({
            org_id: currentOrg.info.id,
            token_value: githubTokenValue,
            token_name: githubTokenName
        })).then(() => {
            setGithubTokenValue('')
        }).catch((err) => {
            safeHandleErrorResponse(err)
        })
    };

    const handleUpdateDesktopAppToken = async () => {
        dispatch(updateUserDesktopAppIntegration({
            desktop_app_token: desktopAppTokenValue ? desktopAppTokenValue : '',
        })).then(() => {
            setDesktopAppTokenValue('')
        }).catch((err) => {
            safeHandleErrorResponse(err)
        })
    };

    const generateToken = () => {
        const randomToken = Math.random().toString(36).substr(2, 20); // Generates a random string
        setDesktopAppTokenValue(randomToken);
    };

    const setting_sections: setting_sections = [
        {
            attr_id: 'ps-general', title: 'Personal Information', settings: [
                {
                    title: 'Profile Image', description: 'Update your profile image.',
                    value: <Row>
                        <div style={{display: 'flex', alignItems: 'flex-end'}}>
                            <UploaderC
                                id={loggedInUser.id}
                                max_items={1}
                                type_action={"users"}
                                listType="picture-card"
                                url={loggedInUser.image ? loggedInUser.image : ""}/>
                        </div>
                    </Row>
                },
                {
                    title: 'Name', description: 'Update your name.',
                    value: <><Input
                        placeholder='New Name'
                        value={name}
                        onChange={handleNameChange}
                        style={{width: 200}}/>
                        <Button
                            type='default'
                            onClick={handleUpdateInfo}
                            style={{marginLeft: 10}}
                            disabled={name?.trim() === ''}>Update
                        </Button></>
                }, {
                    title: 'Lastname', description: 'Update your lastname.',
                    value: <><Input
                        placeholder='Update your lastname.'
                        value={lastname}
                        onChange={handleLastnameChange}
                        style={{width: 200}}/>
                        <Button
                            type='default'
                            style={{marginLeft: 10}}
                            disabled={lastname?.trim() === ''}
                            onClick={handleUpdateInfo}>Update
                        </Button></>
                }, {
                    title: 'Description', description: 'Update your description.',
                    value: <><TextArea rows={4} placeholder="Provide your description." style={{width: 400}}
                                       value={description} onChange={(e: any) => handleDescriptionChange(e)}/><br/>
                        <Button
                            type='default'
                            style={{marginTop: 10}}
                            onClick={handleUpdateInfo}>Update
                        </Button></>
                }
            ]
        },
        {
            attr_id: 'ps-users', title: 'Account', settings: [
                {
                    title: 'Email', description: 'Update your email.',
                    value: <><Input
                        placeholder='New Email'
                        value={email}
                        onChange={handleEmailChange}
                        style={{width: 200}}/>
                        <Button
                            type='default'
                            onClick={handleUpdateInfo}
                            style={{marginLeft: 10}}
                            disabled={email?.trim() === ''}>Update
                        </Button></>
                }, {
                    title: 'Password',
                    description: 'Update your password. Remember that password must contain at least one lowercase letter, one uppercase letter, one special character, and be at least 8 characters long.',
                    value: <><Input.Password
                        placeholder='Current Password'
                        value={password}
                        onChange={handlePasswordChange}
                        style={{width: 200}}/><br/>
                        <Input.Password
                            placeholder='New Password'
                            value={newPassword}
                            onChange={handleNewPasswordChange}
                            style={{width: 200, marginTop: 10}}/><br/>
                        <Input.Password
                            placeholder='New Password'
                            value={reNewPassword}
                            onChange={handleReNewPasswordChange}
                            style={{width: 200, marginTop: 10}}/>
                        <Button
                            type='default'
                            style={{marginLeft: 10}}
                            disabled={password.trim() === '' || newPassword.trim() === '' || reNewPassword.trim() === '' || newPassword !== reNewPassword || !passwordRegex.test(newPassword.trim())}
                            onClick={handleChangePassword}>Update
                        </Button>
                        {newPassword !== reNewPassword ?
                            <p style={{color: '#F6C324'}}>New Password and Repeat New Password should be the
                                same!</p> : null}
                        {(!passwordRegex.test(newPassword.trim()) && newPassword !== '') ?
                            <p style={{color: '#F6C324'}}>Password must contain at least one lowercase letter, one
                                uppercase letter, one special character, and be at least 8 characters long!</p> : null}
                    </>
                }
            ]
        },
        {
            attr_id: 'ps-integrations', title: 'Integrations', settings: [], children: [{
                attr_id: 'ps-github', title: 'Github', description: 'Set up your GitHub Integration.', settings:
                    [{
                        title: 'Github Token',
                        description: 'Set your Github Token name and value.',
                        value: <div>
                            <Input
                                style={{width: 200}}
                                placeholder='Set token name'
                                value={githubTokenName}
                                onChange={handleGithubTokenName}/><br/>
                            <Input
                                style={{width: 200, marginTop: 10}}
                                placeholder='Set token value'
                                value={githubTokenValue}
                                onChange={handleGithubTokenValue}/><br/>
                            <Button
                                type='default'
                                style={{marginTop: 10}}
                                disabled={githubTokenName === undefined || githubTokenName === null || githubTokenName === '' || githubTokenValue === undefined || githubTokenValue === null || githubTokenValue === ''}
                                onClick={handleUpdateGitHubToken}>Set</Button>
                        </div>
                    }
                    ]
            },
            {
                attr_id: 'ps-desktop_app', title: 'Desktop App', description: 'Set up your Desktop App.', settings:
                    [{
                        title: 'Desktop App Token',
                        description: 'Set your Desktop App toket.',
                        value: <div>
                            <Input
                                style={{width: 200, marginTop: 10}}
                                placeholder='Set token value'
                                value={desktopAppTokenValue}
                                onChange={handleDesktopAppTokenValue}/>
                            <Button type='default' style={{ marginTop: 10, marginLeft: 10 }} onClick={generateToken}>
                                Generate Token
                            </Button>
                            <br />
                            <Button
                                type='default'
                                style={{marginTop: 10}}
                                disabled={desktopAppTokenValue === undefined || desktopAppTokenValue === null || desktopAppTokenValue === ''}
                                onClick={handleUpdateDesktopAppToken}>Set</Button>
                        </div>
                    }
                    ]
            }
            ]
        }
    ]
    return (
        <Page title={`User Settings`}>
            <PageHeader title={`User Settings`}/>
            <PageContent>
                <Settings setting_sections={setting_sections}/>
                <FloatButton.BackTop/>
            </PageContent>
        </Page>
    )
}

export default UserSettings;