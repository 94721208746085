import React from 'react';
import {useProjectContext} from "../../contexts/projectContext";
import {CreateRunProvider} from "../../contexts/createRunContext";
import RunDenied from "../../views/run/run_denied";


function CreateRunGuard(): JSX.Element {

    const {currentProject} = useProjectContext()

    if(!currentProject.settings.canCreateRun){
        return <RunDenied/>
    }

    return <CreateRunProvider/>
}

export default CreateRunGuard;