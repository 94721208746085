import React from 'react';
import {Tabs} from "antd";
import {useCreateRunContext} from "../../contexts/createRunContext";
import {FolderOpenOutlined, TagFilled} from "@ant-design/icons";
import TestSuite from "../test_suite/test_suite";
import Components from "../components/components";

function RunSelection(): JSX.Element {
    const {utils, selection} = useCreateRunContext()

    const {sections, cases, components} = selection
    const select_tab_items = [{
        label: <span><FolderOpenOutlined/> Sections & Cases </span>,
        key: "1",
        children:
            <TestSuite
                title={'Select Cases'}
                sectionProps={{
                    sections: {
                        treeData: sections.treeSections,
                        setTreeData: sections.setTreeSections,
                        checkbox: {
                            checkedSections: sections.checkedSections,
                            setCheckedSections: sections.setCheckedSections
                        }
                    },
                }}
                casesProps={{
                    casesOptions: {
                        checkedCases: cases.checkedCases,
                        setCheckedCases: cases.setCheckedCases
                    }
                }}
                options={{useModal: false}}
            />

    }, {
        label: <span><TagFilled/> Components </span>,
        key: "2",
        children: <Components
            componentPage={components.componentPage}
            checkbox={{
                checkedComponents:components.checkedComponents,
                setCheckedComponents: components.setCheckedComponents
            }}
        />
    }]

    return <Tabs activeKey={utils.caseTab}
                 onChange={(e) => utils.setCaseTab(e)}
                 items={select_tab_items}
    />
}

export default RunSelection;