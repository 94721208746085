import { Project } from "../../models/entities/project";

export const empty_project:Project = {
    id: 0,
    name: "",
    p_key: "",
    project_type: 2,
    test_cases: 0,
    org_id: 0,
    icon: ""
}