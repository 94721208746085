import { Dispatch, SetStateAction, useEffect, useState } from "react";
import React from "react";
import ModalC from "../modal";
import {notification, Select} from 'antd';
import RichEditor from "../rich_editor/rich_editor";
import RowC from "../row";
import ButtonC from "../button";
import {notificationTypes} from "../../models/entities/run_script_notifications";
import {useCreateRunContext} from "../../contexts/createRunContext";
import {RenderWebhook} from "./modalAddNewNotification";

type PostProps = {
    visible: boolean,
    setVisible: Dispatch<SetStateAction<boolean>>,
    loadItem: {
        index: number,
        type: notificationTypes,
        title: string,
        id: number,
        message: string
    }
}

// export function ModalEditWebhookNotification(props:any): JSX.Element {
//     const {notifications} = useCreateRunContext()
//
//     const {setCustom} = notifications
//
//     return <RenderWebhook
//         method={method}
//         setMethod={setMethod}
//         url={url}
//         setUrl={setUrl}
//         dataType={dataType}
//         setDataType={setDataType}
//         data={data}
//         setData={setData}
//         validUrl={validUrl}
//         headers={headers}
//         setHeaders={setHeaders}
//     />
// }

export default function ModalEditNotification(props:PostProps):JSX.Element {
    const {notifications} = useCreateRunContext()

    const {setCustom} = notifications

    const {visible, setVisible, loadItem} = props

    let mapHeaders = {
        team: 'Selected Team',
        slack: 'Selected Slack',
        scripts: 'Selected Scripts',
        notifications: 'Selected Notification',
        webhook: 'Selected Webhook'
    }

    const [message, setMessage] = useState<string>(loadItem.message)

    useEffect(()=>{
        setMessage(loadItem.message)
    }, [loadItem])

    const handleEditorChange = (content: any) => {
        setMessage(content)
    };

    const handleCancel = () => {
        setVisible(false)
        setMessage('')
    };

    const handleOk = () => {
        setCustom((prevState)=>
            prevState.map((notification, index)=>{
                if(index === loadItem.index){
                    return {
                        ...notification,
                        message
                    }
                }
                return notification
            }),

        )
        notification.success({
            message: 'Successfully Updated',
            duration: 1
        })
        handleCancel()
    };

    const footer_buttons = [
        <ButtonC key="submit_cancel" onClick={handleCancel} text="Cancel"/>,
        <ButtonC
             key="submit_ok"
             type="primary"
             disabled={message === '' || message === loadItem.message}
             onClick={() => handleOk()}
             text={'Update'}/>

    ];

    return <ModalC
        open={visible}
        title={<>Edit: {loadItem.title}</>}
        onCancel={handleCancel}
        footer={footer_buttons}
        styles={{
            body:{
                minHeight: 400, // Ensures the modal body has a minimum height
                display: 'flex',
                flexDirection: 'column',
            }
        }}
    >
        <RowC>
            <h4>{mapHeaders[loadItem.type]}</h4>
            <Select
                mode="multiple"
                style={{ width: '100%' }}
                disabled={true}
                value={[loadItem.id]}
                optionLabelProp="label"
                options={[{label:loadItem.title, value:loadItem.id}]}
            />
        </RowC>
        <RowC>
            <h4>Message: <ButtonC size={'small'} text={'Clear'} onClick={()=>{handleEditorChange('')}}/></h4>
            <RichEditor value={message} onChange={handleEditorChange}/>
        </RowC>
    </ModalC>
}
