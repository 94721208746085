import React, {useEffect, useState} from 'react';
import {Button, Popover} from 'antd';
import {CheckOutlined, CloseOutlined, CommentOutlined, CopyOutlined} from "@ant-design/icons";
import TPBadge from "../sds/badge";
import {colors} from "../assets/data/colors";
import {copyToClipboard} from "../assets/helpers/copyToClipboard";

type ButtonsSaveOnEditProps = {
    onClick: any,
    disableSave?: boolean,
    reset: any
}
type CopyToClipboardProps = {
    text: string,
    style?: React.CSSProperties,
    type?: 'text' | 'icon'
};

export class Buttons{
    static ButtonsSaveOnEdit = (props: ButtonsSaveOnEditProps): JSX.Element => {
        const {onClick, reset, disableSave} = props

        return <>&nbsp;
            <ButtonC
                type={'text'}
                style={{color: colors.green.forest}}
                disabled={disableSave}
                onClick={() => {
                    onClick()
                    reset()
                }}
                icon={<CheckOutlined alt={'Save'}/>}/>
            <ButtonC
                type={'text'}
                style={{color: colors.red.vermilion}}
                onClick={reset}
                icon={<CloseOutlined alt={'Cancel'}/>}/>
        </>
    }

    static CopyToClipboard({text, style, type}:CopyToClipboardProps): JSX.Element {

        return type && type==='text'?
            <ButtonC style={style}
                     onClick={()=>copyToClipboard(text)}
                     text={'copy'}
                     type={'text'}
            />
            :<ButtonC style={style}
                      onClick={()=>copyToClipboard(text)}
                      icon={<CopyOutlined />}
            />

    }

    static CircleStatus = (props:{
        onClick: ()=>void,
        size?: 'small' | 'middle' | 'large',
        style?: React.CSSProperties,
        defaultStatus: boolean
    }):JSX.Element => {
        let {onClick, size, style, defaultStatus} = props
        const [active, setActive] = useState<boolean>(defaultStatus)

        useEffect(()=>{
            setActive(defaultStatus)
        }, [defaultStatus])

        return <ButtonC
            size={size}
            type='text'
            shape='circle'
            style={style}
            onClick={() => {
                setActive((prevState => !prevState))
                onClick()
            }}
            text={<div title={active? 'Disable': 'Enable'}
                       style={{
                           width: 16,
                           height: 16,
                           background: active? colors.green.gradient: colors.red.gradient,
                           marginLeft: 7,
                           borderRadius: '50%'
                       }}/>}
        />
    }
}

interface ButtonPropsType {
    key?: string,
    onClick?: React.MouseEventHandler<HTMLInputElement> | undefined,
    text?: React.ReactNode,
    type?: "link" | "text" | "default" | "primary" | "dashed" | undefined,
    style?: React.CSSProperties,
    danger?: boolean,
    disabled?: boolean,
    icon?: React.ReactNode,
    shape?: "circle" | "default" | "round" | undefined,
    size?: 'small' | 'middle' | 'large' | undefined,
    htmlType?: "button" | "submit" | "reset" | undefined
    title?: string
}

interface CommentButtonOnlyProps extends ButtonPropsType {
    onClick: React.MouseEventHandler<HTMLInputElement>,
    content: React.ReactNode
}


interface commentsProps extends CommentButtonOnlyProps {
    comments: number,
}


function ButtonC(props: ButtonPropsType): JSX.Element {
    return <Button {...props}>
        {props.text}
    </Button>

}

function CommentButtonOnly({onClick, content}: CommentButtonOnlyProps): JSX.Element {
    const [visible, setVisible] = useState<boolean>(false);

    const handleButtonClick: React.MouseEventHandler<HTMLInputElement> = (event) => {
        // Set visible to true
        setVisible(!visible);

        // Call the provided onClick function
        if (onClick) onClick(event)
    };

    return (
        <Popover
            content={content}
            style={{overflowY: 'visible'}}
            open={visible}
            title={<>Comments <CloseOutlined onClick={() => {
                setVisible(false)
            }} style={{float: "right"}}/></>}
            trigger="click"
            placement="right">
            <ButtonC
                size={'small'}
                shape={'circle'}
                icon={<CommentOutlined/>}
                onClick={handleButtonClick}/>
        </Popover>
    )
}

export function CommentButton({comments, onClick, content}: commentsProps): JSX.Element {
    return <TPBadge size={'small'} count={comments} color={colors.green.bamboo}>
                <CommentButtonOnly onClick={onClick} content={content}/>
            </TPBadge>

}

export default ButtonC;