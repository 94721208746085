import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {Navigate} from 'react-router-dom';
import {getLandingProject} from '../../redux/actions/project_actions';
import {AppDispatch} from '../../redux/store';
import { get_item_from_lcs, LcsKeys } from '../../assets/local_storage/lcs';
import AuthLayout from "../../layouts/auth_layout";
import {safeHandleErrorResponse} from "../../assets/helpers/errorHandler";
import {useUserContext} from "../../contexts/userContext";
import {OrgProviderModel} from "../../models/entities/organization";


function CalculateLandingPage(): JSX.Element {
    const {userToken, resetApp} = useUserContext()

    const dispatch: AppDispatch = useDispatch();
    const [loading, setLoading] = useState<boolean>(true);
    const [orgToGo, setOrgToGo] = useState<string>('');

    useEffect(() => {
        const org: OrgProviderModel = get_item_from_lcs(LcsKeys.current_org)
        if(org && org.info.domain){
            setOrgToGo(org.info.domain)
            setLoading(false)
        }else{
            dispatch(getLandingProject())
                .then(found_page => {
                    setOrgToGo(found_page.org_domain)
                    setLoading(false)
                })
                .catch(err => {
                    resetApp()
                    safeHandleErrorResponse(err)
                })
        }
    }, [dispatch])

    // return <></>
    return !!userToken?
        loading?
            <></>:
            !!orgToGo ?
                // !!projectToGo?
                //     <Navigate to={`/${orgToGo}/projects/${projectToGo}/overview`}/>:
                <Navigate to={`/${orgToGo}/overview`}/>
                : <Navigate to='/create_org'/>

        : <AuthLayout page_type='underConstruction'><p/></AuthLayout>
}

export default CalculateLandingPage;

