import React, {Dispatch, SetStateAction, useEffect, useLayoutEffect, useState} from 'react';
import {AppDispatch} from '../../redux/store';
import {useDispatch} from 'react-redux';
import {Link, useNavigate} from 'react-router-dom';
// antd
import {Button, Col, Divider, Empty, Layout, MenuProps, notification, Popover, Space} from 'antd';
import {
    ApartmentOutlined,
    BellTwoTone,
    SwapRightOutlined,
    TeamOutlined,
    ToolTwoTone
} from '@ant-design/icons';
// components
import MenuC from '../../components/menu';
import {
    getNotifications,
    getTools,
    getUnreadNotifications,
    logoutUser,
    markAsRead
} from '../../redux/actions/user_actions';
import {colors} from '../../assets/data/colors';
import {Tool, UserNotifications} from '../../models/entities/user';
import TPAvatar from "../../sds/avatar";
import RowC from "../../components/row";
import TPBadge from "../../sds/badge";
import ButtonC from "../../components/button";
import {PageResource} from "../../models/dtos/page_resource";
import {createEmptyPage} from "../../services/utils/PageResourceUtils";
import SwitchC from "../../components/switch";
import {Organization} from "../../models/entities/organization";
import {Project} from "../../models/entities/project";
import {Team} from "../../models/entities/team";
import { safeHandleErrorResponse } from '../../assets/helpers/errorHandler';
import DrawerTools from "../../components/drawers/drawerTools";
import {empty_tool} from "../../services/EmptyEntities/EmptyTool";
import { empty_organization } from '../../services/EmptyEntities/EmptyOrganization';
import {useUserContext} from "../../contexts/userContext";


type HeaderProps = {
    setSelectedSideMenu: Dispatch<SetStateAction<any>>,
    org: Organization,
    projects: Project[],
    teams: Team[]
}

function HeaderMenu({setSelectedSideMenu, org, projects, teams}: HeaderProps): JSX.Element {
    const dispatch: AppDispatch = useDispatch();

    const navigate = useNavigate();

    const {loggedInUser, resetApp, tools, setTools} = useUserContext();

    const [projectMenu, setProjectMenu] = useState<MenuProps['items']>([])
    const [teamMenu, setTeamMenu] = useState<MenuProps['items']>([])
    const [notificationsPage, setNotificationsPage] = useState<PageResource<UserNotifications>>(createEmptyPage())
    const [countUserNotifications, setCountUserNotification] = useState<number>(0)
    const [readFlag, setReadFlag] = useState(false);
    const [offset, setOffset] = useState<number>(0);
    const [onlyUnread, setOnlyUnread] = useState<boolean>(true);
    const [visibleDrawer, setVisibleDrawer] = useState<boolean>(false);
    const [toolsMenu, setToolsMenu] = useState<MenuProps['items']>([]);
    const [selectedTool, setSelectedTool] = useState<Tool>(empty_tool);

    const user_id = loggedInUser.id

    useEffect(() => {
        // Connect to the WebSocket server
        const ws = new WebSocket(`ws://localhost:3002?user_id=${user_id}`);
        if(user_id){
            // Set up event listener for messages from the server
            ws.addEventListener('message', (event) => {
                const data = JSON.parse(event.data);

                // ws.onopen = () => {
                //     console.log('WebSocket connection opened');
                // };
                //
                // ws.onclose = (event) => {
                //     console.log('WebSocket connection closed', event);
                //     console.log(`Code: ${event.code}, Reason: ${event.reason}, WasClean: ${event.wasClean}`);
                // };
                //
                // ws.onerror = (error) => {
                //     console.error('WebSocket error', error);
                // };

                // Check if the message is a notification
                if (data.type === 'notification') {
                    // console.log(notificationsPage)
                    // console.log(data)
                    const {message, description} = data.notification;
                    setReadFlag(!readFlag)
                    const newNotifications = countUserNotifications + 1
                    setCountUserNotification(newNotifications)
                    // update notification page as well
                    // Show the notification to the user
                    notification.open({
                        message: <span style={{color: '#ffffff'}}>{message}</span>,
                        description: <span style={{color: '#ffffff'}}>{description}</span>,
                        style: {
                            // Add your custom styles here
                            backgroundColor: colors.green.bamboo,
                            border: '1px solid #d3d3d3',
                            borderRadius: '10px'
                        }
                    });
                }
            });
        }
        else{
            ws.close();
        }
        // Clean up the WebSocket connection on component unmount

    }, [dispatch, countUserNotifications, readFlag, user_id]);

    const userIsAdmin = (): boolean => {
        return true
    }

    const handleLogout = () => {
        if (org !== empty_organization) {
        dispatch(logoutUser())
            .then(() => {
                resetApp()
                navigate('/login')
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })
    }}

    useEffect(() => {
        if (org !== empty_organization) {
        dispatch(getUnreadNotifications({org_id: org.id}))
            .then((info) => {
                setCountUserNotification(info.unread_notifications)
            }).catch((err) => {safeHandleErrorResponse(err)})

    }}, [dispatch, readFlag, org])

    const markAsReadHandler = (notification: UserNotifications) => {

        if (notification.id !== undefined) {
            dispatch(markAsRead({
                org_id: org.id,
                notification_type: notification.notification_type,
                notification_id: notification.id,
                mark_read: notification.viewed !== 1
            }))
                .catch((err) => {safeHandleErrorResponse(err)})


            let newNotifications = countUserNotifications
            let newNotificationPage = {...notificationsPage}

            if (notification.viewed === 1) {
                newNotifications++
                newNotificationPage.totalElements++
            } else {
                newNotifications--
                newNotificationPage.totalElements--
            }

            if (notification.viewed === 0 && onlyUnread) {
                newNotificationPage.content = newNotificationPage.content.filter(n => n.id !== notification.id)
            } else {
                newNotificationPage.content = newNotificationPage.content.map(n =>
                    n.id === notification.id ? {...n, viewed: notification.viewed === 1 ? 0 : 1} : n
                )
            }

            setCountUserNotification(newNotifications)
            setNotificationsPage(newNotificationPage)
        }
    };

    const onlyUnreadHandler = () => {
        dispatch(getNotifications({
            org_id: org.id,
            offset: 0,
            viewed: !onlyUnread ? 0 : undefined
        }))
            .then(results => {
                setNotificationsPage(results)
                offset === 0 ? setOffset(offset + 10) : setOffset(offset)
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })
        setOnlyUnread(!onlyUnread)
    }

    const markAllAsReadHandler = () => {
        dispatch(markAsRead({org_id: org.id}))
            .catch((err) => {safeHandleErrorResponse(err)})

        setReadFlag(!readFlag)
        if (onlyUnread) {
            setNotificationsPage(createEmptyPage())
        }
    }

    const userMenu = (): any[] => {
        let user_menu: any[] = [
            {label: <Link to={`/${org.domain}/user/${user_id}/profile`}>Profile</Link>}
        ]
        user_menu.push({
            label: <Link onClick={() => {
            }} to={`/${org.domain}/user/${user_id}/settings`}> Settings</Link>
        })
        if (userIsAdmin()) {
            user_menu.push({label: <Link to={`/${org.domain}/manage/settings`}>Admin</Link>})
        }
        user_menu.push({type: 'divider'})
        user_menu.push({
            label: <div onClick={handleLogout}>Logout</div>
        })
        return user_menu
    }

    useLayoutEffect(() => {
        let project_submenu: MenuProps['items'] = projects.map(project => ({
            label: <Link onClick={() => {setSelectedSideMenu(['0'])}}
                         to={`/${org.domain}/projects/${project.p_key}/overview`}>{project.name}</Link>,
            key: `P-${project.p_key}`
        }));
        project_submenu.push({type: 'divider'})
        project_submenu.push({
            label: <Link to={`/${org.domain}/projects`}
                         onClick={() => {
                             setSelectedSideMenu(['1'])
                         }}>My Projects</Link>,
            key: 'P-all'
        });

        setProjectMenu(project_submenu)

    }, [projects, org.domain, setSelectedSideMenu]);

    useLayoutEffect(() => {
        let team_submenu: MenuProps['items'] = teams.map(team => {
            // change this to wall ?

            return {
                label: <Link onClick={() => {
                    setSelectedSideMenu(['0'])
                }} to={`/${org.domain}/teams/${team.id}/${team.name.replace(' ', '')}/wall`}>{team.name}</Link>,
                key: `T-${team.id}`
            }
        })
        team_submenu.push({type: 'divider'})
        team_submenu.push({label: <Link to={`/${org.domain}/teams`}>My Teams</Link>, key: 'T-all'})
        setTeamMenu(team_submenu)
    }, [teams, org.domain, setSelectedSideMenu]);

    useLayoutEffect(()=>{
        if (org !== empty_organization) {
        dispatch(getTools({filter: 'myTools'}))
            .then(toolsFound=>{setTools(toolsFound)})
    }}, [dispatch, org, setTools])

    useLayoutEffect(() => {

        let toolsMenu: MenuProps['items'] = tools.map(tool => {
            return {
                label: <Space><img src={tool.icon} style={{width:16, height:16, paddingTop:5}} title={tool.title} alt={tool.title}/> {tool.title}</Space>,
                key: `TL-${tool.id}`,
                onClick: ()=> {
                    setSelectedTool(tool)
                    setVisibleDrawer(true)
                }
            }
        })
        toolsMenu.push({key: 'TL-divider', type: 'divider'})
        toolsMenu.push({
            label: <Link style={{width: 200}} to={`/${org.domain}/user/${user_id}/tools`}>View all Tools</Link>,
            key: `TL-viewAll`
        })

        setToolsMenu(toolsMenu)

    }, [tools, org.domain, user_id]);

    useLayoutEffect(() => {
        if (org !== empty_organization) {
        dispatch(getNotifications({
            org_id: org.id,
            offset: 0,
            viewed: onlyUnread ? 0 : undefined
        }))
        .then(results => {
            setNotificationsPage(results)
        }).catch((err) => {
            safeHandleErrorResponse(err)
        })

    }}, [dispatch, org, readFlag, onlyUnread]);

    const loadMore = () => {
        dispatch(getNotifications({
            org_id: org.id,
            offset: offset,
            viewed: onlyUnread ? 0 : undefined
        }))
            .then((info) => {
                setNotificationsPage((prevState) => ({
                    ...prevState,
                    content: [...(prevState.content || []), ...info.content],
                    totalElements: info.totalElements, // Update other properties if needed
                    totalPages: info.totalPages
                }));

                setOffset(offset + 10);
            }).catch((err) => {
                safeHandleErrorResponse(err)
            });
    };

    return <Layout.Header className="site-layout-background">
        <MenuC
            mode="horizontal"
            style={{width: 300, float: "left"}}
            items={[
                {
                    label: "Projects",
                    key: "projects",
                    icon: <ApartmentOutlined/>,
                    children: projectMenu
                },
                {
                    label: "Teams",
                    key: "teams",
                    icon: <TeamOutlined/>,
                    children: teamMenu
                }
            ]}
        />
        <div style={{float: 'right', marginTop: 5, width:160}}>
            <TPBadge style={{float:'left'}} overflowCount={10} count={countUserNotifications}>
                <Popover
                    content={
                        <div style={{maxHeight: 500, minHeight: 300, overflowY: 'auto'}}>
                            <Divider style={{marginTop: -10, marginBottom: 10}}/>
                            {
                                notificationsPage.content.length > 0 ?
                                    <>
                                        {
                                            notificationsPage.content.map(notification => {
                                                return <RowC key={`${notification.notification_type}-${notification.id}`} style={{minHeight: 70, paddingLeft: 5, marginTop: 10}}>
                                                    <Col flex='50px'>
                                                        <TPAvatar
                                                            size={'large'}
                                                            style={{
                                                                marginTop: 10,
                                                                backgroundColor: colors.blue.dark
                                                            }}
                                                            user={{
                                                                img: notification.creator?.img,
                                                                initials: `${notification.creator?.name?.charAt(0)}${notification.creator?.lastname?.charAt(0)}`,
                                                                fullname: `${notification.creator?.name} ${notification.creator?.lastname}`
                                                            }}
                                                        />
                                                    </Col>
                                                    <Link to={notification.url ? `/${notification.url}` : '/'} style={{color: 'black'}}>
                                                        <Col style={{width: 240, paddingLeft: 5}} flex='auto'>
                                                            <RowC style={{marginTop: 5}}>
                                                                <b>{notification.header}</b>
                                                            </RowC>
                                                            <RowC>
                                                                <div style={{color: colors.gray.shadow}}>
                                                                    {notification.message}
                                                                </div>
                                                            </RowC>
                                                        </Col>
                                                    </Link>
                                                    <Col flex={'90px'} style={{
                                                        color: colors.gray.shadow,
                                                        padding: 4, // on 5 it breaks on 3 digits day
                                                        fontStyle: 'italic',
                                                        textAlign: 'center'
                                                    }}>
                                                        <RowC>{notification.sent_at}</RowC>
                                                        <RowC>
                                                            <ButtonC
                                                                size='small'
                                                                type='text'
                                                                shape='circle'
                                                                style={{
                                                                    float: 'right',
                                                                    marginLeft: '40%'
                                                                }}
                                                                text={<div title={'Mark as read'}
                                                                           style={{
                                                                               width: 10,
                                                                               height: 10,
                                                                               background: notification.viewed !== 0 ? colors.gray.underConstruction :colors.green.gradient,
                                                                               marginLeft: 6,
                                                                               borderRadius: '50%'
                                                                           }}/>
                                                                }
                                                                onClick={() => markAsReadHandler(notification)}/>
                                                        </RowC>
                                                    </Col>
                                                </RowC>
                                            })
                                        }
                                        {
                                            notificationsPage.totalElements > notificationsPage.content.length ?
                                                <div style={{textAlign: 'center'}}>
                                                    <Button onClick={loadMore}>More</Button>
                                                </div> : null
                                        }
                                    </>

                                    : <Empty
                                        description=''
                                        image={
                                            <div style={{textAlign: 'center', width: '100%', height: '100%'}}>
                                                <img style={{width:250, height:250}} src={'/icons/no_notifications.png'} alt='No Notifications'/>
                                                <h3>You're all caught up with your notifications!</h3>
                                            </div>
                                        }
                                    />
                            }
                        </div>
                    }
                    overlayStyle={{maxWidth: 700}}
                    style={{maxHeight: 300, overflowY: 'visible'}}
                    title={<RowC>
                        <Col flex='auto'><h2> Notifications <ButtonC type='text' icon={<SwapRightOutlined
                            title={'Go to all Notifications'} onClick={() => {
                            navigate(`/${org.domain}/user/${user_id}/notifications`)
                        }}/>}/></h2></Col>
                        <Col flex='100px'>
                            <SwitchC label='Only Unread' size={'small'} onChange={onlyUnreadHandler}
                                     defaultChecked={onlyUnread}/>
                            <ButtonC
                                onClick={() => markAllAsReadHandler()}
                                style={{marginTop: 10, color: colors.blue.default}}
                                type='text'
                                text='Mark all as read'/> </Col>
                    </RowC>
                    }
                    trigger="click"
                    placement="bottomLeft">
                    <ButtonC size='large' shape='circle' type='text' icon={<BellTwoTone/>}/>
                </Popover>
            </TPBadge>

            <MenuC
                mode="horizontal"
                style={{ float: "left", background: 'transparent'}}

                items={[{
                    label: <ToolTwoTone style={{fontSize:20}}/>,
                    children: toolsMenu,
                    key: 'tools-menu',
                }
                ]}
            />

            <MenuC
                mode="horizontal"
                style={{ float: "right", marginTop:6, marginRight: 6, background: 'transparent'}}
                items={[{
                    label: <img
                        src={loggedInUser.image} alt="User Profile"
                        // style={{ width: '100px', height: '100px' , display: 'block', margin: 'auto', marginTop: 10}}
                        style={{
                            marginTop: 9,
                            borderRadius: "50%",
                            margin: 'auto',
                            width: "30px", height: '30px',
                            backgroundImage: "cover"
                        }}/>,
                    children: userMenu(),
                    key: 'user-menu'
                }
                ]}
            />
        </div>
        <DrawerTools setVisibleDrawer={setVisibleDrawer} visibleDrawer={visibleDrawer} tool={selectedTool}/>
    </Layout.Header>
}

export default HeaderMenu;