import React, {useEffect, useState} from 'react';
import Page from "../../components/page_structure/page";
import PageContent from "../../components/page_structure/page_content";
import { Button, Card, Col, Dropdown, Empty, FloatButton, Menu, MenuProps, Row } from 'antd';
import {UserNotifications} from "../../models/entities/user";
import {AppDispatch} from "../../redux/store";
import {useDispatch} from "react-redux";
import {getUserProfileProjects, getUserProfileTeams, getNotifications, deleteNotification, markAsRead} from "../../redux/actions/user_actions";
import PageHeader from '../../components/page_structure/page_header';
import { BellOutlined, DeleteOutlined, ExclamationCircleOutlined, IssuesCloseOutlined, MoreOutlined } from '@ant-design/icons';
import { colors } from '../../assets/data/colors';
import { deleteFromArray } from '../../assets/helpers/helper';
import SwitchC from '../../components/switch';
import RowC from '../../components/row';
import { Link } from 'react-router-dom';
import { safeHandleErrorResponse } from '../../assets/helpers/errorHandler';
import {useUserContext} from "../../contexts/userContext";
import {useOrgContext} from "../../contexts/orgContext";


function Notifications(): JSX.Element {
    
    const dispatch: AppDispatch = useDispatch();
    const {loggedInUser} = useUserContext()
    const {currentOrg} = useOrgContext()

    const [userProjects, setUserProjects] = useState<{id: number, name: string, p_key: string}[]>([])
    const [userTeams, setUserTeams] = useState<{id: number, name: string}[]>([])
    const [userNotifications, setUserNotification] = useState<UserNotifications[]>([])
    const [offset, setOffset] = useState<number>(0);
    const [hideMore, setHideMore] = useState(false);
    const [notificationType, setNotificationType] = useState<number>(0);
    const [itemId, setItemId] = useState<number>(0);
    const [readFlag, setReadFlag] = useState(false);
    const [onlyUnread, setOnlyUnread] = useState<boolean>(false);

    useEffect(() => {
        let params:any = { org_id: currentOrg.info.id, offset: offset, notification_type: notificationType, viewed: onlyUnread ? 0 : undefined }
        if(itemId>0){
            params.entity_id = itemId
        }

        dispatch(getNotifications(params))
            .then((info) => {
                setUserNotification(info.content)
                info.content.length < 10 ? setHideMore(true) : setHideMore(false)
            })
            .catch((err) => { safeHandleErrorResponse(err) })

    }, [dispatch, notificationType, itemId, readFlag, onlyUnread, currentOrg.info.id, offset])

    useEffect(() => {
        dispatch(getUserProfileProjects({targetUserId: loggedInUser.id, org_id: currentOrg.info.id}))
            .then((info) => {
                setUserProjects(info.content)
            })
            .catch((err) => { safeHandleErrorResponse(err) })

        dispatch(getUserProfileTeams({targetUserId: loggedInUser.id, org_id: currentOrg.info.id}))
            .then((info) => {
                setUserTeams(info.content)
            })
            .catch((err) => { safeHandleErrorResponse(err) })

    }, [dispatch, currentOrg.info.id, loggedInUser.id])

    const loadMore = () => {
        const params = itemId > 0
            ? { org_id: currentOrg.info.id, offset: offset, notification_type: notificationType, entity_id: itemId, viewed: onlyUnread ? 0 : undefined }
            : { org_id: currentOrg.info.id, offset: offset, notification_type: notificationType, viewed: onlyUnread ? 0 : undefined };
        dispatch(getNotifications(params)).then(info => {
          if (info.content.length < 10) {setHideMore(true)}
          let new_notifications_array: UserNotifications[] = [...userNotifications, ...info.content]
          setUserNotification(new_notifications_array)
          setOffset(offset + 10)
          }).catch((err) => {
            safeHandleErrorResponse(err)
        })
      };

    const markAsReadHandler = (notification: UserNotifications) => {  
        if(notification.id !== undefined){
            dispatch(markAsRead({
                                 org_id: currentOrg.info.id,
                                 notification_type: notification.notification_type,
                                 notification_id: notification.id, mark_read: notification.viewed !== 1}))
                .catch((err) => { safeHandleErrorResponse(err) })

        }
              // console.log('notification.viewed', !notification.viewed)
         setUserNotification(prevNotifications => prevNotifications.map(n =>
             n.id === notification.id ? { ...n, viewed: notification.viewed === 1 ? 0 : 1} : n
         ))
        setOffset(0)
    }

    const markAllAsReadHandler = () => {
        dispatch(markAsRead({org_id: currentOrg.info.id}))
            .catch((err) => { safeHandleErrorResponse(err) })

        setOffset(0)
        setReadFlag(!readFlag)
    }

    const deleteNotificationHandler = (notification: UserNotifications) => {
        const confirmed = window.confirm(`Are you sure you want to delete the notification?`)

        if(confirmed && notification.id !== undefined){
            dispatch(deleteNotification(notification.id, currentOrg.info.id))
                .catch((err) => { safeHandleErrorResponse(err) })

            let notifications: UserNotifications[] = userNotifications
            const notification_to_delete = notifications.find(notif => notif.id === notification.id);
            if (notification_to_delete) {
            const notification_index = notifications.indexOf(notification_to_delete)
            notifications = deleteFromArray(notifications, notification_index);
            setUserNotification(notifications) }
        }
    }

    type MenuItem = Required<MenuProps>['items'][number];

    function getItem(
        label: React.ReactNode,
        key: React.Key,
        icon?: React.ReactNode,
        children?: MenuItem[],
        type?: 'group',
      ): MenuItem {
        return {
          key,
          icon,
          children,
          label,
          type,
        } as MenuItem;
      }

      const items: MenuProps['items'] = [
        getItem('All', '1', <BellOutlined />),
      
        getItem('Projects', '2', <BellOutlined />, userProjects?.map((project) => (
          getItem(project.name, "project_" + project.id)
        ))),

        getItem('Teams', '3', <BellOutlined />, userTeams?.map((team) => (
            getItem(team.name, "team_" + team.id)
          )))
      ];

      const keyToNumber = (key: any): number => {
        if (key === '1') {
          return 1; // For 'All'
        } else if (key.startsWith('project_')) {
          return 2; // For all project keys
        } else if (key.startsWith('team_')) {
            return 3; // For all team keys
        } else {
          return NaN; // Return NaN for unknown keys
        }
      };

    return (
        <Page title={`Notifications`}>
            <PageHeader title={`Notifications`}/>         
            <PageContent>           
            <Row>
            <Col flex="0 1 200px">
            <Menu 
                onClick={({ key }) => {
                    setOffset(0)
                    if (key === '1') {
                      setItemId(0);
                      setNotificationType(0);
                    } else {
                      const matchResult = key.match(/\d+/);
                      const item_id: number | undefined = matchResult ? parseInt(matchResult[0], 10) : undefined;
                  
                      if (item_id !== undefined) {
                        setItemId(item_id);
                      }
                  
                      const selectedNumber = keyToNumber(key);
                  
                      if (!isNaN(selectedNumber)) {
                        setNotificationType(selectedNumber);
                        // Handle logic based on the selected number
                      } else {
                        // console.error('Invalid key:', key);
                      }
                    }
                  }}
                style={{ width: 256 }}
                defaultSelectedKeys={['1']}
                mode="inline"
                items={items}/>
            </Col>
            <Col flex="1 1 120px" style={{marginLeft: 50}}>
            <RowC style={{float: 'right'}}>
            <Col>
            <SwitchC label='Only Unread' size={'small'} onChange={() => setOnlyUnread(!onlyUnread)} defaultChecked={onlyUnread}/><br/>
            <Button style={{color: colors.green.forest, marginTop: 10}} type='text' onClick={() => markAllAsReadHandler()}>Mark all as read</Button>
            </Col>
            </RowC>
                {userNotifications?.length > 0 ? userNotifications?.map((notification) => (
                  <Card hoverable={true} key={notification.id}
                      style={{marginTop: 20, width: 'auto', height: 'auto', background: notification?.viewed === 1 ? 'whitesmoke' : colors.gray.background}} styles={{ body: { padding: 0 } }}
                title={<div><Dropdown 
                    menu={{
                        items: [
                          {
                            key: `read-${notification.id}`,
                            label: notification.viewed === 0 ? <div><IssuesCloseOutlined style={{width: 14, color: colors.green.forest}}/> Mark as read </div> : 
                                                               <div><ExclamationCircleOutlined style={{width: 14, color: colors.green.forest}}/> Mark as unread</div>
                          },
                          {
                            key: `delete-${notification.id}`,
                            label: <><DeleteOutlined style={{width: 14, color: 'red'}}/> Delete </>
                          }],
          
                        onClick: (e) => {
                            if (e.key === `read-${notification.id}`) {
                                markAsReadHandler(notification)
                            }
                            if (e.key === `delete-${notification.id}`) {
                                deleteNotificationHandler(notification)
                              }
                        }
                    }}
                    trigger={['click']}
                    arrow>
                    <Button
                    onClick={() => {
                    }}
                    icon={<MoreOutlined style={{color: colors.green.forest}}/>}
                    size='small'
                    title='Post Actions'
                    shape='circle'
                    style={{background: 'whitesmoke', float:'right', marginRight: 10, marginTop: 5}}
                />
                </Dropdown>
                <h3 onClick={() => notification?.viewed === 0 ? markAsReadHandler(notification) : {}} style={{fontWeight: notification?.viewed === 1 ? 'normal' : 'bold', color: 'grey'}}>{notification.header}</h3></div>}>
                <Link to={notification.url ? `/${notification.url}` : '#'} style={{color: 'black'}} onClick={() => notification?.viewed === 0 ? markAsReadHandler(notification) : {}}>
                <div key={notification.id}>
                    <h4 style={{ marginLeft: 10 }}>
                    <p 
                        style={{ color: 'grey', textDecoration: 'none', fontWeight: notification?.viewed === 1 ? 'normal' : 'bold' }}>
                        {notification.message}
                    </p>
                    </h4>
                </div>
                </Link>
                <br/>
                
                {notification.sent_at && (
                    <p style={{float: 'right', marginRight: 10, color: 'grey', fontStyle: 'italic', fontWeight: notification?.viewed === 1 ? 'normal' : 'bold'}}>
                        Sent at: {notification.sent_at}
                    </p>
                    )}
                </Card>
                )) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                {!hideMore && (
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    <Button onClick={loadMore}>More</Button>
                </div> )}
            </Col>
            </Row>
            <FloatButton.BackTop />
            </PageContent>
        </Page>
    )
}
  
export default Notifications;