import React, {useState} from 'react';
import {Spin, Upload, UploadFile, UploadProps} from 'antd';
import {RcFile} from "antd/lib/upload";
import { deleteAvatar, uploadAvatar } from '../../redux/actions/files_actions';
import { AppDispatch } from '../../redux/store';
import { useDispatch } from 'react-redux';
import { safeHandleErrorResponse } from '../../assets/helpers/errorHandler';
import { useOrgContext } from '../../contexts/orgContext';
import { useProjectContext } from '../../contexts/projectContext';
import { useTeamContext } from '../../contexts/teamContext';
import { useUserContext } from '../../contexts/userContext';
import ImgCrop from 'antd-img-crop';


type UploaderType = {
  type_action: string,
  id: number,
  listType: 'text' | 'picture' | 'picture-card',
  max_items: number,
  url: string
}

const UploaderC = ({type_action, id, listType, max_items=1, url}:UploaderType): JSX.Element =>{
  const dispatch: AppDispatch = useDispatch();

  const {loggedInUser, setAppContextUser} = useUserContext();
  const {currentOrg, setCurrentOrg} = useOrgContext();
  const {currentProject, setCurrentProject} = useProjectContext()
  const {currentTeam, setCurrentTeam} = useTeamContext();

  const [fileList, setFileList] = useState<UploadFile[]>([
    {
      url: url,
      uid: "1",
      name: ""
    }
  ]);
  const [loading, setLoading] = useState<boolean>(false);
  const [sizeAlert, setSizeAlert] = useState<boolean>(false);

const onChange: UploadProps['onChange'] = async ({ fileList: newFileList }) => {
  setSizeAlert(false);

  const updatedFileList: any = await Promise.all(
    newFileList.map(async (file) => {
      if (file.originFileObj) {
        if (newFileList[0].size && newFileList[0].size >= 2000000) {
          setSizeAlert(true);
          return {
            url: url,
            uid: "1",
            name: ""
          }; // Skip the cropping process and return the file as is
          
        }

        // Handle cropped image
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(file.originFileObj as Blob); 
          reader.onload = () => {
            resolve({
              ...file,
              name: file.name, // Ensure name is preserved
              thumbUrl: reader.result, // Use cropped image as thumbUrl
            });
          };
        });
      }
      return file; // Return the file as is if no originFileObj
    })
  );

  setFileList(updatedFileList);
  setLoading(!sizeAlert)
  
  if (updatedFileList.length === 0) {
    // Delete avatar logic
    dispatch(deleteAvatar(id, type_action))
      .then((found) => {
        setLoading(false);
        setFileList([]);
        if (type_action === 'projects') {
          setCurrentProject({
            ...currentProject,
            info: {
              ...currentProject.info,
              icon: found.url,
            },
          });
        }
        if (type_action === 'organizations') {
          setCurrentOrg({
            ...currentOrg,
            info: {
              ...currentOrg.info,
              icon: found.url,
            },
          });
        }
        if (type_action === 'teams') {
          setCurrentTeam({
            ...currentTeam,
            info: {
              ...currentTeam.info,
              icon: found.url,
            },
          });
        }
        if (type_action === 'users') {
          setAppContextUser({
            ...loggedInUser,
            image: found.url,
          });
        }
      })
      .catch((err) => {
        safeHandleErrorResponse(err);
      });
  } else {
    // Upload cropped avatar logic
    if (!sizeAlert) {
      const croppedFile = updatedFileList[0]?.thumbUrl;
      if (croppedFile) {
      dispatch(
        uploadAvatar({
          id,
          type: type_action,
          content: croppedFile,
          filename: updatedFileList[0].name,
        })
      )
        .then((found) => {
          setLoading(false);
          if (type_action === 'projects') {
            setCurrentProject({
              ...currentProject,
              info: {
                ...currentProject.info,
                icon: found.avatar,
              },
            });
          }
          if (type_action === 'organizations') {
            setCurrentOrg({
              ...currentOrg,
              info: {
                ...currentOrg.info,
                icon: found.avatar,
              },
            });
          }
          if (type_action === 'teams') {
            setCurrentTeam({
              ...currentTeam,
              info: {
                ...currentTeam.info,
                icon: found.avatar,
              },
            });
          }
          if (type_action === 'users') {
            setAppContextUser({
              ...loggedInUser,
              image: found.avatar,
            });
          }
        })
        .catch((err) => {
          safeHandleErrorResponse(err);
        });
    }}}
};

  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as RcFile);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  return (
    <div style={{ position: 'relative' }}>
      <Spin spinning={sizeAlert ? false : loading} tip="Uploading...">
      <style>
            {`
                .ant-upload-list-item-error {
                    border-color: green !important; /* Override red with green */
                }
            `}
        </style>
        <ImgCrop
          rotationSlider
          minZoom={1}
          maxZoom={1.2}
          beforeCrop={file => {
            return new Promise(resolve => {
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = () => {
                // Use the reader's result as the source for cropping
                resolve(true); // Proceed with cropping
              };
            });
          }}
        >
        <Upload
            listType={listType}
            action={(file) => `https://tp-production.fra1.cdn.digitaloceanspaces.com/${type_action}/${id}/${file.name}`}
            fileList={fileList}
            onChange={onChange}
            onPreview={onPreview}
        >
            {fileList.length < max_items && '+ Upload'}
        </Upload>
        </ImgCrop>
      </Spin>
      {sizeAlert && (
            <span style={{ color: 'red', marginTop: '8px', display: 'block' }}>
                The selected file is too large. Please choose a file smaller than 2 MB.
            </span>
        )}
    </div>
  )
}

export default UploaderC;