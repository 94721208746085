import React  from 'react';
import { Modal } from 'antd';

type modalOptionsType = {
    open: boolean,
    children: React.ReactNode,
    title: React.ReactNode,
    onOk?: any,
    onCancel?: any,
    footer?: any,
    key?: string,
    maskClosable?: boolean,
    keyboard?: boolean,
    style?: React.CSSProperties,
    styles?: {body?:React.CSSProperties}
    width?: number,
}

function TPModal(modal_options: modalOptionsType): JSX.Element {
    return <Modal {...modal_options}>
        {modal_options.children}
    </Modal>
}

export default TPModal;
