import {Steps} from "antd";
import React, {useState} from "react";
import {useNavigate} from 'react-router-dom';
import {useCreateRunContext} from "../../contexts/createRunContext";


const { Step } = Steps;

function BatchCreateLayoutStepper(): JSX.Element {
    const navigate = useNavigate();

    const {utils} = useCreateRunContext()

    const [step, setStep] = useState<number>(0);

    return <Steps
        current={step}
        onChange={setStep}
        // status="error"
        percent={utils.steps.length > 0? utils.steps[step].percent: 0}
        direction="vertical"
    >
        {
            utils.steps.map((currentStep, index)=> {
                return <Step
                    onClick={()=>navigate(currentStep.to)}
                    key={index}
                    title={
                        <span style={step === index?{
                                fontWeight: "bold",
                                fontSize:  16 ,
                            }: {fontSize:  14}}
                        >{currentStep.title}</span>
                    }
                />
            })
        }
    </Steps>
}

export default BatchCreateLayoutStepper