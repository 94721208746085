import axios from "axios";
import {get_item_from_lcs, LcsKeys} from '../assets/local_storage/lcs'
import {getGlobalOrgId} from "../contexts/orgContext";
import {getGlobalProjectId} from "../contexts/projectContext";


const get_header = () => {
    const token = get_item_from_lcs(LcsKeys.user_token)

    return {
        Authorization: `Bearer ${token}`,
        contentType: 'application/json',
        XClienTtype: 'web'
    }
}

export const get = (url, params) => {

    const config = {
        headers: get_header(),
        params
    }

    return axios.get(url, config)
}

export const deleteWithOrgWrapper = (url, params) => {
    const org_id = getGlobalOrgId()
    const newParams = {
        org_id,
        ...(params && Object.keys(params).length ? params : {}) // Add params only if it has properties
    }

    return deletion(url, newParams)
}

export const deleteWithProjectWrapper = (url, params) => {
    const project_id = getGlobalProjectId()
    const newParams = {
        project_id,
        ...(params && Object.keys(params).length ? params : {}) // Add params only if it has properties
    };
    return deletion(url, newParams);
}

export const deleteWithOrgProjectIdsWrapper = (url, params) => {
    let newParams = {}
    const project_id = getGlobalProjectId()

    if(project_id > 0){
        newParams.project_id = project_id
    }

    newParams = {
        ...newParams,
        ...(params && Object.keys(params).length ? params : {}) // Add params only if it has properties
    }

    return deleteWithOrgWrapper(url, newParams);

}

export const getWithOrgWrapper = (url, params) => {
    const org_id = getGlobalOrgId()
    const newParams = {
        org_id,
        ...(params && Object.keys(params).length ? params : {}) // Add params only if it has properties
    }

    return get(url, newParams)
}

export const getWithProjectWrapper = (url, params) => {
    const project_id = getGlobalProjectId();
    const org_id = getGlobalOrgId()
    const newParams = {
        project_id,
        org_id,
        ...(params && Object.keys(params).length ? params : {}) // Add params only if it has properties
    };
    return get(url, newParams);
}

export const getWithOrgProjectIdsWrapper = (url, params) => {
    let newParams = {}
    const project_id = getGlobalProjectId()

    if(project_id > 0){
        newParams.project_id = project_id
    }

    newParams = {
        ...newParams,
        ...(params && Object.keys(params).length ? params : {}) // Add params only if it has properties
    }

    return getWithOrgWrapper(url, newParams);
}

export const post = (url, data, params) => {

    const config = {
        headers: get_header(),
        params
    }

    return axios.post(url, data, config)
}

export const put = (url, data, params) => {

    const config = {
        headers: get_header(),
        params
    }

    return axios.put(url, data, config)
}

export const deletion = (url, params) => {

    const config = {
        headers: get_header(),
        params
    }

    return axios.delete(url, config)
}
