import {
    get,
    post,
    put,
    getWithOrgWrapper,
    getWithOrgProjectIdsWrapper,
    deleteWithProjectWrapper, getWithProjectWrapper, deleteWithOrgProjectIdsWrapper
} from '../../services/axiosWrapper';
import {PageResource} from "../../models/dtos/page_resource";
import {AppDispatch} from "../store";
import {fetchErrorAction, fetchStartAction, fetchSuccessAction} from "./creators/common_actions";
import {
    Project,
    LandingProject,
    ProjectSetting,
    ProjectTypes,
    ProjectTeamOwners,
    ProjectProviderModel
} from "../../models/entities/project";
import {
    Argument,
    ArgumentScriptSetting,
    GithubSetting,
    ModuleSettings,
    ProjectSettingParams,
    RunProjectSetting,
    SlackIntSetting
} from "../../models/entities/settings";
import {MemberInfo, User} from "../../models/entities/user";
import { PermissionsInterface } from '../../models/entities/permissions';
import {addMemberParamsModalType} from "../../models/entities/helpers/modal";
import {memberType} from "./team_actions";
import React from "react";
import {CascaderOption} from "../../contexts/createRunContext";
import {projectNotificationsProps, SubsectionNotifications} from "../../models/entities/run_script_notifications";


const api_path = '/api/project/';

interface BrowserVersion {
    id: number,
    browser: string,
    version: string
}

type Data = Record<string, BrowserVersion[]>;

interface org_project {
    only_visible?: boolean,
    search?: string
}

interface putSettingActionsParams extends org_project{
    title?: string,
    key?: string,
    // run_arg_switch?: boolean,
    project_visible?: boolean,
    project_state?: boolean,
    project_type_id?: number
}

interface updateProjectGithubIntegrationParams extends org_project{
    project_id: number,
    owner?: string,
    integration_user?: string,
    token_name?: string,
    token_value?: string,
    repo_name?: string,
    repo_url?: string,
    repo_base?: string,
    repo_path?: string,
    github_warnings?: boolean
}

interface updateProjectBrowsersParams extends org_project{
    project_id: number,
    org_id: number,
    new_browser_ids: number[]
}

interface updateProjectBrowserParams extends org_project{
    project_id: number,
    org_id: number,
    browser_id: number
}

interface saveProjectRunScriptParams extends org_project{
    id?: number,
    title?: string,
    default_value?: string,
    status?: boolean
}

interface saveProjectRunSlackIntParams extends org_project{
    id?: number,
    title?: string,
    url?: string,
    channel_id?: string,
    status?: boolean
}

interface deleteRunArgumentScriptsParams extends org_project{
    ids?: React.Key[]
}

interface deleteSlackIntParams extends org_project{
    ids?: number[]
}

interface updateProjectOwnerParams extends org_project{
    owner_id?: number
}

interface saveProjectModulesParams {
    project_id: number,
    title?: string,
    version?: string,
    module_order?: number,
    project_module_id?: number,
    org_id: number
}

const convertToOptionInterface = (data: Data): CascaderOption[] => {
    return Object.entries(data).map(([browser, items]) => ({
        value: items.flatMap<number>(item=>item.id).join(','),
        label: browser.charAt(0).toUpperCase() + browser.slice(1),
        children: items.map((item: BrowserVersion) => ({
            value: `${item.id}`,
            label: `${item.browser} - ${item.version}`
        }))
    }))
}

export const getMyProjects = (params: { search?: string , limit?:number}) => (dispatch: AppDispatch): Promise<Project[]> => {
    dispatch(fetchStartAction());

    let url = `${api_path}getMyProjects`
    const request: any = getWithOrgWrapper(url, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));

            return Promise.reject();
        })
}

export const getProjects = (params: { key?: string, search?: string , limit?:number}) => (dispatch: AppDispatch): Promise<PageResource<Project>> => {
    dispatch(fetchStartAction());

    let url = `${api_path}getProjects`
    const request: any = getWithOrgWrapper(url, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));

            return Promise.reject();
    })
}

export const getCurrentProject = (params: {project_key:string}) => (dispatch: AppDispatch): Promise<ProjectProviderModel> => {
    dispatch(fetchStartAction());

    let url = `${api_path}getCurrentProject`
    const request: any = getWithOrgWrapper(url, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));

            return Promise.reject();
        })
}

export const getLandingProject = () => (dispatch: AppDispatch): Promise<LandingProject> => {
    dispatch(fetchStartAction());
    
    const request: any = get(`${api_path}getLandingProject`)
    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            
            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));

            return Promise.reject();
    })
}

export const getProjectSettings = () => (dispatch: AppDispatch): Promise<ProjectSetting> => {
    dispatch(fetchStartAction());
    
    const request: any = getWithOrgProjectIdsWrapper(`${api_path}getProjectSettings`)
    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            
            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));

            return Promise.reject();
    })
}

export const getProjectTypes = () => (dispatch: AppDispatch): Promise<ProjectTypes[]> => {
    dispatch(fetchStartAction());
    
    const request: any = get(`${api_path}getProjectTypes`)
    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            
            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));

            return Promise.reject();
    })
}

export const createProject = (data:{ name:string, project_key:string, test_cases:number, org_id: number, icon: string, project_type: number}, projects:Project[]) => (dispatch: AppDispatch): Promise<Project[]> => {
    dispatch(fetchStartAction());

    const request: any = post(`${api_path}create`, data)
    return Promise.resolve(request)
            .then(value => {
                dispatch(fetchSuccessAction());

                value.data = [...projects, value.data.new_project];
                return value.data;
            })
            .catch(reason => {
                dispatch(fetchErrorAction(reason));
                return Promise.reject();
            })
}

export const deleteProject = () => (dispatch: AppDispatch): Promise<number> => {
    dispatch(fetchStartAction());

    const request: any = deleteWithOrgProjectIdsWrapper(`${api_path}deleteProject`)
    // todo remove job from users etc
    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            return value.status;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));

            return Promise.reject();
        })
}

export const deleteProjectModule = (ids:number[], org_id: number) => (dispatch: AppDispatch): Promise<number> => {
    dispatch(fetchStartAction());

    const request: any = deleteWithProjectWrapper(`${api_path}deleteProjectModule?ids=${ids}&org_id=${org_id}`)
    // todo remove job from users etc
    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            return value.status;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));

            return Promise.reject();
        })
}

export const getAddProjectSettingsActions = () => (dispatch: AppDispatch): Promise<RunProjectSetting> => {

    dispatch(fetchStartAction());

    const request: any = getWithOrgProjectIdsWrapper(`${api_path}getProjectRunSettings`)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const getProjectSlackIntegrations = (params: {only_visible?: boolean, search?: string}) => (dispatch: AppDispatch): Promise<PageResource<SlackIntSetting>> => {

    dispatch(fetchStartAction());

    const request: any = getWithOrgProjectIdsWrapper(`${api_path}getProjectSlackIntegrations`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const getOrgProjectKeys = () => (dispatch: AppDispatch): Promise<string[]> => {

    dispatch(fetchStartAction());

    const request: any = getWithOrgWrapper(`${api_path}getOrgProjectKeys`)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const getProjectGithubIntegrations = () => (dispatch: AppDispatch): Promise<{content: GithubSetting}> => {

    dispatch(fetchStartAction());

    const request: any = getWithOrgProjectIdsWrapper(`${api_path}getProjectGithubIntegrations`)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const getSystemBrowsers = () => (dispatch: AppDispatch): Promise<CascaderOption[]> => {

    dispatch(fetchStartAction());

    const request: any = get(`${api_path}getSystemBrowsers`)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return convertToOptionInterface(value.data);
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}


export const getProjectBrowsers = (format: "cascader"|"default"="default") => (dispatch: AppDispatch): Promise<{
    selected: string[],
    available: CascaderOption[]
}> => {
    dispatch(fetchStartAction());

    const request: any = getWithOrgProjectIdsWrapper(`${api_path}getProjectBrowsers`);

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            const available = format === "default"? value.data.available.map((item:any)=>({
                value: item.id,
                label: `${item.browser} - ${item.version}`

            })): convertToOptionInterface(value.data.available)

            return {
                selected: value.data.selected,
                available
            }
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject(reason);
        });
}

export const getProjectModules = () => (dispatch: AppDispatch): Promise<any> => {

    dispatch(fetchStartAction());

    const request: any = getWithOrgProjectIdsWrapper(`${api_path}getProjectModules`)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const getProjectNotificationSettings = () => (dispatch: AppDispatch): Promise<projectNotificationsProps> => {

    dispatch(fetchStartAction());

    const request: any = getWithOrgProjectIdsWrapper(`${api_path}getProjectNotificationSettings`)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const getProjectProgrammingLanguages = () => (dispatch: AppDispatch): Promise<{content: {id: number, version: string}}> => {

    dispatch(fetchStartAction());

    const request: any = getWithOrgProjectIdsWrapper(`${api_path}getProjectProgrammingLanguages`)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const updateProjectRunSettings = (params: ProjectSettingParams) => (dispatch: AppDispatch): Promise<PageResource<RunProjectSetting>> => {

    dispatch(fetchStartAction());

    const request: any = put(`${api_path}updateProjectRunSettings`, {params})

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}


export const updateProjectNotificationSetting = (params: {project_id: number, org_id: number, column_title:string, column_value: boolean, before_run?: boolean, after_run?: boolean, case_assignment?: boolean, case_unassignment?: boolean}) => (dispatch: AppDispatch): Promise<boolean> => {

    dispatch(fetchStartAction());

    const request: any = post(`${api_path}updateProjectNotificationSetting`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const updateProjectProgrammingLanguages = (params: {org_id: number, project_id:number, language_id: number, version: string}) => (dispatch: AppDispatch): Promise<boolean> => {

    dispatch(fetchStartAction());

    const request: any = put(`${api_path}updateProjectProgrammingLanguages`, {params})

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const addProjectMembers = (params: addMemberParamsModalType) => (dispatch: AppDispatch): Promise<PageResource<MemberInfo>> => {
    dispatch(fetchStartAction());

    const request: any = post(`${api_path}addProjectMembers`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());
            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const updateProject = (params: putSettingActionsParams) => (dispatch: AppDispatch): Promise<PageResource<LandingProject>> => {

    dispatch(fetchStartAction());

    const request: any = put(`${api_path}updateProject`, {params})

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const updateProjectGithubIntegration = (params: updateProjectGithubIntegrationParams) => (dispatch: AppDispatch): Promise<any> => {

    dispatch(fetchStartAction());

    const request: any = put(`${api_path}updateProjectGithubIntegration`, {params})

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const updateProjectBrowsers = (params: updateProjectBrowsersParams) => (dispatch: AppDispatch): Promise<any> => {

    dispatch(fetchStartAction());

    const request: any = put(`${api_path}updateProjectBrowsers`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const updateDefaultProjectBrowser = (params: updateProjectBrowserParams) => (dispatch: AppDispatch): Promise<any> => {

    dispatch(fetchStartAction());

    const request: any = put(`${api_path}updateDefaultProjectBrowser`, {params})

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const updateProjectOwner = (params: updateProjectOwnerParams) => (dispatch: AppDispatch): Promise<PageResource<ProjectTeamOwners>> => {

    dispatch(fetchStartAction());

    const request: any = put(`${api_path}updateProjectOwner`, {params})

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const getProjectUsers = () => (dispatch: AppDispatch): Promise<PageResource<User>> => {

    dispatch(fetchStartAction());

    const request: any = getWithProjectWrapper(`${api_path}getProjectUsers`)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const fetchProjectMembers = (params: {
    search:string,
    limit:number,
    offset:number
}) => (dispatch: AppDispatch): Promise<PageResource<MemberInfo>> => {

    dispatch(fetchStartAction());

    const request: any = getWithOrgProjectIdsWrapper(`${api_path}fetchProjectMembers`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const getSystemProgrammingLanguages  = () => (dispatch: AppDispatch): Promise<PageResource<{id: number, title: string}>> => {

    dispatch(fetchStartAction());

    const request: any = get(`${api_path}getSystemProgrammingLanguages `)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const saveProjectRunScript = (params: saveProjectRunScriptParams) => (dispatch: AppDispatch): Promise<PageResource<ArgumentScriptSetting>> => {

    dispatch(fetchStartAction());

    const request: any = post(`${api_path}saveProjectRunScript`, {params})

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const saveProjectSlackIntegration = (params: saveProjectRunSlackIntParams) => (dispatch: AppDispatch): Promise<PageResource<SlackIntSetting>> => {

    dispatch(fetchStartAction());

    const request: any = post(`${api_path}saveProjectSlackIntegration`, {params})

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const saveProjectRunArgument = (params: saveProjectRunScriptParams) => (dispatch: AppDispatch): Promise<{isNew:boolean, newId:number}> => {

    dispatch(fetchStartAction());

    const request: any = post(`${api_path}saveProjectRunArgument`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const saveProjectModules = (params: saveProjectModulesParams) => (dispatch: AppDispatch): Promise<PageResource<ModuleSettings>> => {

    dispatch(fetchStartAction());

    const request: any = post(`${api_path}saveProjectModules`, {params})

    return Promise.resolve(request)
    .then(value => {
        dispatch(fetchSuccessAction());

        return value.data;
    })
    .catch(reason => {
        dispatch(fetchErrorAction(reason));
        return Promise.reject();
    })
}

export const updateMemberRole = (params: {project_id: number, id: number, role_id: number, org_id: number, member_type: number}) => (dispatch: AppDispatch): Promise<{"new_role": number, "new_label": string, permissions: PermissionsInterface}> =>{
    dispatch(fetchStartAction());
    
    const request: any = put(`${api_path}updateMemberRole`, params)
    return Promise.resolve(request)
            .then(value => {
                dispatch(fetchSuccessAction());
                return value.data;
            })
            .catch(reason => {
                dispatch(fetchErrorAction(reason));
                return Promise.reject();
            })
}

export const getProjectRunArgs = (params?: org_project) => (dispatch: AppDispatch): Promise<Argument[]> => {

    dispatch(fetchStartAction());

    const request: any = getWithOrgProjectIdsWrapper(`${api_path}getProjectRunArguments`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const getProjectSystemArguments = (params: org_project) => (dispatch: AppDispatch): Promise<PageResource<ArgumentScriptSetting>> => {

    dispatch(fetchStartAction());

    const request: any = getWithOrgProjectIdsWrapper(`${api_path}getProjectSystemArguments`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const getProjectRunScripts = (params: org_project) => (dispatch: AppDispatch): Promise<PageResource<ArgumentScriptSetting>> => {

    dispatch(fetchStartAction());

    const request: any = getWithOrgProjectIdsWrapper(`${api_path}getProjectRunScripts`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const getProjectRunNotifications = () => (dispatch: AppDispatch): Promise<SubsectionNotifications[]> => {

    dispatch(fetchStartAction());

    const request: any = getWithOrgProjectIdsWrapper(`${api_path}getProjectRunNotifications`)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const deleteRunArgument = (params: deleteRunArgumentScriptsParams) => (dispatch: AppDispatch): Promise<ArgumentScriptSetting[]> => {

    dispatch(fetchStartAction());

    const request: any = deleteWithOrgProjectIdsWrapper(`${api_path}deleteRunArgument`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const deleteRunScript = (params: deleteRunArgumentScriptsParams) => (dispatch: AppDispatch): Promise<ArgumentScriptSetting[]> => {

    dispatch(fetchStartAction());

    const request: any = deleteWithOrgProjectIdsWrapper(`${api_path}deleteRunScript`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const deleteSlackIntegration = (params: deleteSlackIntParams) => (dispatch: AppDispatch): Promise<SlackIntSetting[]> => {

    dispatch(fetchStartAction());

    const request: any = deleteWithOrgProjectIdsWrapper(`${api_path}deleteSlackIntegration`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const getAllProjectUsers = (params: org_project) => (dispatch: AppDispatch): Promise<PageResource<User>> => {

    dispatch(fetchStartAction());

    const request: any = getWithOrgProjectIdsWrapper(`${api_path}getAllProjectUsers`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const getMembersNotInProject = (params: {
    id: number,
    search: string,
    searchFor: memberType[]
}) => (dispatch: AppDispatch): Promise<{
    id: number,
    member_type: 1 | 2,
    email: string,
    name: string
}[]> => {

    dispatch(fetchStartAction());

    const request: any = getWithOrgWrapper(`${api_path}getMembersNotInProject`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const deleteProjectUser = (user_ids:number[], org_id: number) => (dispatch: AppDispatch): Promise<boolean> => {
    dispatch(fetchStartAction());
    
    const request: any = deleteWithProjectWrapper(`${api_path}deleteProjectUser?user_ids=${user_ids}&org_id=${org_id}`)
    return Promise.resolve(request)
    .then(value => {
        dispatch(fetchSuccessAction());

        return value;
    })
    .catch(reason => {
        dispatch(fetchErrorAction(reason));
        return Promise.reject();
    })
}

export const syncProject = (project_id:number, branch:string, org_id:number) => (dispatch: AppDispatch): Promise<{
    status: number,
    success: boolean,
    message: string
}> => {
    dispatch(fetchStartAction());

    const request: any = put(`${api_path}syncProject`, {project_id, branch, org_id})

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}

export const validateBranch = (params: {branch: string}) => (dispatch: AppDispatch): Promise<{
    success: boolean,
    msg: string
}> => {
    dispatch(fetchStartAction());

    const request: any = getWithProjectWrapper(`${api_path}validateBranch`, params)

    return Promise.resolve(request)
        .then(value => {
            dispatch(fetchSuccessAction());

            return value.data;
        })
        .catch(reason => {
            dispatch(fetchErrorAction(reason));
            return Promise.reject();
        })
}
