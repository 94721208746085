import React from 'react';
import {createBrowserRouter, Outlet} from 'react-router-dom';
import CalculateLandingPage from '../views/auth/landing_page';
import Error401 from '../views/errors/error401';
import Error404 from '../views/errors/error404';
import Error500 from '../views/errors/error500';
import Overview from '../views/overview/overview';
import Dashboard from '../views/dashboards/dasbhoard';
import Permissions from '../views/permissions/permissions';
import Components from '../views/components/components';
import MyCalendar from '../views/calendar/calendar';
import TestSuite from '../views/test_suite/test_suite';
import Batches from '../views/batches/batches';
import RunReview from '../views/batches/batch';
import AuthLayout from '../layouts/auth_layout';
import Login from '../views/auth/login';
import Register from '../views/auth/register';
import TeamWall from '../views/teams/team_wall';
import Teams from '../views/teams/teams';
import Projects from '../views/projects/projects';
import Jobs from '../views/jobs/jobs';
import ProjectMembers from '../views/projects/project_members';
import OrgSettings from '../views/org/org_settings';
import UserProfile from '../views/users/user_profile';
import TestCaseInfo from '../views/test_suite/test_case_info';
import ProjectSettings from '../views/projects/project_settings';
import TeamSettings from "../views/teams/team_settings";
import MyOrgs from "../views/org/my_orgs";
import Forgot from '../views/auth/forgot';
import Reset from '../views/auth/reset';
import ProjectArguments from '../views/arguments_scripts/projectArgs';
import CreateOrg from '../views/auth/create_org_page';
import UserSettings from '../views/users/user_settings';
import TeamMembers from '../views/teams/team_members';
import Notifications from '../views/users/notifications';
import {GoogleOAuthProvider} from '@react-oauth/google';
import OrgUsers from '../views/org/org_users';
import withAuthElement from '../layouts/authHOC';
import Tools from "../views/tools/tools";
import {TeamProvider} from "../contexts/teamContext";
import {ProjectProvider} from "../contexts/projectContext";
import {OrgProvider} from "../contexts/orgContext";
import CreateRunGuard from "../layouts/guards/createRunGuard";
import RunConfigSettings from "../views/run/run_config_settings";
import RunComplete from "../views/run/run_complete";
import RunConfigArgs from "../views/run/run_config_args";
import RunSelection from "../views/run/run_selection";
import RunNotifications from "../views/run/run_notifications";
import Verified from '../views/auth/verified';

// Org root
const org_root = '/:org_domain';

export const getRoutes = createBrowserRouter([
        {
            path: "/login",
            errorElement: <Error404/>,
            children: [
                {
                    path: 'register',
                    element: <AuthLayout page_type='register'><Register/></AuthLayout>
                },
                {
                    path: 'forgot',
                    element: <AuthLayout page_type='forgot'><Forgot/></AuthLayout>
                },
                {
                    path: 'verified/:hash_id/:email',
                    element: <AuthLayout page_type='verified' skipLogin={true}><Verified/></AuthLayout>
                },
                {
                    path: 'reset',
                    element: <AuthLayout page_type='reset'><Reset/></AuthLayout>
                },
                {
                    path: '/login',
                    element: <AuthLayout page_type='login'><React.StrictMode>
                        <GoogleOAuthProvider
                            clientId='1003242977401-coiq7cvi1udfqotbs4qek6qjmqofj08b.apps.googleusercontent.com'>
                            <Login/>
                        </GoogleOAuthProvider>
                    </React.StrictMode></AuthLayout>
                },
                {
                    element: <Error404/>
                }
            ]
        }, {
            path: "/errors",
            children: [
                {
                    path: '401',
                    element: <Error401/>
                },
                {
                    path: '403',
                    element: <Error404/>
                },
                {
                    path: '404',
                    element: <Error404/>
                },
                {
                    path: '500',
                    element: <Error500/>
                },
                {
                    element: <Error401/>
                }
            ]
        }, {
            path: "/create_org",
            element: <AuthLayout page_type='createOrg' skipLogin={true}><CreateOrg/></AuthLayout>,
            children: [
                {
                    element: <Error404/>
                }
            ]
        }, {
        path: '/',
        element: withAuthElement(() => <Outlet />),
        errorElement: <Error404/>,
        children: [
            {
                path: '/',
                element: <CalculateLandingPage/>,
            },{
                path: org_root,
                element: <OrgProvider/>,
                children: [
                    {
                        path: `overview`,
                        element: <Overview/>
                    }, {
                        path: `teams`,
                        element: <Teams/>
                    },
                    {
                        path: `projects`,
                        element: <Projects/>
                    },
                    {
                        path: `components`,
                        element: <Components hideGlobal={true}/>
                    },
                    {
                        path: `batches`,
                        element: <Batches personal={true}/>
                    },
                    {
                        path: `manage/settings`,
                        element: <OrgSettings/>
                    },
                    {
                        path: `manage/jobs`,
                        element: <Jobs/>
                    },
                    {
                        path: `users/:user_id/view`,
                        element: <UserProfile/>
                    },
                    {
                        path: `users`,
                        element: <OrgUsers/>
                    },
                    {
                        path: `manage/logs`,
                        element: <OrgUsers/>
                    },
                    {
                        path: `manage/permissions`,
                        element: <Permissions/>
                    }, {
                        path: 'projects/:project_key',
                        element: <ProjectProvider/>,
                        children: [{
                            path: `overview`,
                            element: <Overview/>
                        }, {
                            path: `batch/:run_id`,
                            element: <RunReview/>
                        }, {
                            path: `batches`,

                            element: <Batches personal={false}/>
                        }, {
                            path: `test-suite/:case_id/info`,
                            element: <TestCaseInfo/>
                        }, {
                            path: `test-suite`,
                            element: <TestSuite/>
                        }, {
                            path: `calendar`,
                            element: <MyCalendar/>
                        }, {
                            path: `components`,
                            element: <Components/>
                        }, {
                            path: `dashboards`,
                            element: <Dashboard/>
                        }, {
                            path: `users/:user_id/view`,
                            element: <UserProfile/>
                        }, {
                            path: `manage/settings`,
                            element: <ProjectSettings/>
                        }, {
                            path: `manage/users`,
                            element: <ProjectMembers/>
                        }, {
                            path: `manage/usage`,
                            element: <Dashboard/>  // TODO Change this
                        }, {
                            path: `manage/args_scripts`,
                            element: <ProjectArguments/>
                        },{
                            path: `batches`,
                            children: [
                                {
                                    path: ``,
                                    element: <Batches personal={false}/>
                                }, {
                                    path: `:run_id`,
                                    element: <RunReview/>
                                }, {
                                    path: `create`,
                                    element: <CreateRunGuard/>,
                                    children: [
                                        {
                                            path: `settings`,
                                            element: <RunConfigSettings/>
                                        },{
                                            path: `arguments`,
                                            element: <RunConfigArgs/>
                                        },{
                                            path: `notifications`,
                                            element: <RunNotifications/>
                                        },{
                                            path: `selection`,
                                            element: <RunSelection/>
                                        },{
                                            path: `review`,
                                            element: <RunComplete/>
                                        },
                                    ]
                                }
                            ]
                        }, {
                            element: <Error404/>
                        }]
                    },{
                        path: 'teams/:team_id/:team_name',
                        element: <TeamProvider/>,
                        errorElement: <Error404/>,
                        children: [
                            {
                                path: `wall`,
                                element: <TeamWall/>
                            }, {
                                path: `members`,
                                element: <TeamMembers/>
                            }, {
                                path: `settings`,
                                element: <TeamSettings/>
                            },
                            {
                                path: `users/:user_id/view`,
                                element: <UserProfile/>
                            },
                            {
                                element: <Error404/>
                            }
                        ]
                    },{
                        path: 'user/:user_id',
                        errorElement: <Error404/>,
                        children: [
                            {
                                path: `profile`,
                                element: <UserProfile/>
                            },
                            {
                                path: `projects`,
                                element: <Projects/>
                            },
                            {
                                path: `teams`,
                                element: <Teams/>
                            },
                            {
                                path: `settings`,
                                element: <UserSettings/>
                            },
                            {
                                path: `notifications`,
                                element: <Notifications/>
                            },
                            {
                                path: `organizations`,
                                element: <MyOrgs/>
                            },
                            {
                                path: `tools`,
                                element: <Tools/>
                            },
                            {
                                element: <Error404/>
                            }
                        ]
                    },
                    {
                        element: <Error404/>
                    }
                ]
        }
        ],
    }
    ]
)