import React, {useEffect, useState} from 'react';
import { Link, useParams } from 'react-router-dom';
import { AppDispatch } from '../../redux/store';
import { useDispatch } from 'react-redux';
import { safeHandleErrorResponse } from '../../assets/helpers/errorHandler';
import { useUserContext } from '../../contexts/userContext';
import { verifyUser } from '../../redux/actions/auth_actions';

const Verified: React.FC = () => {
    const dispatch: AppDispatch = useDispatch();

    const {hash_id, email} = useParams();

    const {loggedInUser, setAppContextUser} = useUserContext()

    const [message, setMessage] = useState<{status: number, message: string}>()

    useEffect(() => {
            dispatch(verifyUser({hash_id: hash_id ? hash_id : "", email: email ? email : ""})).then(message => {
                setMessage(message)
                if (message.status === 1) {
                    setAppContextUser({
                        ...loggedInUser,
                        verified: message.status
                    })
                }
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })
        }, [dispatch])

    return <>
    <h1>
        {message?.message}
    </h1>
    <p>
        <Link to="/"> Continue </Link>
    </p>
    </>
};

export default Verified;
