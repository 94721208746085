// register.tsx
import React, {useState} from 'react';
import {signUpUser} from '../../redux/actions/auth_actions';
import {useDispatch} from 'react-redux';
import {AppDispatch} from '../../redux/store';
import {useNavigate} from 'react-router';
import {Button, Form, Input} from 'antd';
import {LockOutlined, UserOutlined} from "@ant-design/icons";
import {safeHandleErrorResponse} from "../../assets/helpers/errorHandler";
import {useUserContext} from "../../contexts/userContext";

const Register: React.FC = () => {
    const [password, setPassword] = useState('');
    const dispatch: AppDispatch = useDispatch();

    const {setUserToken, setAppContextUser, resetApp} = useUserContext();
    const navigate = useNavigate();

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const lowercaseRegex = /^(?=.*[a-z])/;
    const uppercaseRegex = /^(?=.*[A-Z])/;
    const specialCharRegex = /^(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-])/;

    const [form] = Form.useForm();

    const handleLogin = () => {
        form
            .validateFields()
            .then(values => {
                dispatch(signUpUser({
                    name: values.firstName,
                    lastname: values.lastName,
                    email: values.email,
                    password: values.password
                }))
                    .then((userFound) => {
                        setAppContextUser(userFound.new_user);
                        setUserToken(userFound.token);
                        navigate(`/`)
                    })
                    .catch((err) => {
                        resetApp()
                        safeHandleErrorResponse(err)
                    })
            })
            .catch((err) => {safeHandleErrorResponse(err)});
    };

    return <>
        <Form
            form={form}
            style={{width: 400}} // Ensure the form takes the full width
        >
            <Form.Item
                name="firstName"
                rules={[{required: true, message: 'First Name is Required'}]}
            >
                <Input
                    size={'large'}
                    prefix={<UserOutlined/>}
                    placeholder="First Name"
                />
            </Form.Item>
            <Form.Item
                name="lastName"
                rules={[{required: true, message: 'Last Name is Required'}]}
            >
                <Input
                    size={'large'}
                    prefix={<UserOutlined/>}
                    placeholder="Last Name"
                />
            </Form.Item>
            <Form.Item
                name="email"
                validateTrigger={false}
                rules={[{required: true, message: 'Email is Required!'},
                    {
                        validator(_, value) {
                            if (emailRegex.test(value)) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('Invalid email format'));
                        },
                    }]}
            >
                <Input
                    size={'large'}
                    prefix={<UserOutlined/>}
                    placeholder="Email"
                />
            </Form.Item>
            <Form.Item
                name="password"
                validateTrigger={false}
                rules={[
                    {required: true, message: 'Password is Required'},
                    {min: 8, message: 'Password must be at least 8 characters long'},
                    () => ({
                        validator(_, value) {
                            if (!value || lowercaseRegex.test(value.trim())) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('Password must contain at least one lowercase letter'));
                        },
                    }),
                    () => ({
                        validator(_, value) {
                            if (!value || uppercaseRegex.test(value.trim())) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('Password must contain at least one uppercase letter'));
                        },
                    }),
                    () => ({
                        validator(_, value) {
                            if (!value || specialCharRegex.test(value.trim())) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('Password must contain at least one special character'));
                        },
                    })
                ]}
            >
                <Input.Password
                    size={'large'}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    prefix={<LockOutlined/>}
                    placeholder="Password"
                />
            </Form.Item>
            <Form.Item
                validateTrigger={false}
                name="repeat_password"
                rules={[{required: true, message: 'Please input your Password!'},
                    () => ({
                        validator(_, value) {
                            if (!value || value === password) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error("Repeat Password doesn't match Password"));
                        },
                    })]}
            >
                <Input.Password
                    size={'large'}
                    prefix={<LockOutlined/>}
                    placeholder="Repeat Password"
                />
            </Form.Item>
            <Form.Item>
                <Button type="default" onClick={handleLogin}>Sign up</Button>
            </Form.Item>
        </Form>
    </>
};

export default Register;
