import { UploadFile } from 'antd';
import {post, get, deletion} from '../../services/axiosWrapper';
import { AppDispatch } from '../store';
import { fetchErrorAction, fetchStartAction, fetchSuccessAction } from './creators/common_actions';

const api_path = '/api/files/';

export const uploadAvatar = (params: {id: number, type: string, content: string, filename: string}) => (dispatch: AppDispatch): Promise<{avatar: string}> => {
    dispatch(fetchStartAction());

    const request: any = post(`${api_path}uploadAvatar`, params)
    console.log("path", request)
    return Promise.resolve(request)
            .then(value => {
                dispatch(fetchSuccessAction());
                return value.data;
            })
            .catch(reason => {
                dispatch(fetchErrorAction(reason));
                return Promise.reject();
            })
}

export const getAvatar = (id: number, type: string) => (dispatch: AppDispatch): Promise<{url: string}> => {
    dispatch(fetchStartAction());

    const request: any = get(`${api_path}getAvatar?id=${id}&type=${[type]}`)

    return Promise.resolve(request)
            .then(value => {
                dispatch(fetchSuccessAction());
                return value.data;
            })
            .catch(reason => {
                dispatch(fetchErrorAction(reason));
                return Promise.reject();
            })
} 

export const deleteAvatar = (id: number, type: string) => (dispatch: AppDispatch): Promise<UploadFile> => {
    dispatch(fetchStartAction());

    const request: any = deletion(`${api_path}deleteAvatar?id=${id}&type=${[type]}`)

    return Promise.resolve(request)
            .then(value => {
                dispatch(fetchSuccessAction());
                return value.data;
            })
            .catch(reason => {
                dispatch(fetchErrorAction(reason));
                return Promise.reject();
            })
} 
