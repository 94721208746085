import React, {useEffect, useMemo, useState} from 'react';
import {Dropdown, Space, Table} from "antd";
import Page from '../../components/page_structure/page';
import PageHeader from '../../components/page_structure/page_header';
import PageContent from '../../components/page_structure/page_content';
import {deleteRunArgument, getProjectRunArgs} from "../../redux/actions/project_actions";
import {safeHandleErrorResponse} from "../../assets/helpers/errorHandler";
import {AppDispatch} from "../../redux/store";
import {useDispatch} from "react-redux";
import CustomArgument from "../../components/modals/custom_args_modal";
import {Argument} from "../../models/entities/settings";
import debounce from "lodash/debounce";
import CheckboxC from "../../components/checkbox";
import CancelAction from "../../components/notification/cancel_req";
import {DeleteOutlined, DownOutlined, EditOutlined} from "@ant-design/icons";
import Searches from "../../sds/search";
import {empty_arg} from "../../services/EmptyEntities/EmptyArgs";


function ProjectArguments(): JSX.Element {
    const dispatch: AppDispatch = useDispatch();

    const [visible, setVisible] = useState<boolean>(false);
    const [showSystem, setShowSystem] = useState<boolean>(false);
    const [args, setArgs] = useState<Argument[]>([])
    const [filteredData, setFilteredData] = useState<Argument[]>([])
    const [dataSource, setDataSource] = useState<any[]>([])
    const [checkedProjectArgs, setCheckedProjectArgs] = useState<React.Key[]>([])
    const [selectedArg, setSelectedArg] = useState<Argument>(empty_arg);

    const selectedArgs: number = checkedProjectArgs ? checkedProjectArgs.length : 0

    const fetchData = (search?:string) => {
        dispatch(getProjectRunArgs({only_visible:false, search}))
            .then(args => {
                setArgs(args)
                setFilteredData(args)
            })
            .catch((err) => {safeHandleErrorResponse(err)});
    }

    useEffect(() => {
        dispatch(getProjectRunArgs({only_visible:false}))
            .then(args => {
                setArgs(args)
                setFilteredData(args)
            })
            .catch((err) => {safeHandleErrorResponse(err)});
        }, [dispatch])

    useEffect(() => {
        const data = filteredData.map((arg)=> ({
            key: arg.id,
            label: arg.label,
            argKey: arg.arg_key,
            default: arg.value,
            active: arg.status ? 'Yes' : 'No',
            actions: <><EditOutlined onClick={() => {setVisible(true); setSelectedArg(arg)}}/> Edit </>
        }))
        setDataSource(data)
    }, [filteredData])

    const debouncedSearch = useMemo(() => debounce((search) => {
        fetchData(search)
    }, 500), []);

    const handleSaveUpdate = (isNew:boolean, item:Argument) => {

        if (isNew) {
            setArgs((prevArgs)=> [...prevArgs, item])
            setFilteredData((prevArgs)=> [...prevArgs, item])
        }else{
            setArgs((prevArgs)=> prevArgs.map(arg=>{return arg.id === item.id? item: arg}))
            setFilteredData((prevArgs)=> prevArgs.map(arg=>{return arg.id === item.id? item: arg}))
        }
    }

    const handleDelete = () => {

        const params = {ids: checkedProjectArgs}

        dispatch(deleteRunArgument(params))
            .then(() => {
                let updatedList = args.filter((item => !checkedProjectArgs.includes(item.id)));
                setArgs(updatedList);
                setFilteredData(updatedList)
            })
            .catch((err) => { safeHandleErrorResponse(err)})
    }

    const columns = [
        {
            title: 'Label',
            dataIndex: 'label',
            key: "label",
        }, {
            title: 'Default Value',
            dataIndex: 'default',
            key: "default",
        }, {
            title: 'Active',
            dataIndex: 'active',
            key: "active",
        }, {
            title: 'Key',
            dataIndex: 'argKey',
            key: "argKey",
        },
        {
            title:
                <>
                    <Dropdown
                        disabled={selectedArgs === 0}
                        menu={{
                            items: [{
                                key: `mass-delete`,
                                label: <CancelAction secs={2} action={handleDelete}><DeleteOutlined /> Delete</CancelAction>,
                            }]
                        }}
                    >
                        <a>
                            <Space>
                                Actions <DownOutlined />
                            </Space>
                        </a>
                    </Dropdown>
                    <div style={{fontSize: 11}}>selected {selectedArgs}</div></>,
            dataIndex: 'actions',
            key: 'actions'
        }
    ]


    return <Page title="Arguments">
        <PageHeader title="Arguments" buttonHeader={{type: "create", onClickFunction:()=>setVisible(true)}}/>
        <PageContent>
            <Space style={{marginTop: 20, marginBottom: 25}}>
                {Searches.FrontSearch({
                    placeholder:"Type to search for arguments...",
                    onChange: debouncedSearch
                })}
                <CheckboxC label={'Show System Arguments'} checked={showSystem} onChange={()=>setShowSystem((prevState)=> !prevState)} />
            </Space>
            <Table
                pagination={false}
                rowSelection={{
                    selectedRowKeys: checkedProjectArgs,
                    onChange: (keys) => {setCheckedProjectArgs(keys)}
                }}
                columns={columns}
                dataSource={dataSource}
            />
        </PageContent>
        <CustomArgument
            visible={visible} setVisible={setVisible}
            selectedArg={selectedArg} setSelectedArg={setSelectedArg}
            allArgs={args}
            handleSaveUpdate={handleSaveUpdate}
        />
    </Page>
}

export default ProjectArguments;
