import React, {useEffect, useState} from 'react';
import {AppDispatch} from "../../redux/store";
import {useDispatch} from "react-redux";
import {Col, Space} from 'antd';
import ButtonC from "../button";
import {getMembersNotInTeam, getParentTeams, memberType} from "../../redux/actions/team_actions";
import {SelectProps} from "rc-select/lib/Select";
import {TeamOutlined, UserAddOutlined} from "@ant-design/icons";
import SwitchC from "../switch";
import {addMemberParamsModalType} from "../../models/entities/helpers/modal";
import RowC from "../row";
import CheckboxC from "../checkbox";
import { colors } from '../../assets/data/colors';
import {getMembersNotInProject} from "../../redux/actions/project_actions";
import { safeHandleErrorResponse } from '../../assets/helpers/errorHandler';
import {TPSelectMultiple} from "../../sds/select";
import TPModal from "../../sds/modal";
import {useOrgContext} from "../../contexts/orgContext";

type entityType = "project" | "team"

type ModalAddMembersProps = {
    setVisible:(is_visible:boolean)=>void,
    visible:boolean,
    entity: {
        id: number,
        type: entityType
    },
    handleAdd: (params: addMemberParamsModalType) => void
}


function ModalAddMembers({ visible, setVisible, entity, handleAdd }: ModalAddMembersProps): JSX.Element {
    const dispatch: AppDispatch = useDispatch();
    const { currentOrg } = useOrgContext();

    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [searchForUsers, setSearchForUsers] = useState<boolean>(false);
    const [searchForTeams, setSearchForTeams] = useState<boolean>(false);
    const [selectedMembers, setSelectedMembers] = useState<string[]>([]);
    const [search, setSearch] = useState<string>('');
    const [parentTeams, setParentTeams] = useState<number[]>([]);
    const [options, setOptions] = useState<SelectProps[]>([]);

    const setNewMembers = (membersFound: { id: number, member_type: 1 | 2, email: string, name: string }[]) => {
        let newOptions: SelectProps[] = membersFound.map(member => {
            return {
                disabled: member.member_type === 2 && parentTeams.includes(member.id),
                value: JSON.stringify({ id: member.id, type: member.member_type }),
                label: <Space>{member.member_type === 2 ? <TeamOutlined /> : <UserAddOutlined />} {member.name}</Space>
            };
        });
        setOptions(newOptions);
    };

    const getNewMembersToAdd = () => {
        let searchFor: memberType[] = [];
        if (searchForUsers) searchFor.push('user');
        if (searchForTeams) searchFor.push('team');

        const params = {
            id: entity.id,
            search: search.trim(),
            searchFor,
        };

        if (entity.type === "team") {
            dispatch(getParentTeams({team_id:entity.id, org_id: currentOrg.info.id}))
                .then((membersFound)=>{
                    setParentTeams(membersFound)
                    dispatch(getMembersNotInTeam(params))
                        .then(membersFound => { setNewMembers(membersFound); })
                        .catch((err) => { safeHandleErrorResponse(err); });
                })
                .catch((err) => { safeHandleErrorResponse(err); });

        } else if (entity.type === "project") {
            dispatch(getMembersNotInProject(params))
                .then(membersFound => { setNewMembers(membersFound); })
                .catch((err) => { safeHandleErrorResponse(err); });
        }
    };

    // Fetch new members when search parameters change
    useEffect(() => {
        if(visible){
            getNewMembersToAdd();
        }
    }, [search, searchForUsers, searchForTeams, visible]);

    const handleCancel = () => {
        setIsAdmin(false);
        setSelectedMembers([]);
        setVisible(false);
    };

    const controlHandleAdd = () => {
        const params: addMemberParamsModalType = {
            isAdmin,
            org_id: currentOrg.info.id,
            members: selectedMembers.map(member => JSON.parse(member)),
            id: entity.id,
        };
        handleAdd(params);

        // Clear selection, remove selected items from options, close modal
        let newOptions = options.filter(option => !selectedMembers.includes(option.value));
        setSelectedMembers([]);
        setOptions(newOptions);
        setVisible(false);
    };

    const footer_buttons = [
        <ButtonC key="cancel" onClick={handleCancel} text="Cancel" />,
        <ButtonC key="add" type="primary" onClick={controlHandleAdd} text={'Add'} />,
    ];

    return (
        <TPModal
            key="add_member_modal"
            open={visible}
            title={'Add New Members'}
            onCancel={handleCancel}
            footer={footer_buttons}
        >
            <RowC>
                <Col flex={'auto'}>
                    <p style={{ color: colors.gray.shadow, textAlign: 'center' }}>
                        {entity.type === "team" ? '* Parent Teams are disabled' : <>&nbsp;</>}
                    </p>
                    <Space direction="vertical" style={{ width: '100%' }} size={'large'}>
                        <TPSelectMultiple
                            style={{ width: 350 }}
                            selectedOptions={selectedMembers}
                            setSelectedOptions={setSelectedMembers}
                            options={options}
                            setSearch={setSearch}
                        />
                        <SwitchC
                            orientation={'right'}
                            label={'Add Selected Members as Admins'}
                            onChange={setIsAdmin}
                            defaultChecked={isAdmin}
                        />
                    </Space>
                </Col>
                <Col flex={'90px'}>
                    <div>
                        <p style={{ textAlign: 'center' }}><b>Search For</b></p>
                        <RowC>
                            <CheckboxC checked={searchForUsers} label={<><UserAddOutlined /> Users</>} onChange={(e) => { setSearchForUsers(e.target.checked); }} />
                        </RowC>
                        <RowC>
                            <CheckboxC checked={searchForTeams} label={<><TeamOutlined /> Teams</>} onChange={(e) => { setSearchForTeams(e.target.checked); }} />
                        </RowC>
                    </div>
                </Col>
            </RowC>
            <br />
            <br />
        </TPModal>
    );
}

export default ModalAddMembers;