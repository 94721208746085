import React, {createContext, Dispatch, SetStateAction, useContext, useEffect, useState} from 'react';

import BatchCreateLayout from "../layouts/batchCreate/batchCreateLayout";
import {recurringOptions, runOptions, RunStep, sequenceOptions} from "../models/entities/template";
import dayjs, {Dayjs} from "dayjs";
import {Argument} from "../models/entities/settings";
import {DataNode} from "antd/lib/tree";
import {checked_section, SuiteCases} from "../models/entities/suite_section_props";
import {PageResource} from "../models/dtos/page_resource";
import {Component} from "../models/entities/component";
import {createEmptyPage} from "../services/utils/PageResourceUtils";
import {
    RunCustomNotification,
    RunNotifications
} from "../models/entities/run_script_notifications";


export type CascaderSelection = (string | number)[][]
export interface CascaderOption {
    value: string | number;
    label: string;
    children?: CascaderOption[];
}

interface CreateBatchContextProps {
    settings: {
        runTitle: string,
        setRunTitle: Dispatch<SetStateAction<string>>,
        githubBranch: string,
        setGithubBranch: Dispatch<SetStateAction<string>>,
        reruns: number,
        setReruns: (value: number | null)=> void,
        maxParallel: number,
        setMaxParallel: (value: number | null)=> void,
        useMaxParallel: boolean,
        setUseMaxParallel: Dispatch<SetStateAction<boolean>>,
        run: {
            runOption: runOptions,
            setRunOption: Dispatch<SetStateAction<runOptions>>,
            recurringOption: recurringOptions,
            setRecurringOption: Dispatch<SetStateAction<recurringOptions>>,
            runDate: Dayjs | null,
            setRunDate: Dispatch<SetStateAction<Dayjs | null>>,
            isRecurring: boolean,
            setIsRecurring: Dispatch<SetStateAction<boolean>>,
            endDate: Dayjs | null,
            setEndDate: Dispatch<SetStateAction<Dayjs | null>>,
            endRecurring: boolean,
            setEndRecurring: Dispatch<SetStateAction<boolean>>
        },
        browsers: {
            availableBrowsers: CascaderOption[],
            setAvailableBrowsers: Dispatch<SetStateAction<CascaderOption[]>>,
            checkedBrowsers: CascaderSelection,
            setCheckedBrowsers: Dispatch<SetStateAction<CascaderSelection>>,
            sequence: sequenceOptions,
            setSequence: Dispatch<SetStateAction<sequenceOptions>>,
            runInterval: number,
            setRunInterval: (value: number | null)=> void
        }
    },
    args: Argument[],
    setArgs: Dispatch<SetStateAction<Argument[]>>,
    notifications:{
        success:RunNotifications[],
        setSuccess:Dispatch<SetStateAction<RunNotifications[]>>,
        fail:RunNotifications[],
        setFail:Dispatch<SetStateAction<RunNotifications[]>>,
        custom:RunCustomNotification[],
        setCustom:Dispatch<SetStateAction<RunCustomNotification[]>>
    },
    selection: {
        sections: {
            treeSections: DataNode[],
            setTreeSections: Dispatch<SetStateAction<DataNode[]>>,
            checkedSections: checked_section,
            setCheckedSections: Dispatch<SetStateAction<checked_section>>
        },
        cases:{
            checkedCases: SuiteCases,
            setCheckedCases: Dispatch<SetStateAction<SuiteCases>>,
            checkedCasesIds: React.Key[]
        },
        components:{
            componentPage: PageResource<Component>,
            setComponentPage: Dispatch<SetStateAction<PageResource<Component>>>,
            checkedComponents: React.Key[],
            setCheckedComponents: Dispatch<SetStateAction<React.Key[]>>
        }
    },
    utils: {
        caseTab: string,
        setCaseTab: Dispatch<SetStateAction<string>>,
        steps: RunStep[],
        setSteps: Dispatch<SetStateAction<RunStep[]>>,
        loadedTemplate: string[],
        setLoadedTemplate: Dispatch<SetStateAction<string[]>>
    }

}

const defaultValue:CreateBatchContextProps = {
    settings:{
        runTitle: 'New Batch',
        setRunTitle: () => {},
        githubBranch: '',
        setGithubBranch: () => {},
        reruns: 0,
        setReruns: () => {},
        maxParallel: 0,
        setMaxParallel: () => {},
        useMaxParallel: false,
        setUseMaxParallel: () => {},
        run: {
            runOption: 'Manually',
            setRunOption: () => {},
            recurringOption: 'Week',
            setRecurringOption: () => {},
            runDate: dayjs(),
            setRunDate: () => {},
            isRecurring: false,
            setIsRecurring: () => {},
            endDate: null,
            setEndDate: () => {},
            endRecurring: true,
            setEndRecurring: () => {}
        },
        browsers: {
            availableBrowsers: [],
            setAvailableBrowsers: () => {},
            checkedBrowsers: [],
            setCheckedBrowsers: () => {},
            sequence: 'Continuously',
            setSequence: () => {},
            runInterval: 0,
            setRunInterval: () => {}
        }
    },
    args: [],
    setArgs: () => {},
    notifications:{
        success:[],
        setSuccess:() => {},
        fail:[],
        setFail:() => {},
        custom:[],
        setCustom:() => {}
    },
    selection: {
        sections: {
            treeSections: [],
            setTreeSections: () => {},
            checkedSections: {checked: [], halfChecked: []},
            setCheckedSections: () => {}
        },
        cases:{
            checkedCases: {},
            setCheckedCases: () => {},
            checkedCasesIds: []
        },
        components:{
            componentPage: createEmptyPage(),
            setComponentPage: () => {},
            checkedComponents: [],
            setCheckedComponents: () => {}
        }
    },
    utils: {
        caseTab: "1",
        setCaseTab: () => {},
        steps: [],
        setSteps: () => {},
        loadedTemplate: [],
        setLoadedTemplate: () => {}
    }
}

const CreateRunContext = createContext<CreateBatchContextProps>(defaultValue);
// TODO include a project id as a param, if there is a param then fetch the project, otherwise use from projectContext
export const CreateRunProvider: React.FC = () => {

    // context
    // step1 settings state
    const settings = defaultValue.settings
    const [runTitle, setRunTitle] = useState<string>(settings.runTitle);  // Set this per project configuration
    const [githubBranch, setGithubBranch] = useState<string>(settings.githubBranch);
    const [reruns, setReruns] = useState<number>(settings.reruns);
    const [maxParallel, setMaxParallel] = useState<number>(settings.maxParallel);
    const [useMaxParallel, setUseMaxParallel] = useState<boolean>(false);

    // run state
    const [runOption, setRunOption] = useState<runOptions>(settings.run.runOption);
    const [runDate, setRunDate] = useState<Dayjs | null>(settings.run.runDate);
    const [recurringOption, setRecurringOption] = useState<recurringOptions>(settings.run.recurringOption);
    const [isRecurring, setIsRecurring] = useState<boolean>(settings.run.isRecurring);
    const [endDate, setEndDate] = useState<Dayjs | null>(settings.run.endDate);
    const [endRecurring, setEndRecurring] = useState<boolean>(settings.run.endRecurring);

    // browsers state

    const browsers = defaultValue.settings.browsers
    const [availableBrowsers, setAvailableBrowsers] = useState<CascaderOption[]>(browsers.availableBrowsers);
    const [checkedBrowsers, setCheckedBrowsers] = useState<CascaderSelection>(browsers.checkedBrowsers);
    const [sequence, setSequence] = useState<sequenceOptions>(browsers.sequence);
    const [runInterval, setRunInterval] = useState<number>(browsers.runInterval);

    // args state
    const [args, setArgs] = useState<Argument[]>(defaultValue.args)

    // Notifications State
    const notifications = defaultValue.notifications
    const [success, setSuccess] = useState<RunNotifications[]>(notifications.success)
    const [fail, setFail] = useState<RunNotifications[]>(notifications.fail)
    const [custom, setCustom] = useState<RunCustomNotification[]>(notifications.custom)

    // Select Cases - By Case/folder
    const selection = defaultValue.selection
    const [treeSections, setTreeSections] = useState<DataNode[]>(selection.sections.treeSections)
    const [checkedSections, setCheckedSections] = useState<checked_section>(selection.sections.checkedSections)
    const [checkedCases, setCheckedCases] = useState<SuiteCases>(selection.cases.checkedCases) // not sure about this default state

    // Select Cases - By Component
    const [componentPage, setComponentPage] = useState<PageResource<Component>>(selection.components.componentPage)
    const [checkedComponents, setCheckedComponents] = useState<React.Key[]>(selection.components.checkedComponents) // not sure about this default state
    const [checkedCasesIds, setCheckedCasesIds] = useState<React.Key[]>(selection.components.checkedComponents) // not sure about this default state

    // utils
    const [caseTab, setCaseTab] = useState<string>(defaultValue.utils.caseTab);
    const [steps, setSteps] = useState<RunStep[]>(defaultValue.utils.steps);
    const [loadedTemplate, setLoadedTemplate] = useState<string[]>(defaultValue.utils.loadedTemplate);

    useEffect(()=>{
        setCheckedCasesIds([].concat(...Object.values(checkedCases)))

    }, [checkedCases])

    const handleSetMaxParallel = (e: number | null) => {
        if (e) setMaxParallel(e)
    }

    const handleChangeReruns = (value: number | null) => {
        setReruns(value ? value : 0)
    }

    const handleChangeIntervals = (value: number | null) => {
        setRunInterval(value ? value : 0)
    }

    const value: CreateBatchContextProps = {
        settings: {
            runTitle,
            setRunTitle,
            githubBranch,
            setGithubBranch,
            reruns,
            setReruns: (value) => handleChangeReruns(value),
            maxParallel,
            setMaxParallel: (value) => handleSetMaxParallel(value),
            useMaxParallel,
            setUseMaxParallel,
            run: {
                runOption,
                setRunOption,
                runDate,
                setRunDate,
                recurringOption,
                setRecurringOption,
                isRecurring,
                setIsRecurring,
                endDate,
                setEndDate,
                endRecurring,
                setEndRecurring
            },
            browsers: {
                availableBrowsers,
                setAvailableBrowsers,
                checkedBrowsers,
                setCheckedBrowsers,
                sequence,
                setSequence,
                runInterval,
                setRunInterval: (value) => handleChangeIntervals(value)
            }
        },
        args,
        setArgs,
        notifications:{
            success,
            setSuccess,
            fail,
            setFail,
            custom,
            setCustom
        },
        selection: {
            sections: {
                treeSections,
                setTreeSections,
                checkedSections,
                setCheckedSections
            },
            cases:{
                checkedCases,
                setCheckedCases,
                checkedCasesIds
            },
            components:{
                componentPage,
                setComponentPage,
                checkedComponents,
                setCheckedComponents
            }
        },
        utils:{
            caseTab,
            setCaseTab,
            steps,
            setSteps,
            loadedTemplate,
            setLoadedTemplate
        }
    }

    return <CreateRunContext.Provider value={value}> <BatchCreateLayout/> </CreateRunContext.Provider>
}

export const useCreateRunContext = (): CreateBatchContextProps => {
    return useContext(CreateRunContext);
};