import React, {useEffect, useState} from 'react';
import {Col, Divider, Tree, Input, notification} from 'antd';
import {
    FileSyncOutlined,
    CheckOutlined,
    PropertySafetyOutlined,
    CloudSyncOutlined,
    ReloadOutlined,
    LeftCircleFilled, RightCircleFilled
} from '@ant-design/icons';
// import Search from 'antd/lib/input/Search';
import {DirectoryTreeProps} from 'antd/lib/tree/DirectoryTree';
import {DataNode, EventDataNode} from 'antd/lib/tree';
import {searchFolders} from '../../assets/helpers/helper';
import ButtonC from '../../components/button';
import RowC from "../../components/row";
import {Key} from "rc-tree/lib/interface";
import {TestSuiteSectionsProps} from '../../models/entities/suite_section_props';
import {removeCommonItems} from '../../assets/helpers/helper';
import {AppDispatch} from "../../redux/store";
import {useDispatch} from "react-redux";
import {getSections, getSectionsSearch} from "../../redux/actions/section_actions";
import Dropdown from 'antd/lib/dropdown';
import {syncProject, validateBranch} from "../../redux/actions/project_actions";
import InputC from "../../components/input";
import { safeHandleErrorResponse } from '../../assets/helpers/errorHandler';
import {colors} from "../../assets/data/colors";
import {useProjectContext} from "../../contexts/projectContext";
import {useOrgContext} from "../../contexts/orgContext";

const {Search} = Input

function TestSuiteFolders({ sections, divider, modal, treeOptions}: TestSuiteSectionsProps): JSX.Element {
    const dispatch: AppDispatch = useDispatch();

    // unpack divider
    const {isExpandedTree, setIsExpandedTree, showDivider} = divider || { isExpandedTree: false, setIsExpandedTree: () => {}, showDivider: false };

    const {multiple, checkStrictly } = treeOptions || { multiple: true, checkStrictly:true };

    // unpack sections
    const {treeData, checkbox, select, setTreeData} = sections
    const {setSelectedSections, setSelectedSection, selectedSections} = select  || { setSelectedSections: () => {}, setSelectedSection: () => {}, selectedSections:[]};
    // unpack modal
    const {setOpenModal, useModal} = modal || { setOpenModal: () => {}, useModal: false};

    // unpack Context
    const {currentOrg} = useOrgContext();
    const {currentProject} = useProjectContext()

    const [isReloadDisabled, setIsReloadDisabled] = useState<boolean>(false)

    const [sortedData, setSortedData] = useState<DataNode[]>([])
    const [showSortedData, setShowSortedData] = useState<boolean>(false)

    const [clickCount, setClickCount] = useState<number>(0);
    const [search, setSearch] = useState<string>('');
    const [branch, setBranch] = useState<string>('master'); // TODO: Fetch this from settings
    const [keysToCheck, setKeysToCheck] = useState<{ checked: number[], halfChecked: number[] }>({
        checked: [],
        halfChecked: []
    });
    const [eventToCheck, setEventToCheck] = useState<any>();
    const [timerId, setTimerId] = useState<number>(0);
    const [lastKey, setLastKey] = useState<number>(0)
    const delay = 200;

    const onRightClick = (info: { event: React.MouseEvent<Element, MouseEvent>, node: EventDataNode<DataNode> }) => {
        if (useModal && setSelectedSection) {
            setSelectedSection(info.node)
            if (setOpenModal) {
                setOpenModal(true)
            }
        }
    };

    const onSelect: DirectoryTreeProps['onSelect'] = (keys, info) => {
        console.log(info.selectedNodes)
        setSelectedSections(info.selectedNodes)
    }

    const findKeysWithChildren = (nodeData: DataNode[]): Key[] => {
        if (!nodeData) {
            return [];
        }

        let keysWithChildren: Key[] = [];

        for (let i = 0; i < nodeData.length; i++) {
            const node = nodeData[i];
            if (node.children) {
                // Add the key of the current node to the result
                keysWithChildren.push(node.key);

                // Recursively find keys with children in the current node's children
                keysWithChildren = keysWithChildren.concat(
                    findKeysWithChildren(node.children)
                );
            }
        }

        return keysWithChildren;
    };

    useEffect(() => {

        if (eventToCheck && checkbox) {
            const checked = eventToCheck.node.checked
            const temp_last_key = eventToCheck.node.key
            const {checkedSections, setCheckedSections} = checkbox

            const now = new Date();
            const temp_time = now.getTime() - now.getMilliseconds();

            if (checked) {
                if (temp_time - timerId < delay && lastKey === temp_last_key) {
                    const temp_children_keys = findKeysWithChildren(eventToCheck.node.children)

                    setCheckedSections({
                        checked: [...keysToCheck.checked, ...temp_children_keys, eventToCheck.node.key],
                        halfChecked: keysToCheck.halfChecked
                    })
                } else {
                    setCheckedSections(keysToCheck)
                    const now = new Date();
                    const currentTimestampWithMilliseconds = now.getTime() - now.getMilliseconds();
                    setTimerId(currentTimestampWithMilliseconds);
                    setLastKey(eventToCheck.node.key)
                }
            } else {
                if (temp_time - timerId < delay && lastKey === temp_last_key) {
                    const temp_children_keys = findKeysWithChildren(eventToCheck.node.children)
                    setCheckedSections({
                        checked: removeCommonItems(checkedSections.checked, [...temp_children_keys, eventToCheck.node.key]),
                        halfChecked: keysToCheck.halfChecked
                    })
                } else {
                    setCheckedSections(keysToCheck)
                    const now = new Date();
                    const currentTimestampWithMilliseconds = now.getTime() - now.getMilliseconds();
                    setTimerId(currentTimestampWithMilliseconds);
                    setLastKey(eventToCheck.node.key)
                }
            }
        }

    }, [clickCount]);

    const handleSetChecked = (keys: any, e: any) => {
        /*
        * if checkStrictly is false, then this must be handled differently
        * */
        setKeysToCheck(keys)
        setEventToCheck(e)
        setClickCount(clickCount + 1)
    }

    const onSearch = (e: any) => {
        if (e.length > 0) {
            // todo: request on back
            const data_found = searchFolders(treeData, e)

            setSortedData(data_found)
            setShowSortedData(true)
        } else {
            setSortedData([])
            setShowSortedData(false)
        }
        // TODO: search folders from back and return them, update state
    }

    const fetchData = async () => {
        dispatch(getSectionsSearch(currentProject.info.id, search))
            .then(foundPage => {
                setTreeData([{
                    title: currentProject.info.name,
                    key: -currentProject.info.id,
                    children: foundPage,
                    selectable: false
                }])
    }).catch((err) => {
        safeHandleErrorResponse(err)
    })}

    const reloadButton = () => {
        setIsReloadDisabled(true)
        dispatch(getSections())
            .then(foundPage => {
                setTreeData([{
                    title: currentProject.info.name,
                    key: -currentProject.info.id,
                    children: foundPage,
                    selectable: false
                }])
                setIsReloadDisabled(false)
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })
    }

    const items = [
        {
            key: 1,
            label: <><CheckOutlined /> Set</>,
            onClick: () =>{
                // console.log('1')
            }
        },
        {
            key: 2,
            disabled: false,
            label: <><FileSyncOutlined /> Sync</>,
            onClick: () =>{
                dispatch(syncProject(currentProject.info.id, branch, currentOrg.info.id))
                    .then((response)=>{
                        if(response.success){
                            if(response.status === 304) {
                                notification.success({
                                    message: 'Sync: No changes Found',
                                    description: response.message
                                })
                            }else{
                                notification.success({
                                    message: 'Github Sync',
                                    description: response.message,
                                    duration: 5
                                })
                            }
                        }else{
                            if(response.status === 203){
                                notification.warning({
                                    message: 'Github Sync',
                                    description: response.message
                                })
                            }else{
                                notification.error({
                                    message: 'Github Sync',
                                    description: response.message
                                })
                            }
                        }
                        //
                        // console.log(response)
                    })
                    .catch((err) => {
                        safeHandleErrorResponse(err)
                    })
            }
        },
        {
            key: 3,
            label: <><PropertySafetyOutlined /> Test</>,
            onClick: () =>{
                dispatch(validateBranch({branch}))
                    .then(response=> {
                        const message = `Test for ${branch}`
                        if (response.success) {
                            notification.success({
                                message,
                                description: response.msg,
                            })
                        } else {
                            notification.warning({
                                message,
                                description: response.msg,
                            })
                        }
                    }).catch((err) => {
                        safeHandleErrorResponse(err)
                    })
            }
        },
        {
            key: 4,
            label: <><CloudSyncOutlined /> Fetch</>,
            onClick: () =>{
                // console.log('3')
            }
        }
    ]

    return (
        <RowC>
            {
                isExpandedTree ?
                    <Col flex="auto">
                        <h3>{currentProject.info.p_key}
                            <ButtonC
                                icon={<ReloadOutlined/>}
                                disabled={isReloadDisabled}
                                key='reload'
                                onClick={reloadButton}
                                shape='circle'
                                size='small'
                                style={{marginLeft: 5}}
                        />
                        </h3>
                        {/*<InputBranch state={{value:'panos', setValue: setSearch}}/>*/}
                        {/* <InputC
                            style={{width: 115, float:'left'}}
                            onChange={(e)=>setBranch(e.target.value)}
                            value={branch} />
                        <Dropdown.Button
                            style={{width: 90, float:'left'}}
                            menu={{ items }}>Set
                        </Dropdown.Button><br/> */}
                        <div style={{width: '100%', float:'left'}}>
                            <Search
                                placeholder="Search for case"
                                onSearch={fetchData}
                                value={search}
                                onChange={(value)=>setSearch(value.target.value)}
                                style={{width: 200, marginTop: 10, verticalAlign: ''}}/>
                        </div>

                        {
                            (showSortedData && sortedData.length !== 0) || (!showSortedData && treeData.length !== 0) ?
                                <Tree style={{float: 'left', marginTop: 20}}
                                      selectable={true}
                                      multiple={multiple}
                                      defaultExpandedKeys={[-currentProject.info.id]}
                                      selectedKeys={selectedSections.map(node => node.key)}
                                      treeData={showSortedData ? sortedData : treeData}
                                      onSelect={onSelect}
                                      checkable={checkbox !== undefined}
                                      checkStrictly={checkStrictly}
                                      checkedKeys={checkbox ? checkbox.checkedSections.checked : []}
                                      onCheck={(keys, e) => handleSetChecked(keys, e)}
                                    // onExpand={onExpand}
                                      onRightClick={onRightClick}
                                /> : null
                        }
                    </Col> : null
            }
            <Col flex="34px">
                {
                    showDivider ?
                        <>
                            {isExpandedTree ?
                                <ButtonC key='buttonLeft' type="text"
                                         icon={<LeftCircleFilled style={{fontSize: 34, color: colors.blue.default}}/>}
                                         shape='circle' onClick={() => setIsExpandedTree(false)}/>
                                :
                                <ButtonC key='buttonRight' type="text" size='small' style={{marginLeft: 4, color: colors.blue.default}}
                                         icon={<RightCircleFilled style={{marginTop: -2, marginLeft: -2, fontSize: 27}}/>}
                                         shape='circle' onClick={() => setIsExpandedTree(true)}/>
                            }
                            <Divider style={{
                                height: 'calc(95vh - 160px)',
                                float: 'right',
                                margin: 'auto',
                                marginRight: 8,
                                paddingRight: 10,
                                marginTop: 0,
                                position: 'sticky',
                                top: 0
                            }} type='vertical'/>
                        </>
                         : null
                }
            </Col>
        </RowC>

    )
}

export default TestSuiteFolders;
