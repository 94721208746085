import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import ButtonC from "../../components/button";
import {AppDispatch} from '../../redux/store';
import {useDispatch} from 'react-redux';
import {logoutUser} from "../../redux/actions/user_actions";
import LcsManager from "../../assets/local_storage/lcs";
import { safeHandleErrorResponse } from '../../assets/helpers/errorHandler';

function Error404(): JSX.Element {
    const dispatch: AppDispatch = useDispatch();
    const lcs_manager = new LcsManager()

    useEffect(()=>{
    }, [])

    return <div style={{  position: "relative", textAlign: "center", minWidth:720}}>
        <img
            src={"/icons/errors/404.png"}
            alt="Panda in Error Page"
            style={{
                display: "block",
                width: 719,
                height: 650,
                margin: "0 auto"
            }}
        />
        <div style={{ position: 'absolute', zIndex:999, left:'49%', top:560, transform: "translateX(-50%)", display: "flex", gap: 120}}>
            <Link to='/'><ButtonC size='large' type='text' text={<b style={{color: '#fff'}}>Go To Home Page</b>} /></Link>
            <Link to='/login'><ButtonC size='large' type='text' text={<b style={{color: '#fff'}}>Log out</b>} onClick={() => {
                dispatch(logoutUser()).then(() => {lcs_manager.reset_local_storage([lcs_manager.Keys.is_sidemenu_collapsed])}).catch((err) => {
                    safeHandleErrorResponse(err)
                })
            }}/></Link>
        </div>

    </div>
}

export default Error404;
