import React, {useEffect, useRef, useState} from 'react';
import Page from "../../components/page_structure/page";
import PageHeader from "../../components/page_structure/page_header";
import PageContent from "../../components/page_structure/page_content";
import {AppDispatch} from '../../redux/store';
import {useDispatch} from 'react-redux';
import {
    acceptTeamInvite,
    declineTeamInvite,
    getOrgTeams,
    joinTeam
} from '../../redux/actions/team_actions';
import {PageResource} from '../../models/dtos/page_resource';
import {Team} from '../../models/entities/team';
import {createEmptyPage} from '../../services/utils/PageResourceUtils';
import {CardItem} from '../../models/entities/helpers/cardItem'
import {Button, Input, InputRef, Row, Tooltip} from 'antd';
import {TeamModal} from '../../components/modals/team_modal';
import {CardIcons} from "../../components/cards/card_icon";
import { colors } from '../../assets/data/colors';
import { safeHandleErrorResponse } from '../../assets/helpers/errorHandler';
import {useOrgContext} from "../../contexts/orgContext";


const {Search} = Input;


function Teams(): JSX.Element {

    const dispatch: AppDispatch = useDispatch();
    const {currentOrg} = useOrgContext();

    const [visible, setVisible] = useState<boolean>(false);

    const [teamPage, setTeamPage] = useState<PageResource<Team>>(createEmptyPage(30));
    const [activeTab, setActiveTab] = useState<1 | 2 | 3>(1);

    const [search, setSearch] = useState<string>('')

    const org_id = currentOrg.info.id

    const getPermissions = (): { createTeam: boolean } => {
        //get permissions
        return {createTeam: true}
    }

    const searchInputRef = useRef<InputRef>(null);

    const updateTeamState = (team_id:number) => {
        let temp_team_page = {...teamPage}
        temp_team_page.content = [...teamPage.content.filter(temp_team=> temp_team.id !== team_id)]

        setTeamPage(temp_team_page)
    }

    const fetchOrgTeams = () => {
        const teamTypes:any = {
            1: 'my_teams',
            2: 'public',
            3: 'invited'
        }

        dispatch(getOrgTeams({org_id, search, limit: teamPage.limit, teams_type:teamTypes[activeTab]}))
            .then(foundPage => {setTeamPage(foundPage)})
            .catch((err) => {
                safeHandleErrorResponse(err)
            })
    }

    useEffect(() => {
        // Focus on the input field when the component mounts
        if (searchInputRef.current) {
            searchInputRef.current.focus();
        }
    }, [search]);

    useEffect(() => {
        fetchOrgTeams()
    }, [dispatch, activeTab])

    const handleJoin = (team: Team) => {
        dispatch(joinTeam({org_id: currentOrg.info.id, team_id: team.id}))
            .then(() => {
                updateTeamState(team.id)
                // Assuming `items` is a state variable
            })
            .catch((err) => {
                safeHandleErrorResponse(err)
            })
    };

    const handleAccept = (team: Team) => {
        dispatch(acceptTeamInvite({ team_id: team.id, org_id: currentOrg.info.id }))
            .then(() => {
                // Assuming `items` is a state variable
                updateTeamState(team.id)
            })
            .catch((err) => {
                safeHandleErrorResponse(err)
            })
    };

    const handleDecline = (team: Team) => {
        dispatch(declineTeamInvite (team.id, currentOrg.info.id)).then(() => {
            // Assuming `items` is a state variable
            updateTeamState(team.id)
        }).catch((err) => {
            safeHandleErrorResponse(err)
        })
    };

    const constructCardData = () => {
        let cardItems: CardItem[] = teamPage.content.map((team) => {
            return {
                key: team.id,
                id: team.id,
                link: activeTab === 1? `/${currentOrg.info.domain}/teams/${team.id}/${team.name}/wall`: undefined,
                icon: <img 
                src={team.icon} 
                alt="Team Icon" 
                style={{ width: '150px', height: '150px' , display: 'block', margin: 'auto'}}
            />,
                name: <Tooltip title={
                    <>
                        <p>{team.name}</p>
                        <p>{team.description}</p>
                    </>
                }><p style={{ 
                    wordWrap: 'break-word',
                    overflowWrap: 'break-word',
                    whiteSpace: 'normal',
                    maxWidth: '100px',  // Adjust the max width as needed
                    textAlign: 'center'
                }}>
                    {team.name}
                </p></Tooltip>,
                description: activeTab === 3? team.description: team.members? `${team.members} Member${team?.members > 1? 's':''}` : '-',
                public: true,
                actions: activeTab === 2?
                    [<Button type='text' style={{color: colors.green.forest}} onClick={() => handleJoin(team)}>Join</Button>]
                    : activeTab === 3?
                        [
                            <Button style={{color: colors.red.vermilion}} type='text' onClick={() => handleDecline(team)}>Decline</Button>,
                            <Button style={{color: colors.green.forest}} type='text' onClick={() => handleAccept(team)}>Accept</Button>
                        ]
                        : undefined
            }
        })
        return cardItems
    }

    return (
        <Page title="Admin Panel-Teams">
            <PageHeader
                title="Teams"
                hasPermissions={getPermissions().createTeam}
                buttonHeader={
                    (currentOrg.userPermissions.team.manage_teams && activeTab === 1)
                        ? {type: "create", onClickFunction: () => setVisible(true)}
                        : null
                }
            />
            <PageContent>
                <div>
                    <div>
                        <Button type='default' onClick={() => setActiveTab(1)}
                                style={{marginRight: 10, backgroundColor: activeTab === 1 ? 'whitesmoke' : 'inherit'}}>My Teams
                        </Button>
                        <Button type='default' onClick={() => setActiveTab(2)}
                                style={{marginRight: 10, backgroundColor: activeTab === 2 ? 'whitesmoke' : 'inherit'}}>Public Teams
                        </Button>
                        <Button type='default' onClick={() => setActiveTab(3)}
                                style={{backgroundColor: activeTab === 3 ? 'whitesmoke' : 'inherit'}}>Pending Teams
                        </Button>
                    </div>
                    <div>
                        <Search
                            style={{marginTop: 20, width: 250}}
                            ref={searchInputRef}
                            placeholder="Search for team"
                            onSearch={() => fetchOrgTeams()}
                            value={search}
                            onChange={(value) => setSearch(value.target.value)}
                        />
                        <Row>
                            {CardIcons(constructCardData())}
                        </Row>
                        <TeamModal
                            visible={visible}
                            setVisible={setVisible}
                            setTeamPage={setTeamPage}
                            teamPage={teamPage}
                        />
                    </div>
                </div>
            </PageContent>
        </Page>
    )
}

export default Teams;