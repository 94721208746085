// @ts-ignore
import React, { FC } from 'react';
import {Avatar, AvatarProps, Tooltip} from "antd";
import {colors} from "../assets/data/colors";
import {TooltipProps} from "antd/es/tooltip";


interface TPAvatarProps extends AvatarProps{
    user: {
        img: string,
        fullname: string,
        initials: string,
        id?: number | string,
        url?: string
    }
}

interface TPTooltipAvatarProps extends AvatarProps{
    avatarPros: TPAvatarProps,
    tooltipProps?: TooltipProps
}

const TPAvatar: FC<TPAvatarProps> = (props) => {
    const {user, ...rest} = props
    return <Avatar style={{ backgroundColor: colors.blue.dark }} {...rest}>
        {user.img?
            <img alt={user.fullname} src={user.img}/>: user.initials}
    </Avatar>;
};

export const TPTooltipAvatar: FC<TPTooltipAvatarProps> = (props) => {
    const {avatarPros, tooltipProps} = props
    const { id, fullname, url } = avatarPros.user
    return <Tooltip
        key={id}
        placement="top"
        title={ <a target='_blank' rel="noreferrer" href={url}> {fullname} </a> }
        {...tooltipProps}
    >
        <Avatar {...avatarPros}/>
    </Tooltip>;
};


export default TPAvatar;