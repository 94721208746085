import React, {useEffect, useState} from 'react';
import Page from '../../components/page_structure/page';
import PageHeader from '../../components/page_structure/page_header';
import PageContent from '../../components/page_structure/page_content';
import './team_wall.css';
import CreatePostPopup from '../../components/modals/post_popup';
import {Avatar, Button, Card, Col, Divider, Drawer, Dropdown, FloatButton, List, Row, Skeleton, Space} from 'antd';
import {
    CloseOutlined,
    DeleteOutlined,
    EditOutlined,
    MessageOutlined,
    MoreOutlined,
    RightCircleOutlined,
    SendOutlined,
    ShareAltOutlined
} from '@ant-design/icons';
import {colors} from '../../assets/data/colors';
import {PostComments, WallPosts} from '../../models/entities/team';
import {AppDispatch} from '../../redux/store';
import {useDispatch} from "react-redux";
import {
    createPostComment,
    deletePost,
    deletePostComment,
    getPostComments,
    getTeamPinnedPosts,
    getTeamPosts,
    getTeamWallInfo,
    updatePost,
    updatePostComment
} from '../../redux/actions/team_wall_actions';
import {deleteFromArray} from '../../assets/helpers/helper';
import {empty_post} from '../../services/EmptyEntities/EmptyPost';
import RichEditor from '../../components/rich_editor/rich_editor';
import EmojiPicker from '../../components/emojis/emoji_picker';
import SharePostPopup from '../../components/modals/share_post';
import {getTeamStats, getTeamUsers} from '../../redux/actions/team_actions';
import {
    createCommentReaction,
    createPostReaction,
    deleteCommentReaction,
    deletePostReaction
} from '../../redux/actions/reaction_actions';
import { VictoryPie, VictoryTooltip } from 'victory';
import { safeHandleErrorResponse } from '../../assets/helpers/errorHandler';
import {useUserContext} from "../../contexts/userContext";
import {useTeamContext} from "../../contexts/teamContext";
import {useOrgContext} from "../../contexts/orgContext";

function TeamWall(): JSX.Element {

    const [visible, setVisible] = useState<boolean>(false);
    const [shareVisible, setShareVisible] = useState<boolean>(false);
    const [editComment, setEditComment] = useState<{ [commentId: number]: boolean }>({});
    const [posts, setPosts] = useState<WallPosts[]>([]);
    const [pinnedPosts, setPinnedPosts] = useState<WallPosts[]>([]);
    const [comments, setComments] = useState<PostComments[]>([]);
    const [open, setOpen] = useState(false);
    const [openComments, setOpenComments] = useState<{ [postId: number]: boolean }>({});
    const [selectedPost, setSelectedPost] = useState<WallPosts>(empty_post);
    const [todayPosts, setTodayPosts] = useState<number>();
    const [postsCount, setPostsCount] = useState<number>();
    const [pinnedPostsCount, setPinnedPostsCount] = useState<number>();
    const [owner, setOwner] = useState<string>();
    const [membersCount, setMembersCount] = useState<number>();
    const [commentsChange, setCommentsChange] = useState<any>();
    const [pinnedPostCommentsChange, setPinnedPostCommentsChange] = useState<any>();
    const [editCommentsChange, setEditCommentsChange] = useState<any>();
    const [showPicker, setShowPicker] = useState<{ [postId: number]: boolean }>({});
    const [showCommentPicker, setShowCommentPicker] = useState<{ [commentId: number]: boolean }>({});
    const [offset, setOffset] = useState<number>(0);
    const [hideMore, setHideMore] = useState(false);
    const [commentOffset, setCommentOffset] = useState<number>(0);
    const [hideMoreComment, setHideMoreComment] = useState(false);
    const [pinnedOffset, setPinnedOffset] = useState<number>(0);
    const [hideMorePinned, setHideMorePinned] = useState(false);
    const [showMore, setShowMore] = useState<{ [postId: number]: boolean }>({});
    const [showMorePinned, setShowMorePinned] = useState<{ [postId: number]: boolean }>({});
    const [showMoreComment, setShowMoreComment] = useState<{ [commentId: number]: boolean }>({});
    const [tagOptions, setTagOptions] = useState<{ id: number, value: string }[]>([]);
    const [teamStats, setTeamStats] = useState<{x: string, y: number, fill: string}[]>([]);
    const [readFlag, setReadFlag] = useState(false);
    const [loading, setLoading] = useState(true);

    const dispatch: AppDispatch = useDispatch();
    const {loggedInUser} = useUserContext();
    const {currentTeam} = useTeamContext();
    const {currentOrg} = useOrgContext();
    const maxCharacters = 1000;

    const team_id = currentTeam.info.id
    const team_name = currentTeam.info.name

    useEffect(() => {
        dispatch(getTeamPosts({org_id: currentOrg.info.id, team_id: team_id, offset: 0})).then(posts => {
            setPosts(posts.content)
            offset === 0 ? setOffset(offset + 10) : setOffset(offset)
            posts.content.length < 10 ? setHideMore(true) : setHideMore(false)
            setLoading(false)
        }).catch((err) => {
            safeHandleErrorResponse(err)
        })

        dispatch(getTeamStats(team_name))
            .then(found => {
                if (found && found.content && found.content.length > 0) {
                    const jobs = found.content[0].jobs || [];

                    // Map over the jobs to create the desired structure
                    const formattedJobs = jobs.map(job => ({
                        x: job.job_name,
                        y: job.id ? job.id : 0,
                        fill: job.color
                    }));

                    // Update the teamStats state with the formatted data
                    setTeamStats(formattedJobs);
                } else {
                    // Handle case where content array is empty or doesn't exist
                    setTeamStats([]); // or some default value
                }
            }).catch((err) => {
            safeHandleErrorResponse(err)
        })

        dispatch(getTeamUsers({org_id: currentOrg.info.id, team_id: team_id})).then(users => {
            const data: any[] = users.content.map(user => {
                return {id: user.id, value: user.name + " " + user.lastname}
            })
            setTagOptions(data)
        }).catch((err) => {
            safeHandleErrorResponse(err)
        })
    }, [dispatch, currentTeam]);


    useEffect(() => {
        dispatch(getTeamPinnedPosts({org_id: currentOrg.info.id, team_id: team_id, offset: 0}))
            .then(posts => {
                setPinnedPosts(posts.content)
                pinnedOffset === 0 ? setPinnedOffset(pinnedOffset + 10) : setPinnedOffset(pinnedOffset)
                posts.content.length < 10 ? setHideMorePinned(true) : setHideMorePinned(false)
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })

        dispatch(getTeamWallInfo({org_id: currentOrg.info.id, team_id: team_id}))
            .then(found => {
                setMembersCount(found.team_member_count)
                setPostsCount(found.team_wall_post_count)
                setPinnedPostsCount(found.pinned_post_count)
                setOwner(found.team_owner !== null ? found.team_owner : 'N/A')
                setTodayPosts(found.today_posts)
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })

    }, [dispatch, currentTeam, readFlag])


    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const loadMore = () => {
        dispatch(getTeamPosts({org_id: currentOrg.info.id, team_id: team_id, offset: offset})).then(new_posts => {
            if (new_posts.content.length < 10) {
                setHideMore(true)
            }
            let new_posts_array: WallPosts[] = [...posts, ...new_posts.content]
            setPosts(new_posts_array)
            setOffset(offset + 10)
        }).catch((err) => {
            safeHandleErrorResponse(err)
        })
    };

    const loadMoreComments = (post: WallPosts) => {
        dispatch(getPostComments({post_id: post.post_id, offset: commentOffset, org_id: currentOrg.info.id, team_id:currentTeam.info.id})).then(new_comments => {
            if (new_comments.content.length < 3) {
                setHideMoreComment(true)
            }
            let new_comments_array: PostComments[] = [...comments, ...new_comments.content]
            setComments(new_comments_array)
            setCommentOffset(commentOffset + 3)
        }).catch((err) => {
            safeHandleErrorResponse(err)
        })
    };

    const loadPinnedMore = () => {
        dispatch(getTeamPinnedPosts({
            org_id: currentOrg.info.id,
            team_id: team_id,
            offset: pinnedOffset
        })).then(new_posts => {
            if (new_posts.content.length < 10) {
                setHideMorePinned(true)
            }
            let new_posts_array: WallPosts[] = [...pinnedPosts, ...new_posts.content]
            setPinnedPosts(new_posts_array)
            setPinnedOffset(pinnedOffset + 10)
        }).catch((err) => {
            safeHandleErrorResponse(err)
        })
    };

    const toggleComments = (postId: number) => {
        setCommentOffset(0)
        setOpenComments(prevOpenComments => {
            // Create a new object to hold the updated state
            const updatedState: Record<string, boolean> = {...prevOpenComments}
            const element = document.getElementById(postId.toString());

            if (element) {
                const topPosition = element.getBoundingClientRect().top + window.scrollY;
                window.scrollTo({top: topPosition - 100, behavior: 'smooth'});
            }

            // Toggle the state for the clicked post
            updatedState[postId] = !updatedState[postId];

            // Close the comments for other posts
            Object.keys(updatedState).forEach(key => {
                if (key !== postId.toString()) {
                    updatedState[key] = false;
                }
            });
            setCommentOffset(3)
            return updatedState;
        });
    };

    const toggleShowMore = (postId: number) => {
        setShowMore(prevOpenComments => {
            // Create a new object to hold the updated state
            const updatedState: Record<string, boolean> = {...prevOpenComments}
            const element = document.getElementById(postId.toString());

            if (element) {
                const topPosition = element.getBoundingClientRect().top + window.scrollY;
                window.scrollTo({
                    top: topPosition - 100, behavior: 'smooth'
                });
            }

            // Toggle the state for the clicked post
            updatedState[postId] = !updatedState[postId];

            // Close the comments for other posts
            Object.keys(updatedState).forEach(key => {
                if (key !== postId.toString()) {
                    updatedState[key] = false;
                }
            });
            setCommentOffset(3)
            return updatedState;
        });
    };

    const toggleShowMorePinned = (postId: number) => {
        setShowMorePinned(prevOpenComments => {
            // Create a new object to hold the updated state
            const updatedState: Record<string, boolean> = {...prevOpenComments}
            const element = document.getElementById(postId.toString());

            if (element) {
                const topPosition = element.getBoundingClientRect().top + window.scrollY;
                window.scrollTo({top: topPosition - 100, behavior: 'smooth'});
            }

            // Toggle the state for the clicked post
            updatedState[postId] = !updatedState[postId];

            // Close the comments for other posts
            Object.keys(updatedState).forEach(key => {
                if (key !== postId.toString()) {
                    updatedState[key] = false;
                }
            });
            setCommentOffset(3)
            return updatedState;
        });
    };

    const toggleShowMoreComment = (commentId: number) => {
        setShowMoreComment(prevOpenComments => {
            // Create a new object to hold the updated state
            const updatedState: Record<string, boolean> = {...prevOpenComments}
            // const element = document.getElementById(commentId.toString());

            // if (element) {
            //   const topPosition = element.getBoundingClientRect().top + window.scrollY;
            //     window.scrollTo({ top: topPosition - 100, behavior: 'smooth' });
            // }

            // Toggle the state for the clicked post
            updatedState[commentId] = !updatedState[commentId];

            // Close the comments for other posts
            Object.keys(updatedState).forEach(key => {
                if (key !== commentId.toString()) {
                    updatedState[key] = false;
                }
            });
            setCommentOffset(3)
            return updatedState;
        });
    };

    const getPostCommentsHandler = async (post: WallPosts) => {
        dispatch(getPostComments({post_id: post.post_id, offset: 0, org_id: currentOrg.info.id, team_id: currentTeam.info.id}))
            .then((comments) => {
                comments.content.length < 3 ? setHideMoreComment(true) : setHideMoreComment(false)
                toggleComments(post.post_id === undefined ? 0 : post.post_id);
                setCommentsChange('')
                setComments(comments.content)
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })
        // setCommentOffset(commentOffset + 3)
        // const element = document.getElementById(post.post_id === undefined ? '' : post.post_id.toString());
        // if (element) {
        //     const topPosition = element.getBoundingClientRect().top + window.scrollY;
        //     window.scrollTo({ top: topPosition, behavior: 'smooth' });}
    }

    const createPostCommentHandler = (post: WallPosts) => {
        const new_comment: PostComments = {
            post_id: post.post_id,
            team_id: team_id,
            comment_text: commentsChange,
            comment_image: commentsChange.includes('<img') ? commentsChange : null
        }
        if (commentsChange.replace(/<(?!img)[^>]+>/g, '').trim().length > 0) {
            dispatch(createPostComment(new_comment, comments))
                .then((comments) => {
                    setComments(comments);
                }).catch((err) => {
                    safeHandleErrorResponse(err)
                })
            setCommentsChange('');
            setTimeout(() => {
            }, 500);
        }
    }

    const createPinnedPostCommentHandler = (post: WallPosts) => {
        const new_comment: PostComments = {
            post_id: post.post_id,
            team_id: team_id,
            comment_text: pinnedPostCommentsChange,
            comment_image: pinnedPostCommentsChange.includes('<img') ? pinnedPostCommentsChange : null
        }

        dispatch(createPostComment(new_comment, comments))
            .then((comments) => {
                setComments(comments);
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })
        setPinnedPostCommentsChange('');
        setTimeout(() => {
        }, 500);
    }

    const updatePostCommentHandler = (comment: PostComments, post: WallPosts) => {
        const new_comment: PostComments = {
            post_id: post.post_id,
            org_id: currentOrg.info.id,
            team_id: team_id,
            comment_id: comment.comment_id,
            poster: comment.poster,
            comment_text: editCommentsChange,
            comment_image: editCommentsChange.includes('<img') ? editCommentsChange : null,
            timestamp: comment.timestamp
        }
        if (editCommentsChange.replace(/<(?!img)[^>]+>/g, '').trim().length > 0) {
            dispatch(updatePostComment(new_comment, comments))
                .then(() => {
                    setComments(comments);
                }).catch((err) => {
                    safeHandleErrorResponse(err)
                })
            setEditCommentsChange('');
            setTimeout(() => {
                cancelEditHandler(comment)
            }, 500);
        }
    }

    const handleCommentsChange = (event: any) => {
        setCommentsChange(event);
    };

    const handlePinnedPostCommentsChange = (event: any) => {
        setPinnedPostCommentsChange(event);
    };

    const handleEditCommentsChange = (event: any) => {
        setEditCommentsChange(event);
    };

    const pinPostHandler = (post: WallPosts, pinned: boolean) => {
        const new_post: WallPosts = {
            org_id: currentOrg.info.id,
            team_id: post.team_id,
            post_text: post.post_text,
            post_id: post.post_id,
            poster: post.poster,
            timestamp: post.timestamp,
            pinned: post.pinned === 1 ? 0 : 1
        };

        if (!pinned) {
            dispatch(updatePost(new_post, posts))
                .then((found) => {
                    setPosts(found);
                    let pinned_posts: WallPosts[] = [];
                    posts.forEach(post => {
                        if (post.pinned === 1) {
                            pinned_posts.push(post);
                        }
                    });
                    setPinnedPosts([...pinned_posts]);
                    setReadFlag(!readFlag)
                }).catch((err) => {
                    safeHandleErrorResponse(err)
                });
        } else {
            dispatch(updatePost(new_post, pinnedPosts))
                .then((found) => {
                    const pinned_index = found.indexOf(new_post)
                    found = deleteFromArray(found, pinned_index);
                    setPinnedPosts(found)

                    const post_index = posts.findIndex(post => post.post_id === new_post.post_id);

                    posts[post_index].pinned = 0;
                    setPosts(posts)
                    setReadFlag(!readFlag)

                }).catch((err) => {
                    safeHandleErrorResponse(err)
                });
        }

        setTimeout(() => {
        }, 500);
    };

    const handleDeletePost = (post_id: number) => {
        dispatch(deletePost(post_id, currentOrg.info.id, currentTeam.info.id))
            .catch((err) => {safeHandleErrorResponse(err)})
        let wall_posts: WallPosts[] = posts
        let pinned_wall_posts: WallPosts[] = pinnedPosts
        const post_to_delete = wall_posts.find(post => post.post_id === post_id);
        const pinned_post_to_delete = pinned_wall_posts.find(post => post.post_id === post_id);
        if (pinned_post_to_delete) {
            const pin_index = pinned_wall_posts.indexOf(pinned_post_to_delete)
            pinned_wall_posts = deleteFromArray(pinned_wall_posts, pin_index);

            setPinnedPosts(pinned_wall_posts)
        }
        if (post_to_delete) {
            const post_index = wall_posts.indexOf(post_to_delete)
            wall_posts = deleteFromArray(wall_posts, post_index);
            setPosts(wall_posts)
        }
    }

    const handleDeletePostComment = (comment_id: number) => {
        dispatch(deletePostComment(comment_id, currentOrg.info.id, currentTeam.info.id))
            .catch((err) => {safeHandleErrorResponse(err)})
        let wall_post_comments: PostComments[] = comments
        const post_comment_to_delete = wall_post_comments.find(comment => comment.comment_id === comment_id);
        if (post_comment_to_delete) {
            const index = wall_post_comments.indexOf(post_comment_to_delete)
            wall_post_comments = deleteFromArray(wall_post_comments, index);
            setComments(wall_post_comments)
        }
    }

    const renderPosts = (post: WallPosts) => {

        return (
            <Dropdown
                menu={{
                    items: [
                        {
                            key: `pin-${post.post_id}`,
                            label: post.pinned === 1 ? <><img src={'/icons/unpin.png'}
                                                              style={{width: 14}}/> Unpin </> : <><img
                                src={'/icons/pin.png'} style={{width: 14}}/> Pin </>
                        }, {
                            key: `edit-${post.post_id}`,
                            label: <><EditOutlined/> Edit </>
                        }, {
                            key: `delete-${post.post_id}`,
                            label: <><DeleteOutlined/> Delete </>
                        }],

                    onClick: (e) => {
                        if (e.key === `edit-${post.post_id}`) {
                            setSelectedPost(post)
                            setVisible(true)
                        }
                        if (e.key === `delete-${post.post_id}`) {
                            {
                                handleDeletePost(post.post_id === undefined ? 0 : post.post_id)
                            }
                        }
                        if (e.key === `pin-${post.post_id}`) {
                            {
                                pinPostHandler(post, false)
                            }
                        }
                    }
                }}
                trigger={['click']}
                arrow>
                <Button
                    onClick={() => {
                    }}
                    icon={<MoreOutlined style={{color: colors.green.forest}}/>}
                    size='small'
                    title='Post Actions'
                    shape='circle'
                    style={{background: post.pinned === 1 ? colors.gray.background : 'whitesmoke'}}
                />
            </Dropdown>
        );
    };

    const renderPinnedPosts = (post: WallPosts) => {

        return (
            <Dropdown
                menu={{
                    items: [
                        {
                            key: `pin-${post.post_id}`,
                            label: <><img src={'/icons/unpin.png'} style={{width: 14}}/> Unpin </>
                        }, {
                            key: `edit-${post.post_id}`,
                            label: <><EditOutlined/> Edit </>
                        }, {
                            key: `delete-${post.post_id}`,
                            label: <><DeleteOutlined/> Delete </>
                        }],

                    onClick: (e) => {
                        if (e.key === `edit-${post.post_id}`) {
                            setSelectedPost(post)
                            setVisible(true)
                        }
                        if (e.key === `delete-${post.post_id}`) {
                            {
                                handleDeletePost(post.post_id === undefined ? 0 : post.post_id)
                            }
                        }
                        if (e.key === `pin-${post.post_id}`) {
                            {
                                pinPostHandler(post, true)
                            }
                        }
                    }
                }}
                trigger={['click']}
                arrow>
                <Button
                    onClick={() => {
                    }}
                    icon={<MoreOutlined style={{color: colors.green.forest}}/>}
                    size='small'
                    title='Post Actions'
                    shape='circle'
                    style={{background: colors.gray.background}}
                />
            </Dropdown>
        );
    };

    const renderComments = (comment: PostComments, post: WallPosts) => {

        return (
            <Dropdown
                menu={{
                    items: [
                        {
                            key: `edit-${comment.comment_id}`,
                            label: <><EditOutlined/> Edit </>
                        }, {
                            key: `delete-${comment.comment_id}`,
                            label: <><DeleteOutlined/> Delete </>
                        }],

                    onClick: (e) => {
                        if (e.key === `edit-${comment.comment_id}`) {
                            setEditCommentsChange(comment.comment_text)
                            const commentId = comment.comment_id === undefined ? 0 : comment.comment_id
                            setEditComment(prevOpenEditComments => {
                                // Create a new object to hold the updated state
                                const updatedState: Record<string, boolean> = {...prevOpenEditComments}

                                // Toggle the state for the clicked post
                                updatedState[commentId] = !updatedState[commentId];

                                // Close the comments for other posts
                                Object.keys(updatedState).forEach(key => {
                                    if (key !== commentId.toString()) {
                                        updatedState[key] = false;
                                    }
                                });
                                return updatedState;
                            });
                        }
                        if (e.key === `delete-${comment.comment_id}`) {
                            {
                                handleDeletePostComment(comment.comment_id === undefined ? 0 : comment.comment_id)
                            }
                        }
                    }
                }}
                trigger={['click']}
                arrow>
                <Button
                    onClick={() => {
                    }}
                    icon={<MoreOutlined style={{color: colors.green.forest}}/>}
                    size='small'
                    title='Post Actions'
                    shape='circle'
                    style={{background: post.pinned === 1 ? colors.gray.background : 'whitesmoke'}}
                />
            </Dropdown>
        );
    };

    const cancelEditHandler = (comment: PostComments) => {
        const commentId = comment.comment_id === undefined ? '0' : comment.comment_id
        setEditComment(prevOpenEditComments => {
            // Create a new object to hold the updated state
            const updatedState: Record<string, boolean> = {...prevOpenEditComments}
            // Toggle the state for the clicked post
            updatedState[commentId] = !updatedState[commentId];
            // Close the comments for other posts
            Object.keys(updatedState).forEach(key => {
                if (key !== commentId) {
                    updatedState[key] = false;
                }
            });
            return updatedState;
        })
    }

    const toggleEmojiPicker = (postId: number) => {
        setShowPicker(prevOpenPickers => {
            // Create a new object to hold the updated state
            const updatedState: Record<string, boolean> = {...prevOpenPickers}

            // Toggle the state for the clicked post
            updatedState[postId] = !updatedState[postId];

            // Close the comments for other posts
            Object.keys(updatedState).forEach(key => {
                if (key !== postId.toString()) {
                    updatedState[key] = false;
                }
            });
            return updatedState;
        });
    };

    const toggleCommentEmojiPicker = (commentId: number) => {
        setShowCommentPicker(prevOpenPickers => {
            // Create a new object to hold the updated state
            const updatedState: Record<string, boolean> = {...prevOpenPickers}

            // Toggle the state for the clicked post
            updatedState[commentId] = !updatedState[commentId];

            // Close the comments for other posts
            Object.keys(updatedState).forEach(key => {
                if (key !== commentId.toString()) {
                    updatedState[key] = false;
                }
            });
            return updatedState;
        });
    };

    const handleEmojiSelect = (emojis: any, post: WallPosts, new_item: boolean) => {

        if ((emojis.users && !emojis.users.includes(loggedInUser.name + " " + loggedInUser.lastname)) ||
            (new_item && !(post.post_reactions && post.post_reactions.some((item) => item.reaction_id === emojis.reaction_id)))) {
            dispatch(createPostReaction({
                post_id: post.post_id === undefined ? 0 : post.post_id,
                react_id: emojis.reaction_id
            }, post.post_reactions ? post.post_reactions : [])).then(() => {
                const updatedPosts = posts.map(p => {
                    const x = {
                        users: [loggedInUser.name + " " + loggedInUser.lastname],
                        reaction_id: emojis.reaction_id,
                        reaction_icons: emojis.reaction_icons,
                        total_number: 1
                    };
                    const reactionIdToCheck = x.reaction_id;

                    // Check if the reaction_id is already in the array a
                    const existingItemIndex = (p.post_reactions || []).findIndex(item => item.reaction_id === reactionIdToCheck);
                    if (p.post_id === post.post_id) {
                        if (existingItemIndex === -1) {
                            // If not present, add the item to the array
                            const updatedPost: WallPosts = {...p, post_reactions: [...(p.post_reactions || []), x]};
                            return updatedPost;
                        } else {
                            // If present, increase the total_number by 1
                            const updatedReactions = [...(p.post_reactions || [])];
                            updatedReactions[existingItemIndex].total_number += 1;
                            // if (new_item) {emojis.users = [... emojis.users, loggedInUser.name + " " + loggedInUser.lastname]}
                            updatedReactions[existingItemIndex].users = [...updatedReactions[existingItemIndex].users, loggedInUser.name + " " + loggedInUser.lastname];
                            return {...p, post_reactions: updatedReactions};
                        }
                    }

                    // If the condition isn't met, return the original post
                    return p;
                });

                // After the map loop, set the updated state
                setPosts(updatedPosts);
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })
        } else {
            // Update the count or remove the emoji from the list
            dispatch(deletePostReaction({
                post_id: post.post_id === undefined ? 0 : post.post_id,
                react_id: emojis.reaction_id
            })).then(found => {
                // console.log(found)
                const updatedPosts = posts.map(p => {
                    if (p.post_id === post.post_id) {
                        return {...p, post_reactions: found.data.content.reactions};
                    }
                    return p;
                });
                setPosts(updatedPosts);
            }).catch((err) => {
                safeHandleErrorResponse(err)
            });
        }
    };

    const handleCommentEmojiSelect = (emojis: any, comment: PostComments, new_item: boolean) => {

        if ((emojis.users && !emojis.users.includes(loggedInUser.name + " " + loggedInUser.lastname)) ||
            (new_item && !(comment.post_reactions && comment.post_reactions.some((item) => item.reaction_id === emojis.reaction_id)))) {
            dispatch(createCommentReaction({
                comment_id: comment.comment_id === undefined ? 0 : comment.comment_id,
                react_id: emojis.reaction_id
            }, comment.post_reactions ? comment.post_reactions : [])).then(() => {
                const updatedComments = comments.map(c => {
                    const x = {
                        users: [loggedInUser.name + " " + loggedInUser.lastname],
                        reaction_id: emojis.reaction_id,
                        reaction_icons: emojis.reaction_icons,
                        total_number: 1
                    };
                    const reactionIdToCheck = x.reaction_id;

                    // Check if the reaction_id is already in the array a
                    const existingItemIndex = (c.post_reactions || []).findIndex(item => item.reaction_id === reactionIdToCheck);
                    if (c.comment_id === comment.comment_id) {
                        if (existingItemIndex === -1) {
                            // If not present, add the item to the array
                            const updatedComment: PostComments = {
                                ...c,
                                post_reactions: [...(c.post_reactions || []), x]
                            };
                            return updatedComment;
                        } else {
                            // If present, increase the total_number by 1
                            const updatedReactions = [...(c.post_reactions || [])];
                            updatedReactions[existingItemIndex].total_number += 1;
                            // if (new_item) {emojis.users = [... emojis.users, loggedInUser.name + " " + loggedInUser.lastname]}
                            updatedReactions[existingItemIndex].users = [...updatedReactions[existingItemIndex].users, loggedInUser.name + " " + loggedInUser.lastname];
                            return {...c, post_reactions: updatedReactions};
                        }
                    }

                    // If the condition isn't met, return the original post
                    return c;
                });

                // After the map loop, set the updated state
                setComments(updatedComments);
            }).catch((err) => {
                safeHandleErrorResponse(err)
            })
        } else {
            // Update the count or remove the emoji from the list
            dispatch(deleteCommentReaction({
                comment_id: comment.comment_id === undefined ? 0 : comment.comment_id,
                react_id: emojis.reaction_id
            })).then(found => {
                // console.log(found)
                const updatedComments = comments.map(c => {
                    if (c.comment_id === comment.comment_id) {
                        return {...c, post_reactions: found.data.content.reactions};
                    }
                    return c;
                });
                setComments(updatedComments);
            }).catch((err) => {
                safeHandleErrorResponse(err)
            });
        }
    };

    // const handleLeaveTeam = () => {
    //     const confirmation = window.confirm('Are you sure you want to leave the Team?')

    //     if (confirmation)
    //     {dispatch(leaveTeam(currentOrg.info.id, team_id, loggedInUser.id))
    //         .then(() => {
    //             setAppContextTeam(empty_team);
    //             navigate(`/${currentOrg.domain}/teams`)
    //         }).catch((err) => {
    //             safeHandleErrorResponse(err)
    //         })}
    // };

    return (
        <Page title={`${team_name} Wall`}>
            <PageHeader title={`${team_name} Wall`}/>
            <PageContent>
                <Row className='sticky_header'>
                    <Col span={4}/>
                    <Col span={15}>
                        <div className="createPost" onClick={() => setVisible(true)}>
                            <div className="createPost_header">
                                {loggedInUser.image ? (
                                    <img src={loggedInUser.image} alt="User Avatar"/>) : (
                                    <Avatar>
                                        {loggedInUser?.name?.charAt(0)}
                                        {loggedInUser?.lastname?.charAt(0)}
                                    </Avatar>)}
                                <div className="open_post">
                                    {loggedInUser?.name}, share with the Team...
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col span={5} style={{marginTop: 20, textAlign: 'center'}}>
                        <Button onClick={showDrawer} icon={<RightCircleOutlined style={{color: 'gray'}}/>}
                                size='large' title='Show Pinned Posts'
                                shape='circle'/>
                        <Drawer title="Pinned Posts" placement="right" onClose={onClose} open={open} size='large'>
                            <List
                                loading={loading}
                                itemLayout="horizontal"
                                dataSource={pinnedPosts}
                                renderItem={(post: WallPosts) => (
                                    <Card hoverable={true} style={{marginTop: 20, width: 'auto', height: 'auto'}}
                                          styles={{ body: { padding: 0 } }}>
                                        <List.Item id={post.post_id?.toString()} key={post.post_id}
                                                   style={{background: colors.gray.background, borderRadius: 10}}>
                                            <ul style={{width: '100%'}}>
                                                <Skeleton avatar title={false} active loading={loading}>
                                                    <List.Item.Meta
                                                        avatar={<div>{loggedInUser.id === post.poster?.id ? (<div
                                                                style={{
                                                                    float: 'left',
                                                                    marginRight: 10,
                                                                    marginTop: 5
                                                                }}> {renderPinnedPosts(post)}</div>) :
                                                            (
                                                                <Dropdown
                                                                    menu={{
                                                                        items: [
                                                                            {
                                                                                key: `pin-${post.post_id}`,
                                                                                label: <><img src={'/icons/unpin.png'}
                                                                                              style={{width: 14}}/> Unpin </>
                                                                            }],

                                                                        onClick: (e) => {
                                                                            if (e.key === `pin-${post.post_id}`) {
                                                                                {
                                                                                    pinPostHandler(post, true)
                                                                                }
                                                                            }
                                                                        }
                                                                    }}
                                                                    trigger={['click']}
                                                                    arrow>
                                                                    <Button
                                                                        onClick={() => {
                                                                        }}
                                                                        icon={<MoreOutlined
                                                                            style={{color: colors.green.forest}}/>}
                                                                        size='small'
                                                                        title='Post Actions'
                                                                        shape='circle'
                                                                        style={{
                                                                            background: colors.gray.background,
                                                                            float: 'left',
                                                                            marginRight: 10,
                                                                            marginTop: 5
                                                                        }}
                                                                    />
                                                                </Dropdown>
                                                            )}
                                                            <a href={`/${currentOrg.info.domain}/teams/${team_id}/${team_name}/users/${post.poster?.id}/view`}
                                                               onMouseOver={(e: any) => e.target.style.color = 'black'}
                                                               onMouseOut={(e: any) => e.target.style.color = 'white'}>
                                                                <Avatar
                                                                    src={post.poster?.img === null ? null : post.poster?.img}>{post.poster?.img === null
                                                                    ? `${post.poster?.name?.charAt(0)}${post.poster?.lastname?.charAt(0)}` : ''}</Avatar></a>
                                                        </div>}
                                                        title={
                                                            <div style={{display: "inline-block", width: '100%'}}>
                                                                <a href={`/${currentOrg.info.domain}/teams/${team_id}/${team_name}/users/${post.poster?.id}/view`}
                                                                   style={{
                                                                       float: 'left',
                                                                       color: 'grey',
                                                                       textDecoration: 'none'
                                                                   }}
                                                                   onMouseOver={(e: any) => e.target.style.color = 'black'}
                                                                   onMouseOut={(e: any) => e.target.style.color = 'grey'}> {post.poster?.name} {post.poster?.lastname} </a>

                                                                {<img src={'/icons/pin.png'} style={{
                                                                    width: 14,
                                                                    float: 'right',
                                                                    marginRight: 5
                                                                }} title='Pinned'/>}
                                                                <div style={{
                                                                    color: 'grey',
                                                                    clear: 'left',
                                                                    fontWeight: 'lighter'
                                                                }}>{post.timestamp?.slice(0, 10)} {post.timestamp?.slice(11, 16)}</div>
                                                            </div>}
                                                        description={<div>
                                                            <div>
                                                                <div
                                                                    dangerouslySetInnerHTML={{__html: showMorePinned[post.post_id === undefined ? 0 : post.post_id] ? !post.post_text.includes('<img') ? post.post_text : post.post_text.replace(/<img/g, `<img class="responsive-team-wall-image"`) : post.post_text.slice(0, maxCharacters)}}
                                                                    style={{
                                                                        overflowWrap: "break-word",
                                                                        inlineSize: "95%",
                                                                        color: '#3d3d3d'
                                                                    }}/>
                                                                {post.post_text.length > maxCharacters && (
                                                                    <Space
                                                                        onClick={() => toggleShowMorePinned(post.post_id === undefined ? 0 : post.post_id)}
                                                                        style={{color: 'green', cursor: 'pointer'}}>
                                                                        {showMorePinned[post.post_id === undefined ? 0 : post.post_id] ? 'Show Less' : 'Show More'}
                                                                    </Space>)}
                                                            </div>
                                                            {Object.entries(post.post_reactions || {}).map(([_, emoji]) => (
                                                                emoji.total_number > 0 ?
                                                                    <button key={emoji.reaction_icons}
                                                                            style={{
                                                                                padding: 3,
                                                                                borderRadius: '8px',
                                                                                marginLeft: 5,
                                                                                fontSize: '12px',
                                                                                backgroundColor: '#E3E3E3',
                                                                                border: emoji.users.includes(loggedInUser.name + " " + loggedInUser.lastname) ? '1px solid green' : 'none',
                                                                                color: 'grey',
                                                                                cursor: 'pointer'
                                                                            }}
                                                                            onClick={() => handleEmojiSelect(emoji, post, false)}
                                                                            title={emoji?.users.toString()}>
                                                                        {emoji.reaction_icons}{emoji.total_number}
                                                                    </button> : null))} </div>
                                                        }
                                                    />
                                                </Skeleton>
                                                <div>
                                                    {!openComments[post.post_id === undefined ? 0 : post.post_id] && (
                                                        <Button size='middle'
                                                                style={{
                                                                    float: 'right',
                                                                    background: colors.gray.background,
                                                                    marginLeft: 10,
                                                                    marginRight: 10,
                                                                    marginBottom: 10
                                                                }}
                                                                title='Replies'
                                                                onClick={() => getPostCommentsHandler(post)}>
                                                            <MessageOutlined style={{
                                                                width: 25,
                                                                height: 'auto',
                                                                color: 'grey'
                                                            }}/></Button>)
                                                    }
                                                    {openComments[post.post_id === undefined ? 0 : post.post_id] && (
                                                        <Button size='middle'
                                                                style={{
                                                                    float: 'right',
                                                                    background: colors.gray.background,
                                                                    marginLeft: 10,
                                                                    marginRight: 10
                                                                }}
                                                                title='Hide Replies'
                                                                onClick={() => {
                                                                    toggleComments(post.post_id === undefined ? 0 : post.post_id)
                                                                }}>
                                                            <MessageOutlined
                                                                style={{width: 25, height: 'auto', color: 'grey'}}
                                                                onClick={() => setSelectedPost(empty_post)}/>
                                                        </Button>)}
                                                    <Button size='middle'
                                                            style={{
                                                                float: 'right',
                                                                background: colors.gray.background,
                                                                marginLeft: 10,
                                                                marginBottom: 10
                                                            }}
                                                            title='Share'
                                                            onClick={() => {
                                                                setShareVisible(true);
                                                                setSelectedPost(post)
                                                            }}>
                                                        <ShareAltOutlined style={{
                                                            width: 25,
                                                            height: 'auto',
                                                            color: 'grey'
                                                        }}/></Button>
                                                    <Button size='middle'
                                                            style={{
                                                                float: 'right',
                                                                background: colors.gray.background,
                                                                marginLeft: 10,
                                                                marginBottom: 10
                                                            }}
                                                            title='Post Reactions'
                                                            onClick={() => {
                                                                toggleEmojiPicker(post.post_id === undefined ? 0 : post.post_id)
                                                            }}>
                                                        <img src={'/user_images/Happy.png'}
                                                             style={{width: 20, height: 'auto'}}/>
                                                    </Button>
                                                    {showPicker[post.post_id === undefined ? 0 : post.post_id] &&
                                                    <EmojiPicker
                                                        onSelect={(emoji) => handleEmojiSelect(emoji, post, true)}/>}
                                                </div>
                                                {openComments[post.post_id === undefined ? 0 : post.post_id] && (
                                                    <div>
                                                        <Divider/>
                                                        <div className="createPost"
                                                             style={{background: colors.gray.background, width: '98%'}}>
                                                            <div className="createPost_header">
                                                                <RichEditor value={pinnedPostCommentsChange}
                                                                            onChange={handlePinnedPostCommentsChange}
                                                                            atValues={tagOptions}/>
                                                                <SendOutlined style={{
                                                                    marginTop: 30,
                                                                    transform: 'rotateX(-30deg) rotateY(30deg)'
                                                                }} title='Reply'
                                                                              onClick={() => createPinnedPostCommentHandler(post)}/>
                                                            </div>
                                                        </div>
                                                        <Divider/>
                                                        <List
                                                            style={{marginLeft: 50}}
                                                            dataSource={comments}
                                                            renderItem={(comment) => (!editComment[comment.comment_id === undefined ? 0 : comment.comment_id] ?
                                                                    <List.Item key={comment.comment_id}>
                                                                        <ul style={{width: '100%'}}>
                                                                            <Skeleton avatar title={false} active
                                                                                      loading={loading}>
                                                                                <List.Item.Meta
                                                                                    avatar={
                                                                                        <div>{loggedInUser.id === comment.poster?.id && (
                                                                                            <div style={{
                                                                                                float: 'left',
                                                                                                marginRight: 10,
                                                                                                marginTop: 5
                                                                                            }}> {renderComments(comment, post)}</div>)}
                                                                                            <a href={`/${currentOrg.info.domain}/teams/${team_id}/${team_name}/users/${comment.poster?.id}/view`}
                                                                                               onMouseOver={(e: any) => e.target.style.color = 'black'}
                                                                                               onMouseOut={(e: any) => e.target.style.color = 'white'}>
                                                                                                <Avatar
                                                                                                    src={comment.poster?.img === null ? null : comment.poster?.img}>
                                                                                                    {comment.poster?.img === null ? `${comment.poster?.name?.charAt(0)}${comment.poster?.lastname?.charAt(0)}` : ''}</Avatar></a>
                                                                                        </div>}
                                                                                    title={
                                                                                        <div style={{
                                                                                            display: "inline-block",
                                                                                            width: '100%'
                                                                                        }}>
                                                                                            <a href={`/${currentOrg.info.domain}/teams/${team_id}/${team_name}/users/${comment.poster?.id}/view`}
                                                                                               style={{
                                                                                                   float: 'left',
                                                                                                   color: 'grey',
                                                                                                   textDecoration: 'none'
                                                                                               }}
                                                                                               onMouseOver={(e: any) => e.target.style.color = 'black'}
                                                                                               onMouseOut={(e: any) => e.target.style.color = 'grey'}> {comment.poster?.name} {comment.poster?.lastname} </a>
                                                                                            <div style={{
                                                                                                color: 'grey',
                                                                                                clear: 'left',
                                                                                                fontWeight: 'lighter'
                                                                                            }}>{comment.timestamp?.slice(0, 10)} {comment.timestamp?.slice(11, 16)}</div>
                                                                                        </div>
                                                                                    }
                                                                                    description={<div>
                                                                                        <div>
                                                                                            <div
                                                                                                dangerouslySetInnerHTML={{__html: showMoreComment[comment.comment_id === undefined ? 0 : comment.comment_id] ? !comment.comment_text.includes('<img') ? comment.comment_text : comment.comment_text.replace(/<img/g, `<img class="responsive-team-wall-image"`) : comment.comment_text.slice(0, maxCharacters)}}
                                                                                                style={{
                                                                                                    overflowWrap: "break-word",
                                                                                                    inlineSize: "95%",
                                                                                                    color: '#3d3d3d'
                                                                                                }}/>
                                                                                            {comment.comment_text.length > maxCharacters && (
                                                                                                <Space
                                                                                                    onClick={() => toggleShowMoreComment(comment.comment_id === undefined ? 0 : comment.comment_id)}
                                                                                                    style={{
                                                                                                        color: 'green',
                                                                                                        cursor: 'pointer'
                                                                                                    }}>
                                                                                                    {showMoreComment[comment.comment_id === undefined ? 0 : comment.comment_id] ? 'Show Less' : 'Show More'}
                                                                                                </Space>)}
                                                                                        </div>
                                                                                        {Object.entries(comment.post_reactions || {}).map(([_, emoji]) => (
                                                                                            emoji.total_number > 0 ?
                                                                                                <button
                                                                                                    key={emoji.reaction_icons}
                                                                                                    style={{
                                                                                                        padding: 3,
                                                                                                        borderRadius: '8px',
                                                                                                        marginLeft: 5,
                                                                                                        fontSize: '12px',
                                                                                                        backgroundColor: '#E3E3E3',
                                                                                                        border: emoji.users.includes(loggedInUser.name + " " + loggedInUser.lastname) ? '1px solid green' : 'none',
                                                                                                        color: 'grey',
                                                                                                        cursor: 'pointer'
                                                                                                    }}
                                                                                                    onClick={() => handleCommentEmojiSelect(emoji, comment, false)}
                                                                                                    title={emoji?.users.toString()}>
                                                                                                    {emoji.reaction_icons}{emoji.total_number}
                                                                                                </button> : null))}
                                                                                    </div>}
                                                                                />
                                                                            </Skeleton>
                                                                            <div>
                                                                                <Button size='middle'
                                                                                        style={{
                                                                                            float: 'right',
                                                                                            background: colors.gray.background,
                                                                                            marginLeft: 10,
                                                                                            marginRight: 10
                                                                                        }}
                                                                                        title='Comment Reactions'
                                                                                        onClick={() => {
                                                                                            toggleCommentEmojiPicker(comment.comment_id === undefined ? 0 : comment.comment_id)
                                                                                        }}>
                                                                                    <img src={'/user_images/Happy.png'}
                                                                                         style={{
                                                                                             width: 20,
                                                                                             height: 'auto'
                                                                                         }}/>
                                                                                </Button>
                                                                                {showCommentPicker[comment.comment_id === undefined ? 0 : comment.comment_id] &&
                                                                                <EmojiPicker
                                                                                    onSelect={(emoji) => handleCommentEmojiSelect(emoji, comment, true)}/>}
                                                                            </div>
                                                                        </ul>
                                                                    </List.Item> :
                                                                    <div>
                                                                        <div className="createPost" style={{
                                                                            background: colors.gray.background,
                                                                            width: '98%'
                                                                        }}>
                                                                            <Avatar
                                                                                src={post.poster?.img === null ? '/icons/default_user.png' : post.poster?.img}
                                                                                style={{
                                                                                    marginTop: 50,
                                                                                    width: '14',
                                                                                    height: '14'
                                                                                }}/>
                                                                            <div className="createPost_header">
                                                                                <RichEditor value={comment.comment_text}
                                                                                            onChange={handleEditCommentsChange}
                                                                                            atValues={tagOptions}/>
                                                                                <SendOutlined style={{
                                                                                    marginTop: 30,
                                                                                    transform: 'rotateX(-30deg) rotateY(30deg)'
                                                                                }} title='Edit Reply'
                                                                                              onClick={() => updatePostCommentHandler(comment, post)}/>
                                                                                <CloseOutlined style={{
                                                                                    marginTop: 35,
                                                                                    marginLeft: 15
                                                                                }} title='Cancel'
                                                                                               onClick={() => cancelEditHandler(comment)}/>
                                                                            </div>
                                                                        </div>
                                                                        <Divider/>
                                                                    </div>
                                                            )}
                                                        />
                                                        {!hideMoreComment && (
                                                            <div style={{textAlign: 'center', marginTop: '20px'}}>
                                                                <Button
                                                                    onClick={() => loadMoreComments(post)}>More</Button>
                                                            </div>)}
                                                    </div>)
                                                }
                                            </ul>
                                        </List.Item></Card>
                                )}
                            />
                            {!hideMorePinned && (
                                <div style={{textAlign: 'center', marginTop: '20px'}}>
                                    <Button onClick={loadPinnedMore}>More</Button>
                                </div>)}
                        </Drawer>
                    </Col>
                </Row>
                <Divider/>
                <Row>
                    <Col span={4}>
                        <Card title={
                            <a href={`/${currentOrg.info.domain}/teams/${team_id}/${team_name}/users/${loggedInUser.id}/view`}
                               onMouseOver={(e: any) => e.target.style.color = 'black'}
                               onMouseOut={(e: any) => e.target.style.color = 'white'}>
                                <Avatar
                                    src={loggedInUser?.image}
                                    style={{ width: '100px', height: '100px' , display: 'block', margin: 'auto', marginTop: 10}}/></a>}
                              style={{
                                  width: 'auto',
                                  marginRight: 25,
                                  background: colors.gray.background,
                                  marginTop: 10
                              }}
                              className='sticky_cards'>
                            <div>
                                <Divider/>
                                <div style={{textAlign: 'center', fontWeight: 'bold'}}>
                                    <a href={`/${currentOrg.info.domain}/teams/${team_id}/${team_name}/users/${loggedInUser.id}/view`}
                                       onMouseOver={(e: any) => e.target.style.color = 'black'}
                                       onMouseOut={(e: any) => e.target.style.color = 'grey'}>
                                        <p style={{color: 'grey'}}>{loggedInUser.name} {loggedInUser.lastname}</p></a>
                                </div>
                                <div style={{textAlign: 'center'}}>
                                    {/*{loggedInUser.job}*/}
                                </div>
                                <div style={{textAlign: 'center', marginTop: 10}}>Member of {team_name}</div>
                                {/* <Divider/>
                                <Button type='text'
                                        style={{color: 'red', 
                                                textAlign: 'center', 
                                                justifyContent: 'center', 
                                                display: 'flex',
                                                width: '100%'}}
                                        onClick={() => handleLeaveTeam()}>Leave Team</Button> */}
                            </div>
                        </Card>
                    </Col>
                    <Col span={15}>
                        <List
                            loading={loading}
                            itemLayout="horizontal"
                            dataSource={posts}
                            renderItem={(post: WallPosts) => (
                                <Card hoverable={true} style={{marginTop: 10, width: 'auto', height: 'auto'}}
                                      styles={{ body: { padding: 0 } }}>
                                    <List.Item id={post.post_id?.toString()} key={post.post_id} style={{
                                        background: post.pinned === 1 ? colors.gray.background : 'whitesmoke',
                                        borderRadius: 10
                                    }}>
                                        <ul style={{width: '100%'}}>
                                            <Skeleton avatar title={false} active loading={loading}>
                                                <List.Item.Meta
                                                    avatar={<div>{loggedInUser.id === post.poster?.id ? (<div style={{
                                                            float: 'left',
                                                            marginRight: 10,
                                                            marginTop: 5
                                                        }}> {renderPosts(post)}</div>) :
                                                        (
                                                            <Dropdown
                                                                menu={{
                                                                    items: [
                                                                        {
                                                                            key: `pin-${post.post_id}`,
                                                                            label: post.pinned === 1 ? <><img
                                                                                src={'/icons/unpin.png'}
                                                                                style={{width: 14}}/> Unpin </> : <><img
                                                                                src={'/icons/pin.png'}
                                                                                style={{width: 14}}/> Pin </>
                                                                        }],

                                                                    onClick: (e) => {
                                                                        if (e.key === `pin-${post.post_id}`) {
                                                                            {
                                                                                pinPostHandler(post, false)
                                                                            }
                                                                        }
                                                                    }
                                                                }}
                                                                trigger={['click']}
                                                                arrow>
                                                                <Button
                                                                    onClick={() => {
                                                                    }}
                                                                    icon={<MoreOutlined
                                                                        style={{color: colors.green.forest}}/>}
                                                                    size='small'
                                                                    title='Post Actions'
                                                                    shape='circle'
                                                                    style={{
                                                                        background: post.pinned === 1 ? colors.gray.background : 'whitesmoke',
                                                                        float: 'left',
                                                                        marginRight: 10,
                                                                        marginTop: 5
                                                                    }}
                                                                />
                                                            </Dropdown>
                                                        )}
                                                        <a href={`/${currentOrg.info.domain}/teams/${team_id}/${team_name}/users/${post.poster?.id}/view`}
                                                           onMouseOver={(e: any) => e.target.style.color = 'black'}
                                                           onMouseOut={(e: any) => e.target.style.color = 'white'}>
                                                            <Avatar
                                                                src={post.poster?.img === null ? null : post.poster?.img}>{post.poster?.img === null
                                                                ? `${post.poster?.name?.charAt(0)}${post.poster?.lastname?.charAt(0)}` : ''}</Avatar></a>
                                                    </div>}
                                                    title={
                                                        <div style={{display: "inline-block", width: '100%'}}>
                                                            <a href={`/${currentOrg.info.domain}/teams/${team_id}/${team_name}/users/${post.poster?.id}/view`}
                                                               style={{
                                                                   float: 'left',
                                                                   color: 'grey',
                                                                   textDecoration: 'none'
                                                               }}
                                                               onMouseOver={(e: any) => e.target.style.color = 'black'}
                                                               onMouseOut={(e: any) => e.target.style.color = 'grey'}> {post.poster?.name} {post.poster?.lastname} </a>

                                                            {post.pinned === 1 ? <img src={'/icons/pin.png'} style={{
                                                                width: 14,
                                                                float: 'right',
                                                                marginRight: 5
                                                            }} title='Pinned'/> : null}
                                                            <div style={{
                                                                color: 'grey',
                                                                clear: 'left',
                                                                fontWeight: 'lighter'
                                                            }}>{post.timestamp?.slice(0, 10)} {post.timestamp?.slice(11, 16)}</div>
                                                        </div>}
                                                    description={<div>
                                                        <div>
                                                            <div
                                                                dangerouslySetInnerHTML={{__html: showMore[post.post_id === undefined ? 0 : post.post_id] ? !post.post_text.includes('<img') ? post.post_text : post.post_text.replace(/<img/g, `<img class="responsive-team-wall-image"`) : post.post_text.slice(0, maxCharacters)}}
                                                                style={{
                                                                    overflowWrap: "break-word",
                                                                    inlineSize: "95%",
                                                                    color: '#3d3d3d'
                                                                }}/>
                                                            {post.post_text.length > maxCharacters && (
                                                                <Space
                                                                    onClick={() => toggleShowMore(post.post_id === undefined ? 0 : post.post_id)}
                                                                    style={{color: 'green', cursor: 'pointer'}}>
                                                                    {showMore[post.post_id === undefined ? 0 : post.post_id] ? 'Show Less' : 'Show More'}
                                                                </Space>)}
                                                        </div>
                                                        {Object.entries(post.post_reactions || {}).map(([_, emoji]) => (
                                                            emoji.total_number > 0 ?
                                                                <button key={emoji.reaction_icons}
                                                                        style={{
                                                                            padding: 3,
                                                                            borderRadius: '8px',
                                                                            marginLeft: 5,
                                                                            fontSize: '12px',
                                                                            backgroundColor: '#E3E3E3',
                                                                            border: emoji.users.includes(loggedInUser.name + " " + loggedInUser.lastname) ? '1px solid green' : 'none',
                                                                            color: 'grey',
                                                                            cursor: 'pointer'
                                                                        }}
                                                                        onClick={() => handleEmojiSelect(emoji, post, false)}
                                                                        title={emoji?.users.toString()}>
                                                                    {emoji.reaction_icons}{emoji.total_number}
                                                                </button> : null))} </div>
                                                    }
                                                />
                                            </Skeleton>
                                            <div>
                                                {!openComments[post.post_id === undefined ? 0 : post.post_id] && (
                                                    <Button size='middle'
                                                            style={{
                                                                float: 'right',
                                                                background: post.pinned === 1 ? colors.gray.background : 'whitesmoke',
                                                                marginLeft: 10,
                                                                marginRight: 10
                                                            }}
                                                            title='Replies'
                                                            onClick={() => getPostCommentsHandler(post)}>
                                                        <MessageOutlined style={{
                                                            width: 25,
                                                            height: 'auto',
                                                            color: 'grey'
                                                        }}/></Button>)
                                                }
                                                {openComments[post.post_id === undefined ? 0 : post.post_id] && (
                                                    <Button size='middle'
                                                            style={{
                                                                float: 'right',
                                                                background: post.pinned === 1 ? colors.gray.background : 'whitesmoke',
                                                                marginLeft: 10,
                                                                marginRight: 10,
                                                                marginBottom: 10
                                                            }}
                                                            title='Hide Replies'
                                                            onClick={() => {
                                                                toggleComments(post.post_id === undefined ? 0 : post.post_id)
                                                            }}>
                                                        <MessageOutlined
                                                            style={{width: 25, height: 'auto', color: 'grey'}}
                                                            onClick={() => setSelectedPost(empty_post)}/>
                                                    </Button>)}
                                                <Button size='middle'
                                                        style={{
                                                            float: 'right',
                                                            background: post.pinned === 1 ? colors.gray.background : 'whitesmoke',
                                                            marginLeft: 10,
                                                            marginBottom: 10
                                                        }}
                                                        title='Share'
                                                        onClick={() => {
                                                            setShareVisible(true);
                                                            setSelectedPost(post)
                                                        }}>
                                                    <ShareAltOutlined
                                                        style={{width: 25, height: 'auto', color: 'grey'}}/></Button>
                                                <Button size='middle'
                                                        style={{
                                                            float: 'right',
                                                            background: post.pinned === 1 ? colors.gray.background : 'whitesmoke',
                                                            marginLeft: 10,
                                                            marginBottom: 10
                                                        }}
                                                        title='Post Reactions'
                                                        onClick={() => {
                                                            toggleEmojiPicker(post.post_id === undefined ? 0 : post.post_id)
                                                        }}>
                                                    <img src={'/user_images/Happy.png'}
                                                         style={{width: 20, height: 'auto'}}/>
                                                </Button>
                                                {showPicker[post.post_id === undefined ? 0 : post.post_id] &&
                                                <EmojiPicker
                                                    onSelect={(emoji) => handleEmojiSelect(emoji, post, true)}/>}
                                            </div>
                                            {openComments[post.post_id === undefined ? 0 : post.post_id] && (
                                                <div>
                                                    <Divider/>
                                                    <div className="createPost"
                                                         style={{background: post.pinned === 1 ? colors.gray.background : 'whitesmoke'}}>
                                                        <div className="createPost_header">
                                                            <RichEditor value={commentsChange}
                                                                        onChange={handleCommentsChange}
                                                                        atValues={tagOptions}/>
                                                            <SendOutlined style={{
                                                                marginTop: 30,
                                                                transform: 'rotateX(-30deg) rotateY(30deg)'
                                                            }} title='Reply'
                                                                          onClick={() => createPostCommentHandler(post)}/>
                                                        </div>
                                                    </div>
                                                    <Divider/>
                                                    <List
                                                        style={{marginLeft: 50}}
                                                        dataSource={comments}
                                                        renderItem={(comment) => (!editComment[comment.comment_id === undefined ? 0 : comment.comment_id] ?
                                                                <List.Item key={comment.comment_id}>
                                                                    <ul style={{width: '100%'}}>
                                                                        <Skeleton avatar title={false} active
                                                                                  loading={loading}>
                                                                            <List.Item.Meta
                                                                                avatar={
                                                                                    <div>{loggedInUser.id === comment.poster?.id && (
                                                                                        <div style={{
                                                                                            float: 'left',
                                                                                            marginRight: 10,
                                                                                            marginTop: 5
                                                                                        }}> {renderComments(comment, post)}</div>)}
                                                                                        <a href={`/${currentOrg.info.domain}/teams/${team_id}/${team_name}/users/${comment.poster?.id}/view`}
                                                                                           onMouseOver={(e: any) => e.target.style.color = 'black'}
                                                                                           onMouseOut={(e: any) => e.target.style.color = 'white'}>
                                                                                            <Avatar
                                                                                                src={comment.poster?.img === null ? null : comment.poster?.img}>{comment.poster?.img === null
                                                                                                ? `${comment.poster?.name?.charAt(0)}${comment.poster?.lastname?.charAt(0)}` : ''}</Avatar></a>
                                                                                    </div>}
                                                                                title={
                                                                                    <div style={{
                                                                                        display: "inline-block",
                                                                                        width: '100%'
                                                                                    }}>
                                                                                        <a href={`/${currentOrg.info.domain}/teams/${team_id}/${team_name}/users/${comment.poster?.id}/view`}
                                                                                           style={{
                                                                                               float: 'left',
                                                                                               color: 'grey',
                                                                                               textDecoration: 'none'
                                                                                           }}
                                                                                           onMouseOver={(e: any) => e.target.style.color = 'black'}
                                                                                           onMouseOut={(e: any) => e.target.style.color = 'grey'}> {comment.poster?.name} {comment.poster?.lastname} </a>
                                                                                        <div style={{
                                                                                            color: 'grey',
                                                                                            clear: 'left',
                                                                                            fontWeight: 'lighter'
                                                                                        }}>{comment.timestamp?.slice(0, 10)} {comment.timestamp?.slice(11, 16)}</div>
                                                                                    </div>
                                                                                }
                                                                                description={<div>
                                                                                    <div>
                                                                                        <div
                                                                                            dangerouslySetInnerHTML={{__html: showMoreComment[comment.comment_id === undefined ? 0 : comment.comment_id] ? !comment.comment_text.includes('<img') ? comment.comment_text : comment.comment_text.replace(/<img/g, `<img class="responsive-team-wall-image"`) : comment.comment_text.slice(0, maxCharacters)}}
                                                                                            style={{
                                                                                                overflowWrap: "break-word",
                                                                                                inlineSize: "95%",
                                                                                                color: '#3d3d3d'
                                                                                            }}/>
                                                                                        {comment.comment_text.length > maxCharacters && (
                                                                                            <Space
                                                                                                onClick={() => toggleShowMoreComment(comment.comment_id === undefined ? 0 : comment.comment_id)}
                                                                                                style={{
                                                                                                    color: 'green',
                                                                                                    cursor: 'pointer'
                                                                                                }}>
                                                                                                {showMoreComment[comment.comment_id === undefined ? 0 : comment.comment_id] ? 'Show Less' : 'Show More'}
                                                                                            </Space>)}
                                                                                    </div>
                                                                                    {Object.entries(comment.post_reactions || {}).map(([_, emoji]) => (
                                                                                        emoji.total_number > 0 ?
                                                                                            <button
                                                                                                key={emoji.reaction_icons}
                                                                                                style={{
                                                                                                    padding: 3,
                                                                                                    borderRadius: '8px',
                                                                                                    marginLeft: 5,
                                                                                                    fontSize: '12px',
                                                                                                    backgroundColor: '#E3E3E3',
                                                                                                    border: emoji.users.includes(loggedInUser.name + " " + loggedInUser.lastname) ? '1px solid green' : 'none',
                                                                                                    color: 'grey',
                                                                                                    cursor: 'pointer'
                                                                                                }}
                                                                                                onClick={() => handleCommentEmojiSelect(emoji, comment, false)}
                                                                                                title={emoji?.users.toString()}>
                                                                                                {emoji.reaction_icons}{emoji.total_number}
                                                                                            </button> : null))} </div>}
                                                                            />
                                                                        </Skeleton>
                                                                        <div>
                                                                            <Button size='middle'
                                                                                    style={{
                                                                                        float: 'right',
                                                                                        background: post.pinned === 1 ? colors.gray.background : 'whitesmoke',
                                                                                        marginLeft: 10,
                                                                                        marginRight: 10
                                                                                    }}
                                                                                    title='Comment Reactions'
                                                                                    onClick={() => {
                                                                                        toggleCommentEmojiPicker(comment.comment_id === undefined ? 0 : comment.comment_id)
                                                                                    }}>
                                                                                <img src={'/user_images/Happy.png'}
                                                                                     style={{
                                                                                         width: 20,
                                                                                         height: 'auto'
                                                                                     }}/>
                                                                            </Button>
                                                                            {showCommentPicker[comment.comment_id === undefined ? 0 : comment.comment_id] &&
                                                                            <EmojiPicker
                                                                                onSelect={(emoji) => handleCommentEmojiSelect(emoji, comment, true)}/>}
                                                                        </div>
                                                                    </ul>
                                                                </List.Item> :
                                                                <div>
                                                                    <div className="createPost"
                                                                         style={{background: post.pinned === 1 ? colors.gray.background : 'whitesmoke'}}>
                                                                        <Avatar
                                                                            src={post.poster?.img === null ? '/icons/default_user.png' : post.poster?.img}
                                                                            style={{
                                                                                marginTop: 50,
                                                                                width: '14',
                                                                                height: '14'
                                                                            }}/>
                                                                        <div className="createPost_header">
                                                                            <RichEditor value={comment.comment_text}
                                                                                        onChange={handleEditCommentsChange}
                                                                                        atValues={tagOptions}/>
                                                                            <SendOutlined style={{
                                                                                marginTop: 30,
                                                                                transform: 'rotateX(-30deg) rotateY(30deg)'
                                                                            }} title='Edit Reply'
                                                                                          onClick={() => updatePostCommentHandler(comment, post)}/>
                                                                            <CloseOutlined
                                                                                style={{marginTop: 35, marginLeft: 15}}
                                                                                title='Cancel'
                                                                                onClick={() => cancelEditHandler(comment)}/>
                                                                        </div>
                                                                    </div>
                                                                    <Divider/>
                                                                </div>
                                                        )}
                                                    />
                                                    {!hideMoreComment && (
                                                        <div style={{textAlign: 'center', marginTop: '20px'}}>
                                                            <Button onClick={() => loadMoreComments(post)}>More</Button>
                                                        </div>)}
                                                </div>)
                                            }
                                        </ul>
                                    </List.Item></Card>
                            )}
                        />
                        {!hideMore && !loading && (
                            <div style={{textAlign: 'center', marginTop: '20px'}}>
                                <Button onClick={loadMore}>More</Button>
                            </div>)}
                        <CreatePostPopup visible={visible} setVisible={setVisible}
                                         wallPostPage={posts} setWallPostPage={setPosts}
                                         wallPinnedPostPage={pinnedPosts} setWallPinnedPostPage={setPinnedPosts}
                                         selectedPost={selectedPost} setSelectedPost={setSelectedPost}
                                         readFlag={readFlag} setReadFlag={setReadFlag}/>
                        <SharePostPopup visible={shareVisible} setVisible={setShareVisible}
                                        selectedPost={selectedPost} setSelectedPost={setSelectedPost}/>
                    </Col>
                    <Col span={5} style={{width: 'auto', height: 'auto', textAlign: 'center'}}>
                    <Card title={<div style={{textAlign: 'center', marginTop: 50}}>Wall Activities</div>}
                              style={{
                                  width: 'auto',
                                  height: 'auto',
                                  marginLeft: 25,
                                  background: colors.gray.background,
                                  marginTop: 10
                              }}>
                            <div>
                                <Divider/>
                                <div style={{textAlign: 'center'}}>Posts: {postsCount}</div>
                                <Divider/>
                                <div style={{textAlign: 'center'}}>Pinned Posts: {pinnedPostsCount}</div>
                                <Divider/>
                                <div style={{textAlign: 'center'}}>Today's Posts: {todayPosts}</div>
                            </div>
                    </Card>
                    <Card title={<div style={{textAlign: 'center', marginTop: 50}}>Team Information</div>}
                          className='sticky_cards'
                              style={{
                                  width: 'auto',
                                  height: 'auto',
                                  marginLeft: 25,
                                  background: colors.gray.background,
                                  marginTop: 10
                              }} >
                                <Divider/>
                                <div style={{ textAlign: 'center' }}>
                                    <span style={{ fontWeight: 'bold', color: 'grey' }}>Members:</span> {membersCount}
                                </div>
                                <Divider/>
                                <><div style={{textAlign: 'center', fontWeight: 'bold', color: 'grey'}}>Team Owner:</div><br/>
                                <div style={{textAlign: 'center'}}>{owner}</div></>
                                <Divider/>
                                <><div style={{textAlign: 'center', fontWeight: 'bold', color: 'grey'}}>Team Description:</div><br/>
                                <div style={{}}>{currentTeam.info.description}</div></>
                            <Divider/>
                            <>
                            <div style={{textAlign: 'center', fontWeight: 'bold', color: 'grey'}}>Jobs:</div><br/>
                            {teamStats.length > 0 ? <VictoryPie
                                data={teamStats}
                                labelRadius={150} // Controls the distance of labels from the center
                                innerRadius={135} // Increase the inner radius to make slices slimmer
                                style={{
                                    data: {
                                    fill: ({ datum }) => datum.fill, // Use custom fill color
                                    },
                                    labels: {
                                    fontSize: 40,
                                    fill: '#fff',
                                    },
                                }}
                                labelComponent={
                                    <VictoryTooltip
                                        text={({ datum }) => datum.x}
                                        style={{ fontSize: 40, fill: 'grey' }}
                                        pointerLength={30} // Hide pointer arrow
                                        flyoutStyle={{ fill: 'none', stroke: 'none' }} // Hide flyout
                                    />
                                //  <VictoryLabel
                                //     text={({ datum }) => datum.x}
                                //     style={{ fontSize: 10, fill: 'grey' }}
                                //     transform={({ x, y }) => `rotate(45, ${x}, ${y})`}
                                //     verticalAnchor="middle"
                                //     textAnchor="middle"
                                //     dy={-20}
                                //   />
                                }
                                /> : 
                                <span>No Jobs Connected</span>}
                                </>
                        </Card>
                    </Col>
                </Row>
                <FloatButton.BackTop/>
            </PageContent>
        </Page>
    )
}

export default TeamWall;
